import {Component, Inject, OnInit} from '@angular/core';
import {Individu, User} from '../../../../model/user.model';
import {Structure} from '../../../../model/structure.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RoleService} from '../../../../services/role.service';
import {ETAT_STRUCTURE, TYPE_ROLE} from '../../../../model/enum.model';
import {UserService} from '../../../../services/user.service';
import {compare} from '../../../../utils/string.utils';

@Component({
  selector: 'app-update-structures-moderateur-dialog',
  templateUrl: './update-structures-moderateur-dialog.component.html',
  styleUrls: ['./update-structures-moderateur-dialog.component.scss']
})
export class UpdateStructuresModerateurDialogComponent implements OnInit {

  user: User;

  constructor(private roleService: RoleService,
              private userService: UserService,
              public dialogRef: MatDialogRef<UpdateStructuresModerateurDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();
  }

  structures: Structure[];

  ngOnInit(): void {
    this.roleService.getRolesByIndividuAndTypeRole(this.data.individu.individuId, TYPE_ROLE.MODERATEUR).subscribe(res => {
      this.structures = res
        .map(r => r.structure)
        .filter(s => s.etatStructure === ETAT_STRUCTURE.EMBRYON)
        .sort((a, b) => compare(a.identite.libelleLong, b.identite.libelleLong));
    });
  }

}

export interface DialogData {
  individu: Individu;
}
