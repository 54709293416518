import {Component, OnInit} from '@angular/core';
import {User} from '../../../../model/user.model';
import {UserService} from '../../../../services/user.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ParametreService} from '../../../../services/parametre.service';
import {Parametre} from '../../../../model/divers.model';
import {NOTIF_STYLE} from '../../../../constants/http.constant';
import {MatSnackBar} from '@angular/material/snack-bar';
import {X_TYPE_ENUM} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-parametre-dialog',
  templateUrl: './parametre-dialog.component.html',
  styleUrls: ['./parametre-dialog.component.scss']
})
export class ParametreDialogComponent implements OnInit {

  user: User;
  dataSource: Parametre[] = [];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];
  timeout = null;

  constructor(private userService: UserService,
              private parametreService: ParametreService,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ParametreDialogComponent>) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit(): void {
    this.parametreService.getAll().subscribe(parametres => {
      parametres.forEach(p => {
        if (p.valeurs) {
          p.input = p.multivaleur ? p.valeurs.toString() : p.valeurs[0];
        }
        this.dataSource.push(p);
      })
    });
  }

  onChange(parametre: Parametre) {
    if(parametre.type === 'Boolean'){
      parametre.input = !parametre.input;
    }

    this.timeout = setTimeout(() => {
    clearTimeout(this.timeout);
      parametre.valeurs = parametre.multivaleur ? parametre.input.split(',') : [parametre.input];

      this.parametreService.update(parametre).subscribe(() => {
        this.snackBar.open('Les valeurs des paramètres ont été mises à jour', 'X', {
          panelClass: NOTIF_STYLE.get(X_TYPE_ENUM.SUCCESS)
        });
      });
    }, 3000);
  }

}
