<h1 mat-dialog-title>Modification de la date de fermeture</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <form class="row justify-content-center" [formGroup]="form">
    <mat-form-field appearance="outline" class="col-lg-8">
      <mat-label>Date de fermeture</mat-label>
      <input formControlName="dateFermeture" [min]="data.selectedStructure.identite.dateOuverture" matInput
             [matDatepicker]="ferpicker" placeholder="Fermeture">
      <mat-datepicker-toggle matSuffix [for]="ferpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #ferpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateFermeture'].invalid && form.controls['dateFermeture'].touched">Format non valide</mat-error>
      <mat-error
        *ngIf="form.controls['dateFermeture'].hasError('matDatepickerMin')">La date de fermeture de la structure ne peut être antérieure à date d'ouverture</mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="modifier la date" (click)="updateDateFermeture()"
          [disabled]="form.invalid || form.pristine"><i class="fas fa-check fa-2x"></i>
  </button>

  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
