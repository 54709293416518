<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire d'annulation de la demande</h4>
    </mat-card-header>

    <mat-card-content>
      <p class="mt-4 alert-warning"
         *ngIf="[typeDemande.CREATION_STRUCT_MAT, typeDemande.CREATION_STRUCT_ORGA_EXT, typeDemande.CREATION_STRUCT_ORGA_INTERNE].includes(demande?.typeDemande.typeDemandeId)">
        Souhaitez-vous réellement annuler votre demande de création ? Si vous confirmez votre choix toutes les données
        relatives à la structure nouvellement créée seront supprimées.
      </p>

      <mat-form-field class="col-lg-12" appearance="outline">
        <mat-label>Motif d'annulation</mat-label>
        <textarea matInput [(ngModel)]="motif" placeholder="Saisie du motif d'annulation de la demande" [maxLength]="200"></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary" matTooltip="demande d'annulation"
                (click)="cancel()"
                [disabled]="!motif || motif.trim() === ''">{{'annuler' | uppercase }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>


