import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {Role} from '../model/user.model';
import * as moment from 'moment';

@Directive({
  selector: '[appHighlightResponsable]'
})
export class HighlightResponsableDirective implements OnChanges {

  @Input() resp: Role;

  constructor(private el: ElementRef) {
  }

  ngOnChanges() {
    this.highlight(this.resp);
  }

  private highlight(resp: Role) {
    const now = new Date();
    if (this.el.nativeElement.style && resp) {
      if (moment(resp.dateDebut).isBefore(now) && (!resp.dateFin || moment(resp.dateFin).isAfter(now))) {
        this.el.nativeElement.style.color = '#03A9F4';
      }
    }
  }
}
