<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de validation de la demande</h4>
    </mat-card-header>

    <mat-card-content class="row">
      <!--MODIF IDENTITE-->
      <form [formGroup]="formIdent" *ngIf="demande.typeDemande.typeDemandeId === typeDemande.MODIFICATION_IDENT">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de début du changement d'identité</mat-label>
          <input matInput formControlName="dateDebut" [min]="demande.lien.structure.identite.dateDebut"
                 [matDatepicker]="debpicker" placeholder="Date de début" required>
          <mat-datepicker-toggle matSuffix [for]="debpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #debpicker></mat-datepicker>
          <mat-error *ngIf="formIdent.controls['dateDebut'].hasError('matDatepickerMin') && formIdent.controls['dateDebut'].touched">
            La date de début de la nouvelle identité ne peut être antérieure à date de début de l'identité actuelle : {{demande.lien.structure.identite.dateDebut | date:'dd/mm/yyyy'}}
          </mat-error>
        </mat-form-field>
      </form>

      <!--FERMETURE D'UNE STRUCTURE-->
      <form [formGroup]="formFerm" *ngIf="demande.typeDemande.typeDemandeId === typeDemande.FERMETURE_STRUCTURE">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de fermeture</mat-label>
          <input matInput formControlName="dateFermeture" [min]="demande.lien.structure.identite.dateOuverture"
                 [matDatepicker]="fermpicker" placeholder="Date de fermeture" required>
          <mat-datepicker-toggle matSuffix [for]="fermpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #fermpicker></mat-datepicker>
          <mat-error *ngIf="formFerm.controls['dateFermeture'].invalid">Format non valide</mat-error>
          <mat-error
            *ngIf="formFerm.controls['dateFermeture'].hasError('matDatepickerMin')">
            La date de fermeture de la structure ne peut être antérieure à date d'ouverture
          </mat-error>
        </mat-form-field>
      </form>

      <!--SUPPRESSION LIEN-->
      <form [formGroup]="formSupprLien" *ngIf="demande.typeDemande.typeDemandeId === typeDemande.SUPPRESSION_LIEN">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de fin</mat-label>
          <input matInput formControlName="dateFin" [min]="lienCible.dateDebut"
                 [matDatepicker]="finpicker" placeholder="Date de fin" required>
          <mat-datepicker-toggle matSuffix [for]="finpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #finpicker></mat-datepicker>
          <mat-error
            *ngIf="formSupprLien.controls['dateFin'].hasError('matDatepickerMin')">La date de fin du lien ne
            peut être antérieure à sa date de début
          </mat-error>
        </mat-form-field>
      </form>

      <!--SI SYNCHRONISATION-->
      <div class="col-lg-12"
           *ngIf="[typeDemande.AJOUT_LIEN_HIERARCHIQUE,
                   typeDemande.MODIFICATION_LIEN_HIERARCHIQUE,
                   typeDemande.SUPPRESSION_LIEN,
                   typeDemande.FERMETURE_STRUCTURE].includes(demande.typeDemande.typeDemandeId)
                   && demande.lien.structure.useRefAq">
        <b>Synchroniser la demande dans le référentiel aquitain</b>
        <mat-checkbox class="ml-2" (change)="useRefAq = $event.checked" [checked]="useRefAq">
        </mat-checkbox>

        <app-referentiel-aquitain-form class="col-lg-6 mt-2"
                                       [structure]="lienCible.structure"
                                       *ngIf="useRefAq"></app-referentiel-aquitain-form>
      </div>

      <mat-form-field class="col-lg-12 mt-4" appearance="outline">
        <mat-label>Commentaire</mat-label>
        <textarea matInput [(ngModel)]="motif"
                  placeholder="Saisie d'un commentaire facultatif à destination du gestionnaire"
                  [maxLength]="200"></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 ml-2" mat-raised-button color="primary" matTooltip="Demande de validation"
                (click)="validate()"
                [disabled]="isButtonDisabled()">
          {{'valider la demande' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
