<div class="row justify-content-center">
  <mat-tab-group class="col-lg-12">
    <!--HISTORIQUE DE LA DEMANDE-->
    <mat-tab label="Consulter l'historique de la demande">
      <div class="row justify-content-center">
        <app-demande-historique class="col-lg-6 md-8 sm-12"[selectedDemande]="demande"></app-demande-historique>
      </div>
    </mat-tab>

    <!-- EDITER DETAILS DE LA STRUCTURE - CONTEXTE STRUCTURE SEULEMENT -->
    <mat-tab label="Editer les informations de la structure"
             *ngIf="[typeDemande.CREATION_STRUCT_ORGA_INTERNE,typeDemande.CREATION_STRUCT_MAT,typeDemande.CREATION_STRUCT_ORGA_EXT].includes(demande.typeDemande.typeDemandeId)
           && ((user.individu.isAdmin && [etatDemande.NOUVEAU.valueOf(), etatDemande.A_TRAITER.valueOf(), etatDemande.PRISE_EN_CHARGE_NIV_1.valueOf(), etatDemande.A_VALIDER.valueOf()].includes(demande.etatDemande))
              || (
                (user.individu.isResponsable || user.individu.isGestionnaire)
                && [etatDemande.ATTENTE_MODIFICATION.valueOf()].includes(demande.etatDemande)
              )
           )">

      <app-modification-structure-organisationnelle class="col-12"
                                                    [demande]="demande"
                                                    [structurePorteuse]="structurePorteuse"
                                                    (actionEmitter)="actionEmitter.emit()"
                                                    *ngIf="demande.lien.structure.catStructure === catStructure.ORGA"></app-modification-structure-organisationnelle>

      <app-modification-structure-matricielle class="col-12"
                                              [demande]="demande"
                                              [structurePorteuse]="structurePorteuse"
                                              (actionEmitter)="actionEmitter.emit()"
                                              *ngIf="demande.lien.structure.catStructure === catStructure.MAT"></app-modification-structure-matricielle>
    </mat-tab>

    <!-- CONSULTER DETAILS DE LA STRUCTURE - CONTEXTE STRUCTURE SEULEMENT -->
    <mat-tab label="Consulter les détails de la structure"
             *ngIf="!(
              [typeDemande.CREATION_STRUCT_ORGA_INTERNE,typeDemande.CREATION_STRUCT_MAT,typeDemande.CREATION_STRUCT_ORGA_EXT].includes(demande.typeDemande.typeDemandeId)
              && (
                (user.individu.isAdmin && [etatDemande.NOUVEAU.valueOf(), etatDemande.A_TRAITER.valueOf(), etatDemande.PRISE_EN_CHARGE_NIV_1.valueOf(), etatDemande.A_VALIDER.valueOf()].includes(demande.etatDemande))
                || (
                  (user.individu.isResponsable || user.individu.isGestionnaire)
                  && [etatDemande.ATTENTE_MODIFICATION.valueOf()].includes(demande.etatDemande)
                )
              )
           )">

      <app-demande-struct-orga-form class="col-12" [demande]="demande"
                                    [structurePorteuse]="structurePorteuse"
                                    [readonly]="true"
                                    *ngIf="demande.lien.structure.catStructure === catStructure.ORGA"></app-demande-struct-orga-form>

      <app-demande-struct-mat-form class="col-12" [demande]="demande"
                                   [structurePorteuse]="structurePorteuse"
                                   [readonly]="true"
                                   *ngIf="demande.lien.structure.catStructure === catStructure.MAT"></app-demande-struct-mat-form>
    </mat-tab>

    <!-- AJOUTER UN MODERATEUR - CONTEXTE STRUCTURE ORGA INTERNE SEULEMENT -->
    <mat-tab label="Remplacer le modérateur pour la validation finale"
             *ngIf="[1].includes(demande.typeDemande.typeDemandeId)
           && user.individu.isAdmin
           && [etatDemande.A_VALIDER.valueOf()].includes(demande.etatDemande)">

      <app-ajout-moderateur class="col-lg-6 md-8 sm-12" [structure]="demande.lien.structure"
                            (actionEmitter)="actionEmitter.emit()"></app-ajout-moderateur>
    </mat-tab>

    <!-- CONSULTER VERSION IDENTITE MODIFIEE - CONTEXTE IDENTITE_STRUCTURE SEULEMENT -->
    <mat-tab label="Consulter la version modifiée à valider"
             *ngIf="demande.typeDemande.contexte === contexteDemande.IDENTITE
           && demande.etatDemande !==etatDemande.VALIDEE">
      <div class="row justify-content-center">
        <app-identite-form class="col-lg-4 md-8 sm-12" [identiteStructure]="identiteStructure"
                           [readonly]="true"
                           [categorie]="demande.lien.structure.catStructure"></app-identite-form>
      </div>
    </mat-tab>

    <!-- MODIFIER LE RESPONSABLE - CONTEXTE STRUCTURE SEULEMENT -->
    <mat-tab label="Modifier le responsable"
             *ngIf="demande.typeDemande.typeDemandeId === typeDemande.CREATION_STRUCT_ORGA_INTERNE
           && user.individu.isAdmin
           && [etatDemande.NOUVEAU.valueOf(), etatDemande.A_TRAITER.valueOf(), etatDemande.PRISE_EN_CHARGE_NIV_1.valueOf()].includes(demande.etatDemande)">
      <div class="row justify-content-center">
        <app-ajout-responsable class="col-lg-6 md-8 sm-12"
                               [readonly]="!user.individu.isAdmin"
                               [structure]="demande.lien.structure"
                               (actionEmitter)="init()">
        </app-ajout-responsable>
      </div>
    </mat-tab>

    <!-- DEMANDE DE MODIFICATION - CONTEXTE STRUCTURE SEULEMENT -->
    <mat-tab label="Demander une modification de la structure"
             *ngIf="[typeDemande.CREATION_STRUCT_ORGA_INTERNE,typeDemande.CREATION_STRUCT_ORGA_INTERNE,typeDemande.CREATION_STRUCT_MAT].includes(demande.typeDemande.typeDemandeId)
           && user.individu.isAdmin
           && [etatDemande.NOUVEAU.valueOf(), etatDemande.A_TRAITER.valueOf(), etatDemande.PRISE_EN_CHARGE_NIV_1.valueOf()].includes(demande.etatDemande)">
      <div class="row justify-content-center">
        <app-demande-modification class="col-lg-12" [demande]="demande" (actionEmitter)="actionEmitter.emit()"></app-demande-modification>
      </div>
    </mat-tab>

    <!-- CONSULTER LA VERSION COURANTE - CONTEXTE IDENTITE_STRUCTURE SEULEMENT -->
    <mat-tab label="Consulter la version courante"
             *ngIf="demande.typeDemande.contexte === contexteDemande.IDENTITE">
      <div class="row justify-content-center">
        <app-identite-form class="col-lg-6 md-8 sm-12" [identiteStructure]="demande.lien.structure.identite"
                           [categorie]="demande.lien.structure.catStructure"
                           [readonly]="true"></app-identite-form>
      </div>
    </mat-tab>

    <!-- CONSULTER LA DEMANDE DE MODIFICATION DU RESPONSABLE - CONTEXTE ROLE SEULEMENT -->
    <mat-tab label="Consulter le nouveau responsable"
             *ngIf="demande.typeDemande.contexte === contexteDemande.ROLE">
      <div class="row justify-content-center">
        <app-ajout-responsable class="col-lg-6 md-8 sm-12" [structure]="demande.lien.structure"
                               [readonly]="true"
                               (actionEmitter)="init()"></app-ajout-responsable>
      </div>
    </mat-tab>

    <!-- CONSULTER LA DEMANDE D'AJOUT/SUPPRESSION D'UN LIEN - CONTEXTE LIEN SEULEMENT -->
    <mat-tab [label]="'Consulter le lien concerné par la demande'"
             *ngIf="demande.typeDemande.contexte === contexteDemande.LIEN">
      <div class="row justify-content-center">
        <mat-card class="col-lg-6 md-8 sm-12 mt-4 mb-4">
          <mat-card-content>
            <app-lien-form [lienContexte]="lienContexte"></app-lien-form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>

    <!-- AJOUTER UNE INFORMATION COMPLEMENTAIRE -->
    <mat-tab label="Ajouter une information complémentaire"
             *ngIf="![etatDemande.ANNULEE.valueOf(), etatDemande.VALIDEE.valueOf(), etatDemande.REFUSEE.valueOf()].includes(demande.etatDemande)">
      <div class="row justify-content-center">
        <app-ajout-informations-complementaires class="col-lg-6 md-8 sm-12" [demande]="demande"
                                                (actionEmitter)="init()"></app-ajout-informations-complementaires>
      </div>
    </mat-tab>

    <!-- DEMANDER UNE INFORMATION COMPLEMENTAIRE -->
    <mat-tab label="Demander une information complémentaire"
             *ngIf="user.individu.isAdmin && [etatDemande.NOUVEAU.valueOf(), etatDemande.A_TRAITER.valueOf(), etatDemande.PRISE_EN_CHARGE_NIV_1.valueOf()].includes(demande.etatDemande)">
      <div class="row justify-content-center">
        <app-demande-informations-complementaires class="col-lg-6 md-8 sm-12" [demande]="demande"
                                                  (actionEmitter)="actionEmitter.emit()"></app-demande-informations-complementaires>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
