import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {Subject} from 'rxjs';
import {LoaderService} from './services/loader.service';
import {ConfigAssetLoaderService, Configuration} from './configAssetLoader.service';
import pack from './../../package.json';
import {AUTHORIZATION, NOTIF_STYLE} from './constants/http.constant';
import {Router} from '@angular/router';
import {UserService} from './services/user.service';
import {User} from './model/user.model';
import {X_TYPE_ENUM} from './constants/enum.constant';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ParametreService} from './services/parametre.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {

  version: string;

  user: User;

  loading: Subject<boolean> = new Subject<boolean>();
  title: string;
  displaySidebar: boolean;
  conf: Configuration;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private loaderService: LoaderService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private configAssetLoaderService: ConfigAssetLoaderService,
              private parametreService: ParametreService,
              private snackBar: MatSnackBar,
              private router: Router) {
    this.loading = this.loaderService.loading;
    this.title = 'Référentiel des structures UB';
    this.displaySidebar = false;
    this.configAssetLoaderService.loadConfigurations().subscribe(res => {
      this.conf = res;
    });
  }

  ngOnInit() {
    const loginAs = this.userService.getLoginAs() as User;
    this.version = pack.version;
    const hasTicket = window.location.href.match(/(.*)[&?]ticket=([^&?]*)$/);

    if (hasTicket) {
      const [, , ticket] = hasTicket;

      this.authenticationService.login(ticket).subscribe(res => {
        this.user = res.body;
        this.userService.setUser(this.user);


        if (this.user?.individu) {
          this.authenticationService.setJwt(res.headers.get(AUTHORIZATION));
          this.router.navigate(['dashboard']);

          this.loadParamsWarning();

        } else {
          this.router.navigate(['error-authenticationService']);
        }
      });

    } else if (!this.authenticationService.getJwt()) {
      this.authenticationService.redirectToCasLogin();

    } else {
      this.userService.getUpdatedUser().subscribe(res => {
        this.userService.setUser(res);

        this.user = res;
        if (loginAs) {
          this.user = this.userService.getLoginAs();
        }

        this.userService.setCurrentUser(this.user);
      });
    }
  }

  onDisplaySideBarEvent() {
    this.displaySidebar = !this.displaySidebar;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private loadParamsWarning():void{
    if (this.user.individu.isAdmin) {
      this.parametreService.getAll().subscribe(res => {
        let msg:string='';
        const emailEnabled = res.filter(r => r.cle === 'email_enabled')[0];
        if(emailEnabled.valeurs[0] === 'true'){
          msg += "L'envoi des mails est activé";
          const emailRedirection = res.filter(r => r.cle === 'email_redirection')[0];
          if(emailRedirection.valeurs?.length > 0 && emailRedirection.valeurs[0] !== ""){
            msg += " avec une redirection des messages"
          }else{
            msg += " sans redirection des messages"
          }
          const emailFiltrage = res.filter(r => r.cle === 'email_filtrage')[0];
          if(emailFiltrage.valeurs?.length > 0 && emailFiltrage.valeurs[0] !== ""){
            msg += " et avec un filtrage d'adresses mail."
          }
        }else{
          msg = "L'envoi des mails inactif."
        }

        this.snackBar.open(msg, 'X', {panelClass: NOTIF_STYLE.get(X_TYPE_ENUM.WARNING)});
      });
    }
  }

}
