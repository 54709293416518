<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de réactivation de la structure {{structure?.identite.libelleLong}}</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Structure porteuse</mat-label>
          <mat-select formControlName="structurePorteuse" placeholder="structure porteuse" required>
            <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of structures"
                        [value]="struct.structureId">
              {{struct.identite.libelleLong}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Gestionnaires à désactiver</mat-label>
          <mat-select formControlName="privilegesASuppr" placeholder="Gestionnaires à désactiver" multiple>
            <mat-option *ngFor="let gest of gestionnaires" [value]="gest">
              {{gest.individu.nom}} {{gest.individu.prenom}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la réactivation</mat-label>
          <textarea matInput formControlName="motif"
                    placeholder="Saisie du motif de la réactivation de la structure"
                    [maxLength]="200"
                    required></textarea>
        </mat-form-field>
      </form>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary"
                (click)="reactivate()" [disabled]="form.invalid">{{'reactiver la structure' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
