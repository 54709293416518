import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {CompteAppli, LdapAccount} from '../../../model/user.model';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {MatDialog} from '@angular/material/dialog';
import {SearchLdapDialogComponent} from '../../../layout/dialog/ldap/search-ldap-dialog/search-ldap-dialog.component';
import {ConfirmDialogComponent} from '../../../layout/dialog/divers/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompteAppliService} from '../../../services/compte-appli.service';
import {AddCompteAppliDialogComponent} from '../../../layout/dialog/compte-application/add-compte-appli-dialog/add-compte-appli-dialog.component';

@Component({
  selector: 'app-administration-comptes-applicatifs',
  templateUrl: './administration-comptes-applicatifs.component.html',
  styleUrls: ['./administration-comptes-applicatifs.component.scss']
})
export class AdministrationComptesApplicatifsComponent implements OnInit {

  comptesAppli: CompteAppli[];

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private snackBar: MatSnackBar,
              private compteAppliService: CompteAppliService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.init();
  }

  update() {
    this.loaderService.start();
    this.compteAppliService.updateMulti(this.comptesAppli).subscribe(() => this.loaderService.stop());
  }

  add() {
    const dialogRef = this.dialog.open(AddCompteAppliDialogComponent, {
      minWidth: 400,
      data:{}
    });
    dialogRef.afterClosed().toPromise().then(res => {
      if (res) {
        this.init();
      }
    });
  }

  isInvalid(): boolean {
    return this.comptesAppli.filter(t => t.hasError).length > 0;
  }

  cancel() {
    this.init();
  }

  delete(compteAppli: CompteAppli) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Suppression du compte applicatif',
        question: 'Etes-vous sur de vouloir supprimer le compte applicatif ' + compteAppli.login
      }
    });
    dialogRef.afterClosed().toPromise().then(res => {
      if (res.ok) {
        this.compteAppliService.deleteCompteAppli(compteAppli.compteAppliId).subscribe(() => this.init());
      }
    });
  }

  searchResponsable(compteAppli: CompteAppli) {
    const dialogRef = this.dialog.open(SearchLdapDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Ajout d\'un responsable d\'application'
      }
    });
    dialogRef.afterClosed().toPromise().then((ldapAccount: LdapAccount) => {
      if (ldapAccount) {
        compteAppli.ldapAccount = ldapAccount;
        compteAppli.responsableApplication = {nom: ldapAccount.nom, prenom: ldapAccount.prenom};
      }
    });
  }

  generateNewPassword(compteAppli: CompteAppli) {
    this.loaderService.start();
    this.compteAppliService.generateNewPassword(compteAppli.compteAppliId).subscribe(() => this.loaderService.stop());
  }

  onLoginChanged(compteAppli: CompteAppli) {
    compteAppli.hasError = false;

    if (!compteAppli.login || compteAppli.login === '') {
      this.snackBar.open(
        'Le login du compte est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      compteAppli.hasError = true;

    } else if (doesAlreadyExist(this.comptesAppli, compteAppli.login, 'login')) {
      this.snackBar.open(
        'Le login choisi pour ce compte existe déjà pour un autre compte applicatif. Votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      compteAppli.hasError = true;
    }
  }

  onSuperUserChanged(compteAppli: CompteAppli) {
    compteAppli.superUser = !compteAppli.superUser;
  }

  onIsValidChanged(compteAppli: CompteAppli) {
    compteAppli.isValid = !compteAppli.isValid;
  }

  init() {
    this.compteAppliService.getAllComptesAppli().subscribe(res => this.comptesAppli = res);
  }

}
