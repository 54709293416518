import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IdentiteStructure} from '../model/structure.model';

@Injectable()
export class IdentiteStructureService {

  constructor(private http: HttpClient) {
  }

  public getLastIdentiteByStructureId(structureId: number): Observable<IdentiteStructure> {
    return this.http.get<IdentiteStructure>('/identite-structure/last-identity/' + structureId);
  }

  public getIdentiteByIdentStructureId(identStructureId: number): Observable<IdentiteStructure> {
    return this.http.get<IdentiteStructure>('/identite-structure/' + identStructureId);
  }

  public update(identiteStructure: IdentiteStructure, structureId: number): Observable<IdentiteStructure> {
    return this.http.put<IdentiteStructure>('/identite-structure/update/' + structureId, identiteStructure);
  }

  public doesLibelleAlreadyExist(structureId: number, libelle: string): Observable<void> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('libelle', libelle);
    return this.http.get<void>('/identite-structure/structure/' + structureId + '/libelle-exists', {params: queryParams});
  }
}
