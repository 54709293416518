<div class="row justify-content-center mt-4">
  <h1 class="title">Consultation des demandes</h1>
</div>

<div class="row justify-content-center mt-4">
  <div class="col-lg-4">
    <mat-accordion>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>{{'Filtrer les demandes'}}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="outline">
          <mat-label>Demandes</mat-label>
          <mat-select [formControl]="formControl" (selectionChange)="onGroupeDemandesChange($event.value)">
            <mat-option [value]="demandeEnum.DEMANDES_EN_COURS">{{'demandes en cours' | uppercase}}</mat-option>
            <mat-option [value]="demandeEnum.DEMANDES_EN_ATTENTE"
                        *ngIf="user.individu.isAdmin">{{'demandes en attente' | uppercase}}</mat-option>
            <mat-option [value]="demandeEnum.DEMANDES_A_TRAITER">{{'demandes à traiter' | uppercase}}</mat-option>
            <mat-option [value]="demandeEnum.DEMANDES_TRAITEES">{{'demandes traitées' | uppercase}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="row">
          <h5 class="col-12">Formulaire de recherche</h5>
          <app-recherche-demande-form (searchEmitter)="search($event)"></app-recherche-demande-form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


<div class="row justify-content-center mt-4">
  <mat-card class="col-lg-12" *ngIf="demandes?.length === 0">
    <h4 class="text-center">Aucune demande à afficher</h4>
  </mat-card>
</div>

<div class="row justify-content-center buffer" *ngIf="demandes?.length > 0">
  <div class="col-lg-12">
    <app-demande [title]="getTitle()"
                 [demandes]="demandes"
                 [first]="first"
                 [rows]="rows"
                 [totalRecords]="totalRecords"
                 [displayActionGestionnaire]="true"
                 [displayActionAdministrateur]="true"
                 (actionEmitter)="onAction()"
                 (PageEmitter)="onChangePage($event)"></app-demande>
  </div>
</div>
