<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de demande de modification</h4>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field class="col-lg-12" appearance="outline">
        <mat-label>Motif de la demande</mat-label>
        <textarea matInput [(ngModel)]="motif" [maxLength]="200"
                  placeholder="Saisie d'un commentaire à destination du gestionnaire"></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary" matTooltip="Demande de modification"
                [disabled]="!motif || motif.trim() === ''"
                (click)="validate()">{{'modifier la demande' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

