<ngx-loading [show]="loading | async"></ngx-loading>

<div *ngIf="user">
  <app-pagetop (menuButtonEmitter)="onDisplaySideBarEvent()"></app-pagetop>

  <p-sidebar [(visible)]="displaySidebar" [baseZIndex]="10000">
    <app-sidebar [numVersion]="version"></app-sidebar>
  </p-sidebar>


  <main id="main" class="col-lg-12 content">
      <router-outlet></router-outlet>
  </main>

  <app-footer [numVersion]="version"></app-footer>
</div>
