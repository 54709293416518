export enum TYPE_ROLE {
  RESPONSABLE = 'RESPONSABLE',
  ADMINISTRATEUR = 'ADMINISTRATEUR',
  MODERATEUR = 'MODERATEUR',
  GESTIONNAIRE = 'GESTIONNAIRE'
}

export enum ETAT_VALID {
  VALIDE = 'VALIDE',
  A_VALIDER = 'A_VALIDER',
  INVALIDE = 'INVALIDE',
}

export enum ETAT_DEMANDE {
  NOUVEAU = 'NOUVEAU',
  ATTENTE_MODIFICATION = 'ATTENTE_MODIFICATION',
  A_TRAITER = 'A_TRAITER',
  A_VALIDER = 'A_VALIDER',
  PRISE_EN_CHARGE_NIV_1 = 'PRISE_EN_CHARGE_NIV_1',
  PRISE_EN_CHARGE_NIV_2 = 'PRISE_EN_CHARGE_NIV_2',
  VALIDEE = 'VALIDEE',
  REFUSEE = 'REFUSEE',
  ANNULEE = 'ANNULEE',
}

export enum CONTEXTE_DEMANDE {
  STRUCTURE = 'STRUCTURE',
  IDENTITE = 'IDENTITE_STRUCTURE',
  LIEN = 'LIEN',
  ROLE = 'ROLE'
}

export enum TYPE_DEMANDE {
  CREATION_STRUCT_ORGA_INTERNE = 1,
  CREATION_STRUCT_ORGA_EXT = 2,
  CREATION_STRUCT_MAT = 3,
  MODIFICATION_IDENT = 4,
  MODIFICATION_RESPONSABLE = 5,
  SUPPRESSION_RESPONSABLE = 6,
  AJOUT_LIEN_HIERARCHIQUE = 7,
  AJOUT_LIEN_NON_HIERARCHIQUE = 8,
  MODIFICATION_LIEN_HIERARCHIQUE = 9,
  SUPPRESSION_LIEN = 10,
  FERMETURE_STRUCTURE = 11,
}

export enum TYPE_CREATION {
  NEW = 'NEW',
  FUSION = 'FUSION',
  SCISSION = 'SCISSION',
  SUCCESSION = 'SUCCESSION'
}

export enum DEMANDE {
  DEMANDES_EN_COURS = 'demandes-en-cours',
  DEMANDES_A_TRAITER = 'demandes-a-traiter',
  DEMANDES_EN_ATTENTE = 'demandes-en-attente',
  DEMANDES_TRAITEES = 'demandes-traitees',
  MES_DEMANDES_EN_COURS = 'mes-demandes-en-cours',
  MES_DEMANDES_NOUVELLES = 'mes-nouvelles-demandes',
  MES_DEMANDES_TRAITEES = 'mes-demandes-traitees',
}

export enum CAT_STRUCTURE {
  ORGA = 'ORGANISATIONNELLE',
  MAT = 'MATRICIELLE',
}

export enum ETAT_STRUCTURE {
  EMBRYON = 'EMBRYON',
  NOUVEAU = 'NOUVEAU',
  ACTIF = 'ACTIF',
  A_SUPPRIMER = 'A_SUPPRIMER',
  A_ARCHIVER = 'A_ARCHIVER',
  SUPPRIME = 'SUPPRIME',
  ARCHIVE = 'ARCHIVE'
}

export enum TYPE_TODO {
  CREATION_STRUCTURE = 'CREATION_STRUCTURE',
  MODIFICATION_IDENTITE = 'MODIFICATION_IDENTITE',
  MISE_A_JOUR_RESPONSABLE = 'MISE_A_JOUR_RESPONSABLE',
  FERMETURE_STRUCTURE = 'FERMETURE_STRUCTURE',
}

export enum TYPE_SYNC {
  CREATION_STRUCTURE = 'CREATION_STRUCTURE',
  FERMETURE_STRUCTURE = 'FERMETURE_STRUCTURE',
  CREATION_LIEN = 'CREATION_LIEN',
  MISE_A_JOUR_LIEN = 'MISE_A_JOUR_LIEN',
  SUPPRESSION_LIEN = 'SUPPRESSION_LIEN',
  CREATION_CODE = 'CREATION_CODE',
  REACTIVATION_STRUCTURE = 'REACTIVATION_STRUCTURE'
}

export enum ETAT_SYNC {
  OK = 'OK',
  NOK = 'NOK'
}

export enum ETAT_TODO {
  NEW = 'NEW',
  ASYNC = 'ASYNC',
  DONE = 'DONE',
  PENDING = 'PENDING',
  ACKED = 'ACKED',
}
