import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TutelleService} from '../../../../services/tutelle.service';
import {CodeService} from '../../../../services/code.service';
import {DomaineService} from '../../../../services/domaine.service';
import {Structure} from '../../../../model/structure.model';
import {FormControl} from '@angular/forms';
import {LoaderService} from '../../../../services/loader.service';
import {TypeCodeService} from '../../../../services/type-code.service';
import {Domaine, Tutelle, TypeCode} from '../../../../model/nomenclature.model';
import {CARACTERISATION} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-add-caracteriation-dialog',
  templateUrl: './add-caracterisation-dialog.component.html',
  styleUrls: ['./add-caracterisation-dialog.component.scss']
})
export class AddCaracterisationDialogComponent implements OnInit {

  title: string;
  tutelles: Tutelle[];
  domaines: Domaine[];
  typeCodes: TypeCode[];
  selectedTutelle: FormControl;
  selectedDomaines: FormControl;
  selectedTypeCode: FormControl;
  codeValue: FormControl;

  caracterisation = CARACTERISATION;

  constructor(
    private loaderService: LoaderService,
    private tutelleService: TutelleService,
    private domaineService: DomaineService,
    private codeService: CodeService,
    private typeCodeService: TypeCodeService,
    public dialogRef: MatDialogRef<AddCaracterisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.selectedDomaines = new FormControl([]);
    this.selectedTutelle = new FormControl(null);
    this.selectedTypeCode = new FormControl(null);
    this.codeValue = new FormControl(null);
  }

  ngOnInit() {
    if (this.data.type === CARACTERISATION.TUTELLE) {
      this.title = 'Ajout d\'une nouvelle tutelle';
      this.tutelleService.getTutellesToAssociate(this.data.structure.structureId).subscribe(res => this.tutelles = res);
    } else if (this.data.type === CARACTERISATION.DOMAINE) {
      this.title = 'Ajout d\'un nouveau domaine';
      this.domaineService.getDomainesToAssociate(this.data.structure.structureId).subscribe(res => this.domaines = res);
    } else if (this.data.type === CARACTERISATION.CODE) {
      this.title = 'Ajout d\'un nouveau code';
      this.typeCodeService.getTypeCodesToAssociate(this.data.structure.structureId).subscribe(res => this.typeCodes = res);
    }
  }

  onAdd() {
    this.loaderService.start();
    if (this.data.type === CARACTERISATION.TUTELLE) {
      this.tutelleService.associate(this.selectedTutelle.value, this.data.structure.structureId).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.type === CARACTERISATION.DOMAINE) {
      this.domaineService.associate(this.selectedDomaines.value, this.data.structure.structureId).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.type === CARACTERISATION.CODE) {
      this.codeService.create({
        codeId: null,
        typeCode: this.selectedTypeCode.value,
        codeValue: this.codeValue.value,
        structureId: this.data.structure.structureId,
        dateDebut: new Date(),
        dateFin: null,
        isValid: true
      }).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onDomainesSelected() {
    if (this.selectedDomaines.value.length > 3) {
      this.selectedDomaines.setErrors({'selection-domaines-invalid': null});
    }
  }

  isDisabled(): boolean {
    return (this.data.type === CARACTERISATION.TUTELLE && this.selectedTutelle.invalid)
      || (this.data.type === CARACTERISATION.DOMAINE && (this.selectedDomaines.invalid || this.selectedDomaines.value.length === 0))
      || (this.data.type === CARACTERISATION.CODE && (this.selectedTypeCode.invalid || this.codeValue.invalid));
  }
}

export interface DialogData {
  type: CARACTERISATION;
  structure: Structure;
}
