import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {LoggedEntity} from '../model/securite.model';
import * as moment from 'moment/moment';

@Directive({
  selector: '[appHighlightActiveConnexion]'
})
export class HighlightActiveConnexionDirective implements OnChanges {

  @Input() loggedEntity: LoggedEntity<any>;

  constructor(private el: ElementRef) {
  }

  ngOnChanges() {
    this.highlight(this.loggedEntity);
  }

  private highlight(loggedEntity: LoggedEntity<any>) {
    if (this.el.nativeElement.style && loggedEntity) {
      if (!moment(new Date(loggedEntity.token.exp * 1000)).isBefore(new Date())) {
        this.el.nativeElement.style.backgroundColor = '#90caf9';
      }
    }
  }
}
