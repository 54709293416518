<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de suppression du lien</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <!--DATE DE FIN -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Date de fin</mat-label>
          <input formControlName="dateFin" matInput [matDatepicker]="finpicker" placeholder="Date de fin">
          <mat-datepicker-toggle matSuffix [for]="finpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #finpicker></mat-datepicker>
          <mat-error
            *ngIf="form.controls['dateFin'].invalid && form.controls['dateFin'].touched">Format non valide
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la demande</mat-label>
          <textarea matInput
                    formControlName="motif"
                    placeholder="Motif de la demande"
                    [maxLength]="200"
                    required></textarea>
          <mat-error *ngIf="form.controls['motif'].invalid && form.controls['motif'].touched">Valeur requise
          </mat-error>
        </mat-form-field>

        <div class="row justify-content-center">
          <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary"
                  matTooltip="Demande de suppression du lien"
                  (click)="removeLien()" [disabled]="form.invalid">
            {{'envoyer une demande de suppression' | uppercase}}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
