<h1 mat-dialog-title>{{data.name}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p-table
    [value]="data.descriptions"
    [resizableColumns]="true"
    [responsive]="true"
    [paginator]="true"
    [rows]="rows"
    [showCurrentPageReport]="true"
    [(first)]="first"
    currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
    [rowsPerPageOptions]="rowsPerPageOptions">
    <ng-template pTemplate="caption">Historique des versions</ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" style="width:20px" pResizableColumn>N° Version</th>
        <th class="text-center" style="width:150px" pResizableColumn>Description</th>
        <th class="text-center" style="width:20px" pResizableColumn>Url</th>
        <th class="text-center" style="width:20px" pResizableColumn>Date de création</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-desc>
      <tr>
        <td>{{desc.versionId}}</td>
        <td>{{desc.description}}</td>
        <td>{{desc.url}}</td>
        <td>{{desc.dateCreation | date:'dd/MM/yyyy HH:mm'}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
