<div class="row justify-content-center mt-4">
  <h1 class="title">Rechercher un utilisateur</h1>
</div>

<div class="row justify-content-center buffer">
  <mat-card class="card col-lg-6 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de recherche</h4>
    </mat-card-header>

    <mat-card-content>
      <button class="float-right mt-2" mat-raised-button color="warn"
              matTooltip="Supprimer cet individu. L'individu ne pourra plus accéder à l'application"
              (click)="deleteIndividu()"
              *ngIf="selectedIndividu && selectedIndividu.idnum !== user.username"><i class="fas fa-user-slash"></i>
      </button>

      <div class="justify-content-center">
        <mat-form-field class="col-10" appearance="outline">
          <mat-label>Utilisateur</mat-label>
          <input type="text" placeholder="Utilisateur" aria-label="Utilisateur" matInput
                 [formControl]="userFC" [matAutocomplete]="auto" (input)="onSearchTape()"
                 (keyup.enter)="onKeyEnterSelect()" [disabled]="options.length ===0">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let ind of filteredOptions | async" [value]="getFieldLabel(ind)"
                        (click)="onIndividuSelect(ind)" (onSelectionChange)="onIndividuSelect(ind)">
              {{getFieldLabel(ind)}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="row justify-content-center buffer">
  <mat-card class="col-lg-12" *ngIf="selectedIndividu && datasource.length === 0">
    <h4 class="text-center">L'individu sélectionné n'a pas de privilège</h4>
  </mat-card>

  <p-table class="col-lg-6"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true"
           *ngIf="datasource?.length > 0 && selectedIndividu">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" pResizableColumn>Détail des privileges
          <button class="button float-right"
                  matTooltip="Ajouter un nouveau privilège de gestionnaire"
                  (click)="addPrivilege()" *ngIf="displayAddButton"><i class="fas fa-plus fa-2x"></i></button>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{row.title}}
          <ul *ngFor="let role of row.roles">
            <li>
              <a href appHighlightStructure
                 [structure]="role.structure"
                 [routerLink]="'/structure/' +  role.structure.structureId">
                {{role.structure | libelleStructure:user}}</a><span
              *ngIf="role.isExtended">{{' et ses structures filles'}}</span>
              <button class="button"
                      matTooltip="Supprimer le privilege"
                      [disabled]="!isInUserPerimeter(role)"
                      (click)="removePrivilege(role)"
                      *ngIf="row.title === typeRole.GESTIONNAIRE && user.individu.individuId !== selectedIndividu.individuId">
                <i class="fas fa-trash"></i></button>
            </li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
