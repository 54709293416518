import {Component, OnInit} from '@angular/core';
import {SyncRefAqService} from '../../../services/syncRefAq.service';
import {LoaderService} from '../../../services/loader.service';
import {SyncRefAqTask} from '../../../model/referentiel-aquitain.model';
import * as moment from 'moment';
import {FormGroup} from '@angular/forms';
import {StructureService} from '../../../services/structure.service';
import {Structure} from '../../../model/structure.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CAT_STRUCTURE, ETAT_SYNC} from '../../../model/enum.model';
import {
  SynchronizeReferentielAquitainDialogComponent
} from '../../../layout/dialog/referentiel-aquitain/synchronize-referentiel-aquitain-dialog/synchronize-referentiel-aquitain-dialog.component';
import {ACTION} from '../../../components/administration/referentielAquitain/synchro-referentiel-aquitain/synchro-referentiel-aquitain.component';
import {MatDialog} from '@angular/material/dialog';
import {LienService} from '../../../services/lien.service';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-administration-synchro-referentiel-aquitain',
  templateUrl: './administration-synchro-referentiel-aquitain.component.html',
  styleUrls: ['./administration-synchro-referentiel-aquitain.component.scss']
})
export class AdministrationSynchroReferentielAquitainComponent implements OnInit {

  user: User;
  form: FormGroup;
  structures: Structure[];
  structuresAsync: Structure[];
  syncRefAqTasks: SyncRefAqTask[];

  datasource: any[] = [];
  cols: any[];

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(public dialog: MatDialog,
              private lienService: LienService,
              private userService: UserService,
              private loaderService: LoaderService,
              private syncRefAqService: SyncRefAqService,
              private snackBar: MatSnackBar,
              private structureService: StructureService) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'dateCreation', header: 'Date de Création'},
      {field: 'structure', header: 'Structure'},
      {field: 'etat', header: 'Etat'},
    ];
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.structureService.getAllStructures().subscribe(res => {
      this.structures = res;
      this.structuresAsync = res.filter(s => !s.useRefAq && s.catStructure === CAT_STRUCTURE.ORGA);
      this.structuresAsync.forEach(s => this.datasource.push(this.convertToDataTable(s)));
    });
    this.syncRefAqService.getAllTasks().subscribe(res =>  this.syncRefAqTasks = res.filter(t => t.etatSync === ETAT_SYNC.NOK));
  }

  synchronize(structure: Structure) {
    this.structureService.getStructureByStructureId(structure.structureId).subscribe(struct => {
      structure = struct;
      // const liens: Lien[] = structure.liensParent.filter(l => l.etatValid === ETAT_VALID.VALIDE
      //   && moment(l.dateDebut).isBefore(new Date())
      //   && (!l.dateFin) || moment(l.dateFin).isAfter(new Date())
      //   && l.typeLien.isHierarchique);

      this.dialog.open(SynchronizeReferentielAquitainDialogComponent, {
        minWidth: 400,
        data: {
          structure,
          action: ACTION.ACTIVATE
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.init();
        }
      });
    });
  }

  convertToDataTable(structure: Structure) {
    return {
      structure: structure,
      etat: structure.etatStructure,
      dateCreation: moment(structure.dateCreation).format('YYYY/MM/DD'),
    };
  }
}
