import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LdapAccount, Role} from '../../../../model/user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatLocalDateWithUTC, getDDMMYYYY} from '../../../../utils/date.utils';
import * as moment from 'moment';
import {LdapService} from '../../../../services/ldap.service';
import {Structure} from '../../../../model/structure.model';
import {ETAT_VALID, TYPE_ROLE} from '../../../../model/enum.model';

@Component({
  selector: 'app-responsable-histo-form-dialog',
  templateUrl: './responsable-histo-form-dialog.component.html',
  styleUrls: ['./responsable-histo-form-dialog.component.scss']
})
export class ResponsableHistoFormDialogComponent implements OnInit {

  warningDates: string;

  form: FormGroup;
  ldapAccount: LdapAccount;
  isDatesValid: boolean;

  constructor(private ldapService: LdapService,
              public dialogRef: MatDialogRef<ResponsableHistoFormDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.form = new FormGroup({
      dateDebut: new FormControl(null, Validators.required),
      dateFin: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data.role.roleId) {
      this.ldapService.getLdapSearchByUid(this.data.role.individu.idnum).toPromise().then(res => this.ldapAccount = res);
      this.form.controls.dateDebut.setValue(this.data.role.dateDebut);
      this.form.controls.dateFin.setValue(this.data.role.dateFin);

    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onValidate() {
    const role: Role = this.data.role;
    role.dateDebut = formatLocalDateWithUTC(this.form.controls.dateDebut.value);
    role.dateFin = formatLocalDateWithUTC(this.form.controls.dateFin.value);
    role.typeRole = TYPE_ROLE.RESPONSABLE;
    role.structureId = this.data.structure.structureId;
    role.etatValid = ETAT_VALID.VALIDE;
    this.dialogRef.close({role, ldapAccount: this.ldapAccount});
  }

  getLdapAccount(ldapAccount: LdapAccount) {
    this.ldapAccount = ldapAccount;
  }

  isDateRangeValid() {
    this.warningDates = null;

    let dateDebut;
    if (this.form.controls.dateDebut.valid) {
      dateDebut = this.form.controls.dateDebut.value;
    }
    let dateFin;
    if (this.form.controls.dateFin.valid) {
      dateFin = this.form.controls.dateFin.value;
    }

    if (dateDebut && dateFin) {
      this.isDatesValid = true;

      if (moment(dateDebut).isAfter(dateFin)) {
        this.warningDates = 'La date de debut doit être antérieure à la date de fin';
        this.isDatesValid = false;

      } else if (moment(dateDebut).isBefore(this.data.structure.identite.dateOuverture)) {
        this.warningDates = 'La date de début ne doit pas être antérieure à la date d\'ouverture de la structure : ' + getDDMMYYYY(this.data.structure.identite.dateOuverture);
        this.isDatesValid = false;

      } else {
        this.data.roles.forEach(r => {
          if (!moment(dateDebut).isAfter(r.dateDebut) && !moment(dateFin).isBefore(r.dateDebut)
            || (r.dateFin == null && !moment(dateDebut).isBefore(r.dateDebut))
            || (r.dateFin != null && !moment(dateDebut).isAfter(r.dateFin) && !moment(dateFin).isBefore(r.dateFin))
            || (r.dateFin != null && !moment(dateDebut).isBefore(r.dateDebut) && !moment(dateFin).isAfter(r.dateFin))
          ) {
            this.warningDates = 'Les dates ne peuvent pas chevaucher les dates d\'un autre responsable  : '
              + r.individu.prenom + ' ' + r.individu.nom.toUpperCase() + ' (' + getDDMMYYYY(r.dateDebut) + '-' + getDDMMYYYY(r.dateFin) + ')';
            this.isDatesValid = false;
            return;
          }
        });
      }
    }
  }

}

export interface DialogData {
  role: Role;
  structure: Structure;
  roles: Role[];
}
