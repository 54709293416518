import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {SecuriteService} from '../../../services/securite.service';
import {User} from '../../../model/user.model';
import {Connexion, LoggedEntity} from '../../../model/securite.model';
import * as moment from 'moment';
import {getDDMMYYYYHHmm} from '../../../utils/date.utils';


@Component({
  selector: 'app-administration-surveillance-connexions',
  templateUrl: './administration-surveillance-connexions.component.html',
  styleUrls: ['./administration-surveillance-connexions.component.scss']
})
export class AdministrationSurveillanceConnexionsComponent implements OnInit {

  user: User;
  userDatasource: any[];
  appliDatasource: any[];
  connexionDatasource: Connexion[];
  loggedEntityCols: any[];
  activityCols: any[];

  constructor(private userService: UserService,
              private securiteService: SecuriteService) {
    this.user = this.userService.getCurrentUser();
    this.activityCols = [
      {field: 'usename', header: 'Role'},
      {field: 'applicationName', header: 'Nom de l\'application'},
      {field: 'clientAddr', header: 'Adresse'},
      {field: 'queryStart', header: 'Date de la requête'},
      {field: 'query', header: 'requête'},
    ];

    this.loggedEntityCols = [
      {field: 'name', header: 'Nom'},
      {field: 'lastConnection', header: 'Dernière connexion'},
      {field: 'intervalWithLastConnection', header: 'Intervalle (en minutes) '},
      {field: 'tokenValidity', header: 'Durée de validité'},
      {field: 'authorities', header: 'Roles'}
    ];
  }

  ngOnInit(): void {
    this.securiteService.getLoggedUsers().subscribe(res => {
      this.userDatasource = res.map(u => this.convertLoggedEntitiesToData(u))
        .sort((a, b) => moment(a.loggedEntity.lastConnection).isAfter(b.loggedEntity.lastConnection) ? -1 : 1);
    });
    this.securiteService.getLoggedApplis().subscribe(res => {
      this.appliDatasource = res.map(u => this.convertLoggedEntitiesToData(u))
        .sort((a, b) => moment(a.loggedEntity.lastConnection).isAfter(b.loggedEntity.lastConnection) ? -1 : 1);
    });
    this.securiteService.getActivityDatabase().subscribe(res => this.connexionDatasource = res);
  }

  convertLoggedEntitiesToData(loggedEntity: LoggedEntity<any>) {
    return {
      loggedEntity,
      name: loggedEntity.name,
      lastConnection: getDDMMYYYYHHmm(loggedEntity.lastConnection),
      intervalWithLastConnection: loggedEntity.intervalWithLastConnection,
      tokenValidity: getDDMMYYYYHHmm(new Date(loggedEntity.token.exp * 1000)),
      authorities: loggedEntity.token.authorities.map(a => a.authority.toLowerCase()).join(', ')
    };
  }

}
