import {Component, OnInit} from '@angular/core';
import {TutelleService} from '../../../services/tutelle.service';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {Tutelle} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-tutelle',
  templateUrl: './tutelle.component.html',
  styleUrls: ['./tutelle.component.scss']
})
export class TutelleComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: Tutelle[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private tutelleService: TutelleService,
              private snackBar: MatSnackBar,
              private structureService: StructureService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(tutelle: Tutelle) {
    tutelle.hasLibError = false;

    if (!tutelle.tutelleLib || tutelle.tutelleLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      tutelle.hasLibError = true;

    } else if (doesAlreadyExist(this.dataSource, tutelle.tutelleLib, 'tutelleLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      tutelle.hasLibError = true;
    }
  }

  onRNEChanged(tutelle: Tutelle) {
    tutelle.hasRneError = false;

    if (tutelle.rne && tutelle.rne !== '' && doesAlreadyExist(this.dataSource, tutelle.rne, 'rne')) {
      this.snackBar.open(
        'Le RNE saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      tutelle.hasLibError = true;
    }
  }

  onIsValidChanged(tutelle: Tutelle) {
    tutelle.isValid = !tutelle.isValid;

    if (!tutelle.isValid) {
      if (tutelle.tutelleId) {
        this.structureService.getStructuresByTutelle(tutelle.tutelleId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                'La tutelle ' + tutelle.tutelleLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
              tutelle.isValid = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(tutelle, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        tutelleId: null,
        tutelleLib: null,
        rne: null,
        isEtab: false,
        isValid: true,
        isHidden: false,
        hasLibError: true,
        hasRneError: false
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.tutelleService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasLibError || t.hasRneError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.tutelleService.getAllTutelles().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }
}
