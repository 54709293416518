<h1 mat-dialog-title>Formulaire de désynchronisation de
  l'application {{data.rss.application.applicationLib.toUpperCase()}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <mat-form-field class="col-lg-12" appearance="outline">
      <textarea matInput
                [(ngModel)]="motif"
                [maxLength]="200"
                placeholder="Motif"
                required></textarea>
  </mat-form-field>

  <div class="col-lg-12" *ngIf="data.rss.structure.etatStructure === etatStructure.ACTIF">
    <mat-checkbox class="ml-2" [(ngModel)]="changeEtat">Dégrader la structure à l'état NOUVEAU</mat-checkbox>
  </div>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="Supprimer l'association"
          [disabled]="!motif || motif.trim() === ''"
          (click)="onConfirm()"><i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
