export class KeyValue {
  key: any;
  value: any;
}

export interface Parametre {
  parametreId: number;
  cle: string;
  description: string;
  multivaleur: boolean;
  obligatoire: boolean;
  type: string;
  valeurs: string[];
  input:any;
}

export class StructNode {
  label: string;
  data: number;
  icon: string;
  leaf: boolean;
  styleClass: string;
  parent: StructNode;
  children: StructNode[];
}

export class Aide {
  aideId: number;
  libelle: string;
  description: string;
  typeMime: string;
  url: string;
  isValid: boolean;
}

export interface Event {
  structureId: number;
  status: string;
  icon: string;
  color: string;
  title: string;
  resume: string;
  date: Date;
}

export class Pagination {
  public offset: number;
  public limit: number;

  constructor(offset: number, limit: number) {
    this.offset = offset;
    this.limit = limit;
  }
}
