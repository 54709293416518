import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Domaine} from '../model/nomenclature.model';

@Injectable()
export class DomaineService {

  constructor(private http: HttpClient) {
  }

  public getAllDomaines(): Observable<Domaine[]> {
    return this.http.get<Domaine[]>('/domaine/all');
  }

  public getDomainesByStructureId(structureId: number): Observable<Domaine[]> {
    return this.http.get<Domaine[]>('/domaine/all/structure/' + structureId);
  }

  public getDomainesToAssociate(structureId: number): Observable<Domaine[]> {
    return this.http.get<Domaine[]>('/domaine/available/structure/' + structureId);
  }

  public associate(domaineIdList: number[], structureId: number): Observable<void> {
    return this.http.put<void>('/domaine/associate/structure/' + structureId, domaineIdList);
  }

  public dissociate(domaineId: number, structureId: number): Observable<void> {
    return this.http.put<void>('/domaine/dissociate/structure/' + structureId + '/domaine/' + domaineId, null);
  }

  public save(domaines: Domaine[]): Observable<Domaine[]> {
    return this.http.put<Domaine[]>('/domaine/save', domaines);
  }
}
