import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IndividuService} from '../../../../services/Individu.service';
import {FormControl} from '@angular/forms';
import {Individu, User} from '../../../../model/user.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {normalize} from '../../../../utils/string.utils';
import {LdapService} from '../../../../services/ldap.service';
import {LoaderService} from '../../../../services/loader.service';

@Component({
  selector: 'app-login-as-dialog',
  templateUrl: './login-as-dialog.component.html',
  styleUrls: ['./login-as-dialog.component.scss']
})
export class LoginAsDialogComponent implements OnInit {

  userFC = new FormControl();
  options: Individu[] = [];
  filteredOptions: Observable<Individu[]>;
  selectedIndividu: Individu;

  constructor(private individuService: IndividuService,
              private loaderService: LoaderService,
              private ldapService: LdapService,
              public dialogRef: MatDialogRef<LoginAsDialogComponent>) {
  }

  ngOnInit(): void {
    this.loaderService.start();
    this.individuService.getAllIndividusDTO().subscribe(res => {
      this.loaderService.stop();
      this.userFC.setValue(null);
      this.selectedIndividu = null;
      this.options = res;
      this.filteredOptions = this.userFC.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
    });
  }

  getFieldLabel(ind: Individu): string {
    return ind.nom.toUpperCase() + ' ' + ind.prenom + ' / ' + ind.idnum;
  }

  onKeyEnterSelect() {
    if (!this.selectedIndividu) {
      this.selectedIndividu = null;
    }
  }

  onSearchTape() {
    this.selectedIndividu = null;
  }

  private _filter(value: string): Individu[] {
    let options: Individu[];

    if (value?.trim().length > 0) {
      const values: string[] = value.split(' ');
      options = this.options.filter(individu =>
        values.every(v => normalize(individu.nom).includes(normalize(v)))
        || values.every(v => normalize(individu.prenom).includes(normalize(v)))
        || values.every(v => normalize(individu.idnum).includes(normalize(v)))
        || values.every(v => normalize(individu.mail).includes(normalize(v)))
      );
    } else {
      options = this.options;
    }

    return options;
  }

  onIndividuSelect(ind: Individu) {
    this.selectedIndividu = ind;
  }

  onCancel(): void {
    this.dialogRef.close({res: null});
  }

  onConfirm(): void {
    this.ldapService.getLdapSearchByUid(this.selectedIndividu.idnum).subscribe(ldapAccount => {
      const user: User = {
        username: ldapAccount.uid,
        individu: this.selectedIndividu,
        isValid: this.selectedIndividu.isValid,
        ldapAccount: ldapAccount
      };
      this.dialogRef.close({loginAs: user});
    });



  }

}
