<div class="row justify-content-center mt-4">
  <h1 class="title">Surveillance des connexions</h1>
</div>

<div class="row justify-content-center mt-4">
  <p-table class="col-lg-6" *ngIf="userDatasource?.length > 0"
           [columns]="loggedEntityCols"
           [value]="userDatasource"
           [responsive]="true"
           [resizableColumns]="true">
    <ng-template pTemplate="caption">Historique des authentifications par les utilisateurs</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" class="text-center">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr appHighlightActiveConnexion [loggedEntity]="rowData['loggedEntity']">
        <td *ngFor="let col of columns" class="text-center">{{rowData[col.field]}}</td>
      </tr>
    </ng-template>
  </p-table>

  <p-table class="col-lg-6" *ngIf="appliDatasource?.length > 0"
           [columns]="loggedEntityCols"
           [value]="appliDatasource"
           [responsive]="true"
           [resizableColumns]="true">
    <ng-template pTemplate="caption">Historique des authentifications applicatives</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" class="text-center">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr appHighlightActiveConnexion [loggedEntity]="rowData['loggedEntity']">
        <td *ngFor="let col of columns" class="text-center">{{rowData[col.field]}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="row justify-content-center mt-4">
  <p-table class="col-lg-12" *ngIf="connexionDatasource?.length > 0"
           [columns]="activityCols"
           [value]="connexionDatasource"
           [responsive]="true"
           [resizableColumns]="true">
    <ng-template pTemplate="caption">Historique des connexions Postgres</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" class="text-center">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" class="text-center">{{rowData[col.field]}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>


