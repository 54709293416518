import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TutelleService} from '../../../../services/tutelle.service';
import {DomaineService} from '../../../../services/domaine.service';
import {CodeService} from '../../../../services/code.service';
import {FormControl, Validators} from '@angular/forms';
import {LoaderService} from '../../../../services/loader.service';
import {formatLocalDateWithUTC} from '../../../../utils/date.utils';
import {CARACTERISATION} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-confirm-remove-dialog',
  templateUrl: './remove-caracterisation-dialog.component.html',
  styleUrls: ['./remove-caracterisation-dialog.component.scss']
})
export class RemoveCaracterisationDialogComponent {

  caracterisation = CARACTERISATION;

  isDefinitive: boolean;
  now = new Date();
  dateFin: FormControl;

  constructor(
    private loaderService: LoaderService,
    private tutelleService: TutelleService,
    private domaineService: DomaineService,
    private codeService: CodeService,
    public dialogRef: MatDialogRef<RemoveCaracterisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    const today = new Date();
    this.dateFin = new FormControl(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0), Validators.required);
    this.isDefinitive = true;
  }

  onDelete() {
    this.loaderService.start();
    if (this.data.type === CARACTERISATION.TUTELLE) {
      this.tutelleService.dissociate(this.data.item.tutelleId, this.data.structureId).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.type === CARACTERISATION.DOMAINE) {
      this.domaineService.dissociate(this.data.item.domaineId, this.data.structureId).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.type === CARACTERISATION.CODE) {
      if (this.isDefinitive) {
        this.data.item.isValid = false;
      } else {
        this.data.item.dateFin = formatLocalDateWithUTC(this.dateFin.value);
      }

      this.codeService.updateOrDelete(this.data.item).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}

export interface DialogData {
  type: string;
  structureId: number;
  item: any;
}
