<mat-card class="card" [class.hover]="updateMode" [class.selected]="selected && updateMode">
  <div class="chip-btn card-btn-delete" (click)="toggleRemove()" *ngIf="updateMode"><i class="fas fa-times"></i></div>

  <span class="float-right clickable ml-2" (click)="displayMap(adresse)" *ngIf="!updateMode">
    <i class="fas fa-map-marker-alt fa-2x"></i></span>
  <mat-card-header>
    <h4>{{adresse.typeAdresse.typeAdresseLib}}</h4>
  </mat-card-header>

  <mat-card-content class="content">
    <div class="ml-4">
      <div class="row">UNIVERSITE DE BORDEAUX</div>
      <div class="row" *ngIf="structure.liensParent?.length>0">{{structure.identite.libelleLong}}</div>
      <div class="row" *ngIf="adresse.pointGeo">{{adresse.pointGeo}}</div>
      <div class="row">
        {{adresse.num ? adresse.num : ''}} {{adresse.compl ? adresse.compl : ''}} {{adresse.typeVoierie ? adresse.typeVoierie.typeVoierieCode : ''}} {{adresse.libelleVoie}}
      </div>
      <div class="row" *ngIf="adresse.mentionSpec">{{adresse.mentionSpec}}</div>
      <div class="row">{{adresse.cp}} {{adresse.bureauDistrib}}</div>
    </div>
  </mat-card-content>
</mat-card>
