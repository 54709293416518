<div *ngIf="display">
  <!--ACTIONS -->
  <div class="row justify-content-center mt-4">
    <button class="btn-white d-inline-block mt-2" mat-raised-button matTooltip="Modifier les infos d'identité"
            (click)="enableIdentityForm()"
            *ngIf="!hasDemandeIdentiteEnCours
                && (user.individu.isAdmin || (isInUserGestRespPerimeter() && structure.etatStructure === etatStructure.ACTIF))">
      {{readonlyForm ? 'Modifier les infos d\'identité' : 'Annuler les modifications de l\'identité'}}
    </button>

    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button matTooltip="Modifier un responsable"
            (click)="enableResponsableForm()"
            *ngIf="structure.catStructure === catStructure.ORGA
                && (user.individu.isAdmin || (isInUserGestRespPerimeter() && [etatStructure.ACTIF.valueOf(), etatStructure.NOUVEAU.valueOf()].includes(structure.etatStructure)))
&& !hasDemandeResponsableEnCours">
      {{readonlyResp ? 'Modifier le responsable' : 'Annuler l\'édition responsable'}}
    </button>

    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button matTooltip="Voir l'historique de la structure"
            (click)="displayHistoStructure()"
            *ngIf="(validIdentites.length > 1 || validResponsables.length > 1)
                && (user.individu.isAdmin || isInUserGestRespPerimeter())
                && structure.etatStructure === etatStructure.ACTIF">
      Voir l'historique de la structure
    </button>

    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            matTooltip="Voir la version en attente de validation"
            (click)="displayVersionEnAttente()"
            *ngIf="hasDemandeIdentiteEnCours
                && (user.individu.isAdmin || isInUserGestRespPerimeter())
                && structure.etatStructure === etatStructure.ACTIF">
      Voir la version en attente de validation
    </button>
  </div>

  <div class="row justify-content-center">
    <div [ngClass]="structure.catStructure === catStructure.ORGA ? 'col-lg-8' : 'col-lg-12'" class="mt-4">
      <!--IDENTITE-->
      <mat-card class="p-4">
        <mat-card-header>
          <h4>Identité de la structure</h4>
        </mat-card-header>

        <mat-card-content>
          <mat-form-field class="col-lg-2" appearance="outline">
            <mat-label>Identifiant</mat-label>
            <input matInput placeholder="Identifiant" [value]="structure.structureId.toString()" disabled>
          </mat-form-field>

          <!--CATEGORIE-->
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Catégorie</mat-label>
            <input matInput placeholder="Catégorie" [value]="structure.catStructure" disabled>
          </mat-form-field>

          <!--ETAT-->
          <mat-form-field class="col-lg-4" appearance="outline">
            <mat-label>Etat</mat-label>
            <input matInput placeholder="Etat" [value]="structure.etatStructure" disabled>
          </mat-form-field>

            <app-identite-form #identiteComponent [identiteStructure]="structure.identite"
                               [categorie]="structure.catStructure"
                               [readonly]="readonlyForm"></app-identite-form>

          <!--MOTIF-->
          <mat-form-field class="col-lg-12 mt-4" appearance="outline"
                          *ngIf="!readonlyForm && ![etatStructure.EMBRYON.valueOf(), etatStructure.NOUVEAU.valueOf()].includes(structure.etatStructure)">
            <mat-label>Motif de la demande</mat-label>
            <textarea matInput [(ngModel)]="motif"  [maxlength]="200"
                      placeholder="Motif de la demande"
                      required></textarea>
          </mat-form-field>

          <div class="row justify-content-center" *ngIf="!readonlyForm">
            <button mat-raised-button color="primary"
                    matTooltip="Envoyer la demande de modification d'identité de structure"
                    [disabled]="(identiteComponent.form.disabled
                          || identiteComponent.form.pristine)
                          || (![etatStructure.EMBRYON.valueOf(), etatStructure.NOUVEAU.valueOf()].includes(structure.etatStructure) && (!motif || motif.trim() === ''))"
                    (click)="onIdentiteUpdate()"><i class="fas fa-check"></i></button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4">
      <!--RESPONSABLE -->
      <app-ajout-responsable class="col-lg-12 mt-4"
                             [structure]="structure"
                             [readonly]="readonlyResp"
                             (actionEmitter)="parent.init();"
                             *ngIf="structure.catStructure === catStructure.ORGA"></app-ajout-responsable>

      <!--INSTANCE DE VALIDATION / PRESENTATION-->
      <div class="row justify-content-center" *ngIf="instancesDemande?.length > 0">
        <mat-card class="col-lg-12 p-4">
          <mat-card-header>
            <h4>Instances de validation/présentation</h4>
          </mat-card-header>

          <mat-card-content>
            <ul class="ml-2">
              <li *ngFor="let instance of instancesDemande">
                <span *ngIf="instance.instance">{{instance.instance.instanceLib}}</span>
                <span *ngIf="instance.dateInstance"> du {{getDateString(instance.dateInstance)}}</span>
                <span class="clickable ml-4" (click)="onDownload(instance.documentId)" *ngIf="instance.documentId">
              <i class="fas fa-download"></i></span>
              </li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
