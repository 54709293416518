import {ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {StructureService} from '../../../services/structure.service';
import {Demande} from '../../../model/demande.model';
import {MatDialog} from '@angular/material/dialog';
import {StructureTreeDialogComponent} from '../../../layout/dialog/divers/structure-tree-dialog/structure-tree-dialog.component';
import {TYPE_CREATION} from '../../../model/enum.model';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-structures-selection-with-tree',
  templateUrl: './structuresSelectionWithTree.component.html',
  styleUrls: ['./structuresSelectionWithTree.component.scss']
})
export class StructuresSelectionWithTreeComponent implements OnChanges {

  @Input() multiple: boolean;
  @Input() single: boolean;
  @Input() readonly: boolean;
  @Input() demande: Demande;

  user: User;

  structures: Structure[];
  selectedStructures: Structure[];

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private userService: UserService,
              private structureService: StructureService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
    this.init();
  }

  ngOnChanges(): void {
    this.structureService.getAllStructures().subscribe(res => {
      this.structures = res;

      if (this.demande && this.demande.typeCreation !== TYPE_CREATION.NEW) {
        this.selectedStructures = this.structures.filter(s => this.demande.typeCreationStruct.includes(s.structureId));
      }
    });
  }

  init() {
    this.multiple = false;
    this.single = true;
    this.readonly = false;
    this.structures = [];
    this.selectedStructures = [];
  }

  openDialog() {
    this.dialog
      .open(StructureTreeDialogComponent, {
        width: '800px',
        height: '600px'
      })
      .afterClosed().subscribe(res => {
      if (res && res.structure) {
        this.selectedStructures.push(res.structure);
        this.selectedStructures = this.selectedStructures.reduce((acc: Structure[], cur: Structure) => {
          if (!acc.map(s => s.structureId).includes(cur.structureId)) {
            acc.push(cur);
          }
          return acc;
        }, []);
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  remove(structure: Structure) {
    const index = this.selectedStructures.indexOf(structure, 0);
    if (index > -1) {
      this.selectedStructures.splice(index, 1);
    }
  }

  getStructureIds(): number[] {
    return this.selectedStructures.map(s => s.structureId);
  }

}
