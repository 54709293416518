import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {InstanceService} from '../../../services/instance.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Demande, InstanceDemande} from '../../../model/demande.model';
import {InstanceDemandeService} from '../../../services/instance-demande.service';
import {DocumentService} from '../../../services/document.service';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {Instance} from '../../../model/nomenclature.model';
import {FileUpload} from 'primeng/fileupload';

@Component({
  selector: 'app-instance-demande-form',
  templateUrl: './instance-demande-form.component.html',
  styleUrls: ['./instance-demande-form.component.scss']
})
export class InstanceDemandeFormComponent implements OnChanges {

  @Output() fileUploadEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() demande: Demande;
  @Input() readonly: boolean;

  @ViewChild(FileUpload) fileUpload: FileUpload;

  instances: Instance[];
  uploadFiles: File[];
  form: FormGroup;
  filename: string;

  constructor(
    private documentService: DocumentService,
    private instanceService: InstanceService,
    private instanceDemandeService: InstanceDemandeService) {
    this.form = new FormGroup({
      instance: new FormControl(null),
      dateInstance: new FormControl(null),
    });

    this.uploadFiles = [];
    this.instanceService.getAllInstances().subscribe(instances => this.instances = instances.filter(i => i.isValid));
  }

  ngOnChanges(): void {
    if (this.readonly) {
      this.form.disable();
    }

    if (this.demande) {
      this.instanceDemandeService.getInstanceDemandeByDemandeId(this.demande.demandeId).subscribe(instanceDemande => {
          if (instanceDemande) {
            if (instanceDemande.documentId) {
              this.documentService.getDocumentByDocumentId(instanceDemande.documentId).subscribe(document => {
                this.form.controls.instance.setValue(instanceDemande.instance.instanceId);
                this.form.controls.dateInstance.setValue(instanceDemande.dateInstance);
                this.uploadFiles.push(new File([document.bytes], document.fileName));
                this.fileUpload.files = this.uploadFiles;
              });
            } else {
              this.form.controls.instance.setValue(instanceDemande.instance.instanceId);
              this.form.controls.dateInstance.setValue(instanceDemande.dateInstance);
            }
          }
        });
    }
  }

  reset() {
    this.form.reset();
    this.fileUpload.clear();
  }

  onUpload(event: any) {
    this.uploadFiles = [];
    this.uploadFiles.push(event.files[0]);
    this.fileUploadEmitter.emit();
  }

  onRemove() {
    this.uploadFiles = [];
    this.fileUpload.clear();
    this.fileUploadEmitter.emit();
  }

  getInstanceDemande(): Promise<InstanceDemande> {
    return new Promise<InstanceDemande>(resolve => {
      if (this.form.controls.instance.value || this.form.controls.dateInstance.value || this.uploadFiles.length > 0) {
        if (this.uploadFiles.length > 0) {
          this.documentService.create(this.uploadFiles[0]).subscribe(res => {
              resolve({
                instanceDemandeId: null,
                demandeId: this.demande ? this.demande.demandeId : null,
                instance: new Instance(this.form.controls.instance.value),
                dateInstance: this.form.controls.dateInstance.valid ? formatLocalDateWithUTC(this.form.controls.dateInstance.value) : null,
                documentId: res.documentId
              });
            });
        } else {
          resolve({
            instanceDemandeId: null,
            demandeId: this.demande ? this.demande.demandeId : null,
            instance: new Instance(this.form.controls.instance.value),
            dateInstance: this.form.controls.dateInstance.valid ? formatLocalDateWithUTC(this.form.controls.dateInstance.value) : null,
            documentId: null
          });
        }
      } else {
        resolve(null);
      }
    });
  }
}
