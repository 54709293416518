import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Tutelle} from '../model/nomenclature.model';

@Injectable()
export class TutelleService {

  constructor(private http: HttpClient) {
  }

  public getAllTutelles(): Observable<Tutelle[]> {
    return this.http.get<Tutelle[]>('/tutelle/all');
  }

  public getTutellesByStructureId(structureId: number): Observable<Tutelle[]> {
    return this.http.get<Tutelle[]>('/tutelle/all/structure/' + structureId);
  }

  public getTutellesToAssociate(structureId: number): Observable<Tutelle[]> {
    return this.http.get<Tutelle[]>('/tutelle/available/structure/' + structureId);
  }

  public associate(tutelleId: number, structureId: number): Observable<void> {
    return this.http.put<void>('/tutelle/associate/structure/' + structureId + '/tutelle/' + tutelleId, null);
  }

  public dissociate(tutelleId: number, structureId: number): Observable<void> {
    return this.http.put<void>('/tutelle/dissociate/structure/' + structureId + '/tutelle/' + tutelleId, null);
  }

  public save(tutelles: Tutelle[]): Observable<Tutelle[]> {
    return this.http.put<Tutelle[]>('/tutelle/save', tutelles);
  }
}
