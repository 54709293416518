<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p [innerHTML]="data.question"></p>
  <p *ngIf="data.body" [innerHTML]="data.body"></p>
</div>


<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="onConfirm()">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
