import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Document} from '../model/demande.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) {
  }

  public getDocumentByDocumentId(documentId: number): Observable<Document> {
    return this.http.get<Document>('/document/' + documentId);
  }

  public create(file: File): Observable<Document> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put<Document>('/document/create', formData);
  }

  public remove(documentId: number): Observable<any> {
    return this.http.delete<any>('/document/delete/' + documentId);
  }
}
