import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-demande-informations-complementaires',
  templateUrl: './demande-informations-complementaires.component.html',
  styleUrls: ['./demande-informations-complementaires.component.scss']
})
export class DemandeInformationsComplementairesComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;

  user: User;
  commentaire: string;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService) {
  }

  askInfos() {
    this.loaderService.start();
    this.workflowService.askInfos(
      {
        demandeId: this.demande.demandeId,
        motif: this.commentaire
      }
    ).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
