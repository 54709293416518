import {Component, Inject, ViewChild} from '@angular/core';
import {Structure} from '../../../../model/structure.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SyncRequest} from '../../../../model/referentiel-aquitain.model';
import {ReferentielAquitainFormComponent} from '../../../../components/administration/referentielAquitain/referentiel-aquitain-form/referentiel-aquitain-form.component';
import {LoaderService} from '../../../../services/loader.service';
import {SyncRefAqService} from '../../../../services/syncRefAq.service';
import {ACTION} from '../../../../components/administration/referentielAquitain/synchro-referentiel-aquitain/synchro-referentiel-aquitain.component';

@Component({
  selector: 'app-synchronize-referentiel-aquitain-dialog',
  templateUrl: './synchronize-referentiel-aquitain-dialog.component.html',
  styleUrls: ['./synchronize-referentiel-aquitain-dialog.component.scss']
})
export class SynchronizeReferentielAquitainDialogComponent {

  @ViewChild(ReferentielAquitainFormComponent) referentielAquitainFormComponent: ReferentielAquitainFormComponent;

  commentaire: string;

  action = ACTION;

  constructor(private loaderService: LoaderService,
              private syncRefAqService: SyncRefAqService,
              public dialogRef: MatDialogRef<SynchronizeReferentielAquitainDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  validate() {
    const syncRequest = {
      syncRefAq: this.referentielAquitainFormComponent ? this.referentielAquitainFormComponent.getSyncRefAq() : null,
      commentaire: this.commentaire
    };

    if (this.data.action === ACTION.ACTIVATE) {
      this.activateSynchro(syncRequest);
    } else if (this.data.action === ACTION.REACTIVATE) {
      this.reactivateSynchro();
    } else if (this.data.action === ACTION.STOP) {
      this.stopSynchro();
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  isDisabled(): boolean {
    return !this.commentaire || this.commentaire.trim() === '' ||
      (this.data.action === ACTION.ACTIVATE && this.referentielAquitainFormComponent.form.invalid);
  }

  private activateSynchro(syncRequest: SyncRequest) {
    this.loaderService.start();
    this.syncRefAqService.activateSynchro(syncRequest).subscribe(() =>  {
      this.loaderService.stop();
      this.dialogRef.close( ACTION.ACTIVATE);
    });
  }

  private reactivateSynchro() {
    this.loaderService.start();
    this.syncRefAqService.reactivateSynchro(this.data.structure.structureId, this.commentaire).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close(ACTION.REACTIVATE);
    });
  }

  private stopSynchro() {
    this.loaderService.start();
    this.syncRefAqService.stopSynchro(this.data.structure.structureId, this.commentaire).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close(ACTION.STOP);
    });
  }
}

export interface DialogData {
  structure: Structure;
  action: string;
}


