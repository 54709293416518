import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';

@Component({
  selector: 'app-close-structure-with-active-links-dialog',
  templateUrl: './close-structure-with-active-links-dialog.component.html',
  styleUrls: ['./close-structure-with-active-links-dialog.component.scss']
})
export class CloseStructureWithActiveLinksDialogComponent {

  constructor(public dialogRef: MatDialogRef<CloseStructureWithActiveLinksDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }


  close() {
    this.dialogRef.close(true);
  }


  onCancel() {
    this.dialogRef.close(null);
  }

}

export interface DialogData {
  structure: Structure;
}
