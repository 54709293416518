import {Component, OnInit} from '@angular/core';
import {Individu, Role, User} from '../../../model/user.model';
import {Structure} from '../../../model/structure.model';
import {LoaderService} from '../../../services/loader.service';
import {RoleService} from '../../../services/role.service';
import {MatDialog} from '@angular/material/dialog';
import {isInUserPerimeter} from '../../../utils/user.utils';
import {RemovePrivilegeDialogComponent} from '../../../layout/dialog/privilege/remove-privilege-dialog/remove-privilege-dialog.component';
import {TYPE_ROLE} from '../../../model/enum.model';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {AddGestionnaireDialogComponent} from '../../../layout/dialog/privilege/add-gestionnaire-dialog/add-gestionnaire-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-privileges-par-structure',
  templateUrl: './privileges-par-structure.component.html',
  styleUrls: ['./privileges-par-structure.component.scss']
})
export class PrivilegesParStructureComponent implements OnInit {

  user: User;
  datasource: any[] = [];
  cols: any[];

  selectedStructure: Structure;

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  typeRole = TYPE_ROLE;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private roleService: RoleService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.cols = [
      {field: 'nomComplet', header: 'Nom Complet'},
      {field: 'typeRole', header: 'Role'},
      {field: 'dateDebut', header: 'depuis le'},
      {field: 'observation', header: 'Observation'},
    ];
  }

  onSelectedStructure(structure: Structure) {
    this.selectedStructure = structure;
    this.loadTable();
  }

  addPrivilege() {
    this.dialog.open(AddGestionnaireDialogComponent, {
      minWidth: 400,
      data: {
        structure: this.selectedStructure
      }
    })
      .afterClosed().subscribe(() => {
      this.loadTable();
    });
  }

  openRemoveDialog(priv: any) {
    this.dialog.open(RemovePrivilegeDialogComponent, {
      minWidth: 400,
      data: {
        roleId: priv.roleId,
        typeRole: priv.typeRole
      }
    })
      .afterClosed().subscribe(() => {
      this.loadTable();
    });
  }

  loadTable() {
    this.datasource = [];
    forkJoin([
      this.getGestionnairesByStructure(this.selectedStructure.structureId),
      this.getResponsableByStructure(this.selectedStructure.structureId)
    ]).subscribe(() => {
      if (this.datasource.length === 0) {
        this.snackBar.open(
          'Il n\'y a actuellement aucun gestionnaire ni responsable déclaré sur cette structure.',
          'X',
          {panelClass: 'notif-warn'}
        );
      }
    });
  }

  getRemarques(ind: Individu): string {
    let res = '';
    if (!ind.isValid) {
      res += 'Le compte de cet utilisateur n\'est plus valide dans l\'annuaire de l\'établissement. Il est probablement nécessaire de mettre à jour (supprimer) les privilèges dont il dispose dans l\'applications.';
    }
    if (ind.idnum === this.user.username) {
      res += 'Vous ne pouvez pas procéder à la suppression de vos propres privilèges.';
    }
    return res;
  }

  isInUserPerimeter(): boolean {
    if (this.selectedStructure) {
      return isInUserPerimeter(this.selectedStructure.structureId, this.user);
    }
    return false;
  }

  private getGestionnairesByStructure(structureId: number): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleService.getRolesValidesByStructureAndTypeRole(structureId, TYPE_ROLE.GESTIONNAIRE).subscribe(roles => {
        this.datasource = this.datasource.concat(roles.map(r => this.convertToDataSource(r)));
        this.roleService.getGestionnairesEtendus(structureId).subscribe(gestEtend => {
          this.datasource = this.datasource.concat(gestEtend.map(r => this.convertToDataSource(r)));
          resolve();
        });
      });
    });
  }

  private getResponsableByStructure(structureId: number): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleService.getRolesValidesByStructureAndTypeRole(structureId, TYPE_ROLE.RESPONSABLE).subscribe(roles => {
        this.datasource = this.datasource.concat(roles.map(r => this.convertToDataSource(r)));
        resolve();
      });
    });
  }

  private convertToDataSource(role: Role) {
    return {
      roleId: role.roleId,
      dateDebut: moment(role.dateDebut).format('DD/MM/YYYY'),
      individu: role.individu,
      nomComplet: role.individu.nom + ' ' + role.individu.prenom,
      structureId: role.structureId,
      typeRole: role.typeRole,
      isExtended: role.isExtended,
      observation: this.getRemarques(role.individu)
    };
  }
}
