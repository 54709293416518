<h1 mat-dialog-title>Ajout d'un gestionnaire</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <app-recherche-ldap-form [selectedLdapAccount]="selectedLdapAccount"
                           (ldapAccountEventEmitter)="onAccountSelected($event)">
  </app-recherche-ldap-form>

  <div *ngIf="selectedLdapAccount">
    <h6>Sélection de la structure</h6>
    <app-selection-structure [selectedLdapAccount]="selectedLdapAccount"
                             [selectedStruct]="data.structure"
                             (roleEmitter)="onStructureSelected($event)">
    </app-selection-structure>
  </div>
</div>
