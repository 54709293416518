import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {User} from '../../../model/user.model';
import {Demande} from '../../../model/demande.model';
import {IdentiteStructure, Lien, Structure} from '../../../model/structure.model';
import {StructureService} from '../../../services/structure.service';
import {LienService} from '../../../services/lien.service';
import {LoaderService} from '../../../services/loader.service';
import {IdentiteStructureService} from '../../../services/identite-structure.service';
import {CAT_STRUCTURE, CONTEXTE_DEMANDE, ETAT_DEMANDE, TYPE_DEMANDE} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-demande-details',
  templateUrl: './demande-details.component.html',
  styleUrls: ['./demande-details.component.scss']
})
export class DemandeDetailsComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();


  @Input() demande: Demande;

  user: User;
  etatDemande = ETAT_DEMANDE;
  catStructure = CAT_STRUCTURE;
  typeDemande = TYPE_DEMANDE;
  contexteDemande = CONTEXTE_DEMANDE;

  identiteStructure: IdentiteStructure;
  structurePorteuse: Structure;
  lienContexte: Lien;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private structureService: StructureService,
              private identiteStructureService: IdentiteStructureService,
              private lienService: LienService) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnChanges() {
    this.init();
  }

  getStructurePorteuse(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getStructureByStructureId(this.demande.lien.structPorteuseId)
        .subscribe(structPorteuse => {
          this.structurePorteuse = structPorteuse;
          resolve();
        });
    });
  }

  getIdentite(): Promise<void> {
    return new Promise<void>(resolve => {
      this.identiteStructureService.getIdentiteByIdentStructureId(this.demande.contexteId).subscribe(res => {
        this.identiteStructure = res;
        resolve();
      });
    });
  }

  init() {
    if (this.demande) {
      this.loaderService.start();
      Promise.all([this.getStructurePorteuse(), this.getIdentite()]).then(() => this.loaderService.stop());
      if (this.demande.typeDemande.contexte === CONTEXTE_DEMANDE.LIEN) {
        this.lienService.getLienByLienId(this.demande.contexteId).subscribe(res => this.lienContexte = res);
      }
    }
  }
}
