import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {TreeModule} from 'primeng/tree';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {GlobalStateService} from './global-state.service';
import {AppComponent} from './app.component';
import {HttpInterceptor} from './http.interceptor';

import {StructureService} from './services/structure.service';
import {AuthenticationService} from './services/authentication.service';
import {RoleService} from './services/role.service';
import {DemandeService} from './services/demande.service';

import {SelectionStructureComponent} from './components/privilege/selection-structure/selection-structure.component';
import {RechercheLdapFormComponent} from './components/ui/recherche-ldap-form/recherche-ldap-form.component';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorAuthenticationComponent} from './components/ui/error-authentication/error-authentication.component';
import {RemovePrivilegeDialogComponent} from './layout/dialog/privilege/remove-privilege-dialog/remove-privilege-dialog.component';
import {CreationStructureOrganisationnelleComponent} from './pages/gestion-des-demandes/creation-structure-organisationnelle/creation-structure-organisationnelle.component';
import {StructureTreeComponent} from './components/ui/structureTree/structureTree.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {DemandeHistoriqueComponent} from './components/demande/demande-historique/demande-historique.component';
import {AjoutResponsableComponent} from './components/privilege/ajout-responsable/ajout-responsable.component';
import {DemandeComponent} from './components/demande/demande/demande.component';
import {AcceptationPourValidationComponent} from './components/workflow/acceptation-pour-validation/acceptation-pour-validation.component';
import {DemandeModificationComponent} from './components/workflow/demande-modification/demande-modification.component';
import {AnnulationDemandeComponent} from './components/workflow/annulation-demande/annulation-demande.component';
import {DemandeDetailsComponent} from './components/demande/demande-details/demande-details.component';
import {RefusDemandeComponent} from './components/workflow/refus-demande/refus-demande.component';
import {ValidationDemandeComponent} from './components/workflow/validation-demande/validation-demande.component';
import {StructureTreeService} from './services/structure-tree.service';
import {CreationStructureMatricielleComponent} from './pages/gestion-des-demandes/creation-structure-matricielle/creation-structure-matricielle.component';
import {StructureDetailsComponent} from './components/structure/structure-details/structure-details.component';
import {DemandeStructOrgaFormComponent} from './components/demande/demande-struct-orga-form/demande-struct-orga-form.component';
import {DemandeStructMatFormComponent} from './components/demande/demande-struct-mat-form/demande-struct-mat-form.component';
import {ModificationStructureOrganisationnelleComponent} from './components/workflow/modification-structure-organisationnelle/modification-structure-organisationnelle.component';
import {ModificationStructureMatricielleComponent} from './components/workflow/modification-structure-matricielle/modification-structure-matricielle.component';
import {MesStructuresComponent} from './pages/mes-structures/mes-structures.component';
import {CaracteristiquesGeneralesComponent} from './components/structure/caracteristiques-generales/caracteristiques-generales.component';
import {IdentiteFormComponent} from './components/structure/identite-form/identite-form.component';
import {HistoStructureDialogComponent} from './layout/dialog/structure/histo-identite-dialog/histo-structure-dialog.component';
import {IdentiteStructureService} from './services/identite-structure.service';
import {IdentiteEnCoursDialogComponent} from './layout/dialog/structure/identite-en-cours-dialog/identite-en-cours-dialog.component';
import {UserService} from './services/user.service';
import {StructuresAssocieesComponent} from './components/structure/structures-associees/structures-associees.component';
import {LienService} from './services/lien.service';
import {AjoutStructureRattachementComponent} from './components/workflow/ajout-structure-rattachement/ajout-structure-rattachement.component';
import {LienFormComponent} from './components/structure/lien-form/lien-form.component';
import {WorkflowService} from './services/workflow.service';
import {AjoutLienNonHierarchiqueComponent} from './components/workflow/ajout-lien-non-hierarchique/ajout-lien-non-hierarchique.component';
import {ModificationStructureRattachementComponent} from './components/workflow/modification-structure-rattachement/modification-structure-rattachement.component';
import {SuppressionLienComponent} from './components/workflow/suppression-lien/suppression-lien.component';
import {DescriptionComponent} from './components/structure/description/description.component';
import {FermetureStructureComponent} from './components/workflow/fermeture-structure/fermeture-structure.component';
import {StructureAdminComponent} from './components/structure/structure-admin/structure-admin.component';
import {EditorModule} from 'primeng/editor';
import {DescriptionStructureService} from './services/description-structure.service';
import {HistoDescriptionDialogComponent} from './layout/dialog/structure/histo-description-dialog/histo-description-dialog.component';
import {AdresseService} from './services/adresse.service';
import {ApplicationService} from './services/application.service';
import {TutelleService} from './services/tutelle.service';
import {CodeService} from './services/code.service';
import {DomaineService} from './services/domaine.service';
import {AdresseFormComponent} from './components/adresse-contact/adresse-form/adresse-form.component';
import {ContactFormComponent} from './components/adresse-contact/contact-form/contact-form.component';
import {AdressesContactsComponent} from './components/structure/adresses-contacts/adresses-contacts.component';
import {CaracterisationComponent} from './components/structure/caracterisation/caracterisation.component';
import {ContactService} from './services/contact.service';
import {RemoveCaracterisationDialogComponent} from './layout/dialog/structure/remove-caracterisation-dialog/remove-caracterisation-dialog.component';
import {AddCaracterisationDialogComponent} from './layout/dialog/structure/add-caracterisation-dialog/add-caracterisation-dialog.component';
import {UpdateCaracterisationDialogComponent} from './layout/dialog/structure/update-caracterisation-dialog/update-caracterisation-dialog.component';
import {ConsultationDemandesComponent} from './pages/gestion-des-demandes/consultation-demandes/consultation-demandes.component';
import {LogService} from './services/log.service';
import {ChartModule} from 'primeng/chart';
import {PrendreEnChargeComponent} from './components/workflow/prendre-en-charge/prendre-en-charge.component';
import {RelancerComponent} from './components/workflow/relancer/relancer.component';
import {DemandeInformationsComplementairesComponent} from './components/workflow/demande-informations-complementaires/demande-informations-complementaires.component';
import {AjoutInformationsComplementairesComponent} from './components/workflow/ajout-informations-complementaires/ajout-informations-complementaires.component';
import {RechercheDemandeFormComponent} from './components/ui/recherche-demande-form/recherche-demande-form.component';
import {RechercheComponent} from './pages/recherche/recherche.component';
import {TypeStructureComponent} from './pages/nomenclature/type-structure/type-structure.component';
import {TutelleComponent} from './pages/nomenclature/tutelle/tutelle.component';
import {DomaineComponent} from './pages/nomenclature/domaine/domaine.component';
import {TypeLienComponent} from './pages/nomenclature/type-lien/type-lien.component';
import {TypeCodeComponent} from './pages/nomenclature/type-code/type-code.component';
import {TypeAdresseComponent} from './pages/nomenclature/type-adresse/type-adresse.component';
import {ApplicationSIComponent} from './pages/nomenclature/application-si/application-si.component';
import {StructuresAssocieesDialogComponent} from './layout/dialog/synchro-application-si/structures-associees-dialog/structures-associees-dialog.component';
import {LogComponent} from './components/administration/log/log.component';
import {IndividuService} from './services/Individu.service';
import {SynchroReferentielAquitainComponent} from './components/administration/referentielAquitain/synchro-referentiel-aquitain/synchro-referentiel-aquitain.component';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {ReferentielAquitainFormDialogComponent} from './layout/dialog/referentiel-aquitain/referentiel-aquitain-form-dialog/referentiel-aquitain-form-dialog.component';
import {SyncRefAqService} from './services/syncRefAq.service';
import {ReferentielAquitainFormComponent} from './components/administration/referentielAquitain/referentiel-aquitain-form/referentiel-aquitain-form.component';
import {
  ConfirmSyncReferentielAquitainDialogComponent
} from './layout/dialog/referentiel-aquitain/confirm-sync-referentiel-aquitain-dialog/confirm-sync-referentiel-aquitain-dialog.component';
import {LoaderService} from './services/loader.service';
import {RepartStructSiComponent} from './components/administration/repart-struct-si/repart-struct-si.component';
import {TodoComponent} from './components/administration/todo/todo.component';
import {CreateNewAssociationDialogComponent} from './layout/dialog/synchro-application-si/create-new-association-dialog/create-new-association-dialog.component';
import {UpdateCodeSiDialogComponent} from './layout/dialog/synchro-application-si/update-code-si-dialog/update-code-si-dialog.component';
import {StopAssociationSiDialogComponent} from './layout/dialog/synchro-application-si/stop-association-si-dialog/stop-association-si-dialog.component';
import {ArchivageStructureComponent} from './components/workflow/archivage-structure/archivage-structure.component';
import {ReactivationStructureComponent} from './components/workflow/reactivation-structure/reactivation-structure.component';
import {AdministrationLogsComponent} from './pages/administration/administration-logs/administration-logs.component';
import {AdministrationSynchroApplicationsSiComponent} from './pages/administration/administration-synchro-applications-si/administration-synchro-applications-si.component';
import {AdministrationArchivageStructureComponent} from './pages/administration/administration-archivage-structure/administration-archivage-structure.component';
import {AdministrationComptesApplicatifsComponent} from './pages/administration/administration-comptes-applicatifs/administration-comptes-applicatifs.component';
import {ReactivateAssociationSiDialogComponent} from './layout/dialog/synchro-application-si/reactivate-association-si-dialog/reactivate-association-si-dialog.component';
import {
  AdministrationSynchroReferentielAquitainComponent
} from './pages/administration/administration-synchro-referentiel-aquitain/administration-synchro-referentiel-aquitain.component';
import {SyncTasksComponent} from './components/administration/referentielAquitain/sync-tasks/sync-tasks.component';
import {ConfirmSyncApplicationSiDialogComponent} from './layout/dialog/synchro-application-si/confirm-sync-application-si-dialog/confirm-sync-application-si-dialog.component';
import {RechercheStructureFormComponent} from './components/ui/recherche-structure-form/recherche-structure-form.component';
import {AdresseCardComponent} from './components/adresse-contact/adresse-card/adresse-card.component';
import {ContactCardComponent} from './components/adresse-contact/contact-card/contact-card.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {ConfigAssetLoaderService} from './configAssetLoader.service';
import {AjoutModerateurComponent} from './components/privilege/ajout-moderateur/ajout-moderateur.component';
import {MapDialogComponent} from './layout/dialog/divers/map-dialog/map-dialog.component';
import {MapComponent} from './layout/dialog/divers/map-dialog/map.component';
import {StructuresSelectionWithTreeComponent} from './components/ui/structuresSelectionWithTree/structuresSelectionWithTree.component';
import {StructureTreeDialogComponent} from './layout/dialog/divers/structure-tree-dialog/structure-tree-dialog.component';
import {TypeVoierieComponent} from './pages/nomenclature/type-voierie/type-voierie.component';
import {HighlightStructureDirective} from './directives/highlight-structure.directive';
import {LibelleStructurePipe} from './pipes/libelle-structure.pipe';
import {PagetopComponent} from './layout/pagetop/pagetop.component';
import {MenuComponent} from './layout/menu/menu.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {AssociationSiService} from './services/association-si.service';
import {TodoService} from './services/todo.service';
import {TypeAdresseService} from './services/type-adresse.service';
import {TypeVoierieService} from './services/type-voierie.service';
import {TypeCodeService} from './services/type-code.service';
import {TypeLienService} from './services/type-lien.service';
import {TypeStructureService} from './services/type-structure.service';
import {UpdateClosingDateDialogComponent} from './layout/dialog/structure-lifecycle/update-closing-date-dialog/update-closing-date-dialog.component';
import {ArchiveDialogComponent} from './layout/dialog/structure-lifecycle/archive-dialog/archive-dialog.component';
import {ReactivateStructureDialogComponent} from './layout/dialog/structure-lifecycle/reactivate-structure-dialog/reactivate-structure-dialog.component';
import {
  SynchronizeReferentielAquitainDialogComponent
} from './layout/dialog/referentiel-aquitain/synchronize-referentiel-aquitain-dialog/synchronize-referentiel-aquitain-dialog.component';
import {LiensAntecedentsComponent} from './components/administration/liens-antecedents/liens-antecedents.component';
import {LiensAntecedentsDialogComponent} from './layout/dialog/structure/liens-antecedents-dialog/liens-antecedents-dialog.component';
import {StructureDetailsTitreComponent} from './components/structure/structure-details-titre/structure-details-titre.component';
import {InstanceComponent} from './pages/nomenclature/instance/instance.component';
import {InstanceDemandeFormComponent} from './components/demande/instance-demande-form/instance-demande-form.component';
import {ResultLdapDialogComponent} from './layout/dialog/ldap/result-ldap-dialog/result-ldap-dialog.component';
import {DatePipe} from './pipes/date.pipe';
import {AdministrationLiensAntecedentsComponent} from './pages/administration/administration-liens-antecedents/administration-liens-antecedents.component';
import {RechercheLogFormComponent} from './components/ui/recherche-log-form/recherche-log-form.component';
import {RechercheLienFormComponent} from './components/ui/recherche-lien-form/recherche-lien-form.component';
import {RechercheTodoFormComponent} from './components/ui/recherche-todo-form/recherche-todo-form.component';
import {
  CloseStructureWithActiveLinksDialogComponent
} from './layout/dialog/structure-lifecycle/close-structure-with-active-links-dialog/close-structure-with-active-links-dialog.component';
import {RechercherUtilisateurComponent} from './pages/gestion-des-privileges/rechercher-utilisateur/rechercher-utilisateur.component';
import {GestionAdministrateurModerateurComponent} from './pages/gestion-des-privileges/gestion-administrateur-moderateur/gestion-administrateur-moderateur.component';
import {PrivilegesParPersonneComponent} from './pages/gestion-des-privileges/privileges-par-personne/privileges-par-personne.component';
import {PrivilegesParStructureComponent} from './pages/gestion-des-privileges/privileges-par-structure/privileges-par-structure.component';
import {AddAdminModerateurDialogComponent} from './layout/dialog/privilege/add-admin-moderateur-dialog/add-admin-moderateur-dialog.component';
import {AddGestionnaireDialogComponent} from './layout/dialog/privilege/add-gestionnaire-dialog/add-gestionnaire-dialog.component';
import {LdapService} from './services/ldap.service';
import {HighlightResponsableDirective} from './directives/highlight-responsable.directive';
import {SearchLdapDialogComponent} from './layout/dialog/ldap/search-ldap-dialog/search-ldap-dialog.component';
import {ConfirmDialogComponent} from './layout/dialog/divers/confirm-dialog/confirm-dialog.component';
import {ResponsableHistoFormDialogComponent} from './layout/dialog/privilege/responsable-histo-form-dialog/responsable-histo-form-dialog.component';
import {EvenementsComponent} from './components/structure/evenements/evenements.component';
import {TimelineModule} from 'primeng/timeline';
import {CardModule} from 'primeng/card';
import {PaginatorModule} from 'primeng/paginator';
import {SidebarModule} from 'primeng/sidebar';
import {FileUploadModule} from 'primeng/fileupload';
import {HistoAssociationsDialogComponent} from './layout/dialog/synchro-application-si/histo-associations-dialog/histo-associations-dialog.component';
import {FooterComponent} from './layout/footer/footer.component';
import {UpdateStructuresModerateurDialogComponent} from './layout/dialog/privilege/update-structures-moderateur-dialog/update-structures-moderateur-dialog.component';
import {AddCompteAppliDialogComponent} from './layout/dialog/compte-application/add-compte-appli-dialog/add-compte-appli-dialog.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {LoginAsDialogComponent} from './layout/dialog/divers/login-as-dialog/login-as-dialog.component';
import {AideComponent} from './pages/aide/aide.component';
import {AideDialogComponent} from './layout/dialog/aide/aide-dialog/aide-dialog.component';
import {NgOptimizedImage} from '@angular/common';
import {AideDisplayDialogComponent} from './layout/dialog/aide/aide-display-dialog/aide-display-dialog.component';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {SafePipe} from './pipes/safe.pipe';
import {AdministrationSurveillanceConnexionsComponent} from './pages/administration/administration-surveillance-connexions/administration-surveillance-connexions.component';
import {HighlightActiveConnexionDirective} from './directives/highlight-active-connexion.directive';
import {ParametreDialogComponent} from './layout/dialog/divers/parametre-dialog/parametre-dialog.component';
import {UpdateLienDialogComponent} from './layout/dialog/structure/update-lien-dialog/update-lien-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    PagetopComponent,
    CreationStructureOrganisationnelleComponent,
    MenuComponent,
    PrivilegesParPersonneComponent,
    RechercheLdapFormComponent,
    SelectionStructureComponent,
    ErrorAuthenticationComponent,
    RemovePrivilegeDialogComponent,
    CreationStructureOrganisationnelleComponent,
    StructureTreeComponent,
    DashboardComponent,
    DemandeComponent,
    DemandeHistoriqueComponent,
    AjoutResponsableComponent,
    AcceptationPourValidationComponent,
    DemandeModificationComponent,
    AnnulationDemandeComponent,
    DemandeDetailsComponent,
    RefusDemandeComponent,
    ValidationDemandeComponent,
    CreationStructureMatricielleComponent,
    StructureDetailsComponent,
    DemandeStructOrgaFormComponent,
    DemandeStructMatFormComponent,
    ModificationStructureOrganisationnelleComponent,
    ModificationStructureMatricielleComponent,
    MesStructuresComponent,
    CaracteristiquesGeneralesComponent,
    IdentiteFormComponent,
    HistoStructureDialogComponent,
    IdentiteEnCoursDialogComponent,
    StructuresAssocieesComponent,
    AjoutStructureRattachementComponent,
    LienFormComponent,
    AjoutLienNonHierarchiqueComponent,
    ModificationStructureRattachementComponent,
    SuppressionLienComponent,
    DescriptionComponent,
    FermetureStructureComponent,
    StructureAdminComponent,
    HistoDescriptionDialogComponent,
    AdresseFormComponent,
    ContactFormComponent,
    AdressesContactsComponent,
    CaracterisationComponent,
    RemoveCaracterisationDialogComponent,
    AddCaracterisationDialogComponent,
    UpdateCaracterisationDialogComponent,
    ConsultationDemandesComponent,
    PrendreEnChargeComponent,
    RelancerComponent,
    DemandeInformationsComplementairesComponent,
    AjoutInformationsComplementairesComponent,
    RechercheDemandeFormComponent,
    RechercheComponent,
    TypeStructureComponent,
    TutelleComponent,
    DomaineComponent,
    TypeLienComponent,
    TypeCodeComponent,
    TypeAdresseComponent,
    ApplicationSIComponent,
    StructuresAssocieesDialogComponent,
    LogComponent,
    SynchroReferentielAquitainComponent,
    ReferentielAquitainFormDialogComponent,
    ReferentielAquitainFormComponent,
    ConfirmSyncReferentielAquitainDialogComponent,
    RepartStructSiComponent,
    TodoComponent,
    CreateNewAssociationDialogComponent,
    UpdateCodeSiDialogComponent,
    ReactivateAssociationSiDialogComponent,
    StopAssociationSiDialogComponent,
    ArchivageStructureComponent,
    ReactivationStructureComponent,
    AdministrationSynchroReferentielAquitainComponent,
    AdministrationLogsComponent,
    AdministrationSynchroApplicationsSiComponent,
    AdministrationArchivageStructureComponent,
    AdministrationComptesApplicatifsComponent,
    SyncTasksComponent,
    ConfirmSyncApplicationSiDialogComponent,
    RechercheStructureFormComponent,
    SidebarComponent,
    AdresseCardComponent,
    ContactCardComponent,
    AjoutModerateurComponent,
    MapDialogComponent,
    MapComponent,
    StructuresSelectionWithTreeComponent,
    StructureTreeDialogComponent,
    TypeVoierieComponent,
    HighlightStructureDirective,
    LibelleStructurePipe,
    UpdateClosingDateDialogComponent,
    ArchiveDialogComponent,
    ReactivateStructureDialogComponent,
    SynchronizeReferentielAquitainDialogComponent,
    LiensAntecedentsComponent,
    LiensAntecedentsDialogComponent,
    StructureDetailsTitreComponent,
    InstanceComponent,
    InstanceDemandeFormComponent,
    ResultLdapDialogComponent,
    DatePipe,
    AdministrationLiensAntecedentsComponent,
    RechercheLogFormComponent,
    RechercheLienFormComponent,
    RechercheTodoFormComponent,
    CloseStructureWithActiveLinksDialogComponent,
    RechercherUtilisateurComponent,
    GestionAdministrateurModerateurComponent,
    PrivilegesParPersonneComponent,
    PrivilegesParStructureComponent,
    AddAdminModerateurDialogComponent,
    AddGestionnaireDialogComponent,
    HighlightResponsableDirective,
    SearchLdapDialogComponent,
    ConfirmDialogComponent,
    ResponsableHistoFormDialogComponent,
    EvenementsComponent,
    HistoAssociationsDialogComponent,
    FooterComponent,
    UpdateStructuresModerateurDialogComponent,
    AddCompteAppliDialogComponent,
    LoginAsDialogComponent,
    AideComponent,
    AideDialogComponent,
    AideDisplayDialogComponent,
    SafePipe,
    AdministrationSurveillanceConnexionsComponent,
    HighlightActiveConnexionDirective,
    ParametreDialogComponent,
    UpdateLienDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ChartModule,
    DialogModule,
    DynamicDialogModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true
    }),
    PaginatorModule,
    SidebarModule,
    CardModule,
    TimelineModule,
    TableModule,
    TreeModule,
    AppRoutingModule,
    HttpClientModule,
    FileUploadModule,
    ClipboardModule,
    NgOptimizedImage,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [
    ApplicationService,
    AssociationSiService,
    AdresseService,
    AuthenticationService,
    CodeService,
    ConfigAssetLoaderService,
    ContactService,
    DemandeService,
    DescriptionStructureService,
    DialogService,
    DomaineService,
    GlobalStateService,
    IdentiteStructureService,
    IndividuService,
    LienService,
    LoaderService,
    LogService,
    RoleService,
    StructureService,
    StructureTreeService,
    SyncRefAqService,
    TutelleService,
    TodoService,
    TypeAdresseService,
    TypeCodeService,
    TypeLienService,
    TypeStructureService,
    TypeVoierieService,
    UserService,
    LdapService,
    WorkflowService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM/YYYY',
        },
      },
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}},
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigAssetLoaderService) => () => configService.loadConfigurations().toPromise(),
      deps: [ConfigAssetLoaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class AppModule {
}
