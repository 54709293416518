import {Component} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-error-authentication',
  templateUrl: './error-authentication.component.html',
  styleUrls: ['./error-authentication.component.scss']
})
export class ErrorAuthenticationComponent {

  constructor(private authenticationService: AuthenticationService) {
  }

  logout() {
    this.authenticationService.redirectToCasLogin();
  }

}
