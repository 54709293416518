<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">

  <form [formGroup]="form" *ngIf="data.action !== action.REMOVE">
    <!--STRUCTURE-->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Structure nouvelle</mat-label>
      <input type="text" placeholder="Recherche" aria-label="Structure" matInput
             formControlName="structure" [matAutocomplete]="struct">
      <mat-autocomplete #struct="matAutocomplete">
        <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of filteredStructures | async"
                    (click)="onStructureSelect(struct)"
                    (onSelectionChange)="onStructureSelect(struct)"
                    [value]="struct.identite.libelleLong">
          {{struct | libelleStructure:user}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!--TYPE LIEN -->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Type de structure</mat-label>
      <mat-select formControlName="typeLien" placeholder="Type de lien" required>
        <mat-option *ngFor="let t of typeLiens" [value]="t.typeLienId">{{ t.typeLienLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['typeLien'].hasError('required') && form.controls['typeLien'].touched">
        Valeur requise</mat-error>
    </mat-form-field>

    <!--STRUCTURE ANTECEDENTE-->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Structure antécédente</mat-label>
      <input type="text" placeholder="Recherche" aria-label="StructurePorteuse" matInput (input)="onInput()"
             formControlName="structurePorteuse" [matAutocomplete]="structPorteuse" required>
      <mat-autocomplete #structPorteuse="matAutocomplete">
        <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of filteredStructuresPorteuses | async"
                    (click)="onStructurePorteuseSelect(struct)"
                    (onSelectionChange)="onStructurePorteuseSelect(struct)"
                    [value]="struct.identite.libelleLong">
          {{struct | libelleStructure:user}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="form.controls['structurePorteuse'].hasError('required') && form.controls['structurePorteuse'].touched">Valeur requise</mat-error>
      <mat-error *ngIf="form.controls['structurePorteuse'].hasError('invalid-structure')">Vous devez sélectionner une structure parmi la liste déroulante</mat-error>
    </mat-form-field>

    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de début</mat-label>
      <input formControlName="dateDebut" matInput [matDatepicker]="debpicker" placeholder="Date de début" required>
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateDebut'].invalid && form.controls['dateDebut'].touched">Format non valide</mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="validate()"
          [disabled]="data.action !== action.REMOVE && (form.invalid || !structure || !structurePorteuse || form.pristine)">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
