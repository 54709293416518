import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LdapAccount, Privilege, Role} from '../model/user.model';
import {TYPE_ROLE} from '../model/enum.model';

@Injectable()
export class RoleService {

  constructor(private http: HttpClient) {
  }

  public getRoleByRoleId(roleId: number): Observable<Role> {
    return this.http.get<Role>('/role/' + roleId);
  }

  public getAllRolesByStructureAndTypeRole(structureId: number, typeRole: TYPE_ROLE): Observable<Role[]> {
    return this.http.get<Role[]>('/role/structure/' + structureId + '/type-role/' + typeRole);
  }

  public getGestionnairesEtendus(structureId: number): Observable<Role[]> {
    return this.http.get<Role[]>('/role/gestionnaires-etendus/structure/' + structureId);
  }

  public getRolesValidesByStructureAndTypeRole(structureId: number, typeRole: TYPE_ROLE): Observable<Role[]> {
    return this.http.get<Role[]>('/role/roles-valides/structure/' + structureId + '/type-role/' + typeRole);
  }

  public getRolesByIndividuAndTypeRole(individuId: number, typeRole: TYPE_ROLE): Observable<Role[]> {
    return this.http.get<Role[]>('/role/individu/' + individuId + '/type-role/' + typeRole);
  }

  public subtituteModerateur(roleId :number, privilege: Privilege): Observable<void> {
    return this.http.put<void>('/role/substitute-moderateur/' + roleId, privilege);
  }

  public create(privilege: Privilege): Observable<void> {
    return this.http.put<void>('/role/create', privilege);
  }

  public close(roleId:number): Observable<Role> {
    return this.http.put<Role>('/role/close/' + roleId, null);
  }

  public insert(role: Role, ldapAccount: LdapAccount): Observable<Role> {
    let params = new HttpParams();
    params = params.append('idnum', ldapAccount.uid);
    params = params.append('nom', ldapAccount.nom);
    params = params.append('prenom', ldapAccount.prenom);
    params = params.append('mail', ldapAccount.mail);
    return this.http.put<Role>('/role/insert', role, {params});
  }

  public update(role: Role, ldapAccount: LdapAccount): Observable<Role> {
    let params = new HttpParams();
    params = params.append('idnum', ldapAccount.uid);
    params = params.append('nom', ldapAccount.nom);
    params = params.append('prenom', ldapAccount.prenom);
    params = params.append('mail', ldapAccount.mail);
    return this.http.put<Role>('/role/update', role, {params});
  }

  public delete(roleId: number): Observable<void> {
    return this.http.delete<void>('/role/delete/' + roleId);
  }

}
