import * as moment from 'moment';

export function getDDMMYYYYHHmm(date: Date): string {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : '';
}

export function getDDMMYYYY(date: Date): string {
  return date ? moment(date).format('DD/MM/YYYY') : '';
}

export function getYYYYMMDD(date: Date): string {
  return date ? moment(date).format('YYYYMMDD') : '';
}

export function formatLocalDateWithUTC(date: Date): Date {
  let newDate = null;
  if (date) {
    newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  }
  return newDate;
}
