import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-sync-application-si-dialog',
  templateUrl: './confirm-sync-application-si-dialog.component.html',
  styleUrls: ['./confirm-sync-application-si-dialog.component.scss']
})
export class ConfirmSyncApplicationSiDialogComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<ConfirmSyncApplicationSiDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.form = new FormGroup({
      codeSI: new FormControl('FAKE_VALUE', Validators.required),
      motif: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    if (this.data.isStart) {
      this.form.reset();
    }
  }

  onCancel(): void {
    this.dialogRef.close({ok: false, form: null});
  }

  onConfirm(): void {
    this.dialogRef.close({ok: true, form: this.form});
  }
}

export interface DialogData {
  question: string;
  isStart: boolean;
}
