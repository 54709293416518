export function normalize(str: string): string {
  let result = null;
  if (str) {
    result = str
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  return result;
}

export function compare(a: string, b: string): number {
  if (normalize(a) < normalize(b)) {
    return -1;
  }
  if (normalize(a) > normalize(b)) {
    return 1;
  }
  return 0;
}
