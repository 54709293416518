<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de refus de la demande</h4>
    </mat-card-header>

    <mat-card-content>
      <p class="mt-4 alert-warning"
         *ngIf="[typeDemande.CREATION_STRUCT_ORGA_INTERNE.valueOf(), typeDemande.CREATION_STRUCT_ORGA_EXT.valueOf(), typeDemande.CREATION_STRUCT_MAT.valueOf()].includes(demande.typeDemande.typeDemandeId)">
        L'annulation de votre demande de création a bien été prise en compte.
      </p>

      <mat-form-field class="col-lg-12" appearance="outline">
        <mat-label>Motif de refus</mat-label>
        <textarea matInput
                  [(ngModel)]="motif"
                  [maxLength]="200"
                  placeholder="Saisie du motif de refus de la demande"></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary" matTooltip="Demande de refus"
                (click)="refuse()" [disabled]="!motif || motif.trim() === ''">
          {{'refuser la demande' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
