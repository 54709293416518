import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SyncRefAq, SyncRefAqTask, SyncRequest} from '../model/referentiel-aquitain.model';
import {KeyValue} from '../model/divers.model';

@Injectable()
export class SyncRefAqService {

  constructor(private http: HttpClient) {
  }

  public getSyncRefAqByLienId(lienId: number): Observable<SyncRefAq> {
    return this.http.get<SyncRefAq>('/sync-ref-aqu/lien/' + lienId);
  }

  public getSyncTasksByStructure(structureId: number): Observable<SyncRefAqTask[]> {
    return this.http.get<SyncRefAqTask[]>('/sync-ref-aqu/tasks/structure/' + structureId);
  }

  public getAllRefTypesStructure(): Observable<any> {
    return this.http.get<any>('/sync-ref-aqu/types-structure');
  }

  public getAllRefCatStructure(): Observable<any> {
    return this.http.get<any>('/sync-ref-aqu/categories-structure');
  }

  public getAllRefTypesLien(): Observable<any> {
    return this.http.get<any>('/sync-ref-aqu/types-lien');
  }

  public getAllRefStructures(): Observable<any> {
    return this.http.get<any>('/sync-ref-aqu/structures');
  }

  public getAllTasks(): Observable<SyncRefAqTask[]> {
    return this.http.get<SyncRefAqTask[]>('/sync-ref-aqu/tasks/all');
  }

  public activateSynchro(syncRequest: SyncRequest): Observable<KeyValue> {
    return this.http.put<KeyValue>('/sync-ref-aqu/activate-synchro', syncRequest);
  }

  public reactivateSynchro(structureId: number, commentaire: string): Observable<void> {
    return this.http.put<void>('/sync-ref-aqu/reactivate-synchro/' + structureId, commentaire);
  }

  public stopSynchro(structureId: number, commentaire: string): Observable<void> {
    return this.http.put<void>('/sync-ref-aqu/stop-synchro/' + structureId, commentaire);
  }

  public forceSynchro(syncId: number, commentaire: string): Observable<void> {
    return this.http.put<void>('/sync-ref-aqu/force-synchro/' + syncId, commentaire);
  }

  public redoSynchro(syncId: number): Observable<KeyValue> {
    return this.http.put<KeyValue>('/sync-ref-aqu/redo-synchro/' + syncId, null);
  }

}
