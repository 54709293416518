import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AcceptRequest,
  CancelRequest,
  CreationRequest,
  ReactivationRequest,
  RefuseRequest,
  LienRequest,
  ModificationCreationRequest,
  ModificationIdentiteRequest,
  ValidationRequest, TakeOverRequest, AddInfoRequest, AskInfoRequest, RekindleRequest, CloseStructureRequest, ModificationResponsableRequest
} from '../model/workflow.model';
import {Observable} from 'rxjs';
import {Demande} from '../model/demande.model';

@Injectable()
export class WorkflowService {

  constructor(private http: HttpClient) {
  }

  public create(newRequest: CreationRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/create', newRequest);
  }

  public cancel(cancelRequest: CancelRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/cancel', cancelRequest);
  }

  public refuse(refuseRequest: RefuseRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/refuse', refuseRequest);
  }

  public validate(validationRequest: ValidationRequest): Observable<any> {
    return this.http.put<any>('/workflow/validate', validationRequest, {observe: 'response'});
  }

  public acceptForValidation(acceptRequest: AcceptRequest): Observable<any> {
    return this.http.put<any>('/workflow/accept-for-validation', acceptRequest, {observe: 'response'});
  }

  public requestModification(requestModification: ModificationCreationRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/request-modification', requestModification);
  }

  public modifyIdentite(requestModificationIdentite: ModificationIdentiteRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/modify-structure-identite', requestModificationIdentite);
  }

  public takeOver(takeOverRequest: TakeOverRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/take-over', takeOverRequest);
  }

  public addInfos(addInfoRequest: AddInfoRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/add-infos', addInfoRequest);
  }

  public askInfos(askInfoRequest: AskInfoRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/ask-infos', askInfoRequest);
  }

  public rekindle(rekindleRequest: RekindleRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/rekindle', rekindleRequest);
  }

  public sendNewSubmission(creationRequest: CreationRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/send-new-submission', creationRequest);
  }

  public updateResponsable(mofificationResponsableRequest: ModificationResponsableRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/update-responsable', mofificationResponsableRequest);
  }

  public insertLien(requestLien: LienRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/insert-lien', requestLien);
  }

  public removeLien(requestLien: LienRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/remove-lien', requestLien);
  }

  public modifyStructureRattachement(requestLien: LienRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/modify-structure-rattachement', requestLien);
  }

  public closeStructure(closeStructureRequest: CloseStructureRequest): Observable<Demande> {
    return this.http.put<Demande>('/workflow/close-structure', closeStructureRequest);
  }

  public archiveStructure(structureId: number, motif: string): Observable<void> {
    return this.http.put<void>('/workflow/archive-structure/' + structureId, motif);
  }

  public reactivateStructure(reactivationRequest: ReactivationRequest): Observable<void> {
    return this.http.put<void>('/workflow/reactivate-structure', reactivationRequest);
  }
}
