<div *ngIf="user && datasource?.length > 0">
  <div class="row justify-content-center mt-4">
    <p-paginator class="col-lg-12"
                 [rows]="rows"
                 [totalRecords]="totalRecords"
                 [pageLinkSize]="pageLinks"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [first]="first"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                 (onPageChange)="onPageChange($event)"
                 *ngIf="!structure">
    </p-paginator>
  </div>

  <div class="row justify-content-center mt-4">
    <p-table class="col-12"
             dataKey="demandeId"
             [columns]="cols"
             [value]="datasource"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">{{title}}</ng-template>

      <!--HEADERS-->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="text-center" style="width:6em">Détails</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="text-center" style="width:6em" *ngIf="displayActionGestionnaire">Annuler</th>
          <th class="text-center" style="width:6em" *ngIf="displayActionGestionnaire">Relancer</th>
          <th class="text-center" style="width:6em" *ngIf="displayActionAdministrateur">Prendre en charge</th>
          <th class="text-center" style="width:6em" *ngIf="displayActionAdministrateur">Valider</th>
          <th class="text-center" style="width:6em" *ngIf="displayActionAdministrateur">Refuser</th>
        </tr>
      </ng-template>

      <!--BODY-->
      <ng-template pTemplate="body" let-rowDem let-expanded="expanded">
        <tr>
          <td class="text-center">
            <button class="button" matTooltip="Voir le détail de la demande" [pRowToggler]="rowDem"
                    [pRowTogglerDisabled]="expanded && modeExpansion !== mode.DETAILS"
                    (click)="expandRow(mode.DETAILS)">
              <span><i class="fas fa-info-circle fa-2x"></i></span>
            </button>
          </td>

          <td>{{rowDem['typeDemande']}}</td>
          <td><a href appHighlightStructure
                 [structure]="rowDem.structure"
                 [routerLink]="'/structure/' +  rowDem.structure.structureId">
            {{rowDem['structure'] | libelleStructure:user}}</a></td>
          <td>{{rowDem['etatDemande']}}
            <span matTooltip="Il n'y a plus de modérateur défini sur cette demande !" *ngIf="rowDem.demande.etatDemande === etatsDemande.A_VALIDER.valueOf() && !rowDem.demande.moderateur">
            <i class="ml-2 pi pi-spin pi-exclamation-triangle" style="font-size: 2rem; color: red"></i>
            </span>
          </td>
          <td>{{rowDem['demandeur']}}</td>
          <td>{{rowDem['dateDemande']}}</td>

          <!--ANNULER-->
          <td class="text-center" *ngIf="displayActionGestionnaire">
            <button class="button" matTooltip="Annuler" [pRowToggler]="rowDem"
                    [pRowTogglerDisabled]="expanded && modeExpansion !== mode.CANCEL"
                    (click)="expandRow(mode.CANCEL)"
                    *ngIf="![etatsDemande.VALIDEE, etatsDemande.ANNULEE, etatsDemande.REFUSEE].includes(rowDem.demande.etatDemande)
                      && (isInGestRespPerimeter(rowDem.demande.lien.structure) || user.individu.individuId === rowDem.demande.demandeur.individuId)">
              <i class="fas fa-trash fa-2x"></i></button>
          </td>

          <!--RELANCER-->
          <td class="text-center" *ngIf="displayActionGestionnaire">
            <button class="button" matTooltip="Relancer la demande" [pRowToggler]="rowDem"
                    [pRowTogglerDisabled]="expanded && modeExpansion !== mode.REKINDLE"
                    (click)="expandRow(mode.REKINDLE)"
                    *ngIf="rowDem.demande.isRelancable
                      && ![etatsDemande.VALIDEE, etatsDemande.ANNULEE, etatsDemande.REFUSEE].includes(rowDem.demande.etatDemande)
                      && (isInGestRespPerimeter(rowDem.demande.lien.structure) || user.individu.individuId === rowDem.demande.demandeur.individuId)">
              <i class="fas fa-redo fa-2x"></i></button>
          </td>

          <!--PRENDRE EN CHARGE-->
          <td class="text-center" *ngIf="displayActionAdministrateur">
            <button class="button" matTooltip="Prendre en charge"
                    (click)="prendreEnCharge(rowDem)"
                    *ngIf="user.individu.isAdmin
                        && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER].includes(rowDem.demande.etatDemande)
                      || (
                        isInModerPerimeter(rowDem.demande.lien.structure)
                            && [etatsDemande.A_VALIDER].includes(rowDem.demande.etatDemande)
                            && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)
                      )">
              <i class="fas fa-folder-open fa-2x"></i>
            </button>
          </td>

          <!--VALIDER -->
          <td class="text-center" *ngIf="displayActionAdministrateur">
            <div
              [matTooltip]="user.individu.isModerateur || (user.individu.isAdmin && rowDem.demande.typeDemande.typeDemandeId !== typesDemande.CREATION_STRUCT_ORGA_INTERNE) ? 'Valider la demande' :
               !hasModerateurs ? 'La validation technique de la demande ne pourra pas être effectuée car il n\'y a pas de modérateur pour la dernière phase de validation administrative' : 'Accepter la demande pour validation'">
              <button class="button"
                      [pRowToggler]="rowDem"
                      [pRowTogglerDisabled]="
                      (
                         expanded
                         && modeExpansion !== mode.ACCEPT
                         && !(
                          (
                            user.individu.isAdmin
                            && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER].includes(rowDem.demande.etatDemande)
                            && ![
                             typesDemande.AJOUT_LIEN_HIERARCHIQUE,
                             typesDemande.MODIFICATION_IDENT,
                             typesDemande.MODIFICATION_LIEN_HIERARCHIQUE,
                             typesDemande.FERMETURE_STRUCTURE,
                             typesDemande.SUPPRESSION_LIEN,
                             typesDemande.CREATION_STRUCT_ORGA_INTERNE
                            ].includes(rowDem.demande.typeDemande.typeDemandeId)
                          ) || (
                           user.individu.isModerateur
                           && rowDem.demande.etatDemande === etatsDemande.A_VALIDER
                           && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)
                          )
                        )

                     ) || (
                        !expanded
                        && (
                          (
                            user.individu.isAdmin
                            && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER].includes(rowDem.demande.etatDemande)
                            && ![
                             typesDemande.AJOUT_LIEN_HIERARCHIQUE,
                             typesDemande.MODIFICATION_IDENT,
                             typesDemande.MODIFICATION_LIEN_HIERARCHIQUE,
                             typesDemande.FERMETURE_STRUCTURE,
                             typesDemande.SUPPRESSION_LIEN,
                             typesDemande.CREATION_STRUCT_ORGA_INTERNE
                            ].includes(rowDem.demande.typeDemande.typeDemandeId)
                          ) || (
                           user.individu.isModerateur
                           && rowDem.demande.etatDemande === etatsDemande.A_VALIDER
                           && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)
                          )
                        )
                     )"
                      [disabled]="!hasModerateurs && rowDem.demande.typeDemande.typeDemandeId === typesDemande.CREATION_STRUCT_ORGA_INTERNE"
                      (click)="valider(rowDem.demande)"
                      *ngIf="user.individu.isAdmin && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER, etatsDemande.PRISE_EN_CHARGE_NIV_1].includes(rowDem.demande.etatDemande)
                    || (isInModerPerimeter(rowDem.demande.lien.structure)
                          && [etatsDemande.A_VALIDER, etatsDemande.PRISE_EN_CHARGE_NIV_2].includes(rowDem.demande.etatDemande)
                           && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)
                        )">
                <i class="fas fa-check fa-2x"></i></button>
            </div>
          </td>

          <!--REFUSER-->
          <td class="text-center" *ngIf="displayActionAdministrateur">
            <button class="button" matTooltip="Refuser" [pRowToggler]="rowDem"
                    [pRowTogglerDisabled]="expanded && modeExpansion !== mode.REFUSE"
                    (click)="expandRow(mode.REFUSE)"
                    *ngIf="user.individu.isAdmin
                      && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER, etatsDemande.PRISE_EN_CHARGE_NIV_1].includes(rowDem.demande.etatDemande)
                    || (
                      isInModerPerimeter(rowDem.demande.lien.structure)
                      && [etatsDemande.A_VALIDER, etatsDemande.PRISE_EN_CHARGE_NIV_2].includes(rowDem.demande.etatDemande)
                      && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)
                    )">
              <i class="fas fa-times fa-2x"></i></button>
          </td>
        </tr>
      </ng-template>

      <!--ROW EXPANSION-->
      <ng-template pTemplate="rowexpansion" let-rowDem>
        <tr>
          <td [attr.colspan]="getColSpan()" class="">
            <app-demande-details [demande]="rowDem.demande"
                                 (actionEmitter)="onAction()"
                                 *ngIf="modeExpansion === mode.DETAILS"></app-demande-details>

            <app-acceptation-pour-validation [demande]="rowDem.demande"
                                             (actionEmitter)="onAction()"
                                             *ngIf="modeExpansion === mode.ACCEPT
                                              && user.individu.isAdmin
                                              && [etatsDemande.NOUVEAU, etatsDemande.A_TRAITER, etatsDemande.PRISE_EN_CHARGE_NIV_1].includes(rowDem.demande.etatDemande)
                                              && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId)">
            </app-acceptation-pour-validation>

            <app-validation-demande [demande]="rowDem.demande"
                                    (actionEmitter)="onAction()"
                                    *ngIf="modeExpansion === mode.ACCEPT
                                     && user.individu.isAdmin
                                     && (
                                      ([etatsDemande.NOUVEAU, etatsDemande.A_TRAITER, etatsDemande.PRISE_EN_CHARGE_NIV_1].includes(rowDem.demande.etatDemande)
                                        && [typesDemande.AJOUT_LIEN_HIERARCHIQUE,
                                         typesDemande.MODIFICATION_IDENT,
                                         typesDemande.SUPPRESSION_LIEN,
                                         typesDemande.MODIFICATION_LIEN_HIERARCHIQUE,
                                         typesDemande.FERMETURE_STRUCTURE
                                        ].includes(rowDem.demande.typeDemande.typeDemandeId)) ||
                                        ([etatsDemande.A_VALIDER, etatsDemande.PRISE_EN_CHARGE_NIV_2].includes(rowDem.demande.etatDemande)
                                        && [typesDemande.CREATION_STRUCT_ORGA_INTERNE].includes(rowDem.demande.typeDemande.typeDemandeId))
                                     )">
            </app-validation-demande>

            <app-annulation-demande [demande]="rowDem.demande"
                                    (actionEmitter)="onAction()"
                                    *ngIf="modeExpansion === mode.CANCEL"></app-annulation-demande>

            <app-refus-demande [demande]="rowDem.demande"
                               (actionEmitter)="onAction()"
                               *ngIf="modeExpansion === mode.REFUSE"></app-refus-demande>

            <app-relancer [demande]="rowDem.demande"
                          (actionEmitter)="onAction()"
                          *ngIf="modeExpansion === mode.REKINDLE"></app-relancer>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
