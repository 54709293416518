import {Component, Input, OnChanges} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {EventService} from '../../../services/event.service';
import {PrimeIcons} from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-evenements',
  templateUrl: './evenements.component.html',
  styleUrls: ['./evenements.component.scss']
})
export class EvenementsComponent implements OnChanges {

  @Input() structure: Structure;

  eventList: any;
  dates: string[];

  primeIcons = PrimeIcons;
  status = STATUS;

  constructor(private eventService: EventService) {
  }

  ngOnChanges(): void {
    if (this.structure) {
      this.eventService.getAllEvents(this.structure.structureId).subscribe(events => {
        const groupBy = (xs, key) => {
          return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };

        this.eventList = groupBy(events, 'date');
        this.dates = Object.keys(this.eventList)
          .map(d => moment(d, 'YYYY-MM-DD'))
          .sort((a, b) => {
            if (a.isBefore(b)) {
              return 1;
            } else if (a.isAfter(b)) {
              return -1;
            } else {
              return 0;
            }
          }).map(d => d.format('YYYY-MM-DD'));
      });
    }
  }
}

export enum STATUS {
  DEMANDE = 'DEMANDE',
  LOG = 'LOG',
  ASSOCIATION = 'ASSOCIATION',
  TODO = 'TODO'
}

