import {X_TYPE_ENUM} from './enum.constant';

export const X_MESSAGE = 'x-message';
export const X_DATA = 'x-data';
export const X_TYPE = 'x-type';
export const AUTHORIZATION = 'Authorization';

export const NOTIF_STYLE: Map<string, string> = new Map<string, string>([
  [X_TYPE_ENUM.SUCCESS, 'notif-success'],
  [X_TYPE_ENUM.INFO, 'notif-info'],
  [X_TYPE_ENUM.WARNING, 'notif-warn'],
  [X_TYPE_ENUM.ERROR, 'notif-error']
]);


