<div *ngIf="structure">
  <div class="row mt-4">
    <app-structure-details-titre class="col-lg-12" [structure]="structure"></app-structure-details-titre>
  </div>

  <div class="row justify-content-center mt-4">
    <!--BOUTON CONSULTER LES DEMANDES ASSOCIEES-->
    <div
      matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
      [matTooltipDisabled]="user.individu.isAdmin || ((user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter())">
      <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
              (click)="selectedAction = action.CONSULTER_DEMANDE"
              [disabled]="!(user.individu.isAdmin || ((user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter()))"
              *ngIf="demandesAssociees?.length > 0 && selectedAction === action.DETAILS">Consulter les demandes
        associées
      </button>
    </div>

    <!--BOUTON CREER UNE SOUS-STRUCTURE ORGANISATIONNELLE-->
    <div
      matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
      [matTooltipDisabled]="(user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter() || user.individu.isAdmin">
      <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
              (click)="createSousStructure(catStructure.ORGA)"
              [disabled]="!((user.individu.isGestionnaire  || user.individu.isResponsable) && isInUserGestRespPerimeter() || user.individu.isAdmin)"
              *ngIf="[etatStructure.EMBRYON.valueOf(), etatStructure.NOUVEAU.valueOf(), etatStructure.ACTIF.valueOf()].includes(structure.etatStructure) && selectedAction === action.DETAILS">
        Créer une sous-structure organisationnelle
      </button>
    </div>

    <!--BOUTON CREER UNE SOUS-STRUCTURE MATRICIELLE-->
    <div
      matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
      [matTooltipDisabled]="(user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter() || user.individu.isAdmin">
      <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
              (click)="createSousStructure(catStructure.MAT)"
              [disabled]="!((user.individu.isGestionnaire  || user.individu.isResponsable) && isInUserGestRespPerimeter() || user.individu.isAdmin)"
              *ngIf="[etatStructure.EMBRYON.valueOf(), etatStructure.NOUVEAU.valueOf(), etatStructure.ACTIF.valueOf()].includes(structure.etatStructure) && selectedAction === action.DETAILS">
        Créer une sous-structure matricielle
      </button>
    </div>

    <!--BOUTON DEMANDER LA FERMETURE DE LA STRUCTURE-->
    <div [matTooltip]="!(user.individu.isAdmin || ((user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter())) ?
            'Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir' :  !(structure.etatStructure === etatStructure.ACTIF && !hasDemandeEnCours) ?
            'Les demandes en cours de la structure et/ou des structures filles doivent être clôturées pour procéder à une demande de fermeture.' : !(structure.etatStructure === etatStructure.ACTIF && !hasDemandeFermeture) ?
            'Une demande de fermeture a déjà été faite pour une structure parente.' : null"
         [matTooltipDisabled]=" (user.individu.isAdmin || (user.individu.isGestionnaire  || user.individu.isResponsable) && isInUserGestRespPerimeter())
          && structure.etatStructure === etatStructure.ACTIF
          && !hasDemandeEnCours
          && !hasDemandeFermeture">
      <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
              (click)="selectedAction = action.FERMER"
              [disabled]="!(
                (user.individu.isAdmin || (user.individu.isGestionnaire  || user.individu.isResponsable) && isInUserGestRespPerimeter())
                && structure.etatStructure === etatStructure.ACTIF
                && !hasDemandeEnCours
                && !hasDemandeFermeture
            )"
              *ngIf="selectedAction === action.DETAILS">
        Demander la fermeture de la structure
      </button>
    </div>

    <!--BOUTON ARCHIVER LA STRUCTURE-->
    <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
            (click)="selectedAction = action.ARCHIVER"
            *ngIf="[etatStructure.A_ARCHIVER.valueOf()].includes(structure.etatStructure) && user.individu.isAdmin && selectedAction === action.DETAILS">
      Archiver la structure
    </button>

    <!--BOUTON REACTIVER LA STRUCTURE-->
    <button class="d-inline-block ml-2 mt-2" mat-raised-button color="primary"
            *ngIf="[etatStructure.A_ARCHIVER.valueOf(), etatStructure.ARCHIVE.valueOf()].includes(structure.etatStructure) && user.individu.isAdmin"
            (click)="selectedAction = action.REACTIVER">
      Réactiver la structure
    </button>

    <!--BOUTON RETOUR AU DETAIL DE LA STRUCTURE-->
    <button class="float-right" mat-raised-button color="primary"
            (click)="selectedAction = action.DETAILS"
            *ngIf="selectedAction !== action.DETAILS">Retour au détails de la structure
    </button>
  </div>


  <app-demande [demandes]="demandesAssociees" title="Demandes associées"
               [displayActionAdministrateur]="false"
               [displayActionGestionnaire]="false"
               [structure]="structure"
               *ngIf="selectedAction === action.CONSULTER_DEMANDE"></app-demande>

  <mat-tab-group class="col-lg-12 mt-4" *ngIf="selectedAction === action.DETAILS">
    <mat-tab label="Caractéristiques générales">
      <app-caracteristiques-generales [structure]="structure"></app-caracteristiques-generales>
    </mat-tab>

    <mat-tab label="Structures associées">
      <app-structures-associees [structure]="structure"></app-structures-associees>
    </mat-tab>

    <mat-tab label="Description">
      <app-description [structure]="structure"></app-description>
    </mat-tab>

    <mat-tab label="Adresses et contacts">
      <app-adresses-contacts [structure]="structure"></app-adresses-contacts>
    </mat-tab>

    <mat-tab label="Caractérisation">
      <app-caracterisation [structure]="structure"></app-caracterisation>
    </mat-tab>

    <mat-tab [label]="user.individu.isAdmin || user.individu.isModerateur? 'Administration' : 'Correspondances SI'">
      <app-structure-admin [structure]="structure"></app-structure-admin>
    </mat-tab>
  </mat-tab-group>

  <app-fermeture-structure [structure]="structure"
                           [hasLiensParentsOnly]="hasLiensParentsOnly"
                           (actionEmitter)="init()"
                           *ngIf="selectedAction === action.FERMER"></app-fermeture-structure>

  <app-archivage-structure [structure]="structure"
                           (actionEmitter)="init()"
                           *ngIf="selectedAction === action.ARCHIVER"></app-archivage-structure>

  <app-reactivation-structure [structure]="structure"
                              (actionEmitter)="init()"
                              *ngIf="selectedAction === action.REACTIVER"></app-reactivation-structure>
</div>
