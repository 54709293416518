import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lien} from '../model/structure.model';
import {Pagination} from '../model/divers.model';

@Injectable()
export class LienService {

  constructor(private http: HttpClient) {
  }

  public search(params: HttpParams, pagination: Pagination): Observable<any> {
    return this.http.post<any>('/lien/search', pagination, {params, observe: 'response'});
  }

  public getAllLiensAntecedents(): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/all-antecedents');
  }

  public getLienByLienId(lienId: number): Observable<Lien> {
    return this.http.get<Lien>('/lien/' + lienId);
  }

  public getLiensHierarchiquesByIdentiteStructureId(identiteStructureId: number): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/liens-hierarchiques/identite-structure/' + identiteStructureId);
  }

  public getAllLiensByStructureId(structureId: number): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/structure/' + structureId);
  }

  public getLiensOuvertsByStructureId(structureId: number): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/liens-ouverts/structure/' + structureId);
  }

  public getChildrenByStructureId(structureId: number): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/children/structure/' + structureId);
  }

  public getAllLiensAntecedentsByStructureId(structureId: number): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/liens-antecedents/structure/' + structureId);
  }

  public getLiensAntecedentsValidesWithStructuresPorteusesAFermer(): Observable<Lien[]> {
    return this.http.get<Lien[]>('/lien/liens-fusionnes-valides-with-structures-fusionnees-ouvertes');
  }

  public create(lien: Lien) {
    return this.http.put<Lien>('/lien/create', lien);
  }

  public update(lien: Lien) {
    return this.http.put<Lien>('/lien/update', lien);
  }

  public delete(lienId: number) {
    return this.http.delete<Lien>('/lien/delete/' + lienId);
  }

}
