import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TypeCode} from '../model/nomenclature.model';

@Injectable({
  providedIn: 'root'
})
export class TypeCodeService {

  constructor(private http: HttpClient) {
  }

  public getAllTypesCode(): Observable<TypeCode[]> {
    return this.http.get<TypeCode[]>('/type-code/all');
  }

  public getTypeCodesToAssociate(structureId: number): Observable<TypeCode[]> {
    return this.http.get<TypeCode[]>('/type-code/available/structure/' + structureId);
  }

  public save(typesCode: TypeCode[]): Observable<TypeCode[]> {
    return this.http.put<TypeCode[]>('/type-code/save', typesCode);
  }
}
