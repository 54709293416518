<div class="row justify-content-center mt-4">
  <h1 class="title">Privilèges par structure</h1>
</div>

<div class="row justify-content-center buffer">
  <mat-accordion class="col-lg-8 mt-4 mb-4 p-4">
    <mat-expansion-panel [expanded]="!selectedStructure">
      <mat-expansion-panel-header>
        <mat-panel-title><h4>Sélection de la structure</h4></mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree (selectedStructureEmitter)="onSelectedStructure($event)"
                          [structuresActivesOnly]="false"
                          [title]="null"
                          [displayOption]="true"
                          [searchMode]="true">
      </app-structure-tree>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row justify-content-center buffer" *ngIf="datasource.length > 0">
  <p-table class="buffer"
           dataKey="roleId"
           [value]="datasource"
           [columns]="cols"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions">
    <ng-template pTemplate="caption">
      <button class="button float-right" matTooltip="Ajouter un gestionnaire à cette structure"
              (click)="addPrivilege()"><i class="fas fa-plus fa-2x"></i></button>
      <a appHighlightStructure
         [structure]="selectedStructure"
         [routerLink]="'/structure/' +  selectedStructure.structureId">
        {{ selectedStructure  | libelleStructure:user | uppercase }}</a>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="text-center" pResizableColumn>Réduire les droits</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-priv>
      <tr>
        <td *ngFor="let col of columns">
          {{priv[col.field]}}
        </td>
        <td class="text-center">
          <button class="button" [matTooltip]="priv.isExtended && priv.structureId != selectedStructure.structureId ? 'Ce droit est étendu d\'une structure plus haute et ne peut être supprimé ici' :
          'Fermer le rôle de gestionnaire de cet individu'"
                  (click)="openRemoveDialog(priv)"
                  [matTooltipDisabled]="isInUserPerimeter() && !(priv.isExtended && priv.structureId != selectedStructure.structureId)"
                  [disabled]="!isInUserPerimeter() || (priv.isExtended && priv.structureId !== selectedStructure.structureId)"
                  *ngIf="priv.typeRole === typeRole.GESTIONNAIRE && user.individu?.individuId !== priv.individu.individuId">
            <i class="fas fa-trash fa-2x"></i></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

