<div class="row justify-content-center mt-4">
  <h1 class="title">Synchronisation du référentiel aquitain</h1>
</div>

<mat-card class="col-lg-12 mt-4" *ngIf="syncRefAqTasks?.length === 0 && datasource?.length === 0">
  <h4 class="text-center">Aucune tâche de synchonisation à afficher</h4>
</mat-card>

<div class="row buffer justify-content-center" *ngIf="syncRefAqTasks?.length > 0">
  <app-sync-tasks class="col-lg-12" [syncRefAqTasks]="syncRefAqTasks"
                  [fromAdmin]="true" (actionEmitter)="init()"></app-sync-tasks>
</div>

<div class="row justify-content-center mt-4" *ngIf="datasource">
  <p-table class="col-lg-8"
           [columns]="cols"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="datasource.length > 0">
    <ng-template pTemplate="caption">Liste des structures non synchronisées</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th class="text-center" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
        <th class="text-center" style="width:6em">Synchroniser</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{row.dateCreation}}</td>
        <td><a href appHighlightStructure
               [structure]="row.structure"
               [routerLink]="'/structure/' +  row.structure.structureId">
          {{row.structure | libelleStructure:user}}</a>
        </td>
        <td>{{row.etat}}</td>

        <td class="text-center">
          <button class="button" matTooltip="synchroniser" (click)="synchronize(row.structure)">
            <i class="fas fa-redo fa-2x"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>


