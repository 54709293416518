<h1 mat-dialog-title>Formulaire d'association d'une nouvelle application du SI</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <form [formGroup]="form">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Application</mat-label>
      <mat-select formControlName="application" placeholder="Application" required>
        <mat-option *ngFor="let a of applications" [value]="a">{{a.applicationLib}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="form.controls.application.hasError('required') && form.controls.application.touched">Valeur requise
      </mat-error>
    </mat-form-field>

    <mat-radio-group class="row" formControlName=createTodo aria-label="Mode de synchronisation"
                     (change)="OnInitSynchroChange()">

      <mat-radio-button class="col-lg-12" [value]="true">Générer une nouvelle tâche d'initialisation de la
        synchronisation (pour un traitement automatique ou manuel ultérieur)
      </mat-radio-button>

      <mat-radio-button class="col-lg-12" [value]="false">Démarrer la synchronisation (nécessite d'avoir au
        préalablement créer la structure dans l'application cible)
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="col-lg-12" appearance="outline" *ngIf="!form.controls.createTodo.value">
      <mat-label>Code SI de la structure</mat-label>
      <input matInput placeholder="Code SI de la structure" formControlName="codeSI" required>
      <mat-error
        *ngIf="form.controls.codeSI.hasError('required') && form.controls.codeSI.touched">Valeur requise
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="associer" [disabled]="form.invalid"
          (click)="onConfirm()"><i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>



