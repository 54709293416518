<form [formGroup]="form">
  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Catégories de la structure</mat-label>
    <mat-select formControlName="categories" placeholder="Catégorie de la structure" multiple>
      <mat-option *ngFor="let c of categoriesStructure" [value]="c.value">
        {{c.libelle}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Types de structure</mat-label>
    <mat-select formControlName="types" placeholder="Type de structure" multiple>
      <mat-option *ngFor="let t of typesStructure" [value]="t.typeStructureId">
        {{t.typeStructureLib}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Interne/Externe</mat-label>
    <mat-select formControlName="intExt" placeholder="Interne/Externe" multiple>
      <mat-option [value]="'INT'">Interne</mat-option>
      <mat-option [value]="'EXT'">Externe</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Domaines d'activité</mat-label>
    <mat-select formControlName="domaines" placeholder="Domaines d'activité" multiple>
      <mat-option *ngFor="let d of domaines" [value]="d.domaineId">
        {{d.domaineLib}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Etablissements de tutelle</mat-label>
    <mat-select formControlName="tutelles" placeholder="Etablissements de tutelle" multiple>
      <mat-option *ngFor="let t of tutelles" [value]="t.tutelleId">
        {{t.tutelleLib}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Etats de la structure</mat-label>
    <mat-select formControlName="etats" placeholder="Etat de la structure" multiple>
      <mat-option *ngFor="let e of etatsStructure" [value]="e.value">
        {{e.libelle}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Ouverture à partir du</mat-label>
    <input formControlName="dateOuverture" matInput [matDatepicker]="ouvpicker" placeholder="Date d'ouverture">
    <mat-datepicker-toggle matSuffix [for]="ouvpicker">
    </mat-datepicker-toggle>
    <mat-datepicker #ouvpicker></mat-datepicker>
    <mat-error
      *ngIf="form.controls['dateOuverture'].invalid && form.controls['dateOuverture'].touched">Format non valide
    </mat-error>
  </mat-form-field>

  <mat-form-field class="col-lg-4" appearance="outline">
    <mat-label>Date de fermeture</mat-label>
    <input formControlName="dateFermeture" matInput [matDatepicker]="ferpicker" placeholder="Date de fermeture">
    <mat-datepicker-toggle matSuffix [for]="ferpicker">
    </mat-datepicker-toggle>
    <mat-datepicker #ferpicker></mat-datepicker>
    <mat-error
      *ngIf="form.controls['dateFermeture'].invalid && form.controls['dateFermeture'].touched">Format non valide
    </mat-error>
  </mat-form-field>
</form>

<div class="row justify-content-center">
  <button class="mt-2 ml-2" mat-raised-button color="primary" matTooltip="Filtrer"
          (click)="search()"><i class="fas fa-search"></i></button>
</div>
