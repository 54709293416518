import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DemandeService} from '../../../services/demande.service';
import {Structure} from '../../../model/structure.model';
import {IndividuService} from '../../../services/Individu.service';
import {User} from '../../../model/user.model';
import {isInModerPerimeter, isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {getDDMMYYYYHHmm} from '../../../utils/date.utils';
import {StructureService} from '../../../services/structure.service';
import * as type from '../../../constants/type-libelle.constant';
import {LoaderService} from '../../../services/loader.service';
import {WorkflowService} from '../../../services/workflow.service';
import {TakeOverRequest, ValidationRequest} from '../../../model/workflow.model';
import {ETAT_DEMANDE, TYPE_DEMANDE} from '../../../model/enum.model';
import {ROOT_STRUCTURE_ID} from '../../../constants/datas.constant';
import {getLienFictif} from '../../../utils/divers.utils';
import {Paginator} from 'primeng/paginator';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'app-demande',
  templateUrl: './demande.component.html',
  styleUrls: ['./demande.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class DemandeComponent implements OnChanges {

  @Output() PageEmitter: EventEmitter<Paginator> = new EventEmitter<Paginator>();
  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() demandes: Demande[];
  @Input() title: string;

  @Input() displayActionAdministrateur;
  @Input() displayActionGestionnaire;

  @Input() first: number;
  @Input() rows: number;
  @Input() totalRecords: number;

  user: User;

  datasource: any[];
  cols: any[];

  mode = MODE;
  libellesEtatDemande = type.data.etat_demande;
  etatsDemande = ETAT_DEMANDE;
  typesDemande = TYPE_DEMANDE;

  rowsPerPageOptions = [10, 25, 50];
  pageLinks: number;

  hasUserGestDemande: boolean;
  hasModerateurs: boolean;
  modeExpansion: MODE;

  constructor(private demandeService: DemandeService,
              private userService: UserService,
              private individuService: IndividuService,
              private structureService: StructureService,
              private router: Router,
              private loaderService: LoaderService,
              private workflowService: WorkflowService) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'typeDemande', header: 'Type de demande'},
      {field: 'structure', header: 'Structure'},
      // {field: 'structurePorteuse', header: 'Structure porteuse'},
      {field: 'etatDemande', header: 'Etat de la demande'},
      {field: 'demandeur', header: 'Demandeur'},
      {field: 'dateDemande', header: 'Date de la demande'}
    ];

    this.demandeService.hasDemandeEnCours(this.user.individu.individuId).subscribe(res => this.hasUserGestDemande = res);
    this.individuService.getAllModerateurs().subscribe(res => {
      this.hasModerateurs = res.length > 0;
    });
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.user = this.userService.getCurrentUser();
    this.modeExpansion = MODE.READ;

    if (this.totalRecords && this.rows) {
      this.pageLinks = Math.ceil(this.totalRecords / this.rows);
    }

    if (this.demandes) {
      const promises: Promise<any>[] = [];
      this.demandes.forEach(d => promises.push(this.convertToDataTable(d)));
      Promise.all(promises).then(res => {
        this.datasource = res;
      });
    }

    if (this.displayActionGestionnaire) {
      this.displayActionGestionnaire = this.user.individu.isGestionnaire || this.user.individu.isResponsable
        || (this.user.individu.isAdmin && this.demandes.filter(d => d.demandeur.individuId === this.user.individu.individuId).length > 0);
    }

    if (this.displayActionAdministrateur) {
      this.displayActionAdministrateur = this.user.individu.isAdmin || (this.user.individu.isModerateur && this.demandes.filter(d => d.typeDemande.typeDemandeId === 1).length > 0);
    }
  }

  onPageChange(paginator: Paginator) {
    this.PageEmitter.emit(paginator);
  }

  onAction() {
    this.actionEmitter.emit();
  }

  getColSpan(): number {
    let res = this.cols.length + 1;
    if (this.displayActionAdministrateur) {
      res += 3;
    }
    if (this.displayActionGestionnaire) {
      res += 2;
    }
    return res;
  }

  expandRow(mode: MODE) {
    this.modeExpansion = mode;
  }

  isInGestRespPerimeter(structure: Structure) {
    return isInGestRespActionPerimeter(structure.structureId, this.user);
  }

  isInModerPerimeter(structure: Structure) {
    return isInModerPerimeter(structure.structureId, this.user);
  }

  convertToDataTable(demande: Demande): Promise<any> {
    return new Promise<any>(resolve => {
      if (!demande.lien) {
        this.structureService.getStructureByStructureId(ROOT_STRUCTURE_ID).subscribe(res => {
          demande.lien = getLienFictif(res);
          resolve({
            demande,
            demandeId: demande.demandeId,
            typeDemande: demande.typeDemande.typeDemandeLib,
            etatDemande: demande.moderateur && demande.moderateur.individu.individuId !== this.user.individu.individuId ? this.getEtatDemandeLibelle(demande.etatDemande) + ' (' + demande.moderateur.individu.prenom + ' ' + demande.moderateur.individu.nom + ')' : this.getEtatDemandeLibelle(demande.etatDemande),
            structure: res,
            structurePorteuse: null,
            demandeur: demande.demandeur.nom + ' ' + demande.demandeur.prenom,
            dateDemande: getDDMMYYYYHHmm(demande.dateDemande),
          });
        });
      } else {
        resolve({
          demande,
          demandeId: demande.demandeId,
          typeDemande: demande.typeDemande.typeDemandeLib,
          etatDemande: demande.moderateur && demande.moderateur.individu.individuId !== this.user.individu.individuId ?
            this.getEtatDemandeLibelle(demande.etatDemande) + ' (' + demande.moderateur.individu.prenom + ' ' + demande.moderateur.individu.nom + ')' :
            this.getEtatDemandeLibelle(demande.etatDemande),
          structure: demande.lien.structure,
          structurePorteuse: demande.lien.structurePorteuse,
          demandeur: demande.demandeur.nom + ' ' + demande.demandeur.prenom,
          dateDemande: getDDMMYYYYHHmm(demande.dateDemande),
        });
      }
    });
  }

  getEtatDemandeLibelle(value: string): string {
    return this.libellesEtatDemande.filter(e => e.value === value)[0].libelle;
  }

  prendreEnCharge(demande: Demande) {
    this.loaderService.start();
    const takeOverRequest: TakeOverRequest = {
      demandeId: demande.demandeId,
      etatDemande: ETAT_DEMANDE[type.data.etat_demande.filter(ed => ed.libelle === demande.etatDemande)[0].value],
      motif: 'Prise en charge par ' + this.user.individu.prenom + ' ' + this.user.individu.nom.toUpperCase(),
      avertir: true
    };
    this.workflowService.takeOver(takeOverRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  valider(demande: Demande) {
    if ((this.user.individu.isAdmin
      && ([ETAT_DEMANDE.NOUVEAU.valueOf(), ETAT_DEMANDE.A_TRAITER.valueOf()].includes(demande.etatDemande))
      && ![
        TYPE_DEMANDE.AJOUT_LIEN_HIERARCHIQUE,
        TYPE_DEMANDE.MODIFICATION_IDENT,
        TYPE_DEMANDE.MODIFICATION_LIEN_HIERARCHIQUE,
        TYPE_DEMANDE.SUPPRESSION_LIEN,
        TYPE_DEMANDE.FERMETURE_STRUCTURE,
        TYPE_DEMANDE.CREATION_STRUCT_ORGA_INTERNE
      ].includes(demande.typeDemande.typeDemandeId)
    ) || (
      this.user.individu.isModerateur
      && demande.etatDemande === ETAT_DEMANDE.A_VALIDER
      && [TYPE_DEMANDE.CREATION_STRUCT_ORGA_INTERNE].includes(demande.typeDemande.typeDemandeId)
    )) {
      const validationRequest: ValidationRequest = {
        demandeId: demande.demandeId,
        motif: 'Validation faite par ' + this.user.individu.prenom + ' ' + this.user.individu.nom
      };

      this.loaderService.start();
      this.workflowService.validate(validationRequest).subscribe(() => {
        this.loaderService.stop();
        this.actionEmitter.emit();
      });

    } else {
      this.modeExpansion = this.mode.ACCEPT;
    }
  }
}

export enum MODE { READ, DETAILS, ACCEPT, REFUSE, CANCEL, TAKE_OVER, REKINDLE }
