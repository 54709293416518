<div class="row justify-content-center">
  <mat-card class="col-lg-8 mt-4">
    <mat-card-header>
      <h4>Identité de la structure <span *ngIf="demande?.lien.structureId">{{demande.lien.structureId}}</span></h4>
    </mat-card-header>

    <mat-card-content>
      <app-identite-form #identiteFormComponent
                         [categorie]="catStructure.ORGA"
                         [identiteStructure]="demande?.lien.structure.identite"
                         [structurePorteuseIds]="[this.structurePorteuse?.structureId]"
                         [readonly]="readonly"></app-identite-form>
    </mat-card-content>
  </mat-card>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-8 mt-4">
    <mat-card-header>
      <h4>Instance de validation/présentation</h4>
    </mat-card-header>

    <mat-card-content>
      <app-instance-demande-form #instanceDemandeComponent
                                 (fileUploadEmitter)="onFileUploadChange()"
                                 [demande]="demande"
                                 [readonly]="readonly">
      </app-instance-demande-form>
    </mat-card-content>
  </mat-card>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-8 mt-4">
    <mat-card-header>
      <h4>Informations complémentaires</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <div class="row">
          <!--STRUCTURE PORTEUSE -->
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Structure porteuse</mat-label>
            <input matInput
                   type="text"
                   placeholder="Libellé de la structure porteuse"
                   [matAutocomplete]="auto"
                   formControlName="structurePorteuse"
                   required>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option appHighlightStructure [structure]="structure"
                          *ngFor="let structure of filteredOptions | async"
                          [value]="structure.identite.libelleLong">
                {{structure | libelleStructure:user}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!--TYPE DE LIEN -->
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Relation</mat-label>
            <mat-select formControlName="typeLien" placeholder="Relation">
              <mat-option *ngFor="let t of typeLiens" [value]="t.typeLienId">{{ t.typeLienLib}}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['typeLien'].hasError('required') && form.controls['typeLien'].touched">
              Valeur requise
            </mat-error>
          </mat-form-field>
        </div>

        <!--radio INTERNE /EXTERNE -->
        <div class="row mt-4 mb-4">
          <mat-radio-group class="col-lg-12" formControlName="interne" aria-label="interne/externe">
            <mat-radio-button [value]="true" [checked]="form.controls.interne.value">Interne</mat-radio-button>
            <mat-radio-button class="ml-4" [value]="false" [checked]="!form.controls.interne.value">Externe
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="form.controls['interne'].hasError('required') && form.controls['interne'].touched">Valeur requise
          </mat-error>
        </div>

        <div class="row mt-4" *ngIf="form.controls.interne.value">
          <!--TYPE DE CREATION -->
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Type de création</mat-label>
            <mat-select formControlName="typeCreation" placeholder="Type de création" required>
              <mat-option [value]="typeCreation.NEW">Nouvelle Structure</mat-option>
              <mat-option [value]="typeCreation.SUCCESSION">Succession de la structure existante</mat-option>
              <mat-option [value]="typeCreation.SCISSION">Scission à partir d'une structure existante</mat-option>
              <mat-option [value]="typeCreation.FUSION">Fusion de structure existante</mat-option>
            </mat-select>
            <mat-error
              *ngIf="form.controls['typeCreation'].hasError('required') && form.controls['typeCreation'].touched">
              Valeur requise
            </mat-error>
          </mat-form-field>

          <app-structures-selection-with-tree #structureSelectionWithTree class="col-lg-6"
                                              [demande]="demande"
                                              [single]="[typeCreation.SCISSION, typeCreation.SUCCESSION].includes(form.controls.typeCreation.value)"
                                              [multiple]="[typeCreation.FUSION].includes(form.controls.typeCreation.value)"
                                              [readonly]="readonly || demande && [etatDemande.VALIDEE, etatDemande.ANNULEE, etatDemande.REFUSEE].includes(demande.etatDemande)"
                                              [hidden]="[typeCreation.NEW].includes(form.controls.typeCreation.value)">
          </app-structures-selection-with-tree>
        </div>

        <!--DOMAINES D'ACTIVITE -->
        <div class="row">
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Domaines d'activités</mat-label>
            <mat-select formControlName="domaines" placeholder="Domaines d'activités" multiple>
              <mat-option *ngFor="let d of domaines" [value]="d.domaineId">{{ d.domaineLib}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row">
          <!--MOTIF -->
          <mat-form-field class="col-lg-12" appearance="outline" *ngIf="!demande">
            <mat-label>Motif de la demande</mat-label>
            <textarea formControlName="motif" matInput rows="1" placeholder="Motif de la demande"  [maxlength]="200"
                      required></textarea>
            <mat-error *ngIf="form.controls['motif'].hasError('required') && form.controls['motif'].touched">
              Valeur requise
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<!--RESPONSABLE -->
<div class="row justify-content-center">
  <app-ajout-responsable class="col-lg-8 mt-4" *ngIf="form.controls.interne.value && !demande"></app-ajout-responsable>
</div>

