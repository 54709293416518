import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {LienRequest} from '../../../model/workflow.model';
import {LoaderService} from '../../../services/loader.service';
import {CAT_STRUCTURE, ETAT_VALID} from '../../../model/enum.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-modification-structure-rattachement',
  templateUrl: './modification-structure-rattachement.component.html',
  styleUrls: ['./modification-structure-rattachement.component.scss']
})
export class ModificationStructureRattachementComponent {

  @Output() demandeEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() lienContexte: Lien;
  @Input() structure: Structure;

  form: FormGroup;

  displayForm: boolean;
  user: User;

  structRattach: Structure;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private workflowService: WorkflowService,
              private snackBar: MatSnackBar,
              private structTreeService: StructureTreeService) {
    this.user = this.userService.getCurrentUser();
    this.displayForm = false;
    this.structRattach = null;

    this.form = new FormGroup({
      dateDebut: new FormControl(null, [Validators.required]),
      motif: new FormControl(null, Validators.required),
    });
  }

  onSelectedStructure(structure: Structure) {
    this.displayForm = false;
    this.structRattach = null;

    if (structure) {
      this.loaderService.start();
      this.structTreeService.getChildrenIdByStructureId(this.structure.structureId).subscribe(childIds => {
        this.loaderService.stop();
        if (structure.catStructure !== CAT_STRUCTURE.ORGA || !structure.interne) {
          this.snackBar.open(
            'Attention, la structure de rattachement doit être une structure organisationnelle interne.',
            'X',
            {panelClass: 'notif-warn'}
          );
          return;
        }
        if (this.structure.liensParent.map(l => l.structPorteuseId).includes(structure.structureId)) {
          this.snackBar.open(
            'Attention, la structure de rattachement doit être différente de la structure parente.',
            'X',
            {panelClass: 'notif-warn'}
          );
          return;
        }
        if (childIds.includes(structure.structureId)) {
          this.snackBar.open(
            'Attention, la structure de rattachement ne peut pas être une structure fille.',
            'X',
            {panelClass: 'notif-warn'}
          );
          return;
        }

        this.displayForm = true;
        this.structRattach = structure;
      });
    }
  }

  modifyStructureRattachement() {
    const lienContexte = Object.assign({}, this.lienContexte);
    lienContexte.dateDebut = formatLocalDateWithUTC(this.form.controls.dateDebut.value);
    lienContexte.dateFin = null;
    lienContexte.etatValid = ETAT_VALID.A_VALIDER;
    lienContexte.structPorteuseId = this.structRattach.structureId;
    lienContexte.structure = null;
    lienContexte.structurePorteuse = null;

    const requestLien: LienRequest = {
      lienDemande: this.structure.liensParent[0],
      lienContexte,
      motif: this.form.controls.motif.value
    };

    this.loaderService.start();
    this.workflowService.modifyStructureRattachement(requestLien).subscribe(() => {
      this.displayForm = false;
      this.structRattach = null;
      this.form.reset();
      this.loaderService.stop();
      this.demandeEmitter.emit();
    });
  }
}
