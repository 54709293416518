export enum ACTION {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE'
}

export enum CARACTERISATION {
  TUTELLE = 'TUTELLE',
  DOMAINE = 'DOMAINE',
  CODE = 'CODE'
}

export enum X_TYPE_ENUM {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export enum TYPE_MIME{
  PDF= 'application/pdf',
  MPEG= 'video/mpeg',
  JPEG='image/jpeg',
}
