<div class="row justify-content-center">
  <mat-card class="col-lg-12 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire d'ajout d'informations complémentaires</h4>
    </mat-card-header>

    <mat-card-content>
      <div class="row">
        <mat-checkbox class="col-lg-12" [(ngModel)]="avertir" [checked]="avertir">
          Avertir le demandeur par messagerie de l'ajout d'informations complémentaires à sa demande
        </mat-checkbox>
      </div>

      <div class="row">
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Information complémentaire</mat-label>
          <textarea matInput [(ngModel)]="motif" placeholder="Saisie de l'information à ajouter" [maxLength]="200"></textarea>
        </mat-form-field>
      </div>

      <div class="row justify-content-center">
        <button class="col-lg-6" mat-raised-button color="primary"
                matTooltip="Ajouter des informations complémentaires"
                (click)="addInfos()" [disabled]="!motif || motif.trim() === ''">{{'ajouter une information' | uppercase }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
