<div *ngIf="aides">
  <div class="row justify-content-center mt-4">
    <h1 class="title">Aide</h1>
  </div>

  <div class="row justify-content-center buffer">
    <button mat-raised-button color="primary" class="m-4"
            (click)="save(null)"
            *ngIf="user.individu.isAdmin">Ajouter une aide
    </button>
  </div>

  <div class="row justify-content-center buffer">
    <mat-card class="col-lg-12" *ngIf="aides.length == 0">
      <h4 class="text-center">Aucune aide à afficher</h4>
    </mat-card>
  </div>

  <div class="row">
    <ng-container  *ngFor="let aide of aides">
    <div class="col-lg-4 top-buffer" *ngIf="aide.isValid || user.individu.isAdmin">
      <mat-card class="aide-card">
        <mat-card-header>
          <mat-card-subtitle>{{typeMimeMap.get(aide.typeMime)}}</mat-card-subtitle>
          <mat-card-title>{{aide.libelle}}</mat-card-title>
        </mat-card-header>

<!--        <div class="img-container">-->
<!--          <img mat-card-image src="{{aide.url}}" [alt]="aide.libelle" *ngIf="aide.typeMime === typeMime.JPEG.valueOf()">-->
<!--        </div>-->

        <mat-card-content>
          <p>{{aide.description}}</p>
          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="afficher(aide)">Afficher</button>
          <button mat-button (click)="save(aide)" *ngIf="user.individu.isAdmin">Modifier</button>
          <button mat-button (click)="supprimer(aide)" *ngIf="user.individu.isAdmin">Supprimer</button>
          <button mat-button class="float-right" (click)="isVisible(aide)" *ngIf="user.individu.isAdmin">
            <span [hidden]="!aide.isValid"><i class="fas fa-eye"></i></span>
            <span [hidden]="aide.isValid"><i class="fas fa-eye-slash"></i></span>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    </ng-container>
  </div>
</div>
