<h1 mat-dialog-title>Synchronisation de la structure dans le Référentiel Aquitain</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <app-referentiel-aquitain-form [structure]=data.structure
                                 *ngIf="data.action === action.ACTIVATE"></app-referentiel-aquitain-form>

  <mat-form-field class="col-lg-12">
    <textarea matInput [(ngModel)]="commentaire" placeholder="Commentaire obligatoire"  [maxLength]="200"></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="Valider" (click)="validate()" [disabled]="isDisabled()">
    <i class="fas fa-check fa-2x"></i>
  </button>

  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
