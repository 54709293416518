import {Component, Input, OnChanges} from '@angular/core';
import {DemandeService} from '../../../services/demande.service';
import {Demande, DetailDemande} from '../../../model/demande.model';

@Component({
  selector: 'app-demande-historique',
  templateUrl: './demande-historique.component.html',
  styleUrls: ['./demande-historique.component.scss']
})
export class DemandeHistoriqueComponent implements OnChanges {

  @Input() selectedDemande: Demande;

  details: DetailDemande[] = [];

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private demandeService: DemandeService) {
  }

  ngOnChanges() {
    this.demandeService.getDetailsDemande(this.selectedDemande.demandeId).subscribe(res => this.details = res);
  }
}
