import {Role, User} from '../model/user.model';
import * as moment from 'moment';

export function isInUserPerimeter(structureId: number, user: User): boolean {
  return user.individu.isAdmin
    || isInGestPerimeter(structureId, user)
    || isInRespAccessPerimeter(structureId, user)
    || isInModerPerimeter(structureId, user);
}

export function isInGestRespAccessPerimeter(structureId: number, user: User): boolean {
  return isInGestPerimeter(structureId, user) || isInRespAccessPerimeter(structureId, user);
}

export function isInGestRespActionPerimeter(structureId: number, user: User): boolean {
  return isInGestPerimeter(structureId, user) || isInRespActionPerimeter(structureId, user);
}

export function isInGestPerimeter(structureId: number, user: User): boolean {
  return user.individu.isGestionnaire && user.individu.gestPerimeter.filter(id => id === structureId).length > 0;
}

export function isInRespAccessPerimeter(structureId: number, user: User): boolean {
  return user.individu.isResponsable && user.individu.respPerimeter.filter(id => id === structureId).length > 0;
}

export function isInRespActionPerimeter(structureId: number, user: User): boolean {
  return user.individu.isResponsable && user.individu.roles.filter(r =>  r.typeRole === 'RESPONSABLE' && isCurrentRole(r) && r.structureId === structureId).length > 0
}

export function isInModerPerimeter(structureId: number, user: User): boolean {
  return user.individu.isModerateur && user.individu.moderPerimeter.filter(id => id === structureId).length > 0;
}

export function isCurrentRole(role: Role): boolean {
  return !moment(role.dateDebut).isAfter(new Date()) && (!role.dateFin || !moment(role.dateFin).isBefore(new Date()));
}
