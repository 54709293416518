<div *ngIf="datasource">
  <div class="row justify-content-center mt-4" *ngIf="!structure && datasource.length > 0">
    <p-paginator class="col-lg-12"
                 [rows]="rows"
                 [totalRecords]="totalRecords"
                 [pageLinkSize]="pageLinks"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [first]="first"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                 (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>

  <div class="row justify-content-center mt-4" *ngIf="datasource.length > 0">
    <p-table class="col-lg-12"
             dataKey="todoId"
             [columns]="cols"
             [value]="datasource"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liste des tâches de synchonisation</ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" class="text-center"
              [hidden]="structure && col.field === 'structure'">{{col.header}}</th>
          <th class="text-center" style="width:6em">Exécuter</th>
          <th class="text-center" style="width:6em">Clôturer</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <ng-container>
            <td [hidden]="structure">
              <a href appHighlightStructure
                 [structure]="row.structure"
                 [routerLink]="'/structure/' +  row.structure.structureId">
                {{row.structure | libelleStructure:user}}</a>
            </td>
            <td>{{row.application}}</td>
            <td>{{row.etat}}</td>
            <td>{{row.date}}</td>
          </ng-container>
          <td class="text-center">
            <button class="button" matTooltip="Exécuter la tâche" (click)="onActionClick(row, true)"
                    *ngIf="![etatTodo.ACKED, etatTodo.DONE].includes(row.etat)">
              <i class="fas fa-check fa-2x"></i>
            </button>
          </td>
          <td class="text-center">
            <button class="button" matTooltip="Clôturer la tâche" (click)="onActionClick(row, false)"
                    *ngIf="![etatTodo.ACKED, etatTodo.DONE].includes(row.etat)">
              <i class="fas fa-times fa-2x"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
