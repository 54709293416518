import {Pipe, PipeTransform} from '@angular/core';
import {getDDMMYYYY} from '../utils/date.utils';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(date: Date): unknown {
    return getDDMMYYYY(date);
  }

}
