<header class="page-top">
  <button class="button icon float-left ml-4" id="menu-btn" (click)="onMenuButtonClick()"
          [class.disabled]="!user.individu"><i class="fas fa-bars fa-2x"></i></button>

  <a [routerLink]="'dashboard'" id="logo" class="float-left ml-4" [class.disabled]="!user.individu">
    <img src="../../../assets/img/logo.png" alt="logo" title="Université de Bordeaux"/>
  </a>

  <div class="d-inline-block ml-4" [ngClass]="loginAsUser? 'title-container-with-login-as' :'title-container'">
    <div class="title-app">Référentiel des structures UB</div>
    <div *ngIf="loginAsUser">Connexion en tant que {{loginAsUser.individu.prenom}} {{loginAsUser.individu.nom}}
      (<button class="button" (click)="logoutAs()">cliquez ici pour revenir à votre profil</button>)</div>
  </div>

  <div id="initials" class="float-right mr-4">
    <div id="initials-btn" class="chip-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
         aria-expanded="false" [class.disabled]="!user.individu">
      <button class="button">{{initials}}</button>
    </div>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <button class="dropdown-item button" (click)="loginAs()" *ngIf="user.individu.isAdmin"><i
        class="fas fa-user ml-4 mr-2"></i>Se connecter en tant que
      </button>
      <button class="dropdown-item button" (click)="setup()" *ngIf="user.individu.isAdmin"><i
        class="fas fa-cog ml-4 mr-2"></i>Paramètres
      </button>
      <button class="dropdown-item button" (click)="logout()"><i class="fas fa-power-off ml-4 mr-2"></i>Déconnexion
      </button>
    </div>
  </div>

  <app-menu class="d-inline-block float-right mr-4" id="menu"></app-menu>

</header>


