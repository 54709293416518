import {Component, Inject} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AssociationSiService} from '../../../../services/association-si.service';
import {ApplicationService} from '../../../../services/application.service';
import {AssociationRequest, RepartStructSI} from '../../../../model/association.model';
import {Application} from '../../../../model/nomenclature.model';
import {ETAT_STRUCTURE} from '../../../../model/enum.model';

@Component({
  selector: 'app-consultation-applications-synchronisees',
  templateUrl: './stop-association-si-dialog.component.html',
  styleUrls: ['./stop-association-si-dialog.component.scss']
})
export class StopAssociationSiDialogComponent {

  applications: Application[] = [];
  changeEtat: boolean;
  motif: string;
  etatStructure = ETAT_STRUCTURE;

  constructor(private loaderService: LoaderService,
              private applicationService: ApplicationService,
              private associationSiService: AssociationSiService,
              public dialogRef: MatDialogRef<StopAssociationSiDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.changeEtat = false;
    this.motif = null;
    this.applicationService.getAllAppliSI().subscribe(res => this.applications = res);
  }

  onConfirm() {
    this.loaderService.start();
    this.associationSiService.disable(this.data.rss.repartStructSiId, this.changeEtat, this.motif).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close();
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  rss: RepartStructSI;
}
