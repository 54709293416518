import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Individu} from '../model/user.model';

@Injectable()
export class IndividuService {

  constructor(private http: HttpClient) {
  }

  public getAllIndividus(): Observable<Individu[]> {
    return this.http.get<Individu[]>('/individu/all');
  }

  public getIndividuByUid(uid: string): Observable<Individu> {
    return this.http.get<Individu>('/individu/uid/' + uid);
  }


  public getAllIndividusDTO(): Observable<Individu[]> {
    return this.http.get<Individu[]>('/individu/all-with-roles');
  }

  public getAllAdministrateurs(): Observable<Individu[]> {
    return this.http.get<Individu[]>('/individu/all-admin');
  }

  public getAllModerateurs(): Observable<Individu[]> {
    return this.http.get<Individu[]>('/individu/all-moder');
  }

  public getOtherModerateurs(structureId: number): Observable<Individu[]> {
    return this.http.get<Individu[]>('/individu/other-moderateurs/structure/' + structureId);
  }

  public isValid(individuId: number): Observable<boolean> {
    return this.http.get<boolean>('/individu/is-valid/' + individuId);
  }

  public disableAdminPrivilege(individuId: number): Observable<void> {
    return this.http.get<void>('/individu/disable-administrateur/' + individuId);
  }

  public disableModerPrivilege(individuId: number): Observable<void> {
    return this.http.get<void>('/individu/disable-moderateur/' + individuId);
  }

  public deleteIndividu(individuId: number): Observable<void> {
    return this.http.delete<void>('/individu/delete/' + individuId);
  }
}
