import {Component, Input, OnChanges} from '@angular/core';
import {DescriptionStructure, Structure} from '../../../model/structure.model';
import {DescriptionStructureService} from '../../../services/description-structure.service';
import {MatDialog} from '@angular/material/dialog';
import {HistoDescriptionDialogComponent} from '../../../layout/dialog/structure/histo-description-dialog/histo-description-dialog.component';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {LoaderService} from '../../../services/loader.service';
import {User} from '../../../model/user.model';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  updateMode: boolean;

  currentStructureDescription: DescriptionStructure;
  descriptions: DescriptionStructure[];
  hasMultiVersions: boolean;

  form: FormGroup;
  logoBase64: string;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private descriptionStructureService: DescriptionStructureService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
    this.updateMode = false;
    this.hasMultiVersions = false;
    this.descriptions = [];

    this.form = new FormGroup({
      description: new FormControl(null, [Validators.required, this.lengthDescValidator()]),
      url: new FormControl(null, [Validators.required, Validators.pattern('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')]),
    });
  }

  ngOnChanges() {
    if (this.structure) {
      this.init();
    }
  }

  lengthDescValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const length = control.value.replace(/<[^>]*>/gi, '').length;
      return length >= 25 && length <= 1000 ? null : {'desc-length-invalid': 'longueur description invalide : ' + length + ' caractères'};
    };
  }

  onLogoChange(event: any) {
    this.logoBase64 = null;
    const files: FileList = event.files;
    const reader = new FileReader();
    Array.from(files).forEach(file => {
      reader.readAsDataURL(file);
      reader.onload = () => this.logoBase64 = reader.result.toString();
    });
  }

  deleteLogo() {
    this.logoBase64 = null;
  }

  displayVersionHisto() {
    this.dialog.open(HistoDescriptionDialogComponent, {
      minWidth: 400,
      data: {
        name: 'Historique des versions',
        descriptions: this.descriptions
      }
    });
  }

  modify() {
    this.updateMode = !this.updateMode;
    this.updateMode ? this.form.enable() : this.init();
  }

  validate() {
    const description: DescriptionStructure = {
      descriptionStructureId: null,
      structureId: this.structure.structureId,
      versionId: this.currentStructureDescription ? this.currentStructureDescription.versionId : 1,
      description: this.form.controls.description.value,
      url: this.form.controls.url.value,
      logoBase64: this.logoBase64,
      isCurrentVersion: true,
      dateCreation: new Date()
    };

    this.loaderService.start();
    this.descriptionStructureService.create(description, this.structure.structureId).subscribe(() => {
      this.loaderService.stop();
      this.updateMode = false;
      this.init();
    });
  }


  init() {
    this.descriptions = [];
    this.form.reset();
    this.form.disable();
    this.logoBase64 = null;

    this.descriptionStructureService.getDescriptionsByStructure(this.structure.structureId).subscribe(res => {
      if (res.length > 1) {
        const descList: string[] = [];
        res.forEach(d => {
          if (!descList.includes(d.description)) {
            descList.push(d.description);
            this.descriptions.push(d);
          }
        });
      }

      this.currentStructureDescription = res.filter(d => d.isCurrentVersion)[0];
      if (this.currentStructureDescription) {
        this.form.controls.url.setValue(this.currentStructureDescription.url);
        this.form.controls.description.setValue(this.currentStructureDescription.description);
        this.logoBase64 = this.currentStructureDescription.logoBase64;
      }
    });
  }

  isInUserGestRespPerimeter(structure: Structure): boolean {
    return isInGestRespActionPerimeter(structure.structureId, this.user);
  }
}
