import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {ValidationRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {RoleService} from '../../../services/role.service';
import {LienService} from '../../../services/lien.service';
import {Lien} from '../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReferentielAquitainFormComponent} from '../../administration/referentielAquitain/referentiel-aquitain-form/referentiel-aquitain-form.component';
import {LoaderService} from '../../../services/loader.service';
import {IdentiteStructureService} from '../../../services/identite-structure.service';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {ETAT_VALID, TYPE_DEMANDE} from '../../../model/enum.model';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-validation-demande',
  templateUrl: './validation-demande.component.html',
  styleUrls: ['./validation-demande.component.scss']
})
export class ValidationDemandeComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() demande: Demande;
  @ViewChild(ReferentielAquitainFormComponent) referentielAquitainFormComponent: ReferentielAquitainFormComponent;

  useRefAq = false;
  lienCible: Lien;

  formIdent: FormGroup;
  formFerm: FormGroup;
  formSupprLien: FormGroup;

  motif: string;

  typeDemande = TYPE_DEMANDE;
  etatValid = ETAT_VALID;

  constructor(private loaderService: LoaderService,
              private lienService: LienService,
              private roleService: RoleService,
              private identiteStructureService: IdentiteStructureService,
              private structureTreeService: StructureTreeService,
              private snackBar: MatSnackBar,
              private workflowService: WorkflowService) {
    this.formIdent = new FormGroup({
      dateDebut: new FormControl(null, Validators.required),
    });

    this.formFerm = new FormGroup({
      dateFermeture: new FormControl(null, Validators.required),
    });

    this.formSupprLien = new FormGroup({
      dateFin: new FormControl(null, Validators.required),
    });
  }

  ngOnChanges() {
    if (this.demande) {
      this.lienCible = this.demande.lien;

      if ([TYPE_DEMANDE.AJOUT_LIEN_HIERARCHIQUE, TYPE_DEMANDE.SUPPRESSION_LIEN].includes(this.demande.typeDemande.typeDemandeId)) {
        this.lienService.getLienByLienId(this.demande.contexteId).subscribe(lien => {
          this.lienCible = lien;
          this.formSupprLien.controls.dateFin.setValue(this.lienCible.dateFin);
          this.formSupprLien.controls.dateFin.disable();
        });

      } else if (this.demande.typeDemande.typeDemandeId === TYPE_DEMANDE.MODIFICATION_IDENT) {
        this.identiteStructureService.getIdentiteByIdentStructureId(this.demande.contexteId).subscribe(ident => this.formIdent.controls.dateDebut.setValue(ident.dateDebut));
      } else if (this.demande.typeDemande.typeDemandeId === TYPE_DEMANDE.FERMETURE_STRUCTURE) {
        this.identiteStructureService.getIdentiteByIdentStructureId(this.demande.contexteId).subscribe(ident => this.formFerm.controls.dateFermeture.setValue(ident.dateFermeture));
        this.structureTreeService.getChildrenIdByStructureId(this.demande.contexteId).subscribe(res => {
          if (res.length > 0) {
            this.snackBar.open(
              'Attention, la demande de fermeture concerne la structure elle-même et TOUTES ses structures filles.',
              'X',
              {panelClass: 'notif-warn'}
            );
          }
        });
      }
    }
  }

  isButtonDisabled(): boolean {
    return (this.useRefAq && this.referentielAquitainFormComponent?.form.invalid) ||
      (this.demande.typeDemande.typeDemandeId === this.typeDemande.MODIFICATION_IDENT && this.formIdent.invalid) ||
      (this.demande.typeDemande.typeDemandeId === this.typeDemande.FERMETURE_STRUCTURE && this.formFerm.invalid) ||
      (this.demande.typeDemande.typeDemandeId === this.typeDemande.SUPPRESSION_LIEN && this.formSupprLien.invalid);
  }

  validate() {
    const validationRequest: ValidationRequest = {
      demandeId: this.demande.demandeId,
      motif: this.motif && this.motif.trim() !== '' ? this.motif : 'Validation par modérateur niveau 2',
      syncRefAq: this.referentielAquitainFormComponent ? this.referentielAquitainFormComponent.getSyncRefAq() : null,
      dateDebut: formatLocalDateWithUTC(this.formIdent.controls.dateDebut.value),
      dateFin: this.demande.typeDemande.typeDemandeId === TYPE_DEMANDE.FERMETURE_STRUCTURE ?
        formatLocalDateWithUTC(this.formFerm.controls.dateFermeture.value) : formatLocalDateWithUTC(this.formSupprLien.controls.dateFin.value)
    };

    this.loaderService.start();
    this.workflowService.validate(validationRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
