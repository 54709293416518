<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de prise en charge de la demande</h4>
    </mat-card-header>

    <mat-card-content>
      <mat-checkbox class="col-lg-12" [(ngModel)]="avertir" [checked]="avertir">
        Avertir le demandeur par messagerie de la prise en charge de sa demande
      </mat-checkbox>

      <mat-form-field class="col-lg-12" appearance="outline">
        <mat-label>Commentaire</mat-label>
        <textarea matInput [(ngModel)]="commentaire" [maxLength]="200"
                  placeholder="Saisie d'un commentaire à destination du demandeur"
                  required></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary" matTooltip="Prise en charge de la demande"
                (click)="takeOver()" [disabled]="!commentaire || commentaire.trim() === ''">{{'prendre en charge la demande' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
