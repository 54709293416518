import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {LoaderService} from '../../../services/loader.service';
import {WorkflowService} from '../../../services/workflow.service';

@Component({
  selector: 'app-archivage-structure',
  templateUrl: './archivage-structure.component.html',
  styleUrls: ['./archivage-structure.component.scss']
})
export class ArchivageStructureComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;

  motif: string;

  constructor(private loaderService: LoaderService, private workFlowService: WorkflowService) {
  }

  archive() {
    this.loaderService.start();
    this.workFlowService.archiveStructure(this.structure.structureId, this.motif).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
