import {Component, Input} from '@angular/core';
import {User} from '../../model/user.model';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input() numVersion: string;

  user: User;
  loginAsUser: User;


  constructor(private userService: UserService) {
    this.user = this.userService.getCurrentUser();
    this.loginAsUser = this.userService.getLoginAs();
  }

  logoutAs(): void {
    this.userService.logoutAs();
  }
}
