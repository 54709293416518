<h1 mat-dialog-title>Résultat de la recherche dans l'annuaire ldap</h1>

<div mat-dialog-content  class="row justify-content-center mt-4" >
  <p-table
    [value]="data.ldapAccounts"
    [responsive]="true"
    [resizableColumns]="true"
    [paginator]="true"
    [rows]="rows"
    [showCurrentPageReport]="true"
    [(first)]="first"
    currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
    [rowsPerPageOptions]="rowsPerPageOptions"
    *ngIf="data.ldapAccounts">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" class="text-center" [pSortableColumn]="col.field"
            pResizableColumn>
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="text-center">Ajouter des privilèges</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-pers>
      <tr [ngClass]="pers.logLevel === 'CRITICAL'? 'error' : ''">
        <td>
          {{pers['nomComplet']}}
        </td>
        <td>
          <ng-container *ngFor="let struct of pers['structures']">
            <span appHighlightStructure [structure]="struct">{{struct.identite.libelleLong}}</span>
        </ng-container>
        </td>
        <td>
          {{pers['observations']}}
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Choisir cette personne" (click)="onSelect(pers)" *ngIf=!pers.bloque>
            <i class="fas fa-check fa-2x"></i></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


