<h1 mat-dialog-title>{{data.name}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">

  <p-table class="col-12"
             [value]="identites"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions">
    <ng-template pTemplate="caption">Historique des versions</ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" pResizableColumn>N° Version</th>
        <th class="text-center" pResizableColumn>Libellé long</th>
        <th class="text-center" pResizableColumn>Libellé court</th>
        <th class="text-center" pResizableColumn>Date d'ouverture</th>
        <th class="text-center" pResizableColumn>Type de structure</th>
        <th class="text-center" pResizableColumn>Date de début</th>
        <th class="text-center" pResizableColumn>Date de fin</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-ident>
      <tr [ngClass]="ident.isCurrentVersion ? 'current-version' : null">
        <td>{{ident.versionId}}</td>
        <td>{{ident.libelleLong}}</td>
        <td>{{ident.libelleCourt}}</td>
        <td>{{ident.dateOuverture | date:'dd/MM/yyyy'}}</td>
        <td>{{ident.typeStructure.typeStructureLib}}</td>
        <td>{{ident.dateDebut | date:'dd/MM/yyyy'}}</td>
        <td>{{ident.dateFin | date:'dd/MM/yyyy'}}</td>
      </tr>
    </ng-template>
  </p-table>

  <p-table class="col-12 mt-4"
           [value]="data.roles"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions">

    <ng-template pTemplate="caption">
      <button class="button float-right" matTooltip="Ajouter un responsable à l'historique"
              (click)="addResponsable()" *ngIf="user.individu.isAdmin">
        <i class="fas fa-plus fa-2x"></i>
      </button>

      Historique des responsables
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" pResizableColumn>IdNum</th>
        <th class="text-center" pResizableColumn>Nom Prenom</th>
        <th class="text-center" pResizableColumn>Date de début</th>
        <th class="text-center" pResizableColumn>Date de fin</th>
        <th class="text-center" pResizableColumn>Validation</th>
        <th class="text-center" pResizableColumn *ngIf="user.individu.isAdmin">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-role>
      <tr>
        <td>{{role.individu.idnum}}</td>
        <td>{{role.individu.nom.toUpperCase() + ' ' + role.individu.prenom}}</td>
        <td>{{role.dateDebut | date:'dd/MM/yyyy'}}</td>
        <td>{{role.dateFin | date:'dd/MM/yyyy'}}</td>
        <td>{{role.etatValid}}</td>
        <td class="text-center" *ngIf="user.individu.isAdmin">
          <button class="button" (click)="updateResponsable(role)"><i class="fas fa-edit fa-2x"></i></button>
          <button class="button ml-4" (click)="deleteResponsable(role)"><i class="fas fa-trash fa-2x"></i></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
