<p-timeline [value]="dates" align="alternate" *ngIf="eventList">
  <ng-template pTemplate="marker">
        <span class="custom-marker p-shadow-2">
            <i [ngClass]="primeIcons.CIRCLE_FILL"></i>
        </span>
  </ng-template>
  <ng-template pTemplate="content" let-date>
    <mat-card>
      <mat-card-title>{{ date  | date: 'dd/MM/yyyy'}}</mat-card-title>
      <ng-container *ngFor="let event of eventList[date]">
        <mat-card-subtitle [ngSwitch]="event.status">
          <span *ngSwitchCase="status.DEMANDE">{{primeIcons.USER_EDIT}}</span>
          <span *ngSwitchCase="status.LOG">{{primeIcons.BOOKMARK}}</span>
          <span *ngSwitchCase="status.TODO">{{primeIcons.LIST}}</span>
          <span *ngSwitchCase="status.ASSOCIATION">{{primeIcons.LINK}}</span>
          <span class="ml-2">{{event.title}}</span>
        </mat-card-subtitle>
        <mat-card-content>
          <p [innerHTML]="event.resume"></p>
        </mat-card-content>
        <hr>
      </ng-container>
    </mat-card>
  </ng-template>
</p-timeline>

