import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {MatDialog} from '@angular/material/dialog';
import {CreateNewAssociationDialogComponent} from '../../../layout/dialog/synchro-application-si/create-new-association-dialog/create-new-association-dialog.component';
import {ReactivateAssociationSiDialogComponent} from '../../../layout/dialog/synchro-application-si/reactivate-association-si-dialog/reactivate-association-si-dialog.component';
import {StopAssociationSiDialogComponent} from '../../../layout/dialog/synchro-application-si/stop-association-si-dialog/stop-association-si-dialog.component';
import {UpdateCodeSiDialogComponent} from '../../../layout/dialog/synchro-application-si/update-code-si-dialog/update-code-si-dialog.component';
import {RepartStructSI} from '../../../model/association.model';
import {AssociationSiService} from '../../../services/association-si.service';
import {HistoAssociationsDialogComponent} from '../../../layout/dialog/synchro-application-si/histo-associations-dialog/histo-associations-dialog.component';
import {UserService} from '../../../services/user.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-repart-struct-si',
  templateUrl: './repart-struct-si.component.html',
  styleUrls: ['./repart-struct-si.component.scss']
})
export class RepartStructSiComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() rssList: RepartStructSI[];
  @Input() structure: Structure;

  user: User;

  histo: RepartStructSI[];

  cols: any[];
  datasource: any[];


  constructor(private associationSiService: AssociationSiService,
              private userService: UserService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.datasource = [];

    this.cols = [
      {field: 'application', header: 'Application'},
      {field: 'etat', header: 'Etat'},
      {field: 'code', header: 'Code'},
      {field: 'dateDebut', header: 'Date de début'},
      {field: 'dateFin', header: 'Date de fin'},
    ];
  }

  ngOnChanges() {
    this.associationSiService.getHistoByStructureId(this.structure.structureId).subscribe(res => this.histo = res);

    if (this.rssList) {
      this.datasource = [];
      this.rssList.forEach(rss => this.datasource.push(this.convertToDataSource(rss)));
    }
  }

  openHistoDialog() {
    this.dialog.open(HistoAssociationsDialogComponent, {
      minWidth: 400,
      data: {
        histo: this.histo
      }
    });
  }

  openAddDialog() {
    this.dialog.open(CreateNewAssociationDialogComponent, {
      minWidth: 400,
      maxWidth: 600,
      data: {
        structure: this.structure
      }
    }).afterClosed().subscribe(() => this.actionEmitter.emit());
  }

  openUpdateCodeSiDialog(rss: RepartStructSI) {
    this.dialog.open(UpdateCodeSiDialogComponent, {
      minWidth: 400,
      maxWidth: 600,
      data: {
        rss
      }
    }).afterClosed().subscribe(() => this.actionEmitter.emit());
  }

  openSynchroSiDialog(rss: RepartStructSI) {
    this.dialog.open(ReactivateAssociationSiDialogComponent, {
      minWidth: 400,
      data: {
        rss
      }
    }).afterClosed().subscribe(() => this.actionEmitter.emit());
  }

  openDesynchroSiDialog(rss: RepartStructSI) {
    this.dialog.open(StopAssociationSiDialogComponent, {
      minWidth: 400,
      data: {
        rss
      }
    }).afterClosed().subscribe(() => this.actionEmitter.emit());
  }

  convertToDataSource(rss: RepartStructSI) {
    return {
      application: rss.application.applicationLib,
      code: rss.codeStructSI,
      dateDebut: moment(rss.dateDebut).format('DD/MM/YYYY'),
      dateFin : rss.dateFin ? moment(rss.dateFin).format('DD/MM/YYYY') : "",
      etat: rss.isValid && (!rss.dateFin || !moment(rss.dateFin).isBefore(new Date())) ? 'Synchro' : 'Désynchro',
      rss
    };
  }
}
