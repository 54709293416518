import {Pipe, PipeTransform} from '@angular/core';
import {Structure} from '../model/structure.model';
import * as moment from 'moment';
import {User} from '../model/user.model';

@Pipe({
  name: 'libelleStructure'
})
export class LibelleStructurePipe implements PipeTransform {

  transform(structure: Structure, user: User): string {
    let res = '';
    if (structure) {
      if (user.individu.isAdmin) {
        res += structure.structureId + ' - ';
      }

      res += structure.identite.libelleLong;
      let details: string = '';
      if (moment(structure.identite.dateOuverture).isAfter(new Date())) {
        details = ' (Ouverture le ' + moment(structure.identite.dateOuverture).format('DD/MM/YYYY');
      }

      if (structure.etatStructure === 'ACTIF' && structure.identite.dateFermeture != null) {
        if (details !== '') {
          details += ' - ';
        } else {
          details += ' (';
        }

        details += 'Fermeture le ' + moment(structure.identite.dateFermeture).format('DD/MM/YYYY');
      }

      if (details !== '') {
        res += details + ')';
      }
    }

    return res;
  }

}
