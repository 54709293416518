import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {Structure} from 'src/app/model/structure.model';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {RoleService} from 'src/app/services/role.service';
import {LdapAccount, Role, User} from 'src/app/model/user.model';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {CAT_STRUCTURE, ETAT_VALID, TYPE_ROLE} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';
import {isInGestRespAccessPerimeter} from '../../../utils/user.utils';

@Component({
  selector: 'app-selection-structure',
  templateUrl: './selection-structure.component.html',
  styleUrls: ['./selection-structure.component.scss']
})
export class SelectionStructureComponent implements OnChanges {

  @Output() roleEmitter: EventEmitter<Role[]> = new EventEmitter<Role[]>();
  @Input() selectedLdapAccount: LdapAccount;
  @Input() selectedStruct: Structure;

  user: User;
  catStructure = CAT_STRUCTURE;
  structForm: FormGroup;
  displayCatStructure: boolean;
  fullPerimeter: Structure[];
  structureSelectList: Structure[];

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private privilegeService: RoleService,
              private structureService: StructureService,
              private structureTreeService: StructureTreeService) {
    this.user = this.userService.getCurrentUser();

    this.structForm = new FormGroup({
      catStructure: new FormControl(null, Validators.required),
      structure: new FormControl(null, Validators.required),
      droitEtendu: new FormControl(true),
    });
  }

  ngOnChanges() {
    this.structureService.getAllStructures().subscribe(res => {
      this.fullPerimeter = res;
      this.structForm.controls.catStructure.setValue(CAT_STRUCTURE.ORGA);
      this.structForm.controls.droitEtendu.setValue(true);

      if (this.user.individu.isAdmin && this.fullPerimeter) {
        if (this.fullPerimeter.filter(struct => struct.catStructure === CAT_STRUCTURE.MAT).length > 0) {
          this.displayCatStructure = true;
          this.onCatStructureChange();
        } else {
          this.structureSelectList = this.fullPerimeter;
        }

      } else {
        this.displayCatStructure = false;
        this.structureSelectList = this.fullPerimeter.filter(struct => isInGestRespAccessPerimeter(struct.structureId, this.user));

        if (this.structureSelectList.length === 1) {
          this.structForm.controls.structure.setValue(this.structureSelectList[0].structureId);
        }
      }

      if (this.selectedStruct) {
        this.structureTreeService.isRootStructure(this.selectedStruct.structureId).subscribe(isRootStruct => {
          this.structForm.controls.catStructure.setValue(this.selectedStruct.catStructure);
          this.structForm.controls.structure.setValue([this.selectedStruct.structureId]);
          this.structForm.controls.droitEtendu.setValue(!isRootStruct);
        });
      }
    });
  }

  onCatStructureChange() {
    this.structureSelectList = this.fullPerimeter.filter(s => s.catStructure === this.structForm.controls.catStructure.value);
  }

  onValidate() {
    const roles: Role[] = [];
    this.structForm.controls.structure.value.forEach(structureId => {
      roles.push({
        typeRole: TYPE_ROLE.GESTIONNAIRE,
        structureId: structureId,
        dateDebut: new Date(),
        isExtended: this.structForm.controls.droitEtendu.value,
        etatValid: ETAT_VALID.VALIDE
      });
    });
    this.roleEmitter.emit(roles);
  }
}
