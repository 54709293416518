import {Lien, Structure} from '../model/structure.model';
import {TypeLien} from '../model/nomenclature.model';
import {ETAT_VALID} from '../model/enum.model';
import {LIEN_HIERARCHIQUE} from '../constants/datas.constant';

export function doesAlreadyExist(list: any[], value: string, label: string) {
  const ts = list.filter(t => t[label].toUpperCase().trim().replace(' ', '') === value.toUpperCase().trim().replace(' ', ''));
  return ts.length > 1;
}

// todo refact
export function getLienFictif(struct: Structure): Lien {
  return {
    lienId: 0,
    typeLien: new TypeLien(LIEN_HIERARCHIQUE),
    structureId: struct.structureId,
    structPorteuseId: 0,
    dateDebut: null,
    dateFin: null,
    etatValid: ETAT_VALID.VALIDE,
    structure: struct,
    structurePorteuse: null,
    hasDemandeSuppressionEnCours: false
  };
}
