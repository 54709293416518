import {Component, OnInit} from '@angular/core';
import {Individu, Role, User} from '../../../model/user.model';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {IndividuService} from '../../../services/Individu.service';
import {MatDialog} from '@angular/material/dialog';
import {isInUserPerimeter} from '../../../utils/user.utils';
import {RemovePrivilegeDialogComponent} from '../../../layout/dialog/privilege/remove-privilege-dialog/remove-privilege-dialog.component';
import {map, startWith} from 'rxjs/operators';
import {compare, normalize} from '../../../utils/string.utils';
import {TYPE_ROLE} from '../../../model/enum.model';
import {AddGestionnaireDialogComponent} from '../../../layout/dialog/privilege/add-gestionnaire-dialog/add-gestionnaire-dialog.component';
import {LdapService} from '../../../services/ldap.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-rechercher-utilisateur',
  templateUrl: './rechercher-utilisateur.component.html',
  styleUrls: ['./rechercher-utilisateur.component.scss']
})
export class RechercherUtilisateurComponent implements OnInit {

  user: User;
  userFC = new FormControl();
  options: Individu[] = [];
  filteredOptions: Observable<Individu[]>;

  selectedIndividu: Individu;
  displayAddButton: boolean;
  datasource: Row[];

  typeRole = TYPE_ROLE;

  constructor(
    private userService: UserService,
    private ldapService: LdapService,
    private individuService: IndividuService,
    public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.init();
  }

  addPrivilege() {
    this.ldapService.getLdapSearchByUid(this.selectedIndividu.idnum).subscribe(ldapAccount => {
      const dialogRef = this.dialog.open(AddGestionnaireDialogComponent, {
        minWidth: 400,
        data: {
          ldapAccount
        }
      });

      dialogRef.afterClosed().subscribe(() => this.init());
    });
  }

  removePrivilege(role: Role) {
    this.dialog
      .open(RemovePrivilegeDialogComponent, {
        minWidth: 400,
        data: {
          roleId: role.roleId,
          typeRole: role.typeRole
        }
      })
      .afterClosed()
      .subscribe(() => this.loadIndividu());
  }

  isInUserPerimeter(role: Role): boolean {
    return isInUserPerimeter(role.structureId, this.user);

  }

  deleteIndividu() {
    this.individuService.deleteIndividu(this.selectedIndividu.individuId).subscribe(() => this.init());
  }

  onSearchTape() {
    this.selectedIndividu = null;
  }

  onIndividuSelect(ind: Individu) {
    if (ind) {
      this.selectedIndividu = ind;
      this.initRows();

    } else {
      if (this.userFC.value) {
        const split: string[] = this.userFC.value.split(' ');
        const indList: Individu[] = this._filter(split[0] + ' ' + split[1]);
        this.selectedIndividu = indList.length === 1 ? indList[0] : null;
      }

      if (!this.selectedIndividu) {
        this.datasource = [];
      }
    }

    if (this.selectedIndividu) {
      this.individuService.isValid(this.selectedIndividu.individuId).subscribe(res => this.displayAddButton = res);
    }
  }

  initRows() {
    this.datasource = [];
    if (this.selectedIndividu.isAdmin) {
      this.datasource.push({title: TYPE_ROLE.ADMINISTRATEUR});
    }
    if (this.selectedIndividu.isModerateur) {
      this.datasource.push({title: TYPE_ROLE.MODERATEUR});
    }
    if (this.selectedIndividu.isResponsable) {
      const roles: Role[] = this.selectedIndividu.roles
        .filter(r => r.typeRole === TYPE_ROLE.RESPONSABLE)
        .sort((r1, r2) => compare(r1.structure.identite.libelleLong, r2.structure.identite.libelleLong));
      this.datasource.push({title: TYPE_ROLE.RESPONSABLE, roles});
    }
    if (this.selectedIndividu.isGestionnaire) {
      const roles: Role[] = this.selectedIndividu.roles
        .filter(r => r.typeRole === TYPE_ROLE.GESTIONNAIRE)
        .sort((r1, r2) => compare(r1.structure.identite.libelleLong, r2.structure.identite.libelleLong));
      this.datasource.push({title: TYPE_ROLE.GESTIONNAIRE, roles});
    }
  }

  onKeyEnterSelect(){
    if(!this.selectedIndividu){
      this.selectedIndividu = null;
    }
  }

  getFieldLabel(ind: Individu): string {
    return ind.nom.toUpperCase() + ' ' + ind.prenom + ' / ' + ind.idnum;
  }

  private init() {
    this.individuService.getAllIndividusDTO().subscribe(res => {
      this.datasource = [];
      this.userFC.setValue(null);
      this.selectedIndividu = null;
      this.options = res;
      this.filteredOptions = this.userFC.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
    });
  }

  private loadIndividu() {
    this.individuService.getAllIndividusDTO().subscribe(res => {
      this.options = res;
      this.filteredOptions = this.userFC.valueChanges.pipe(startWith(''), map(value => this._filter(value)));

      this.selectedIndividu = res.filter(i => this.selectedIndividu && i.individuId === this.selectedIndividu.individuId)[0];
      this.onIndividuSelect(this.selectedIndividu);
    });
  }

  private _filter(value: string): Individu[] {
    let options: Individu[];

    if (value?.trim().length > 0) {
      const values: string[] = value.split(' ');
      options = this.options.filter(individu =>
        values.every(v => normalize(individu.nom).includes(normalize(v)))
        || values.every(v => normalize(individu.prenom).includes(normalize(v)))
        || values.every(v => normalize(individu.idnum).includes(normalize(v)))
        || values.every(v => normalize(individu.mail).includes(normalize(v)))
      );
    }else{
      options = this.options
    }

    return options;
  }
}

export class Row {
  title: string;
  roles?: Role[];
}
