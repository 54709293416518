import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Todo} from '../model/association.model';
import {Pagination} from '../model/divers.model';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  constructor(private http: HttpClient) {
  }

  public search(params: HttpParams, pagination: Pagination): Observable<any> {
    return this.http.post<any>('/todo/search', pagination, {params, observe: 'response'});
  }

  public getTodosByStructureId(structureId: number): Observable<Todo[]> {
    return this.http.get<Todo[]>('/todo/structure/' + structureId);
  }

  public startTodo(todoId: number, codeStructSi, motif: string): Observable<void> {
    return this.http.put<void>('/todo/start/' + todoId + '/code-si/' + codeStructSi, motif);
  }

  public close(todoId: number, motif: string): Observable<void> {
    return this.http.put<void>('/todo/close/' + todoId, motif);
  }

}
