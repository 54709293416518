<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <div *ngIf="data.type === caracterisation.TUTELLE">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Tutelle</mat-label>
      <mat-select [formControl]="selectedTutelle" required>
        <mat-option *ngFor="let t of tutelles" [value]="t.tutelleId">
          {{ t.tutelleLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="selectedTutelle.hasError('required')">Valeur requise</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="data.type === caracterisation.DOMAINE">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Domaine(s)</mat-label>
      <mat-select [formControl]="selectedDomaines" (selectionChange)="onDomainesSelected()" multiple required>
        <mat-option *ngFor="let d of domaines" [value]="d.domaineId">
          {{ d.domaineLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="selectedDomaines.hasError('required')">Valeur requise</mat-error>
      <mat-error *ngIf="selectedDomaines.hasError('selection-domaines-invalid')">{{'Merci de ne sélectionner que 3 domaines au maximum'}}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="data.type === caracterisation.CODE">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>TypeCode</mat-label>
      <mat-select [formControl]="selectedTypeCode" required>
        <mat-option *ngFor="let t of typeCodes" [value]="t">
          {{ t.typeCodeLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="selectedTypeCode.hasError('required')">Valeur requise</mat-error>
    </mat-form-field>

    <mat-form-field class="col-lg-12" appearance="outline">
      <input matInput [formControl]="codeValue"  placeholder="Valeur du code"  maxlength="200" required>
      <mat-error *ngIf="codeValue.hasError('required')">Valeur requise</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" [disabled]="isDisabled()" (click)="onAdd()">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>

