<p-table class="col-lg-12"
         [value]="details"
         [responsive]="true"
         [resizableColumns]="true"
         [paginator]="true"
         [rows]="rows"
         [showCurrentPageReport]="true"
         [(first)]="first"
         currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
         [rowsPerPageOptions]="rowsPerPageOptions"
         *ngIf="details.length > 0">
  <ng-template pTemplate="caption">Historique des actions</ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th class="text-center">Type</th>
      <th class="text-center" style="width:150px">Date</th>
      <th class="text-center">Acteur</th>
      <th class="text-center">Commentaire</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    <tr>
      <td>{{row.typeAction}}</td>
      <td>{{row.dateAction  | date:'dd/MM/yyyy HH:mm'}}</td>
      <td>{{row.acteur.nom}} {{row.acteur.prenom}}</td>
      <td>{{row.commentaire}}</td>
    </tr>
  </ng-template>
</p-table>
