<form [formGroup]="structForm">
  <mat-radio-group class="row col-lg-12 mt_4 mb-4" formControlName="catStructure"
                   (change)="onCatStructureChange()"
                   aria-label="Selectionner une catégorie de structure" required [hidden]="!displayCatStructure">
    <mat-radio-button [value]="catStructure.ORGA"
                      [checked]="structForm.controls.catStructure.value === catStructure.ORGA">Organisationnelle
    </mat-radio-button>
    <mat-radio-button class="ml-4" [value]="catStructure.MAT"
                      [checked]="structForm.controls.catStructure.value === catStructure.MAT">Matricielle
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Structure</mat-label>
    <mat-select formControlName="structure" placeholder="Structure" multiple
                required>
      <mat-option *ngFor="let struct of structureSelectList" [value]="struct.structureId">
        <span appHighlightStructure [structure]="struct">{{struct | libelleStructure:user}}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="structForm.controls.structure.hasError('required')
                    && structForm.controls.structure.touched">Valeur requise</mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="droitEtendu" class="col-lg-12"
                (change)="structForm.controls.droitEtendu.setValue($event.checked)"
                [checked]="structForm.controls.droitEtendu.value">Etendre le droit de gestionnaire sur toutes les
    structures filles
  </mat-checkbox>
</form>

<div class="row justify-content-center">
  <button class="col-lg-6" mat-raised-button color="primary"
          matTooltip="Ajouter à cette personne un rôle de gestionnaire sur cette structure"
          [disabled]="structForm.invalid || !selectedLdapAccount" (click)="onValidate()">Valider
  </button>
</div>
