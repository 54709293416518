import {Component, OnDestroy, OnInit} from '@angular/core';
import {DemandeService} from '../../services/demande.service';
import {CompteAppli, Role, User} from '../../model/user.model';
import {LogService} from '../../services/log.service';
import {NavigationEnd, Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';
import {Lien, Structure} from '../../model/structure.model';
import {StructureService} from '../../services/structure.service';
import {LienService} from '../../services/lien.service';
import {isInGestRespAccessPerimeter} from '../../utils/user.utils';
import {Log} from '../../model/log.model';
import {DEMANDE, ETAT_STRUCTURE, TYPE_ROLE} from '../../model/enum.model';
import {UserService} from '../../services/user.service';
import {CompteAppliService} from '../../services/compte-appli.service';
import {AddCompteAppliDialogComponent} from '../../layout/dialog/compte-application/add-compte-appli-dialog/add-compte-appli-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfigAssetLoaderService} from '../../configAssetLoader.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {ConfirmDialogComponent} from '../../layout/dialog/divers/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  user: User;
  navigationSubscription: any;

  selectedRow: any;

  moderateurRoles: Role[];
  gestionnaireRoles: Role[];
  responsableRole: Role[];

  jwt: string;
  docSwagger: string;
  comptesAppli: CompteAppli[];

  mesDemandes: any;
  mesNouvellesDemandes: number;
  mesDemandesEnCours: number;
  mesDemandesTraites: number;
  demandesEnCours: number;
  demandesATraiter: number;
  demandesEnAttente: number;

  demandes = DEMANDE;

  liensFusionnesOuRemplacesValides: Lien[] = [];
  firstLiensFusionnesOuRemplacesValides = 0;
  rowsLiensFusionnesOuRemplacesValides = 5;
  rowsPerPageOptionsLiensFusionnesOuRemplacesValides = [5, 10, 25];

  structuresFermees: Structure[] = [];
  firstStructFermees = 0;
  rowsStructFermees = 5;
  rowsPerPageOptionsStructFermees = [5, 10, 25];

  lastEvents: Log[] = [];

  constructor(private loaderService: LoaderService,
              private configAssetLoaderService: ConfigAssetLoaderService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private compteAppliService: CompteAppliService,
              private structureService: StructureService,
              private lienService: LienService,
              private demandeService: DemandeService,
              private router: Router,
              private clipboard: Clipboard,
              private logService: LogService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
    this.navigationSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  selectDemandes(groupeDemande: DEMANDE) {
    this.router.navigate(['demandes/gestion-demandes', groupeDemande]);
  }

  selectMesDemandes(event) {
    if (event.element.index === 0) {
      // mes nouvelles demandes
      this.router.navigate(['demandes/gestion-demandes', DEMANDE.MES_DEMANDES_NOUVELLES]);
    } else if (event.element.index === 1) {
      // mes demandes en cours
      this.router.navigate(['demandes/gestion-demandes', DEMANDE.MES_DEMANDES_EN_COURS]);
    } else if (event.element.index === 2) {
      // mes demandes traitées
      this.router.navigate(['demandes/gestion-demandes', DEMANDE.MES_DEMANDES_TRAITEES]);
    }
  }

  updateCompteApplicatif(compteAppli: CompteAppli) {
    const dialogRef = this.dialog.open(AddCompteAppliDialogComponent, {
      minWidth: 400,
      data: {
        compteAppli,
      }
    });
    dialogRef.afterClosed().toPromise().then(res => {
      if (res) {
        this.init();
      }
    });
  }

  generateNewPassword(compteAppli: CompteAppli) {
    this.dialog.open(ConfirmDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Envoi d\'un nouveau mot de passe',
        question: '<p>Souhaitez-vous générer un nouveau mot de passe ? <br\> ' +
          'Si vous confirmez, l\'application "' + compteAppli.login + '" restera connectée au référentiel des structures jusqu\'à la fin de validité du token en cours.</p>'
      }
    }).afterClosed().subscribe(res => {
      if (res.ok) {
        this.loaderService.start();
        this.compteAppliService.generateNewPassword(compteAppli.compteAppliId).subscribe(() => this.loaderService.stop());
      }
    });

  }

  private init() {
    this.selectedRow = null;

    this.gestionnaireRoles = this.user.individu.roles.filter(r => r.typeRole === TYPE_ROLE.GESTIONNAIRE);
    this.responsableRole = this.user.individu.roles.filter(r => r.typeRole === TYPE_ROLE.RESPONSABLE);
    this.moderateurRoles = this.user.individu.roles
      .filter(r => r.typeRole === TYPE_ROLE.MODERATEUR)
      .filter(r => r.structure.etatStructure === ETAT_STRUCTURE.EMBRYON);

    if (this.user.individu.isAdmin || this.user.individu.isModerateur || this.user.individu.isGestionnaire || this.user.individu.isResponsable) {
      this.getCountDemandesEnCours();
      this.getCountDemandesATraiter();
      this.getCountDemandesEnAttente();
      this.getLiensAntecedents();
      this.getLogs();
      this.getStructuresFermees();
      this.getMesDemandes();
    }
    this.configAssetLoaderService.loadConfigurations().subscribe(conf => {
      this.compteAppliService.getComptesAppliByIndividu(this.user.individu.individuId).subscribe(res => this.comptesAppli = res);
      this.docSwagger = conf.apiUrl + '/swagger-ui/index.html';
      this.jwt = this.authenticationService.getJwt();
    });
  }

  private getStructuresFermees(): Promise<void> {
    return new Promise<void>(resolve => {
        this.structureService.getStructuresFermees().subscribe(res => {
          this.structuresFermees = res.filter(s => (this.user.individu.isAdmin || isInGestRespAccessPerimeter(s.structureId, this.user)));
          resolve();
        });
      }
    );
  }

  private getMesDemandes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getDemandesByIndividuId(this.user.individu.individuId).subscribe(res => {
        this.mesNouvellesDemandes = res.filter(dem => ['NOUVEAU'].includes(dem.etatDemande)).length;
        this.mesDemandesEnCours = res.filter(dem => !['NOUVEAU', 'ANNULEE', 'REFUSEE', 'VALIDEE'].includes(dem.etatDemande)).length;
        this.mesDemandesTraites = res.filter(dem => ['ANNULEE', 'REFUSEE', 'VALIDEE'].includes(dem.etatDemande)).length;

        this.mesDemandes = {
          labels: ['Nouvelles demandes', 'Demandes en cours', 'Demandes traitées'],
          datasets: [
            {
              data: [this.mesNouvellesDemandes, this.mesDemandesEnCours, this.mesDemandesTraites],
              backgroundColor: [
                '#587F8E',
                '#E2B788',
                '#63A67A'
              ],
              hoverBackgroundColor: [
                '#587F8E',
                '#E2B788',
                '#63A67A'
              ]
            }]
        };
      });
      resolve();
    });
  }

  private getCountDemandesEnCours(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getCountDemandesEnCours().subscribe(res => this.demandesEnCours = res);
      resolve();
    });
  }

  private getCountDemandesEnAttente(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getCountDemandesEnAttente().subscribe(res => this.demandesEnAttente = res);
      resolve();
    });
  }

  private getCountDemandesATraiter(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getCountDemandesATraiter().subscribe(res => this.demandesATraiter = res);
      resolve();
    });
  }

  private getLiensAntecedents(): Promise<void> {
    return new Promise<void>(resolve => {
        this.lienService.getLiensAntecedentsValidesWithStructuresPorteusesAFermer().subscribe(res => {
          this.liensFusionnesOuRemplacesValides = res.filter(s => (this.user.individu.isAdmin || isInGestRespAccessPerimeter(s.structureId, this.user)));
          resolve();
        });
      }
    );
  }

  private getLogs(): Promise<void> {
    return new Promise<void>(resolve => {
        this.logService.getLogsByUser().subscribe(res => this.lastEvents = res);
        resolve();
      }
    );
  }
}

