import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {LoaderService} from '../../../services/loader.service';
import {WorkflowService} from '../../../services/workflow.service';
import {ReactivationRequest} from '../../../model/workflow.model';
import {StructureService} from '../../../services/structure.service';
import {RoleService} from '../../../services/role.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TypeLien} from '../../../model/nomenclature.model';
import {Role} from '../../../model/user.model';
import {CAT_STRUCTURE, ETAT_VALID, TYPE_ROLE} from '../../../model/enum.model';
import {LIEN_HIERARCHIQUE} from '../../../constants/datas.constant';

@Component({
  selector: 'app-reactivation-structure',
  templateUrl: './reactivation-structure.component.html',
  styleUrls: ['./reactivation-structure.component.scss']
})
export class ReactivationStructureComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;

  structures: Structure[];
  gestionnaires: Role[];

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private roleService: RoleService,
              private structureService: StructureService,
              private workFlowService: WorkflowService) {
    this.form = new FormGroup({
      structurePorteuse: new FormControl(null),
      privilegesASuppr: new FormControl([]),
      motif: new FormControl(null, Validators.required),
    });
  }

  ngOnChanges() {
    this.loaderService.start();
    this.structureService.getStructuresByCategorie(CAT_STRUCTURE.ORGA).subscribe(res => {
      this.loaderService.stop();
      this.structures = res;
      this.form.controls.structurePorteuse.setValue(this.structure.liensParent[0].structPorteuseId);
    });

    if (this.structure) {
      this.roleService.getRolesValidesByStructureAndTypeRole(this.structure.structureId, TYPE_ROLE.GESTIONNAIRE)
        .subscribe(res => this.gestionnaires = res);
    }
  }

  reactivate() {
    const reactivationRequest: ReactivationRequest = {
      lien: {
        lienId: null,
        typeLien: new TypeLien(LIEN_HIERARCHIQUE),
        structureId: this.structure.structureId,
        structPorteuseId: this.form.controls.structurePorteuse.value,
        dateDebut: new Date(),
        dateFin: null,
        etatValid: ETAT_VALID.VALIDE,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false
      },
      rolesACloturer: this.form.controls.privilegesASuppr.value,
      motif: this.form.controls.motif.value
    };

    this.loaderService.start();
    this.workFlowService.reactivateStructure(reactivationRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

}
