<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de contact</h4>
    </mat-card-header>

    <mat-card-content>
      <div class="card-warning alert-warning"
           *ngIf="!readonly && contact.isValid && !hasOneContactAtLeast()">Vous devez renseigner au moins un moyen de
        contact parmi la messagerie, le mobile, le téléphone fixe ou le fax
      </div>

      <form [formGroup]="form">
        <div class="row">
          <!-- INTITULE CONTACT -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Titre</mat-label>
            <input formControlName="intituleContact" matInput placeholder="Ex. Secrétariat" maxlength="200" required>
            <mat-error
              *ngIf="form.controls['intituleContact'].hasError('required') && form.controls['intituleContact'].touched">
              Valeur requise
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row" *ngIf="!readonly">
          <!-- AJOUTER NOM -->
          <mat-checkbox formControlName="addNom" class="col-lg-12"
                        (change)="onAddName()"
                        [checked]="form.controls.addNom.value"> Ajouter un nom ?
          </mat-checkbox>
        </div>

        <div class="row" *ngIf="form.controls.addNom.value || (!readonly && contact.nomPrenom)">
          <!-- NOM PRENOM -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Nom Prénom</mat-label>
            <input formControlName="nomPrenom" matInput placeholder="Nom Prénom"  maxlength="200" required>
            <mat-error
              *ngIf="form.controls['intituleContact'].hasError('required') && form.controls['intituleContact'].touched">
              Valeur requise
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <!-- MAIL -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Adresse de messagerie</mat-label>
            <input formControlName="mail" matInput placeholder="Adresse de messagerie"  maxlength="200">
            <mat-error *ngIf="form.controls['mail'].hasError('email')">Format non valide</mat-error>
            <mat-error *ngIf="!hasOneContactAtLeast()"></mat-error>
          </mat-form-field>

          <!-- MOBILE -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Téléphone portable</mat-label>
            <input formControlName="mobile" matInput placeholder="+33 6 12 34 45 67">
            <mat-error *ngIf="form.controls['mobile'].hasError('pattern')">Format attendu : +33 X XX XX XX XX
            </mat-error>
            <mat-error *ngIf="!hasOneContactAtLeast()"></mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <!-- TELEPHONE -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Téléphone fixe</mat-label>
            <input formControlName="telephone" matInput placeholder="+33 5 12 34 45 67">
            <mat-error *ngIf="form.controls['telephone'].hasError('pattern')">Format attendu : +33 X XX XX XX XX
            </mat-error>
            <mat-error *ngIf="!hasOneContactAtLeast()"></mat-error>
          </mat-form-field>

          <!-- FAX -->
          <mat-form-field class="col-lg-12" appearance="outline">
            <mat-label>Fax</mat-label>
            <input formControlName="fax" matInput placeholder="+33 5 12 34 45 67">
            <mat-error *ngIf="form.controls['fax'].hasError('pattern')">Format attendu : +33 X XX XX XX XX</mat-error>
            <mat-error *ngIf="!hasOneContactAtLeast()"></mat-error>
          </mat-form-field>
        </div>
      </form>

      <div class="row justify-content-center">
        <button class="mt-2 mb-2" mat-raised-button color="primary"
                [matTooltip]="contact?.contactId ? 'Modifier le contact' : 'Ajouter le contact'"
                (click)="save()" [disabled]="form.invalid || !hasOneContactAtLeast()"><i class="fas fa-check"></i>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
