import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-relancer',
  templateUrl: './relancer.component.html',
  styleUrls: ['./relancer.component.scss']
})
export class RelancerComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() demande: Demande;

  user: User;
  commentaire: string;

  constructor(private loaderService: LoaderService, private workflowService: WorkflowService) {
  }

  rekindle() {
    this.loaderService.start();
    this.workflowService.rekindle(
      {
        demandeId: this.demande.demandeId,
        motif: this.commentaire,
        etatDemande: this.demande.etatDemande
      }
    ).subscribe(() => {
        this.loaderService.stop();
        this.actionEmitter.emit();
      });
  }
}
