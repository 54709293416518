import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IdentiteStructureService} from '../../../../services/identite-structure.service';
import {formatLocalDateWithUTC} from '../../../../utils/date.utils';

@Component({
  selector: 'app-update-closing-date-dialog',
  templateUrl: './update-closing-date-dialog.component.html',
  styleUrls: ['./update-closing-date-dialog.component.scss']
})
export class UpdateClosingDateDialogComponent implements OnInit {

  form: FormGroup;

  constructor(private identiteStructureService: IdentiteStructureService,
              public dialogRef: MatDialogRef<UpdateClosingDateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      dateFermeture: new FormControl(this.data.selectedStructure.identite.dateFermeture, Validators.required),
    });
  }

  updateDateFermeture() {
    this.data.selectedStructure.identite.dateFermeture = formatLocalDateWithUTC(this.form.controls.dateFermeture.value);
    this.identiteStructureService.update(this.data.selectedStructure.identite, this.data.selectedStructure.structureId)
      .subscribe(res => this.dialogRef.close(res));
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  selectedStructure: Structure;
}
