import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {TreeNode} from 'primeng/api';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {User} from '../../../model/user.model';
import {LoaderService} from '../../../services/loader.service';
import {StructureService} from '../../../services/structure.service';
import {StructNode} from '../../../model/divers.model';
import {compare, normalize} from '../../../utils/string.utils';
import {CAT_STRUCTURE, ETAT_STRUCTURE} from '../../../model/enum.model';
import {LienService} from '../../../services/lien.service';
import {UserService} from '../../../services/user.service';
import {isInGestRespAccessPerimeter} from '../../../utils/user.utils';

@Component({
  selector: 'app-structure-tree',
  templateUrl: './structureTree.component.html',
  styleUrls: ['./structureTree.component.scss']
})
export class StructureTreeComponent implements OnChanges {

  @Output() selectedStructureEmitter: EventEmitter<Structure> = new EventEmitter<Structure>();

  @Input() title = 'Choix de la structure';
  @Input() tree = true;
  @Input() displayOption = true;
  @Input() autoSelection = true;
  @Input() structuresActivesOnly = false;
  @Input() searchMode = true;

  user: User;

  structuresOptions: Structure[];
  structCtrl: FormControl;
  filteredStructuresOptions: Observable<Structure[]>;

  dataTree: StructNode[];
  lazyStructTree: StructNode[];

  structTable: Structure[];
  selectedRow: Structure;
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  orgaDisplay: boolean;
  matDisplay: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private loaderService: LoaderService,
    private lienService: LienService,
    private structureService: StructureService,
    private structureTreeService: StructureTreeService) {
    this.user = this.userService.getCurrentUser();
    this.orgaDisplay = true;
    this.matDisplay = false;
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.structCtrl = new FormControl(null);
    this.getAllStructures();

    this.loaderService.start();
    return Promise.all([this.loadTree(), this.getStructuresMatricielles()]).then(() => this.loaderService.stop());
  }

  getStructuresMatricielles(): Promise<boolean> {
    return new Promise(resolve => {
      this.structTable = [];
      this.structureService.getStructuresByCategorie(CAT_STRUCTURE.MAT).subscribe(res => {
        if (this.user.individu.isAdmin || this.user.individu.isModerateur) {
          this.structTable = res;
        } else {
          this.structTable = res
            .filter(s => isInGestRespAccessPerimeter(s.structureId, this.user))
            .filter(s => ![ETAT_STRUCTURE.SUPPRIME, ETAT_STRUCTURE.ARCHIVE].includes(s.etatStructure));
        }

        if (this.structuresActivesOnly) {
          this.structTable = this.structTable.filter(s => ![ETAT_STRUCTURE.EMBRYON].includes(s.etatStructure));
        }

        this.structTable.sort((s1, s2) => compare(s1.identite.libelleLong, s2.identite.libelleLong));
      });
      resolve(true);
    });
  }

  getAllStructures(): Promise<void> {
    return new Promise(resolve => {
      this.structuresOptions = [];
      this.structureService.getAllStructures().subscribe(res => {
        if (this.user.individu.isAdmin) {
          this.structuresOptions = res;
        } else {
          this.structuresOptions = res
            .filter(s => isInGestRespAccessPerimeter(s.structureId, this.user))
            .filter(s => ![ETAT_STRUCTURE.SUPPRIME, ETAT_STRUCTURE.ARCHIVE].includes(s.etatStructure));
        }

        if (this.structuresActivesOnly) {
          this.structuresOptions = this.structuresOptions.filter(s => ![ETAT_STRUCTURE.EMBRYON].includes(s.etatStructure) && s.catStructure !== CAT_STRUCTURE.MAT);
        }

        if (this.autoSelection && this.structuresOptions?.length === 1) {
          this.structCtrl.setValue(this.structuresOptions[0].identite.libelleLong);
          this.onStructureSelect(this.structuresOptions[0]);
        } else {
          this.structuresOptions.sort((s1, s2) => compare(s1.identite.libelleLong, s2.identite.libelleLong));
          this.filteredStructuresOptions = this.structCtrl.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        }
        resolve();
      });
    });
  }

  loadTree(): Promise<boolean> {
    return new Promise(resolve => {
      this.dataTree = [];
      this.lazyStructTree = [];
      this.structureTreeService.getLazyStructTree().toPromise()
        .then(res => {
          this.lazyStructTree = res;
          this.dataTree = this.lazyStructTree;
          resolve(true);
        }).catch(() => this.loaderService.stop());
    });
  }

  loadNode(event: any) {
    if (!event.node.children) {
      const structNode: StructNode = {
        label: event.node.label,
        data: event.node.data,
        icon: event.node.icon,
        leaf: event.node.leaf,
        styleClass: event.node.styleClass,
        parent: event.node.parent,
        children: event.node.children,
      };

      this.loaderService.start();
      this.structureTreeService.getLazyNodeChildren(JSON.stringify(structNode, this.getCircularReplacer()))
        .subscribe(res => {
          event.node.children = res;
          this.loaderService.stop();
        });
    }
  }

  onNodeSelect(node: StructNode) {
    this.structureService.getStructureByStructureId(node.data).subscribe(struct => {
      this.selectedStructureEmitter.emit(struct);
    });
  }

  onRowSelected() {
    this.structureService.getStructureByStructureId(this.selectedRow.structureId).subscribe(struct => {
      this.selectedStructureEmitter.emit(struct);
    });
  }

  onStructureSelect(structure: Structure) {
    if (structure) {
      this.selectedStructureEmitter.emit(structure);
    }
  }

  onInputSearch(value: any) {
    this.expandAll();
    if (!value.data) {
      this.dataTree = this.lazyStructTree;
    }
  }

  private _filter(value: string): Structure[] {
    let options: Structure[];

    if (value?.trim().length > 0) {
      const values: string[] = value.split(' ');
      options = this.structuresOptions.filter(structure =>
        values.every(v =>
          normalize(structure.identite.libelleLong).includes(normalize(v))
          || (structure.identite.libelleCourt &&
            normalize(structure.identite.libelleCourt).includes(normalize(v)))
        )
      );
    } else {
      options = this.structuresOptions;
    }
    return options;
  }

  private expandAll() {
    this.dataTree.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

}
