import {Component, EventEmitter, Output} from '@angular/core';
import {Individu, User} from '../../../model/user.model';
import {FormControl, FormGroup} from '@angular/forms';
import {TypeDemande} from '../../../model/demande.model';
import {Structure} from '../../../model/structure.model';
import {StructureService} from '../../../services/structure.service';
import {DemandeService} from '../../../services/demande.service';
import * as moment from 'moment';
import {IndividuService} from '../../../services/Individu.service';
import * as type from '../../../constants/type-libelle.constant';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'app-recherche-demande-form',
  templateUrl: './recherche-demande-form.component.html',
  styleUrls: ['./recherche-demande-form.component.scss']
})
export class RechercheDemandeFormComponent {

  @Output() searchEmitter: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  form: FormGroup;

  user: User;

  typeDemandes: TypeDemande[];
  demandeurs: Individu[];
  structures: Structure[];

  etatDemandes = type.data.etat_demande;

  constructor(private structureService: StructureService,
              private userService: UserService,
              private individuService: IndividuService,
              private demandeService: DemandeService) {
    this.user = this.userService.getCurrentUser();

    this.form = new FormGroup({
      types: new FormControl(null),
      etats: new FormControl(null),
      demandeurs: new FormControl(null),
      apres: new FormControl(null),
      avant: new FormControl(null),
      structures: new FormControl(null),
      isMine: new FormControl(false),
    });

    this.structureService.getAllStructures().subscribe(res => this.structures = res);
    this.demandeService.getAllTypesDemande().subscribe(res => this.typeDemandes = res);
    this.individuService.getAllIndividus().subscribe(res => this.demandeurs = res);
  }

  search() {
    let params = new HttpParams();

    if (this.form.controls.types.value && this.form.controls.types.value.length > 0) {
      params = params.append('types', this.form.controls.types.value);
    }
    if (this.form.controls.etats.value && this.form.controls.etats.value.length > 0) {
      params = params.append('etats', this.form.controls.etats.value);
    }
    if (this.form.controls.demandeurs.value && this.form.controls.demandeurs.value.length > 0) {
      params = params.append('demandeurs', this.form.controls.demandeurs.value);
    }
    if (this.form.controls.avant.value) {
      params = params.append('avant', moment(this.form.controls.avant.value).format('DD/MM/YYYY HH:mm'));
    }
    if (this.form.controls.apres.value) {
      params = params.append('apres', moment(this.form.controls.apres.value).format('DD/MM/YYYY HH:mm'));
    }
    if (this.form.controls.structures.value && this.form.controls.structures.value.length > 0) {
      params = params.append('structures', this.form.controls.structures.value);
    }
    if (this.form.controls.isMine.value) {
      params = params.append('isMine', this.form.controls.isMine.value);
    }
    this.searchEmitter.emit(params);
  }
}
