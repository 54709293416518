<div class="row justify-content-center mt-4">
  <h1 class="title">Mes structures</h1>
</div>

<div class="row justify-content-center">
  <mat-accordion class="col-lg-8 mt-4 mb-4 p-4">
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>{{selectedStructure ? selectedStructure.identite.libelleLong : 'Sélection de la structure'}}</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree (selectedStructureEmitter)="onSelectedStructure($event)"
                          [title]="null">
      </app-structure-tree>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<app-structure-details [structure]="selectedStructure" *ngIf="selectedStructure"></app-structure-details>


