import {Component, OnInit} from '@angular/core';
import {Individu, User} from '../../../model/user.model';
import {LoaderService} from '../../../services/loader.service';
import {RoleService} from '../../../services/role.service';
import {IndividuService} from '../../../services/Individu.service';
import {MatDialog} from '@angular/material/dialog';
import {RemovePrivilegeDialogComponent} from '../../../layout/dialog/privilege/remove-privilege-dialog/remove-privilege-dialog.component';
import {TYPE_ROLE} from '../../../model/enum.model';
import {AddAdminModerateurDialogComponent} from '../../../layout/dialog/privilege/add-admin-moderateur-dialog/add-admin-moderateur-dialog.component';
import {UserService} from '../../../services/user.service';
import {UpdateStructuresModerateurDialogComponent} from '../../../layout/dialog/privilege/update-structures-moderateur-dialog/update-structures-moderateur-dialog.component';

@Component({
  selector: 'app-gestion-administrateur-moderateur',
  templateUrl: './gestion-administrateur-moderateur.component.html',
  styleUrls: ['./gestion-administrateur-moderateur.component.scss']
})
export class GestionAdministrateurModerateurComponent implements OnInit {

  user: User;
  cols: any[];

  adminList: any[];
  firstAdmin = 0;
  rowsAdmin = 25;
  rowsPerPageOptionsAdmin = [10, 25, 50];

  moderList: any[];
  firstModer = 0;
  rowsModer = 25;
  rowsPerPageOptionsModer = [10, 25, 50];

  typeRole = TYPE_ROLE;

  selectedResp: Individu;

  constructor(private loaderService: LoaderService,
              private userService:UserService,
              private roleService: RoleService,
              private individuService: IndividuService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'nomComplet', header: 'Nom complet'},
      {field: 'observations', header: 'Observations'},
    ];

    this.adminList = [];
    this.moderList = [];
  }

  ngOnInit() {
    this.init();
  }

  onAddButton(typeRole: TYPE_ROLE) {
    const dialogRef = this.dialog.open(AddAdminModerateurDialogComponent, {
      minWidth: 400,
      data: {
        typeRole
      }
    });

    dialogRef.afterClosed().subscribe(() => {
        this.init();
    });
  }

  openRemoveDialog(typeRole: TYPE_ROLE, individu: Individu) {
    const dialogRef = this.dialog.open(RemovePrivilegeDialogComponent, {
      minWidth: 400,
      data: {
        typeRole,
        individu
      }
    });

    dialogRef.afterClosed().subscribe(() => {
        this.init();
    });
  }

  openStructuresDialog(individu: Individu) {
    const dialogRef = this.dialog.open(UpdateStructuresModerateurDialogComponent, {
      minWidth: 400,
      data: {
        individu
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.init();
    });
  }

  init() {
    this.individuService.getAllAdministrateurs().subscribe(res => {
      this.adminList = [];
      res.forEach(i => this.adminList.push(this.convertToDataSource(i)));
    });

    this.individuService.getAllModerateurs().subscribe(res => {
      this.moderList = [];
      res.forEach(i => this.moderList.push(this.convertToDataSource(i)));
    });
  }

  convertToDataSource(individu: Individu) {
    return {
      individuId: individu.individuId,
      individu,
      nomComplet: individu.nom + ' ' + individu.prenom,
      observations: this.getRemarques(individu)
    };
  }

  private getRemarques(ind: Individu): string {
    let res = '';
    if (!ind.isValid) {
      res += 'Le compte de cet utilisateur n\'est plus valide dans l\'annuaire de l\'établissement. Il est probablement nécessaire de mettre à jour (supprimer) les privilèges dont il dispose dans l\'applications.';
    }
    if (ind.idnum === this.user.username) {
      res += 'Vous ne pouvez pas procéder à la suppression de vos propres privilèges.';
    }
    return res;
  }
}
