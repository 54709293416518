export const data = {
  type_contact: [
    {value: 'GENERIQUE', libelle: 'Générique'},
    {value: 'INDIVIDU', libelle: 'Individu'},
  ],
  cat_structure: [
    {value: 'ORGANISATIONNELLE', libelle: 'Organisationnelle'},
    {value: 'MATRICIELLE', libelle: 'Matricielle'},
  ],
  etat_structure: [
    {value: 'EMBRYON', libelle: 'Embryon'},
    {value: 'NOUVEAU', libelle: 'Nouveau'},
    {value: 'ACTIF', libelle: 'Actif'},
    {value: 'A_SUPPRIMER', libelle: 'A supprimer'},
    {value: 'A_ARCHIVER', libelle: 'A archiver'},
    {value: 'SUPPRIME', libelle: 'Supprimé'},
    {value: 'ARCHIVE', libelle: 'Archivé'},
  ],
  etat_valid: [
    {value: 'VALIDE', libelle: 'Valide'},
    {value: 'INVALIDE', libelle: 'Invalide'},
    {value: 'A_VALIDER', libelle: 'A valider'},
  ],
  type_role: [
    {value: 'GESTIONNAIREhttp://localhost.u-bordeaux.fr:4200/#/dashboard', libelle: 'Gestionnaire'},
    {value: 'RESPONSABLE', libelle: 'Responsable'},
    {value: 'ADMINISTRATEUR', libelle: 'Administrateur'},
  ],
  type_validation: [
    {value: 'FORTE', libelle: 'forte'},
    {value: 'SIMPLE', libelle: 'simple'},
  ],
  contexte: [
    {value: 'LIEN', libelle: 'Lien'},
    {value: 'IDENTITE_STRUCTURE', libelle: 'Identité structure'},
    {value: 'ROLE', libelle: 'Rôle'},
    {value: 'STRUCTURE', libelle: 'Structure'},
  ],
  etat_demande: [
    {value: 'NOUVEAU', libelle: 'Nouveau'},
    {value: 'PRISE_EN_CHARGE_NIV_1', libelle: 'Prise en charge - Niveau 1'},
    {value: 'ATTENTE_MODIFICATION', libelle: 'En attente de modification'},
    {value: 'A_TRAITER', libelle: 'A traiter'},
    {value: 'A_VALIDER', libelle: 'A valider'},
    {value: 'PRISE_EN_CHARGE_NIV_2', libelle: 'Prise en charge - Niveau 2'},
    {value: 'VALIDEE', libelle: 'Validée'},
    {value: 'REFUSEE', libelle: 'Refusée'},
    {value: 'ANNULEE', libelle: 'Annulée'},
  ],
  log_level: [
    {value: 'INFO', libelle: 'Info'},
    {value: 'WARNING', libelle: 'Warning'},
    {value: 'CRITICAL', libelle: 'Error'},
  ],
  type_todo: [
    {value: 'CREATION_STRUCTURE', libelle: 'Création d\'une stucture'},
    {value: 'MODIFICATION_IDENTITE', libelle: 'Modification d\'identité'},
    {value: 'NOUVEAU_RESPONSABLE', libelle: 'Ajout d\'un nouveau responsable'},
    {value: 'FERMETURE_STRUCTURE', libelle: 'Fermeture d\'une structure'},
  ],
  etat_todo: [
    {value: 'NEW', libelle: 'NEW'},
    {value: 'ASYNC', libelle: 'ASYNC'},
    {value: 'DONE', libelle: 'DONE'},
    {value: 'PENDING', libelle: 'PENDING'},
    {value: 'ACKED', libelle: 'ACKED'},
  ]
};
