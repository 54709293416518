import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {Individu, User} from '../../../model/user.model';
import {StructureService} from '../../../services/structure.service';
import {Demande} from '../../../model/demande.model';
import {AcceptRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {ApplicationService} from '../../../services/application.service';
import {ReferentielAquitainFormComponent} from '../../administration/referentielAquitain/referentiel-aquitain-form/referentiel-aquitain-form.component';
import {LoaderService} from '../../../services/loader.service';
import {IndividuService} from '../../../services/Individu.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Todo} from '../../../model/association.model';
import {Application} from '../../../model/nomenclature.model';
import {CAT_STRUCTURE, ETAT_TODO, TYPE_TODO} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-acceptation-pour-validation',
  templateUrl: './acceptation-pour-validation.component.html',
  styleUrls: ['./acceptation-pour-validation.component.scss']
})
export class AcceptationPourValidationComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;

  @ViewChild(ReferentielAquitainFormComponent) referentielAquitainFormComponent: ReferentielAquitainFormComponent;

  user: User;

  appliSIList: Application[];
  moderateurs: Individu[];
  moderateurParDefaut: string = 'cheurtev';

  form: FormGroup;

  commentaire: string;

  catStructure = CAT_STRUCTURE;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private individuService: IndividuService,
              private structureService: StructureService,
              private applicationService: ApplicationService,
              private workflowService: WorkflowService) {
    this.user = this.userService.getCurrentUser();
    this.appliSIList = [];

    this.form = new FormGroup({
      moderateurs: new FormControl([], Validators.required),
      appliSI: new FormControl([]),
      useRefAq: new FormControl(false),
    });
  }

  ngOnChanges() {
    if (this.demande.typeDemande.typeDemandeId === 1) {
      this.individuService.getAllModerateurs().subscribe(res => {
        this.moderateurs = res;
        this.form.controls.moderateurs.setValue(this.moderateurs?.filter(m => m.idnum === this.moderateurParDefaut));
      });
      this.applicationService.getAvailableApplications(this.demande.lien.structureId).subscribe(res => this.appliSIList = res.filter(a => a.isValid));
    }
  }

  validate() {
    const todos: Todo[] = [];
    this.form.controls.appliSI.value.forEach(app => {
      const todo: Todo = {
        todoId: null,
        applicationId: app.applicationId,
        structureId: this.demande.lien.structureId,
        typeTodo: TYPE_TODO.CREATION_STRUCTURE,
        etatTodo: ETAT_TODO.PENDING,
        dateTodo: new Date(),
        commentaire: 'Association avec l\'appli ' + app.applicationLib,
        structure: this.demande.lien.structure,
        application: app
      };
      todos.push(todo);
    });

    const acceptReq: AcceptRequest = {
      todos,
      useReferentielAqu: this.form.controls.useRefAq.value,
      moderateurs: [this.form.controls.moderateurs.value],
      syncRefAq: this.referentielAquitainFormComponent ? this.referentielAquitainFormComponent.getSyncRefAq() : null,
      demandeId: this.demande.demandeId,
      motif: !this.commentaire || this.commentaire.trim() === '' ? 'Validation technique par la DSI' : this.commentaire
    };

    this.loaderService.start();
    this.workflowService.acceptForValidation(acceptReq).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  isDisabled(): boolean {
    return (this.demande.typeDemande.typeDemandeId === 1 && this.form.invalid) || (this.referentielAquitainFormComponent && this.referentielAquitainFormComponent.form.invalid);
  }
}
