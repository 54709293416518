import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {RefuseRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {TYPE_DEMANDE} from '../../../model/enum.model';

@Component({
  selector: 'app-refus-demande',
  templateUrl: './refus-demande.component.html',
  styleUrls: ['./refus-demande.component.scss']
})
export class RefusDemandeComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() demande: Demande;

  user: User;
  motif: string;

  typeDemande = TYPE_DEMANDE;

  constructor(private loaderService: LoaderService,
              private workFlowService: WorkflowService) {
  }

  refuse() {
    const refuseRequest: RefuseRequest = {
      demandeId: this.demande.demandeId,
      motif: this.motif
    };

    this.loaderService.start();
    this.workFlowService.refuse(refuseRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
