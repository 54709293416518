import {Component, Inject} from '@angular/core';
import {CodeService} from '../../../../services/code.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {LoaderService} from '../../../../services/loader.service';
import {getYYYYMMDD} from '../../../../utils/date.utils';
import {CARACTERISATION} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-update-caracterisation-dialog',
  templateUrl: './update-caracterisation-dialog.component.html',
  styleUrls: ['./update-caracterisation-dialog.component.scss']
})
export class UpdateCaracterisationDialogComponent {

  caracterisation = CARACTERISATION;

  codeValue: FormControl;
  isHisto: boolean;
  now: Date;
  dateFin: FormControl;
  title: string;

  constructor(
    private loaderService: LoaderService,
    private codeService: CodeService,
    public dialogRef: MatDialogRef<UpdateCaracterisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    if (this.data.type === CARACTERISATION.CODE) {
      this.now = new Date();
      this.now.setDate(this.now.getDate() - 1);
      this.title = 'Modification du code ' + this.data.item.typeCode.typeCodeLib;
      this.dateFin = new FormControl(this.now, Validators.required);
      this.isHisto = false;
      this.codeValue = new FormControl(this.data.item.codeValue);
    }
  }

  onUpdate() {
    this.loaderService.start();
    if (this.data.type === CARACTERISATION.CODE) {
      let dateFin: string = null;
      if (this.isHisto) {
        dateFin = getYYYYMMDD(this.dateFin.value);
      }
      this.data.item.codeValue = this.codeValue.value;

      this.codeService.updateWithHisto(this.data.item, dateFin).subscribe(() =>{
        this.loaderService.stop();
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}

export interface DialogData {
  type: string;
  structureId: number;
  item: any;
}
