<h1 mat-dialog-title>Fermeture de la structure {{data.structure.identite.libelleLong}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p>La structure possède des liens ouverts avec des structures filles actives.
    Si vous validez, les structures filles seront également fermées.</p>

  <p>Souhaitez-vous continuer ?</p>

  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" matTooltip="Demande d'archivage"
            (click)="close()">
      <i class="fas fa-check fa-2x"></i>
    </button>

    <button mat-raised-button color="accent" (click)="onCancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</div>
