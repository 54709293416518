import {Component, OnInit} from '@angular/core';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {TypeLienService} from '../../../services/type-lien.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {TypeLien} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-type-lien',
  templateUrl: './type-lien.component.html',
  styleUrls: ['./type-lien.component.scss']
})
export class TypeLienComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: TypeLien[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private typeLienService: TypeLienService,
              private snackBar: MatSnackBar,
              private structureService: StructureService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(typeLien: TypeLien) {
    typeLien.hasError = false;

    if (!typeLien.typeLienLib || typeLien.typeLienLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeLien.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, typeLien.typeLienLib, 'typeLienLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeLien.hasError = true;
    }
  }

  onIsValidChanged(typeLien: TypeLien) {
    typeLien.isValid = !typeLien.isValid;

    if (!typeLien.isValid) {
      if (typeLien.typeLienId) {
        this.structureService.getStructuresByTypeLien(typeLien.typeLienId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                'Le type de lien ' + typeLien.typeLienLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
              typeLien.isValid = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(typeLien, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        typeLienId: null,
        typeLienLib: null,
        relation: null,
        isAntecedent: false,
        isHierarchique: false,
        isValid: true,
        isHidden: false,
        hasError: true,
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.typeLienService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.typeLienService.getAllTypesLien().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }

}
