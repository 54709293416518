<div class="row justify-content-center mt-4">
  <h1 class="title">Gestion des types d'adresse</h1>
</div>

<div class="row justify-content-center buffer">
  <p-table class="col-lg-8"
           [value]="dataSource"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="dataSource">

    <ng-template pTemplate="caption">
      <button class="button float-left" matTooltip="Annuler les modifications" (click)="cancel()">
        <i class="fas fa-times fa-2x"></i></button>

      <button class="button float-left" matTooltip="Sauvegarder les modifications" [disabled]="isInvalid()"
              (click)="validate()">
        <i class="fas fa-check fa-2x"></i></button>

      <button class="button float-right" matTooltip="Afficher les types d'adresse invalides" (click)="displayInvalid()"
              *ngIf="hasInvalidValues">
        <span [hidden]="!displayInvalidValues"><i class="fas fa-eye-slash fa-2x"></i></span>
        <span [hidden]="displayInvalidValues"><i class="fas fa-eye fa-2x"></i></span>
      </button>

      <button class="button float-right" matTooltip="Ajouter un nouveau type d'adresse" (click)="add()">
        <i class="fas fa-plus fa-2x"></i></button>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" pResizableColumn>Libellé</th>
        <th class="text-center" style="width:12em" pResizableColumn>Multiple</th>
        <th class="text-center" style="width:12em" pResizableColumn>Activer</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr [hidden]="row.isHidden">
        <td>
          <input class="table-input" [(ngModel)]="row.typeAdresseLib" (input)="onLibelleChanged(row)"  maxlength="200"
                 [disabled]="!row.isValid">
        </td>

        <td class="text-center">
          <mat-slide-toggle color="primary" [checked]="row.isMultiple" (toggleChange)="onIsMultipleChanged(row)"
                            [disabled]="!row.isValid"></mat-slide-toggle>
        </td>

        <td class="text-center">
          <mat-slide-toggle color="primary" [checked]="row.isValid"
                            (toggleChange)="onIsValidChanged(row)"></mat-slide-toggle>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
