import {Component, Inject, OnInit} from '@angular/core';
import {RoleService} from '../../../../services/role.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Individu, Role, User} from '../../../../model/user.model';
import {UserService} from '../../../../services/user.service';
import {IndividuService} from '../../../../services/Individu.service';
import {LoaderService} from '../../../../services/loader.service';
import {TYPE_ROLE} from '../../../../model/enum.model';
import {StructureTreeService} from '../../../../services/structure-tree.service';
import {Structure} from '../../../../model/structure.model';

@Component({
  selector: 'app-remove-privilege-dialog',
  templateUrl: './remove-privilege-dialog.component.html',
  styleUrls: ['./remove-privilege-dialog.component.scss']
})
export class RemovePrivilegeDialogComponent implements OnInit {

  user: User;
  display = false;

  typeRole = TYPE_ROLE;
  role: Role;

  constructor(
    private loaderService: LoaderService,
    private individuService: IndividuService,
    private roleService: RoleService,
    private userService: UserService,
    public dialogRef: MatDialogRef<RemovePrivilegeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
    if ([TYPE_ROLE.ADMINISTRATEUR.valueOf(), TYPE_ROLE.MODERATEUR.valueOf()].includes(this.data.typeRole)) {
      this.display = true;
    } else {
      this.roleService.getRoleByRoleId(this.data.roleId).subscribe(res => {
        this.role = res;
        this.display = true;
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.loaderService.start();
    if (this.data.typeRole === TYPE_ROLE.MODERATEUR) {
      this.individuService.disableModerPrivilege(this.data.individu.individuId).subscribe(() => {
        if (this.data.individu.individuId === this.user.individu.individuId) {
          this.user.individu.isModerateur = false;
          this.userService.setUser(this.user);
        }
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.typeRole === TYPE_ROLE.ADMINISTRATEUR) {
      this.individuService.disableAdminPrivilege(this.data.individu.individuId).subscribe(() => {
        if (this.data.individu.individuId === this.user.individu.individuId) {
          this.user.individu.isAdmin = false;
          this.userService.setUser(this.user);
        }
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else {
      this.roleService.close(this.role.roleId).subscribe(() => {
        if (this.role.individuId === this.user.individu.individuId) {
          this.userService.getUpdatedUser();
        }
        this.loaderService.stop();
        this.dialogRef.close();
      });
    }
  }
}

export interface DialogData {
  roleId: number;
  typeRole: TYPE_ROLE;
  individu: Individu;
  structure: Structure;
}


