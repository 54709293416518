import {Component, Inject, Input, OnChanges, ViewChild} from '@angular/core';
import {IdentiteStructure, Structure} from '../../../model/structure.model';
import {StructureService} from '../../../services/structure.service';
import {IdentiteFormComponent} from '../identite-form/identite-form.component';
import {Role, User} from '../../../model/user.model';
import {MatDialog} from '@angular/material/dialog';
import {HistoStructureDialogComponent} from '../../../layout/dialog/structure/histo-identite-dialog/histo-structure-dialog.component';
import {IdentiteStructureService} from '../../../services/identite-structure.service';
import {IdentiteEnCoursDialogComponent} from '../../../layout/dialog/structure/identite-en-cours-dialog/identite-en-cours-dialog.component';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {LienService} from '../../../services/lien.service';
import {StructureDetailsComponent} from '../structure-details/structure-details.component';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {InstanceDemande} from '../../../model/demande.model';
import {InstanceDemandeService} from '../../../services/instance-demande.service';
import {getDDMMYYYY} from '../../../utils/date.utils';
import {DownloadService} from '../../../services/download.service';
import {CAT_STRUCTURE, ETAT_DEMANDE, ETAT_STRUCTURE, ETAT_VALID, TYPE_DEMANDE, TYPE_ROLE} from '../../../model/enum.model';
import {DemandeService} from '../../../services/demande.service';
import {RoleService} from '../../../services/role.service';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModificationIdentiteRequest} from '../../../model/workflow.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-caracteristiques-generales',
  templateUrl: './caracteristiques-generales.component.html',
  styleUrls: ['./caracteristiques-generales.component.scss']
})
export class CaracteristiquesGeneralesComponent implements OnChanges {

  @Input() structure: Structure;
  @ViewChild(IdentiteFormComponent) identiteComponent: IdentiteFormComponent;

  user: User;
  display: boolean;

  etatDemande = ETAT_DEMANDE;
  catStructure = CAT_STRUCTURE;
  etatStructure = ETAT_STRUCTURE;

  motif: string;
  readonlyForm: boolean;
  readonlyResp: boolean;
  hasDemandeIdentiteEnCours: boolean;
  hasDemandeResponsableEnCours: boolean;
  instancesDemande: InstanceDemande[];
  validIdentites: IdentiteStructure[];
  validResponsables: Role[];

  constructor(
    private downloadService: DownloadService,
    private userService: UserService,
    private loaderService: LoaderService,
    private instanceDemandeService: InstanceDemandeService,
    private demandeService: DemandeService,
    private identiteStructureService: IdentiteStructureService,
    private structureService: StructureService,
    private lienService: LienService,
    private roleService: RoleService,
    private workflowService: WorkflowService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(StructureDetailsComponent) public parent: StructureDetailsComponent) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnChanges() {
    if (this.structure) {
      this.display = false;
      Promise.all([
        this.getIdentites(),
        this.getLastIdentite(),
        this.getDemandesResponsableEnCours(),
        this.getRoles(),
        this.getInstancesDemande()]
      ).then(() => {
        this.readonlyResp = true;
        this.readonlyForm = true;
        this.display = true;
      });
    }
  }

  onDownload(documentId: number) {
    this.downloadService.download(documentId);
  }

  enableIdentityForm() {
    this.readonlyForm = !this.readonlyForm;
    this.readonlyResp = true;
  }

  onIdentiteUpdate() {
    this.loaderService.start();

    if (this.user.individu.isAdmin && [ETAT_STRUCTURE.EMBRYON, ETAT_STRUCTURE.NOUVEAU].includes(this.structure.etatStructure)) {
      this.identiteStructureService.update(this.identiteComponent.getIdentite(), this.structure.structureId).toPromise().then(() => {
        this.loaderService.stop();
        this.parent.init();
        this.readonlyForm = true;
      });

    } else {
      const requestModificationIdentite: ModificationIdentiteRequest = {
        structureId: this.structure.structureId,
        identiteStructure: this.identiteComponent.getIdentite(),
        motif: this.motif
      };

      this.workflowService.modifyIdentite(requestModificationIdentite).subscribe(() => {
        this.loaderService.stop();
        this.parent.init();
        this.readonlyForm = true;
      });
    }
  }

  displayHistoStructure() {
    this.dialog.open(HistoStructureDialogComponent, {
      minWidth: 400,
      data: {
        name: 'Historique de la structure',
        structure: this.structure,
        roles: this.validResponsables
      }
    });
  }

  displayVersionEnAttente() {
    this.dialog.open(IdentiteEnCoursDialogComponent, {
      minWidth: 400,
      data: {
        name: 'Version en attente de validation',
        structure: this.structure
      }
    });
  }

  isInUserGestRespPerimeter(): boolean {
    if (this.structure) {
      return isInGestRespActionPerimeter(this.structure.structureId, this.user);
    }
  }

  enableResponsableForm() {
    this.readonlyResp = !this.readonlyResp;
    this.readonlyForm = true;
  }

  getDateString(date: Date): string {
    return getDDMMYYYY(date);
  }

  private getDemandesResponsableEnCours(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getDemandesByStructureId(this.structure.structureId).subscribe(res => {
        this.hasDemandeResponsableEnCours = res.filter(d => d.typeDemande.typeDemandeId === TYPE_DEMANDE.MODIFICATION_RESPONSABLE
          && [ETAT_DEMANDE.NOUVEAU].includes(d.etatDemande)).length > 0;
        resolve();
      });
    });
  }

  private getIdentites(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getStructureByStructureId(this.structure.structureId).subscribe(res => {
        this.hasDemandeIdentiteEnCours = res.identites.filter(i => i.etatValid === ETAT_VALID.A_VALIDER).length > 0;
        this.validIdentites = res.identites.filter(i => i.etatValid === ETAT_VALID.VALIDE);
        resolve();
      });
    });
  }

  private getRoles(): Promise<void> {
    return new Promise<void>(resolve => {
      this.roleService.getAllRolesByStructureAndTypeRole(this.structure.structureId, TYPE_ROLE.RESPONSABLE).subscribe(res => {
        this.validResponsables = res.sort(((a, b) => {
          if (moment(a.dateDebut).isBefore(b.dateDebut)) {
            return -1;
          } else if (moment(a.dateDebut).isAfter(b.dateDebut)) {
            return 1;
          } else {
            return 0;
          }
        }));
        resolve();
      });
    });
  }

  private getLastIdentite(): Promise<void> {
    return new Promise<void>(resolve => {
      this.identiteStructureService.getLastIdentiteByStructureId(this.structure.structureId).subscribe(id => {
        if (id.etatValid === ETAT_VALID.A_VALIDER) {
          this.snackBar.open(
            'Les informations ci-dessous sont les dernières à avoir été validées. Il existe cependant une nouvelle version en attente de validation par les administrateurs.',
            'X',
            {panelClass: 'notif-warn'}
          );
        }
        resolve();
      });
    });
  }

  private getInstancesDemande(): Promise<void> {
    return new Promise<void>(resolve => {
      this.instanceDemandeService.getInstancesDemandeByStructureId(this.structure.structureId).subscribe(res => {
        this.instancesDemande = res;
        resolve();
      });
    });
  }
}
