import {Structure} from './structure.model';
import {Application} from './nomenclature.model';
import {ETAT_TODO, TYPE_TODO} from './enum.model';

export class RepartStructSI {
  constructor(
    public repartStructSiId?: number,
    public application?: Application,
    public structureId?: number,
    public codeStructSI?: string,
    public dateDebut?: Date,
    public dateFin?: Date,
    public isValid?: boolean,
    public structure?: Structure
  ) {
  }

}

export class Todo {
  todoId: number;
  applicationId: number;
  structureId: number;
  typeTodo: TYPE_TODO;
  etatTodo: ETAT_TODO;
  dateTodo: Date;
  commentaire: string;
  structure: Structure;
  application: Application;
}

export class AssociationRequest {
  constructor(
    public repartStructSi?: RepartStructSI,
    public createTodo?: boolean,
    public motif?: string) {
  }

}
