<h4 *ngIf="title">{{title}}</h4>

<!--CHAMPS DE RECHERCHE-->
<form class="row" *ngIf="searchMode">
  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Structure</mat-label>
    <input matInput
           type="text"
           placeholder="Recherche"
           [formControl]="structCtrl"
           [matAutocomplete]="auto"
           (keyup.enter)="onStructureSelect(null)">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let struct of filteredStructuresOptions | async"
                  [value]="struct.identite.libelleLong"
                  (onSelectionChange)="onStructureSelect(struct)">
        <span appHighlightStructure [structure]="struct">{{struct | libelleStructure:user}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<div *ngIf="tree">
  <!--OPTION AFFICHAGE CATEGORIE-->
  <div class="row" *ngIf=displayOption>
    <div class="col-lg-12">
      Afficher les structures :
      <mat-checkbox class="ml-2" [(ngModel)]="orgaDisplay">Organisationnelles</mat-checkbox>
      <mat-checkbox class="ml-2" [(ngModel)]="matDisplay" [disabled]="structTable?.length === 0">Matricielles
      </mat-checkbox>
    </div>
  </div>

  <!--ORGANIGRAMME DES STRUCTURES ORGANISATIONNELLES-->
  <p-tree class="col-lg-8 mt-4"
          [value]="dataTree" selectionMode="single"
          [filter]="false"
          (onNodeExpand)="loadNode($event)"
          (onNodeSelect)="onNodeSelect($event.node)"
          (input)="onInputSearch($event)"
          *ngIf="dataTree?.length > 0 && orgaDisplay">
  </p-tree>

  <!--TABLEAU DES STRUCTURES MATRICIELLES-->
  <p-table class="col-lg-4 mt-4"
           dataKey="structureId"
           [value]="structTable"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           selectionMode="single"
           [(selection)]="selectedRow"
           (onRowSelect)="onRowSelected()"
           *ngIf="matDisplay">
    <ng-template pTemplate="caption">Structures matricielles</ng-template>

    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <td appHighlightStructure [structure]="row">{{row | libelleStructure:user}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
