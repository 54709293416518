import {Component, Inject} from '@angular/core';
import {DescriptionStructure} from '../../../../model/structure.model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-histo-description-dialog',
  templateUrl: './histo-description-dialog.component.html',
  styleUrls: ['./histo-description-dialog.component.scss']
})
export class HistoDescriptionDialogComponent {

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}

export interface DialogData {
  name: string;
  descriptions: DescriptionStructure[];
}
