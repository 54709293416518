import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {RoleService} from '../../../services/role.service';
import {Individu, Privilege, Role} from '../../../model/user.model';
import {IndividuService} from '../../../services/Individu.service';
import {FormControl, Validators} from '@angular/forms';
import {Structure} from '../../../model/structure.model';
import {TYPE_ROLE} from '../../../model/enum.model';
import {LdapService} from '../../../services/ldap.service';
import {LoaderService} from '../../../services/loader.service';
import {DemandeService} from '../../../services/demande.service';

@Component({
  selector: 'app-ajout-moderateur',
  templateUrl: './ajout-moderateur.component.html',
  styleUrls: ['./ajout-moderateur.component.scss']
})
export class AjoutModerateurComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() readonly = false;

  moderateurs: Role[];
  modToAdd: Individu[];
  modFormControl: FormControl;

  constructor(private individuService: IndividuService,
              private demandeService: DemandeService,
              private loaderService: LoaderService,
              private ldapService: LdapService,
              private roleService: RoleService) {
    this.modFormControl = new FormControl([], Validators.required);
  }

  ngOnChanges() {
    this.init();
  }

  validate() {
    this.loaderService.start();
    this.ldapService.getLdapSearchByUid(this.modFormControl.value.idnum).subscribe(ldapAccountDTO => {
      const role: Role = {
        typeRole: TYPE_ROLE.MODERATEUR,
        dateDebut: new Date(),
        structureId: this.structure.structureId,
        isExtended: false,
        etatValid: 'VALIDE'
      };
      const privilege: Privilege = {role, ldapAccountDTO};

      if (this.moderateurs.length > 0) {
        this.roleService.subtituteModerateur(this.moderateurs[0].roleId, privilege).subscribe(() => {
          this.loaderService.stop();
          this.init();
          this.actionEmitter.emit();
        });

      } else {
        this.roleService.create(privilege).subscribe(() => {
          this.loaderService.stop();
          this.init();
          this.actionEmitter.emit();
        });
      }
    });
  }

  private init(): void {
    this.roleService.getRolesValidesByStructureAndTypeRole(this.structure.structureId, TYPE_ROLE.MODERATEUR).subscribe(res => this.moderateurs = res);
    this.individuService.getOtherModerateurs(this.structure.structureId).subscribe(modList => this.modToAdd = modList);
  }
}
