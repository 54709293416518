import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompteAppli, LdapAccount, User} from '../../../../model/user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompteAppliService} from '../../../../services/compte-appli.service';
import {LdapService} from '../../../../services/ldap.service';

@Component({
  selector: 'app-add-compte-appli-dialog',
  templateUrl: './add-compte-appli-dialog.component.html',
  styleUrls: ['./add-compte-appli-dialog.component.scss']
})
export class AddCompteAppliDialogComponent implements OnInit {

  display=true;
  user: User;
  form: FormGroup;
  ldapAccount: LdapAccount;

  constructor(private userService: UserService,
              private loaderService: LoaderService,
              private ldapService:LdapService,
              private compteAppliService: CompteAppliService,
              public dialogRef: MatDialogRef<AddCompteAppliDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();

    this.form = new FormGroup({
      login: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      alert: new FormControl(false),
      superUser: new FormControl(false),
    });
  }

  ngOnInit(): void {
    if (this.data.compteAppli) {
      this.loaderService.start();
      this.display = false;
      this.ldapService.getLdapSearchByUid(this.data.compteAppli.responsableApplication.idnum).subscribe(res => {
        this.form.controls.login.setValue(this.data.compteAppli.login);
        this.form.controls.description.setValue(this.data.compteAppli.description);
        this.form.controls.superUser.setValue(this.data.compteAppli.superUser);
        this.ldapAccount = res;
        this.loaderService.stop();
        this.display = true;
      })
    }
  }

  onLoginChanged() {
    const login: string = this.form.controls.login.value;
    if (login && login !== '') {
      this.compteAppliService.doesLoginAlreadyExist(login).subscribe(res => {
        if (res) {
          this.form.controls.login.setErrors({'login-exists': 'Le login choisi pour ce compte existe déjà pour un autre compte applicatif'});
        }
      });
    }
  }

  getLdapAccount(ldapAccount: LdapAccount) {
    this.ldapAccount = ldapAccount;
  }

  validate() {
    this.loaderService.start();

    if (!this.data.compteAppli) {
      const compteAppli: CompteAppli = {
        compteAppliId: null,
        login: this.form.controls.login.value,
        description: this.form.controls.description.value,
        superUser: this.form.controls.superUser.value,
        isValid: true,
        ldapAccount: this.ldapAccount,
        hasError: false,
      };

      this.compteAppliService.createCompteAppli(compteAppli).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close(true);
      });

    } else {
      this.data.compteAppli.login = this.form.controls.login.value;
      this.data.compteAppli.description = this.form.controls.description.value;
      this.compteAppliService.update(this.data.compteAppli, this.form.controls.alert.value).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close(true);
      });
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

}

export interface DialogData {
  compteAppli: CompteAppli;
  updateMode: boolean;
}
