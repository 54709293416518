import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../model/user.model';
import {Structure} from '../../model/structure.model';
import {StructureService} from '../../services/structure.service';
import {StructureDetailsComponent} from '../../components/structure/structure-details/structure-details.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LienService} from '../../services/lien.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-mes-structures',
  templateUrl: './mes-structures.component.html',
  styleUrls: ['./mes-structures.component.scss']
})
export class MesStructuresComponent implements OnInit {

  @ViewChild(StructureDetailsComponent) structureDetailsComponent: StructureDetailsComponent;

  user: User;
  selectedStructure: Structure;
  expanded = true;

  constructor(
    private structureService: StructureService,
    private userService: UserService,
    private lienService: LienService,
    private router: Router,
    private route: ActivatedRoute) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.structureId) {
        this.structureService.getStructureByStructureId(params.structureId).subscribe(structure => {
          this.selectedStructure = structure;
          this.expanded = false;
        });
      }
    });
  }

  onSelectedStructure(structure: Structure) {
    this.router.navigate(['structure', structure.structureId]).then(() => {
      this.selectedStructure = structure;
      this.expanded = false;
    });
  }
}
