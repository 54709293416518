<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.aide.libelle}} <span
  class="mt-2 float-right clickable" (click)="close()">
  <i class="fas fa-times fa-2x"></i></span></h1>

<div mat-dialog-content class="mt-4">
  <ng-container [ngSwitch]="data.aide.typeMime">
    <img *ngSwitchCase="typeMime.JPEG.valueOf()" [src]="data.aide.url">

    <video controls *ngSwitchCase="typeMime.MPEG.valueOf()">
      <source [src]="data.aide.url" type="video/mp4"/>
      Navigateur non supporté
    </video>

    <object *ngSwitchCase="typeMime.PDF.valueOf()" [data]="data.aide.url | safe" [type]="data.aide.typeMime">
      <iframe [src]="'https://docs.google.com/viewer?url=' + data.aide.url + '&embedded=true' | safe">
      </iframe>
    </object>
  </ng-container>
</div>

