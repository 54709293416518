<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header *ngIf="!readonly">
      <h4>Remplacement du modérateur de la structure {{structure?.identite.libelleLong}}</h4>
    </mat-card-header>

    <mat-card-content>
      <ul *ngIf="moderateurs?.length > 0"><b>Modérateur actuel</b>
        <li *ngFor="let mod of moderateurs">{{mod.individu.prenom + ' ' + mod.individu.nom}}
        </li>
      </ul>

      <p  class="mt-4 alert-warning" *ngIf="moderateurs?.length === 0">Attention, cette structure n'a plus de modérateur !</p>

      <div *ngIf="!readonly">
        <div *ngIf="modToAdd?.length === 0">
          <p class="mt-4 alert-warning">Il est impossible de remplacer le modérateur actuel car il n'y a pas d'autres
            modérateurs à sélectionner. Merci d'ajouter d'autres modérateurs en allant dans la gestion des privilèges</p>
        </div>

        <div *ngIf="modToAdd?.length > 0">
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>modérateurs suppléants</mat-label>
            <mat-select [formControl]="modFormControl" placeholder="Changer de modérateur" required>
              <mat-option *ngFor="let mod of modToAdd" [value]="mod">{{mod.nom}} {{mod.prenom}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="(modFormControl.value || modFormControl.value.length ===0) && modFormControl.touched">Valeur
              requise
            </mat-error>
          </mat-form-field>

          <div class="row justify-content-center">
            <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary"
                    (click)="validate()" [disabled]="modFormControl.invalid">REMPLACER LE MODERATEUR
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
