export class Application {
  applicationId: number;
  applicationLib: string;
  syncResp: boolean;
  todosACreer: boolean;
  isValid: boolean;

  displayTodoBox: boolean;
  isHidden = false;
  hasError = false;
}

export class Domaine {
  domaineId: number;
  domaineLib: string;
  isValid: boolean;

  deleteAssociation: false;
  hasAssociation = false;
  isHidden = false;
  hasError = false;

  constructor(domaineId: number) {
    this.domaineId = domaineId;
  }
}

export class Instance {
  instanceId: number;
  instanceLib: string;
  isValid: boolean;

  isHidden: boolean;
  hasError = false;

  constructor(instanceId: number) {
    this.instanceId = instanceId;
  }
}

export class Tutelle {
  tutelleId: number;
  tutelleLib: string;
  rne: string;
  isEtab: boolean;
  isValid: boolean;

  isHidden = false;
  hasLibError = false;
  hasRneError = false;
}

export class TypeAdresse {
  typeAdresseId: number;
  typeAdresseLib: string;
  isMultiple: boolean;
  isValid: boolean;

  isHidden = false;
  hasError = false;

  constructor(typeAdresseId: number) {
    this.typeAdresseId = typeAdresseId;
  }
}

export class TypeCode {
  typeCodeId: number;
  typeCodeLib: string;
  isValid: boolean;

  isHidden = false;
  hasError = false;
}

export class TypeLien {
  typeLienId: number;
  typeLienLib: string;
  relation: string;
  isAntecedent: boolean;
  isHierarchique: boolean;
  isValid: boolean;

  isHidden = false;
  hasError = false;

  constructor(typeLienId: number) {
    this.typeLienId = typeLienId;
  }
}

export class TypeStructure {
  typeStructureId: number;
  typeStructureLib: string;
  orgaOnly: boolean;
  matOnly: boolean;
  isValid: boolean;

  isHidden = false;
  hasError = false;

  constructor(typeStructureId) {
    this.typeStructureId = typeStructureId;
  }
}

export class TypeVoierie {
  typeVoierieId: number;
  typeVoierieCode: string;
  typeVoierieLib: string;
  ordre: boolean;
  isValid: boolean;

  isHidden = false;
  hasError = false;

  constructor(typeVoierieId: number) {
    this.typeVoierieId = typeVoierieId;
  }
}
