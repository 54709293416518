import {Component, Inject} from '@angular/core';
import {Aide} from '../../../../model/divers.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TYPE_MIME} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-aide-dialog',
  templateUrl: './aide-dialog.component.html',
  styleUrls: ['./aide-dialog.component.scss']
})
export class AideDialogComponent {

  form: FormGroup = new FormGroup({
    libelle: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    typeMime: new FormControl(null, Validators.required),
    url: new FormControl(null, Validators.required),
    isValid: new FormControl(true),
  });

  typeMime = TYPE_MIME;

  constructor(public dialogRef: MatDialogRef<AideDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.aide) {
      this.form.controls.libelle.setValue(data.aide.libelle);
      this.form.controls.description.setValue(data.aide.description);
      this.form.controls.typeMime.setValue(data.aide.typeMime);
      this.form.controls.url.setValue(data.aide.url);
      this.form.controls.isValid.setValue(data.aide.isValid);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  validate(): void {
    const aide: Aide = {
      aideId: this.data.aide?.aideId,
      libelle: this.form.controls.libelle.value,
      description: this.form.controls.description.value,
      typeMime: this.form.controls.typeMime.value,
      url: this.form.controls.url.value,
      isValid: this.form.controls.isValid.value
    };

    this.dialogRef.close({aide});
  }

}

export interface DialogData {
  title: string;
  aide: Aide;
}
