<form [formGroup]="form">
  <div class="row">
    <!--LIBELLE LONG -->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Libellé long</mat-label>
      <textarea matInput formControlName="libelleLong" #libelleLong rows="2"
                placeholder="Libellé de la structure"
                [maxLength]="200" required></textarea>
      <mat-error *ngIf="form.controls['libelleLong'].hasError('required')
      && form.controls['libelleLong'].touched">Valeur requise
      </mat-error>
      <mat-error *ngIf="form.controls['libelleLong'].hasError('libelle-exists')">
        {{form.controls['libelleLong'].errors['libelle-exists']}}</mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <!--LIBELLE COURT -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Libellé court</mat-label>
      <input formControlName="libelleCourt" #libelleCourt matInput placeholder="Libellé court ou acronyme"
             maxlength="50">
      <mat-error
        *ngIf="form.controls['libelleCourt'].hasError('libelle-exists')
        && form.controls['libelleCourt'].untouched">
        {{form.controls['libelleCourt'].errors['libelle-exists']}}</mat-error>
    </mat-form-field>

    <!--TYPE STRUCTURE -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Type de structure</mat-label>
      <mat-select formControlName="typeStructure" placeholder="Type de structure" required>
        <mat-option *ngFor="let t of typeStructures" [value]="t.typeStructureId">{{ t.typeStructureLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['typeStructure'].hasError('required') && form.controls['typeStructure'].touched">
        Valeur requise
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <!--DATE OUVERTURE -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date d'ouverture</mat-label>
      <input formControlName="dateOuverture" matInput [matDatepicker]="ouvpicker" placeholder="Date d'ouverture"
             required>
      <mat-datepicker-toggle matSuffix [for]="ouvpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #ouvpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateOuverture'].invalid && form.controls['dateOuverture'].touched">Format non valide
      </mat-error>
    </mat-form-field>

    <!--DATE FERMETURE -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de fermeture</mat-label>
      <input formControlName="dateFermeture" matInput [matDatepicker]="ferpicker" placeholder="Date de fermeture">
      <mat-datepicker-toggle matSuffix [for]="ferpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #ferpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateFermeture'].invalid && form.controls['dateFermeture'].touched">Format non valide
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="identiteStructure">
    <!--DATE DEBUT -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>{{readonly ? 'Dernière modification' :  'Date d\'application des modifications' }}</mat-label>
      <input formControlName="dateDebut" matInput [matDatepicker]="debpicker" placeholder="Date de début" [min]="identiteStructure.dateDebut">
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error *ngIf="form.controls['dateDebut'].hasError('matDatepickerMin') && form.controls['dateDebut'].touched">
        La date de début de la nouvelle identité ne peut être antérieure à date de début de l'identité actuelle : {{identiteStructure.dateDebut | date:'dd/mm/yyyy'}}
      </mat-error>
    </mat-form-field>
  </div>
</form>
