<div class="row justify-content-center">
  <mat-card class="col-lg-12 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de demande d'informations complémentaires</h4>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field class="col-lg-12" appearance="outline">
        <textarea matInput [(ngModel)]="commentaire" [maxLength]="200" placeholder="Saisie de la demande d'information"></textarea>
      </mat-form-field>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 ml-2" mat-raised-button color="primary"
                matTooltip="Demander des informations complémentaires"
                (click)="askInfos()"
                [disabled]="!commentaire || commentaire.trim() === ''">{{'demander des informations' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
