<h1 mat-dialog-title>Confirmez-vous la suppression ?</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <div *ngIf="data.type === caracterisation.CODE">
    <mat-radio-group class="col-lg-12" [(ngModel)]="isDefinitive" aria-label="Selectionner le mode de suppression" required>
      <mat-radio-button class="col-12" [value]="true"
                        [checked]="isDefinitive">Supression définitive
      </mat-radio-button>
      <mat-radio-button class="col-12" [value]="false"
                        [checked]="!isDefinitive">Invalidité à partir d'une date donnée
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="col-lg-12" appearance="outline" [hidden]="isDefinitive">
      <input matInput [formControl]="dateFin" [max]="now" [matDatepicker]="finpicker" placeholder="Date de fin" required>
      <mat-datepicker-toggle matSuffix [for]="finpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #finpicker></mat-datepicker>
      <mat-error *ngIf="dateFin.hasError('matDatepickerMax')">{{'La date de fin de validité ne peut être supérieure à la date du jour'}}</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" [disabled]="data.type === caracterisation.CODE && !isDefinitive && dateFin.invalid" (click)="onDelete()">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
