<h1 mat-dialog-title>{{data.name}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4" *ngIf="display">
  <p class="alert-warning" *ngIf="datasource.length === 0">Cette application n'est actuellement associée et
    synchronisée avec aucune structure.</p>

  <p-table class="col-lg-6"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="datasource.length > 0">
    <ng-template pTemplate="caption">Structures associées</ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td><a href appHighlightStructure
               [structure]="row.structure"
               [routerLink]="'/structure/' +  row.structure.structureId">
          {{row.structure | libelleStructure:user}}</a></td>
        <td>{{row.codeSi}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>


