<div class="row justify-content-center mt-4">
  <h1 class="title">Administration des comptes applicatifs</h1>
</div>

<div class="row buffer justify-content-center" *ngIf="comptesAppli">
  <mat-card class="col-lg-12" *ngIf="comptesAppli.length === 0">
    <h4 class="text-center">Aucune compte applicatif à afficher</h4>
  </mat-card>

  <p-table class="col-lg-10"
           [value]="comptesAppli"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="comptesAppli.length > 0">

    <ng-template pTemplate="caption">
      <span>Liste des comptes applicatifs</span>
      <button class="button float-right ml-4" matTooltip="Annuler les modifications" (click)="cancel()">
        <i class="fas fa-times fa-2x"></i></button>

      <button class="button float-right ml-4" matTooltip="Sauvegarder les modifications" [disabled]="isInvalid()"
              (click)="update()">
        <i class="fas fa-check fa-2x"></i></button>

      <button class="button float-right ml-4" matTooltip="Ajouter un noueavu compte" (click)="add()">
        <i class="fas fa-plus fa-2x"></i></button>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" pResizableColumn>Login</th>
        <th class="text-center" pResizableColumn>Description</th>
        <th class="text-center" pResizableColumn>Responsable</th>
        <th class="text-center" style="width:12em" pResizableColumn>Super User</th>
        <th class="text-center" style="width:12em" pResizableColumn>Activer</th>
        <th class="text-center" style="width:12em" pResizableColumn>Générer nouveau mot de passe</th>
        <th class="text-center" style="width:12em" pResizableColumn>Supprimer</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr>
        <td>
          <input class="table-input" [(ngModel)]="row.login" (input)="onLoginChanged(row)" [disabled]="!row.isValid">
        </td>

        <td>
          <input class="table-input" [(ngModel)]="row.description" [disabled]="!row.isValid">
        </td>

        <td>
          <span
            *ngIf="row.responsableApplication">{{row.responsableApplication.nom.toUpperCase() + ' ' + row.responsableApplication.prenom }}</span>
          <span class="float-right clickable" (click)="searchResponsable(row)"><i class="fas fa-search"></i></span>
        </td>

        <td class="text-center">
          <mat-slide-toggle color="primary" [checked]="row.superUser" [disabled]="!row.isValid"
                            (toggleChange)="onSuperUserChanged(row)"></mat-slide-toggle>

        <td class="text-center">
          <mat-slide-toggle color="primary" [checked]="row.isValid"
                            (toggleChange)="onIsValidChanged(row)"></mat-slide-toggle>
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Générer un nouveau mot de passe"
                  (click)="generateNewPassword(row)"><i class="fas fa-key fa-2x"></i></button>
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Supprimer"
                  (click)="delete(row)"><i class="fas fa-trash fa-2x"></i></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
