import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {CancelRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {TYPE_DEMANDE} from '../../../model/enum.model';

@Component({
  selector: 'app-annulation-demande',
  templateUrl: './annulation-demande.component.html',
  styleUrls: ['./annulation-demande.component.scss']
})
export class AnnulationDemandeComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() demande: Demande;

  user: User;
  motif: string;
  typeDemande = TYPE_DEMANDE;

  constructor(private loaderService: LoaderService, private workflowService: WorkflowService) {
  }

  cancel() {
    const cancelRequest: CancelRequest = {
      demandeId: this.demande.demandeId,
      motif: this.motif
    };

    this.loaderService.start();
    this.workflowService.cancel(cancelRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
