<h1 mat-dialog-title>Historique des associations</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p-table
           [value]="data.histo"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions">

    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" pResizableColumn>Application</th>
        <th class="text-center" pResizableColumn>Code SI</th>
        <th class="text-center" pResizableColumn>Date début</th>
        <th class="text-center" pResizableColumn>Date de fin</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{data.application.applicationLib}}</td>
        <td>{{data.codeStructSI}}</td>
        <td>{{data.dateDebut | date:'dd/MM/yyyy'}}</td>
        <td>{{data.dateFin | date:'dd/MM/yyyy'}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
