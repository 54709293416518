import {Component, OnInit} from '@angular/core';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {TypeStructureService} from '../../../services/type-structure.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {TypeStructure} from '../../../model/nomenclature.model';
import {CAT_STRUCTURE} from '../../../model/enum.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-type-structure',
  templateUrl: './type-structure.component.html',
  styleUrls: ['./type-structure.component.scss']
})
export class TypeStructureComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: TypeStructure[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private typeStructureService: TypeStructureService,
              private snackBar: MatSnackBar,
              private structureService: StructureService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(typeStructure: TypeStructure) {
    typeStructure.hasError = false;

    if (!typeStructure.typeStructureLib || typeStructure.typeStructureLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeStructure.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, typeStructure.typeStructureLib, 'typeStructureLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeStructure.hasError = true;
    }
  }

  onOrgaOnlyChanged(typeStructure: TypeStructure) {
    typeStructure.orgaOnly = !typeStructure.orgaOnly;

    if (typeStructure.orgaOnly && typeStructure.typeStructureId) {
      this.structureService.getStructuresByTypeStructure(typeStructure.typeStructureId).subscribe(
        res => {
          if (res.filter(s => s.catStructure === CAT_STRUCTURE.MAT).length > 0) {
            this.snackBar.open(
              'Le type de structure ' + typeStructure.typeStructureLib + ' est actuellement utilisé dans la définition d\'au moins une structure matricielle valide, vous ne pouvez le limiter aux structures organisationnelles.',
              'X',
              {panelClass: 'notif-warn'}
            );
            typeStructure.orgaOnly = false;
          }
        });
    }
  }

  onMatOnlyChanged(typeStructure: TypeStructure) {
    typeStructure.matOnly = !typeStructure.matOnly;

    if (typeStructure.matOnly && typeStructure.typeStructureId) {
      this.structureService.getStructuresByTypeStructure(typeStructure.typeStructureId).subscribe(
        res => {
          if (res.filter(s => s.catStructure === CAT_STRUCTURE.ORGA).length > 0) {
            this.snackBar.open(
              'Le type de structure ' + typeStructure.typeStructureLib + ' actuellement utilisé dans la définition d\'au moins une structure organisationnelle valide, vous ne pouvez le limiter aux structures matricielles.',
              'X',
              {panelClass: 'notif-warn'}
            );
            typeStructure.matOnly = false;
          }
        });
    }
  }

  onIsValidChanged(typeStructure: TypeStructure) {
    typeStructure.isValid = !typeStructure.isValid;

    if (!typeStructure.isValid) {
      if (typeStructure.typeStructureId) {
        this.structureService.getStructuresByTypeStructure(typeStructure.typeStructureId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                'Le type de structure ' + typeStructure.typeStructureLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
            }
          });
      } else {
        const index = this.dataSource.indexOf(typeStructure, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        typeStructureId: null,
        typeStructureLib: null,
        isValid: true,
        orgaOnly: false,
        matOnly: false,
        isHidden: false,
        hasError: true
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.typeStructureService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.typeStructureService.getAllTypesStructure().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }

}
