import {AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {IdentiteStructure} from '../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Individu} from '../../../model/user.model';
import {IdentiteStructureService} from '../../../services/identite-structure.service';
import {TypeStructureService} from '../../../services/type-structure.service';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {TypeStructure} from '../../../model/nomenclature.model';
import {X_MESSAGE} from '../../../constants/http.constant';
import {CAT_STRUCTURE} from '../../../model/enum.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LienService} from '../../../services/lien.service';
import {debounceTime, distinctUntilChanged, fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-identite-form',
  templateUrl: './identite-form.component.html',
  styleUrls: ['./identite-form.component.scss']
})
export class IdentiteFormComponent implements OnChanges, AfterViewInit {

  @Input() identiteStructure: IdentiteStructure;
  @Input() structurePorteuseIds: number[];
  @Input() categorie: string;
  @Input() readonly: boolean;

  @ViewChild('libelleCourt') libelleCourt: ElementRef;
  @ViewChild('libelleLong') libelleLong: ElementRef;

  form: FormGroup;
  typeStructures: TypeStructure[];
  responsable: Individu;

  constructor(private identiteStructureService: IdentiteStructureService,
              private lienService: LienService,
              private typeStructureService: TypeStructureService,
              private snackBar: MatSnackBar) {
    this.readonly = false;

    this.form = new FormGroup({
      libelleLong: new FormControl(null, Validators.required),
      libelleCourt: new FormControl(null),
      dateOuverture: new FormControl(null, Validators.required),
      dateFermeture: new FormControl(null),
      dateDebut: new FormControl(new Date(), Validators.required),
      typeStructure: new FormControl(null, Validators.required)
    });
  }

  ngOnChanges() {
    if (this.identiteStructure && !this.structurePorteuseIds) {
      this.lienService.getLiensHierarchiquesByIdentiteStructureId(this.identiteStructure.identiteStructureId)
        .subscribe(res => this.structurePorteuseIds = res.map(l => l.structPorteuseId));
    }

    if (this.categorie) {
      this.typeStructureService.getAllTypeStructureByCategorie(this.categorie).subscribe(typeStructures => {
        this.typeStructures = typeStructures.filter(t =>
          ((!t.matOnly && !t.orgaOnly)
            || (this.categorie === CAT_STRUCTURE.MAT && t.matOnly)
            || (this.categorie === CAT_STRUCTURE.ORGA && t.orgaOnly))
          && t.isValid
        );
      });
    }

    if (this.identiteStructure) {
      this.fillForm();
    }

    if(this.readonly){
      this.form.disable()
    } else{
      this.form.controls.dateDebut.setValue(new Date());
      this.form.enable()
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.libelleCourt.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => this.onLibelleCourtChange());

    fromEvent(this.libelleLong.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => this.onLibelleLongChange());
  }

  fillForm() {
    this.form.controls.libelleLong.setValue(this.identiteStructure.libelleLong);
    this.form.controls.libelleCourt.setValue(this.identiteStructure.libelleCourt);
    this.form.controls.dateOuverture.setValue(this.identiteStructure.dateOuverture);
    this.form.controls.dateFermeture.setValue(this.identiteStructure.dateFermeture);
    this.form.controls.dateDebut.setValue(this.identiteStructure.dateDebut);
    this.form.controls.typeStructure.setValue(this.identiteStructure.typeStructure.typeStructureId);
  }

  onLibelleLongChange() {
    const value: string = this.form.controls.libelleLong.value;
    this.snackBar.dismiss();
    if (value && value !== '') {
      this.identiteStructureService.doesLibelleAlreadyExist(this.identiteStructure ? this.identiteStructure.structureId : -1, value)
        .subscribe({
          error: (error) => {
            if (error.status === 418) {
              this.form.controls.libelleLong.setErrors({'libelle-exists': error.headers.get(X_MESSAGE)});
            }
          }
        });
    }
  }

  onLibelleCourtChange() {
      const value: string = this.form.controls.libelleCourt.value;
      if (value && value !== '') {
        this.identiteStructureService.doesLibelleAlreadyExist(this.identiteStructure ? this.identiteStructure.structureId : -1, value)
          .subscribe({
            error: (error) => {
              if (error.status === 418) {
                this.form.controls.libelleCourt.setErrors({'libelle-exists': error.headers.get(X_MESSAGE)});
              }
            }
          });
      }
  }

  getIdentite(): IdentiteStructure {
    let identite: IdentiteStructure = new IdentiteStructure();

    if (this.identiteStructure) {
      identite = Object.assign({}, this.identiteStructure);
    }

    identite.typeStructure = new TypeStructure(this.form.controls.typeStructure.value);
    identite.libelleLong = this.form.controls.libelleLong.value;
    identite.libelleCourt = this.form.controls.libelleCourt.value;
    identite.dateOuverture = formatLocalDateWithUTC(this.form.controls.dateOuverture.value);
    identite.dateFermeture = formatLocalDateWithUTC(this.form.controls.dateFermeture.value);
    identite.dateDebut = formatLocalDateWithUTC(this.form.controls.dateDebut.value);
    return identite;
  }


}
