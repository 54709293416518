<form class="row" [formGroup]="form">
  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Type de structure</mat-label>
    <mat-select formControlName="typeStructure" placeholder="Type de structure" required>
      <mat-option *ngFor="let t of typesStructure" [value]="t.id">
        {{t.libelle}}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="form.controls.typeStructure.hasError('required') && form.controls.typeStructure.touched">Valeur requise</mat-error>
  </mat-form-field>

  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Catégorie de la Structure</mat-label>
    <mat-select formControlName="catStructure" placeholder="Catégorie de la structure" required>
      <mat-option *ngFor="let c of categoriesStructure" [value]="c.id">
        {{c.libelle}}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="form.controls.catStructure.hasError('required') && form.controls.catStructure.touched">Valeur requise</mat-error>
  </mat-form-field>

  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Type de lien</mat-label>
    <mat-select formControlName="typeLien" placeholder="Type de lien" required>
      <mat-option *ngFor="let t of typesLien" [value]="t.id">
        {{t.libelle}}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="form.controls.typeLien.hasError('required') && form.controls.typeLien.touched">Valeur requise</mat-error>
  </mat-form-field>

  <mat-form-field class="col-lg-12" appearance="outline">
    <mat-label>Structure porteuse</mat-label>
    <input type="text" placeholder="Structure porteuse" aria-label="Structure porteuse" matInput
           formControlName="structurePorteuse" [matAutocomplete]="auto" required>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of filteredStructures | async"
                  [value]="struct.libelleLong"
                  (click)="onStructureSelect(struct.libelleLong)"
                  (onSelectionChange)="onStructureSelect(struct.libelleLong)">
        {{struct.libelleLong}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
