import {Component, Input, OnChanges} from '@angular/core';
import {Code, Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {TutelleService} from '../../../services/tutelle.service';
import {DomaineService} from '../../../services/domaine.service';
import {CodeService} from '../../../services/code.service';
import {MatDialog} from '@angular/material/dialog';
import {RemoveCaracterisationDialogComponent} from '../../../layout/dialog/structure/remove-caracterisation-dialog/remove-caracterisation-dialog.component';
import {AddCaracterisationDialogComponent} from '../../../layout/dialog/structure/add-caracterisation-dialog/add-caracterisation-dialog.component';
import {UpdateCaracterisationDialogComponent} from '../../../layout/dialog/structure/update-caracterisation-dialog/update-caracterisation-dialog.component';
import {LoaderService} from '../../../services/loader.service';
import * as moment from 'moment';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {Domaine, Tutelle} from '../../../model/nomenclature.model';
import {CAT_STRUCTURE} from '../../../model/enum.model';
import {CARACTERISATION} from '../../../constants/enum.constant';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-caracterisation',
  templateUrl: './caracterisation.component.html',
  styleUrls: ['./caracterisation.component.scss']
})
export class CaracterisationComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  updateMode: boolean;
  colsTutelles: any[];
  tutelles: Tutelle[];
  colsDomaines: any[];
  domaines: Domaine[];
  colsCodes: any[];
  codes: any[];
  caracterisation = CARACTERISATION;
  catStructure = CAT_STRUCTURE;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private tutelleService: TutelleService,
              private domaineService: DomaineService,
              private codeService: CodeService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
    this.updateMode = false;

    this.colsTutelles = [
      {field: 'tutelleLib', header: 'Libellé'},
      {field: 'rne', header: 'RNE'},
    ];

    this.colsDomaines = [
      {field: 'domaineLib', header: 'Libellé'},
    ];

    this.colsCodes = [
      {field: 'typeCode', header: 'Type de code'},
      {field: 'codeValue', header: 'Valeur'},
      {field: 'dateDebut', header: 'Date de début'},
    ];

    this.tutelles = [];
    this.domaines = [];
    this.codes = [];
  }

  ngOnChanges() {
    if (this.structure) {
      this.init();
    }
  }

  init() {
    this.tutelleService.getTutellesByStructureId(this.structure.structureId).subscribe(res => {
      this.tutelles = [];
      this.tutelles = res;
    });

    this.domaineService.getDomainesByStructureId(this.structure.structureId).subscribe(res => {
      this.domaines = [];
      this.domaines = res;
    });

    this.codeService.getCodesByStructureId(this.structure.structureId).subscribe(res => {
      this.codes = [];
      res.filter(c => c.dateFin == null || !moment(c.dateFin).isBefore(new Date()) || c.isValid).forEach(c => this.codes.push(this.convertToDataCode(c)));
    });
  }

  openAddDialog(type: CARACTERISATION) {
    this.dialog.open(AddCaracterisationDialogComponent, {
      minWidth: 400,
      data: {
        type,
        structure: this.structure
      }
    }).afterClosed().subscribe(() => {
      this.init();
    });
  }

  openUpdateDialog(item: any, type: CARACTERISATION) {
    this.dialog.open(UpdateCaracterisationDialogComponent, {
      minWidth: 400,
      data: {
        type,
        item
      }
    }).afterClosed().subscribe(() => {
      this.init();
    });
  }

  openRemoveDialog(item: any, type: CARACTERISATION) {
    this.dialog.open(RemoveCaracterisationDialogComponent, {
      minWidth: 400,
      data: {
        type,
        structureId: this.structure.structureId,
        item
      }
    }).afterClosed().subscribe(() => {
      this.init();
    });
  }

  isInUserGestRespPerimeter(structure: Structure): boolean {
    if (structure) {
      return isInGestRespActionPerimeter(structure.structureId, this.user);
    }
    return false;
  }

  convertToDataCode(code: Code) {
    return {
      code,
      typeCode: code.typeCode.typeCodeLib,
      codeValue: code.codeValue,
      dateDebut: code.dateDebut
    };
  }

}
