import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {Demande} from '../../../model/demande.model';
import {DemandeService} from '../../../services/demande.service';
import {MatDialog} from '@angular/material/dialog';
import {LiensAntecedentsComponent} from '../../administration/liens-antecedents/liens-antecedents.component';
import {RepartStructSiComponent} from '../../administration/repart-struct-si/repart-struct-si.component';
import {ApplicationService} from '../../../services/application.service';
import {TodoService} from '../../../services/todo.service';
import {AssociationSiService} from '../../../services/association-si.service';
import {StructureService} from '../../../services/structure.service';
import {RepartStructSI, Todo} from '../../../model/association.model';
import {Log} from '../../../model/log.model';
import {LogService} from '../../../services/log.service';
import {LogComponent} from '../../administration/log/log.component';
import {LienService} from '../../../services/lien.service';
import {ACTION} from '../../../constants/enum.constant';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-structure-admin',
  templateUrl: './structure-admin.component.html',
  styleUrls: ['./structure-admin.component.scss']
})
export class StructureAdminComponent implements OnChanges {

  @Input() structure: Structure;

  @ViewChild(LogComponent) logsComponent: LogComponent;
  @ViewChild(LiensAntecedentsComponent) liensAntecedentsComponent: LiensAntecedentsComponent;
  @ViewChild(RepartStructSiComponent) repartStructSiComponent: RepartStructSiComponent;

  user: User;
  displayLog: boolean;

  logs: Log[];
  liensAntecedents: Lien[];
  rssList: RepartStructSI[];
  todos: Todo[];
  hasAvailableAssociations: boolean;
  demandesAssociees: Demande[];

  constructor(private associationSiService: AssociationSiService,
              private userService: UserService,
              private applicationService: ApplicationService,
              private demandeService: DemandeService,
              private todoService: TodoService,
              private lienService: LienService,
              private logService: LogService,
              private structureService: StructureService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnChanges() {
    if (this.structure) {
      this.init();
    }
  }

  addLienAntecedent() {
    this.liensAntecedentsComponent.openDialog({structureId: this.structure.structureId, structure: this.structure}, ACTION.ADD);
  }

  addSI() {
    this.repartStructSiComponent.openAddDialog();
  }

  init() {
    Promise.all([
      this.getStructure(),
      this.getRepartStructSi(),
      this.getTodos(),
      this.getLogs(),
      this.getLiensAntecedents(),
      this.getAvailableApplications(),
      this.getDemandes()
    ]);
  }

  private getStructure(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getStructureByStructureId(this.structure.structureId).subscribe(res => {
        this.structure = res;
        resolve();
      });
    });
  }

  private getRepartStructSi(): Promise<void> {
    return new Promise<void>(resolve => {
      this.associationSiService.getRepartStructSiByStructureId(this.structure.structureId).subscribe(res => {
        this.rssList = res;
        resolve();
      });
    });
  }

  private getTodos(): Promise<void> {
    return new Promise<void>(resolve => {
      if(this.user.individu.isAdmin) {
        this.todoService.getTodosByStructureId(this.structure.structureId).subscribe(res => {
          this.todos = res;
          resolve();
        });
      }else{
        resolve();
      }
    });
  }

  private getLogs(): Promise<void> {
    return new Promise<void>(resolve => {
      if(this.user.individu.isAdmin) {
        this.logService.getLogsByStructure(this.structure.structureId).subscribe(res => {
          this.logs = res;
          resolve();
        });
      }else{
        resolve();
      }
    });
  }

  private getLiensAntecedents(): Promise<void> {
    return new Promise<void>(resolve => {
      this.lienService.getAllLiensAntecedentsByStructureId(this.structure.structureId).subscribe(res => {
        this.liensAntecedents = res;
        resolve();
      });
    });
  }

  private getAvailableApplications(): Promise<void> {
    return new Promise<void>(resolve => {
      this.applicationService.getAvailableApplications(this.structure.structureId).subscribe(res => {
        this.hasAvailableAssociations = res.length > 0;
        resolve();
      });
    });
  }

  private getDemandes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getDemandesByStructureId(this.structure.structureId).subscribe(res => {
        this.demandesAssociees = res;
        resolve();
      });
    });
  }
}
