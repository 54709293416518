import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {Structure} from '../../../model/structure.model';
import {DemandeStructOrgaFormComponent} from '../../demande/demande-struct-orga-form/demande-struct-orga-form.component';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {CreationRequest} from '../../../model/workflow.model';
import {User} from '../../../model/user.model';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {ETAT_DEMANDE} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-modification-structure-organisationnelle',
  templateUrl: './modification-structure-organisationnelle.component.html',
  styleUrls: ['./modification-structure-organisationnelle.component.scss']
})
export class ModificationStructureOrganisationnelleComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;
  @Input() structurePorteuse: Structure;

  @ViewChild(DemandeStructOrgaFormComponent) demandeStructOrgaComponent: DemandeStructOrgaFormComponent;

  user: User;
  motif: string;

  etatDemande = ETAT_DEMANDE;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private workflowService: WorkflowService) {
      this.user = this.userService.getCurrentUser();
  }

  validate() {
    this.loaderService.start();
    this.getCreationRequest().then(creationRequest => {
      this.workflowService.sendNewSubmission(creationRequest).subscribe(() => {
        this.loaderService.stop();
        this.actionEmitter.emit();
      });
    });
  }

  isInGestRespPerimeter(structureId: number) {
    return isInGestRespActionPerimeter(structureId, this.user);
  }

  private getCreationRequest(): Promise<CreationRequest> {
    return new Promise<CreationRequest>(resolve => {
      this.demandeStructOrgaComponent.instanceDemandeFormComponent.getInstanceDemande().then(instanceDemande => {
        const creationRequest: CreationRequest = {
          isNewSubmission: !this.user.individu.isAdmin,
          demandeId: this.demande.demandeId,
          typeDemandeId: this.demande.typeDemande.typeDemandeId,
          motif: this.motif,
          catStructure: this.demande.lien.structure.catStructure,
          typeStructureId: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().typeStructure.typeStructureId,
          interne: this.demandeStructOrgaComponent.form.controls.interne.value,
          typeLienId: this.demandeStructOrgaComponent.form.controls.typeLien.value,
          structurePorteuseId: this.demandeStructOrgaComponent.getStructurePorteuseId(),
          libelleLong: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().libelleLong,
          libelleCourt: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().libelleCourt,
          dateOuverture: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().dateOuverture,
          dateFermeture: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().dateFermeture,
          domaineIds: this.demandeStructOrgaComponent.form.controls.domaines.value,
          tutelleId: null,
          typeCreation: this.demandeStructOrgaComponent.form.controls.typeCreation.value,
          typeCreationStruct: this.demandeStructOrgaComponent.form.controls.interne.value ?
            this.demandeStructOrgaComponent.structuresSelectionWithTreeComponent.getStructureIds() : null,
          responsable: null,
          instanceDemande
        };
        resolve(creationRequest);
      });
    });
  }
}
