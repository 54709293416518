import {Component, Inject} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AssociationSiService} from '../../../../services/association-si.service';
import {RepartStructSI} from '../../../../model/association.model';
import {Application} from '../../../../model/nomenclature.model';

@Component({
  selector: 'app-desynchro-si-dialog',
  templateUrl: './reactivate-association-si-dialog.component.html',
  styleUrls: ['./reactivate-association-si-dialog.component.scss']
})
export class ReactivateAssociationSiDialogComponent {

  applications: Application[];

  motif: string;

  constructor(private loaderService: LoaderService,
              private associationService: AssociationSiService,
              public dialogRef: MatDialogRef<ReactivateAssociationSiDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.motif = null;
  }

  onConfirm() {
    this.loaderService.start();
    this.associationService.enable(this.data.rss.repartStructSiId, this.motif).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close();
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  rss: RepartStructSI;
}
