<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>{{demande.lien.structure.catStructure === catStructure.MAT || !demande.lien.structure.interne ?
        'Formulaire de validation la demande' : 'Formulaire d\'acceptation de la demande pour validation'}}</h4>
    </mat-card-header>

    <mat-card-content>
      <!--CREATION D'UNE STRUCTURE ORGA INTERNE-->
      <form [formGroup]="form" *ngIf="demande.typeDemande.typeDemandeId === 1">
        <div class="row mt-2">
          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Choix du modérateur</mat-label>
            <mat-select formControlName="moderateurs" required>
              <mat-option *ngFor="let mod of moderateurs" [value]="mod">{{mod.nom}} {{mod.prenom}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="(!form.controls.moderateurs.value || form.controls.moderateurs.value.length ===0) && form.controls.moderateurs.touched">
              Vous devez choisir au moins un modérateur pour la validation finale de votre demande
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-lg-6" appearance="outline">
            <mat-label>Intégration de la structure dans les applications du SI</mat-label>
            <mat-select formControlName="appliSI" multiple>
              <mat-option *ngFor="let app of appliSIList" [value]="app">{{app.applicationLib}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row mt-2">
          <div class="col-lg-12">
            <b>Intégration de la structure dans le référentiel aquitain</b>
            <mat-checkbox class="ml-2" formControlName="useRefAq"
                          (change)="form.controls.useRefAq.setValue($event.checked)"
                          [checked]="form.controls.useRefAq.value">
            </mat-checkbox>
          </div>

          <div class="row justify-content-center mt-2">
            <app-referentiel-aquitain-form class="col-lg-8"
                                           [structure]="demande.lien.structure"
                                           *ngIf="form.controls.useRefAq.value"></app-referentiel-aquitain-form>
          </div>
        </div>
      </form>

      <div class="row justify-content-center">
        <mat-form-field class="col-lg-12" appearance="outline">
          <textarea matInput
                    [(ngModel)]="commentaire"
                    [maxLength]="200"
                    placeholder="'Saisie facultative d'un commentaire"></textarea>
        </mat-form-field>

        <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary" (click)="validate()"
                [disabled]="isDisabled()">{{'accepter la demande' | uppercase }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>



