<p-table class="col-lg-12"
         [columns]="cols"
         [value]="datasource"
         [responsive]="true"
         [resizableColumns]="true"
         [paginator]="true"
         [rows]="rows"
         [showCurrentPageReport]="true"
         [(first)]="first"
         currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
         [rowsPerPageOptions]="rowsPerPageOptions"
         *ngIf="datasource.length > 0">
  <ng-template pTemplate="caption">Liste des tâches de synchro échouées</ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th class="text-center"
            [pSortableColumn]="col.field"
            [hidden]="!fromAdmin && col.field === 'structure'"
            style="width:6em">{{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <th class="text-center" style="width:6em" [hidden]="readonly">Rejouer</th>
      <th class="text-center" style="width:6em" [hidden]="readonly">Forcer</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      <td>{{row.syncId}}</td>
      <td [hidden]="!fromAdmin">
        <a href appHighlightStructure
           [structure]="row.structure"
           [routerLink]="'/structure/' +  row.structure.structureId">
          {{row.structure  | libelleStructure:user}}</a>
      </td>
      <td>{{row.nbAttempt}}</td>
      <td>{{row.firstAttempt}}</td>
      <td>{{row.lastAttempt}}</td>
      <td>{{row.operation}}</td>
      <td>{{row.description}}</td>
      <td>{{row.etat}}</td>
      <td class="text-center" [hidden]="readonly">
        <button class="button" matTooltip="Redo" (click)="onRowSelected(row, true)" *ngIf="row.etat === etatSync.NOK">
          <i class="fas fa-redo fa-2x"></i>
        </button>
      </td>
      <td class="text-center" [hidden]="readonly">
        <button class="button" matTooltip="Force" (click)="onRowSelected(row, false)" *ngIf="row.etat === etatSync.NOK">
          <i class="fas fa-arrow-alt-circle-up fa-2x"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
