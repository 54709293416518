import {Component, Inject} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {WorkflowService} from '../../../../services/workflow.service';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})
export class ArchiveDialogComponent {

  motif: string;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService,
              public dialogRef: MatDialogRef<ArchiveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  archive() {
    this.workflowService.archiveStructure(this.data.selectedStructure.structureId, this.motif)
      .subscribe(res => this.dialogRef.close(res));
  }


  onCancel() {
    this.dialogRef.close(null);
  }

}

export interface DialogData {
  selectedStructure: Structure;
}
