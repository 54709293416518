<h1 mat-dialog-title>{{'Gestion des paramètres'}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p-table class="col-lg-8"
           [value]="dataSource"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="dataSource">

    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Parametre</th>
        <th class="text-center" style="width:12em">Description</th>
        <th class="text-center"> Multivalué (sép ",")</th>
        <th class="text-center">Requis</th>
        <th class="text-center" style="width:12em">Valeur</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr>
        <td style="width:12em">{{row['cle']}}</td>
        <td style="width:12em">{{row['description']}}</td>
        <td style="width:12em">{{row['multivaleur'] ? 'Oui': 'Non'}}</td>
        <td style="width:12em">{{row['obligatoire'] ? 'Oui': 'Non'}}</td>
        <td class="text-center" [ngSwitch]="row['type']">
          <input type="text" *ngSwitchCase="'String'" class="table-input" [(ngModel)]="row['input']"
                 (keyup)="onChange(row)" required>
          <input type="number" *ngSwitchCase="'Integer'" class="table-input" [(ngModel)]="row['input']"
                 (keyup)="onChange(row)" (change)="onChange(row)" required>
          <mat-slide-toggle *ngSwitchCase="'Boolean'" color="primary" [checked]="row['input']"
                            (toggleChange)="onChange(row)"></mat-slide-toggle>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
