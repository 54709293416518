<h1 mat-dialog-title>Synchronisation de la Structure dans le référentiel aquitain</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <h4>Formulaire de synchronisation de la structure dans le Référentiel Aquitain</h4>

  <app-referentiel-aquitain-form #referentielAquitainFormComponent
                                 [structure]="data.lien.structure"></app-referentiel-aquitain-form>

  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" [disabled]="referentielAquitainFormComponent.form.invalid"
            (click)="validate()">
      <i class="fas fa-check fa-2x"></i>
    </button>
    <button mat-raised-button color="accent" (click)="onCancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</div>
