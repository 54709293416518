import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {InstanceService} from '../../../services/instance.service';
import {DemandeService} from '../../../services/demande.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {Instance} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss']
})
export class InstanceComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: Instance[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private instanceService: InstanceService,
              private snackBar: MatSnackBar,
              private demandeService: DemandeService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(instance: Instance) {
    instance.hasError = false;

    if (!instance.instanceLib || instance.instanceLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      instance.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, instance.instanceLib, 'instanceLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      instance.hasError = true;
    }
  }

  onIsValidChanged(instance: Instance) {
    instance.isValid = !instance.isValid;

    if (!instance.isValid) {
      if (instance.instanceId) {
        this.demandeService.getDemandesByInstanceId(instance.instanceId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                'L`\'instance ' + instance.instanceLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
              instance.isValid = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(instance, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        instanceId: null,
        instanceLib: null,
        isValid: true,
        isHidden: false,
        hasError: true
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.instanceService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.instanceService.getAllInstances().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }

}
