import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-sync-referentiel-aquitain-dialog',
  templateUrl: './confirm-sync-referentiel-aquitain-dialog.component.html',
  styleUrls: ['./confirm-sync-referentiel-aquitain-dialog.component.scss']
})
export class ConfirmSyncReferentielAquitainDialogComponent {

  commentaire: string;

  constructor(public dialogRef: MatDialogRef<ConfirmSyncReferentielAquitainDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onCancel(): void {
    this.dialogRef.close({ok: false, commentaire: null});
  }

  onConfirm(): void {
    this.dialogRef.close({ok: true, commentaire: this.commentaire});
  }
}

export interface DialogData {
  question: string;
  isRedoMode: boolean;
}
