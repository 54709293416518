import {Component, Inject, Input, OnChanges, ViewChild} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {LienService} from '../../../services/lien.service';
import {DemandeService} from '../../../services/demande.service';
import {AjoutStructureRattachementComponent} from '../../workflow/ajout-structure-rattachement/ajout-structure-rattachement.component';
import {User} from '../../../model/user.model';
import {isInGestRespAccessPerimeter, isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {getDDMMYYYY} from '../../../utils/date.utils';
import {StructureDetailsComponent} from '../structure-details/structure-details.component';
import {CAT_STRUCTURE, ETAT_STRUCTURE, ETAT_VALID} from '../../../model/enum.model';
import {ROOT_STRUCTURE_ID} from '../../../constants/datas.constant';
import * as moment from 'moment';
import {UserService} from '../../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {UpdateLienDialogComponent} from '../../../layout/dialog/structure/update-lien-dialog/update-lien-dialog.component';

@Component({
  selector: 'app-structures-associees',
  templateUrl: './structures-associees.component.html',
  styleUrls: ['./structures-associees.component.scss']
})
export class StructuresAssocieesComponent implements OnChanges {

  @Input() structure: Structure;
  @ViewChild(AjoutStructureRattachementComponent) ajoutStructureRattachementComponent: AjoutStructureRattachementComponent;

  user: User;

  catStructure = CAT_STRUCTURE;
  etatStructure = ETAT_STRUCTURE;
  etatValid = ETAT_VALID;

  rootStructureId = ROOT_STRUCTURE_ID;

  cols: any[];
  tabValues: any[][];

  selectedAction: ACTION;
  action = ACTION;

  selectedLien: Lien;

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  hasLienHierarchiqueEnAttente: boolean;
  hasSuppressionStructureRattachementEnAttente: boolean;
  countStructureRattachementValides: number;

  private static convertToDatasource(lien: Lien) {
    return {
      lien,
      structureId: lien.structureId,
      structure: lien.structure,
      relation: lien.typeLien.typeLienLib,
      typeLien: lien.typeLien,
      etatValid: lien.etatValid,
      structurePorteuse: lien.structurePorteuse,
      dateDebut: getDDMMYYYY(lien.dateDebut),
      dateFin: getDDMMYYYY(lien.dateFin),
      hasDemandeSuppressionEnCours: lien.hasDemandeSuppressionEnCours
    };
  }

  constructor(private lienService: LienService,
              private userService: UserService,
              private demandeService: DemandeService,
              public dialog: MatDialog,
              @Inject(StructureDetailsComponent) public parent: StructureDetailsComponent) {
    this.user = this.userService.getCurrentUser();
    this.cols = [
      {field: 'structure', header: 'Structure'},
      {field: 'relation', header: 'Relation'},
      {field: 'structurePorteuse', header: 'Structure porteuse'},
      {field: 'dateDebut', header: 'Date de début'},
      {field: 'dateFin', header: 'Date de fin'},
      {field: 'etatValid', header: 'Etat'}
    ];
  }

  ngOnChanges() {
    this.init();
  }

  modifierStructureRattachement(lien: Lien) {
    this.selectedLien = lien;
    this.selectedAction = ACTION.MOD_STRUCT_RATT;
  }

  supprimerLien(lien: Lien) {
    this.selectedLien = lien;
    this.selectedAction = ACTION.DEL_LIEN;
  }

  isInUserGestRespActionPerimeter(structure: Structure): boolean {
    return isInGestRespActionPerimeter(structure.structureId, this.user);
  }

  isInUserGestRespAccessPerimeter(structure: Structure): boolean {
    return isInGestRespAccessPerimeter(structure.structureId, this.user);
  }

  isUpdateButtonVisible(lien: Lien): boolean {
    return lien.structureId === this.structure.structureId
      && lien.etatValid === ETAT_VALID.VALIDE && moment(lien.dateDebut).isBefore(new Date())
      && (!lien.dateFin || moment(lien.dateFin).isAfter(new Date()));
  }

  isRemoveButtonVisible(lien: Lien): boolean {
    return lien.etatValid === ETAT_VALID.VALIDE && moment(lien.dateDebut).isBefore(new Date())
      && (!lien.dateFin || moment(lien.dateFin).isAfter(new Date()));
  }

  update(lien: Lien) {
    this.dialog.open(UpdateLienDialogComponent, {
      minWidth: 600,
      maxHeight: 800,
      data: {
        lien,
        structure: this.structure
      }
    }).afterClosed().subscribe(() => this.init());
  }

  private init() {
    this.selectedLien = null;
    this.hasLienHierarchiqueEnAttente = false;
    this.countStructureRattachementValides = 0;
    this.tabValues = [];
    this.selectedAction = ACTION.READ;

    if (this.structure) {
      let orgaLiensAscendants: any[] = [];
      let orgaLiensDescendants: any[] = [];
      let autresLiens: any[] = [];

      this.lienService.getAllLiensByStructureId(this.structure.structureId).subscribe(liens => {
        this.countStructureRattachementValides = liens.filter(l =>
          l.structureId === this.structure.structureId
          && l.typeLien.isHierarchique
          && l.etatValid === ETAT_VALID.VALIDE
          && moment(l.dateDebut).isBefore(new Date())
          && (!l.dateFin || moment(l.dateFin).isAfter(new Date()))).length;

        orgaLiensAscendants = liens
          .filter(l => l.typeLien.isHierarchique
            && this.structure.structureId === l.structureId
            && l.structurePorteuse.catStructure === CAT_STRUCTURE.ORGA && l.structurePorteuse.interne)
          .sort((a, b) => a.dateDebut < b.dateDebut ? 1 : -1)
          .map(l => StructuresAssocieesComponent.convertToDatasource(l));

        orgaLiensDescendants = liens
          .filter(l => l.typeLien.isHierarchique
            && this.structure.structureId === l.structPorteuseId
            && l.structure.catStructure === CAT_STRUCTURE.ORGA && l.structure.interne)
          .sort((a, b) => a.dateDebut < b.dateDebut ? 1 : -1)
          .map(l => StructuresAssocieesComponent.convertToDatasource(l));

        autresLiens = liens
          .filter(l => !(orgaLiensDescendants.concat(orgaLiensAscendants).map(row => row.lien.lienId).includes(l.lienId)))
          .sort((a, b) => a.dateDebut < b.dateDebut ? 1 : -1)
          .map(l => StructuresAssocieesComponent.convertToDatasource(l));

        this.tabValues = [orgaLiensAscendants, orgaLiensDescendants, autresLiens];
      });

      this.demandeService.hasLienHierarchiqueEnAttente(this.structure.structureId).subscribe(res => this.hasLienHierarchiqueEnAttente = res);
      this.demandeService.hasSuppressionStructureRattachementEnAttente(this.structure.structureId).subscribe(res => this.hasSuppressionStructureRattachementEnAttente = res);
    }
  }
}

export enum ACTION {
  READ,
  ADD_STRUCT_RATT,
  ADD_STRUCT_ORGA,
  ADD_STRUCT_MAT,
  MOD_STRUCT_RATT,
  DEL_LIEN
}
