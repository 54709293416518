import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Structure} from '../../../model/structure.model';
import {TypeLog} from '../../../model/log.model';
import {Individu, User} from '../../../model/user.model';
import {LogService} from '../../../services/log.service';
import {StructureService} from '../../../services/structure.service';
import {IndividuService} from '../../../services/Individu.service';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'app-recherche-log-form',
  templateUrl: './recherche-log-form.component.html',
  styleUrls: ['./recherche-log-form.component.scss']
})
export class RechercheLogFormComponent {

  @Output() searchEmitter: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  user: User;

  form: FormGroup;

  structures: Structure[];
  typesLog: TypeLog[];
  acteurs: Individu[];

  constructor(private logService: LogService,
              private userService: UserService,
              private structureService: StructureService,
              private individuService: IndividuService) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      structures: new FormControl(null),
      avant: new FormControl(null),
      apres: new FormControl(null),
      typesLog: new FormControl(null),
      acteurs: new FormControl(null),
    });

    structureService.getAllStructures().subscribe(res => this.structures = res);
    logService.getAllTypesLog().subscribe(res => this.typesLog = res);
    this.individuService.getAllIndividus().subscribe(res => this.acteurs = res);
  }

  search() {
    let params = new HttpParams();

    if (this.form.controls.structures.value && this.form.controls.structures.value.length > 0) {
      params = params.append('structures', this.form.controls.structures.value);
    }
    if (this.form.controls.typesLog.value && this.form.controls.typesLog.value.length > 0) {
      params = params.append('typesLog', this.form.controls.typesLog.value);
    }
    if (this.form.controls.acteurs.value && this.form.controls.acteurs.value.length > 0) {
      params = params.append('acteurs', this.form.controls.acteurs.value);
    }
    if (this.form.controls.avant.value) {
      params = params.append('avant', moment(this.form.controls.avant.value).format('DD/MM/YYYY HH:mm'));
    }
    if (this.form.controls.apres.value) {
      params = params.append('apres', moment(this.form.controls.apres.value).format('DD/MM/YYYY HH:mm'));
    }

    this.searchEmitter.emit(params);
  }

}
