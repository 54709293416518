import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Demande, DetailDemande, TypeDemande} from '../model/demande.model';
import {Pagination} from '../model/divers.model';

@Injectable()
export class DemandeService {

  constructor(private http: HttpClient) {
  }

  public search(params: HttpParams, pagination: Pagination): Observable<any> {
    return this.http.post<any>('/demande/search', pagination, {params, observe: 'response'});
  }

  public getCountDemandesEnCours(): Observable<number> {
    return this.http.get<number>('/demande/count/en-cours');
  }

  public getCountDemandesATraiter(): Observable<number> {
    return this.http.get<number>('/demande/count/a-traiter');
  }

  public getCountDemandesEnAttente(): Observable<number> {
    return this.http.get<number>('/demande/count/en-attente');
  }

  public getAllTypesDemande(): Observable<TypeDemande[]> {
    return this.http.get<TypeDemande[]>('/demande/types-demande');
  }

  public getDemandesByStructureId(structureId: number): Observable<Demande[]> {
    return this.http.get<Demande[]>('/demande/structure/' + structureId);
  }

  public getDemandesByIndividuId(individuId: number): Observable<Demande[]> {
    return this.http.get<Demande[]>('/demande/individu/' + individuId);
  }

  public getDemandesByInstanceId(instanceId: number): Observable<Demande[]> {
    return this.http.get<Demande[]>('/demande/instance/' + instanceId);
  }

  public getDetailsDemande(demandeId: number): Observable<DetailDemande[]> {
    return this.http.get<DetailDemande[]>('/demande/' + demandeId + '/details-demande');
  }

  public hasDemandeEnCoursByTree(structureId: number): Observable<boolean> {
    return this.http.get<boolean>('/demande/has-demande-en-cours-by-tree/structure/' + structureId);
  }

  public hasDemandesFermetureFromParents(structureId: number): Observable<boolean> {
    return this.http.get<boolean>('/demande/has-demande-fermeture-from-parents/structure/' + structureId);
  }

  public hasLienHierarchiqueEnAttente(structureId: number): Observable<boolean> {
    return this.http.get<boolean>('/demande/has-lien-hierarchique-en-cours/structure/' + structureId);
  }

  public hasSuppressionStructureRattachementEnAttente(structureId: number): Observable<boolean> {
    return this.http.get<boolean>('/demande/has-suppression-structure-rattachement-en-cours/structure/' + structureId);
  }

  public hasDemandeEnCours(individuId: number): Observable<boolean> {
    return this.http.get<boolean>('/demande/has-demande-en-cours/individu/' + individuId);
  }
}
