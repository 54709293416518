import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../../../services/application.service';
import {LoaderService} from '../../../../services/loader.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AssociationSiService} from '../../../../services/association-si.service';
import {AssociationRequest, RepartStructSI} from '../../../../model/association.model';
import {Application} from '../../../../model/nomenclature.model';
import {formatLocalDateWithUTC} from '../../../../utils/date.utils';

@Component({
  selector: 'app-update-code-si-dialog',
  templateUrl: './update-code-si-dialog.component.html',
  styleUrls: ['./update-code-si-dialog.component.scss']
})
export class UpdateCodeSiDialogComponent {

  applications: Application[];

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private applicationService: ApplicationService,
              private associationSiService: AssociationSiService,
              public dialogRef: MatDialogRef<UpdateCodeSiDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.applicationService.getApplicationsByStructure(this.data.rss.structureId).subscribe(res => this.applications = res);

    this.form = new FormGroup({
      dateDebut: new FormControl(new Date(), Validators.required),
      codeSI: new FormControl(null, Validators.required),
    });
  }

  onConfirm() {
    const rss = Object.assign({}, this.data.rss);
    rss.codeStructSI = this.form.controls.codeSI.value;
    rss.dateDebut = formatLocalDateWithUTC(this.form.controls.dateDebut.value);
    rss.isValid=true;
    const associationRequest: AssociationRequest = new AssociationRequest(rss);

    this.loaderService.start();
    this.associationSiService.modifyCode(associationRequest).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close();
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

}

export interface DialogData {
  rss: RepartStructSI;
}
