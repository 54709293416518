import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {User} from 'src/app/model/user.model';
import {UserService} from '../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {LoginAsDialogComponent} from '../dialog/divers/login-as-dialog/login-as-dialog.component';
import {ParametreDialogComponent} from '../dialog/divers/parametre-dialog/parametre-dialog.component';

@Component({
  selector: 'app-pagetop',
  templateUrl: './pagetop.component.html',
  styleUrls: ['./pagetop.component.scss']
})
export class PagetopComponent {

  @Output() menuButtonEmitter: EventEmitter<void> = new EventEmitter<void>();

  loginAsUser: User;
  user: User;
  initials: string;

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService,
              public dialog: MatDialog) {
    this.init();
  }

  loginAs(): void {
    this.dialog
      .open(LoginAsDialogComponent)
      .afterClosed().subscribe(res => {
      if (res?.loginAs) {
        this.userService.loginAs(res.loginAs);
      }
    });
  }

  logoutAs(): void {
    this.userService.logoutAs();
    window.location.reload();
  }

  logout(): void {
    localStorage.clear();
    this.authenticationService.redirectToCasLogin();
  }

  setup(): void {
    this.dialog.open(ParametreDialogComponent, {
      minWidth: 1000,
      minHeight:600
    });
  }

  onMenuButtonClick(): void {
    this.menuButtonEmitter.emit();
  }

  init(): void {
    this.user = this.userService.getUser();
    this.loginAsUser = this.userService.getLoginAs();
    if (this.user) {
      this.initials = this.user.ldapAccount.prenom.substr(0, 1).toUpperCase() + this.user.ldapAccount.nom.substr(0, 1).toUpperCase();
    }
  }
}
