import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DescriptionStructure} from '../model/structure.model';


@Injectable()
export class DescriptionStructureService {

  constructor(private http: HttpClient) {
  }

  public getDescriptionsByStructure(structureId: number): Observable<DescriptionStructure[]> {
    return this.http.get<DescriptionStructure[]>('/description-structure/structure/' + structureId);
  }

  public create(descriptionStructure: DescriptionStructure, structureId: number): Observable<string[]> {
    return this.http.put<string[]>('/description-structure/create/structure/' + structureId, descriptionStructure);
  }
}
