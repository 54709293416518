import {Component, OnInit} from '@angular/core';
import {StructureService} from '../../../services/structure.service';
import {Structure} from '../../../model/structure.model';
import {IdentiteStructureService} from '../../../services/identite-structure.service';
import {MatDialog} from '@angular/material/dialog';
import {LoaderService} from '../../../services/loader.service';
import {UpdateClosingDateDialogComponent} from '../../../layout/dialog/structure-lifecycle/update-closing-date-dialog/update-closing-date-dialog.component';
import {ArchiveDialogComponent} from '../../../layout/dialog/structure-lifecycle/archive-dialog/archive-dialog.component';
import {ReactivateStructureDialogComponent} from '../../../layout/dialog/structure-lifecycle/reactivate-structure-dialog/reactivate-structure-dialog.component';
import {getDDMMYYYY} from '../../../utils/date.utils';
import {ETAT_STRUCTURE} from '../../../model/enum.model';
import {Router} from '@angular/router';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-administration-archivage-structure',
  templateUrl: './administration-archivage-structure.component.html',
  styleUrls: ['./administration-archivage-structure.component.scss']
})
export class AdministrationArchivageStructureComponent implements OnInit {


  user: User;

  cols: any[];
  datasource: any[];

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  action = ACTION;

  constructor(
    private structureService: StructureService,
    private userService: UserService,
    private loaderService: LoaderService,
    private identiteStructureService: IdentiteStructureService,
    public dialog: MatDialog,
    private router: Router) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'structure', header: 'Structure'},
      {field: 'dateFermeture', header: 'Date de fermeture'},
    ];
  }

  ngOnInit() {
    this.init();
  }

  onClick(row: any, action: ACTION) {
    let dialogRef;
    this.structureService.getStructureByStructureId(row.structureId).subscribe(selectedStructure => {

      switch (action) {
        case ACTION.UPDATE: {
          dialogRef = this.dialog.open(UpdateClosingDateDialogComponent, {
            minWidth: 400,
            data: {
              selectedStructure
            }
          });
          break;
        }
        case ACTION.ARCHIVE: {
          dialogRef = this.dialog.open(ArchiveDialogComponent, {
            minWidth: 400,
            data: {
              selectedStructure
            }
          });
          break;
        }
        case ACTION.REACTIVATE: {
          dialogRef = this.dialog.open(ReactivateStructureDialogComponent, {
            minWidth: 400,
            data: {
              selectedStructure
            }
          });
          break;
        }
      }

      dialogRef.afterClosed().subscribe(() => {
        this.init();
      });
    });
  }

  onStructureSelected(structure: Structure) {
    this.router.navigate(['structure', structure.structureId]);
  }

  convertToDataSource(struct: Structure) {
    return {
      structureId: struct.structureId,
      dateFermeture: getDDMMYYYY(struct.identite.dateFermeture),
      structure: struct
    };
  }

  init() {
    this.structureService.getStructuresByEtat(ETAT_STRUCTURE.A_ARCHIVER).subscribe(res => {
      this.datasource = [];
      if (res?.length > 0) {
        res.forEach(s => this.datasource.push(this.convertToDataSource(s)));
      }
    });
  }
}

export enum ACTION {
  UPDATE,
  ARCHIVE,
  REACTIVATE
}
