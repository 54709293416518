import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {ModificationCreationRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-demande-modification',
  templateUrl: './demande-modification.component.html',
  styleUrls: ['./demande-modification.component.scss']
})
export class DemandeModificationComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;

  user: User;
  motif: string;
  displayForm: boolean;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService) {
    this.displayForm = false;
  }

  validate() {
    const requestModification: ModificationCreationRequest = {
      demandeId: this.demande.demandeId,
      motif: this.motif
    };

    this.loaderService.start();
    this.workflowService.requestModification(requestModification).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
