import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {Demande} from '../../../model/demande.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LienService} from '../../../services/lien.service';
import {DomaineService} from '../../../services/domaine.service';
import {IdentiteFormComponent} from '../../structure/identite-form/identite-form.component';
import {TypeLienService} from '../../../services/type-lien.service';
import {Domaine, TypeLien} from '../../../model/nomenclature.model';
import {User} from '../../../model/user.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {normalize} from '../../../utils/string.utils';
import {StructureService} from '../../../services/structure.service';
import {CAT_STRUCTURE, ETAT_STRUCTURE} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-demande-struct-mat-form',
  templateUrl: './demande-struct-mat-form.component.html',
  styleUrls: ['./demande-struct-mat-form.component.scss']
})
export class DemandeStructMatFormComponent implements OnChanges {

  @Input() demande: Demande;
  @Input() structurePorteuse: Structure;
  @Input() readonly: boolean;

  @ViewChild(IdentiteFormComponent) identiteFormComponent: IdentiteFormComponent;

  form: FormGroup;
  user: User;

  catStructure = CAT_STRUCTURE;

  domaines: Domaine[];
  typeLiens: TypeLien[];

  options: Structure[];
  filteredOptions: Observable<Structure[]>;

  constructor(private lienService: LienService,
              private userService: UserService,
              private structureService: StructureService,
              private domaineService: DomaineService,
              private typeLienService: TypeLienService) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      structurePorteuse: new FormControl(null, Validators.required),
      typeLien: new FormControl(null, Validators.required),
      domaines: new FormControl(null),
      interne: new FormControl(false),
      motif: new FormControl(null, Validators.required)
    });

    this.readonly = false;
  }

  init() {
    this.form.reset();
    this.identiteFormComponent.form.reset();
  }

  ngOnChanges() {
    return Promise.all([
      this.getAllStructures(),
      this.getAllTypesLien(),
      this.getAllDomaines()
    ]).then(() => {
      this.readonly ? this.form.disable() : this.form.enable();

      if (this.structurePorteuse) {
        this.form.controls.structurePorteuse.setValue(this.structurePorteuse.identite.libelleLong);
        this.form.controls.structurePorteuse.disable();
      }

      if (this.demande) {
        if (this.user.individu.isAdmin && !this.readonly) {
          this.form.controls.structurePorteuse.enable();
        }

        this.form.controls.typeLien.setValue(this.demande.lien.typeLien.typeLienId);
        this.form.controls.interne.setValue(this.demande.lien.structure.interne);
        this.form.controls.motif.setValue('S/O');

        const domList: number[] = [];
        this.demande.lien.structure.domaines.forEach(d => {
          domList.push(d.domaineId);
        });

        this.form.controls.domaines.setValue(domList);
      }
    });
  }

  getStructurePorteuseId(): number {
    return this.options.filter(o => o.identite.libelleLong === this.form.controls.structurePorteuse.value)[0].structureId;
  }

  getAllTypesLien(): Promise<void> {
    return new Promise<void>(resolve => {
        this.typeLienService.getAllTypesLien().subscribe(typeLiens => {
          this.typeLiens = typeLiens.filter(t => !t.isAntecedent && t.isValid);
          resolve();
        });
      }
    );
  }

  getAllDomaines(): Promise<void> {
    return new Promise<void>(resolve => {
      this.domaineService.getAllDomaines().subscribe(domaines => {
        this.domaines = domaines.filter(d => d.isValid);
        resolve();
      });
    });
  }

  private getAllStructures(): Promise<void> {
    return new Promise<void>(resolve => {
      this.options = [];
      this.structureService.getAllStructures().subscribe(res => {
        this.options = res.filter(s => ![ETAT_STRUCTURE.SUPPRIME, ETAT_STRUCTURE.ARCHIVE].includes(s.etatStructure));
        this.filteredOptions = this.form.controls.structurePorteuse.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        resolve();
      });
    });
  }

  private _filter(value: any): Structure[] {
    let options: Structure[];
    if (value && value.trim().length > 2) {
      const values: string[] = value.split(' ');
      options = this.options.filter(structure =>
        values.every(v =>
          normalize(structure.identite.libelleLong).includes(normalize(v))
        ) ||
        values.every(v => structure.identite.libelleCourt &&
          normalize(structure.identite.libelleCourt).includes(normalize(v))
        )
      );
    }
    return options;
  }

  isFormDisabled() {
    return (this.identiteFormComponent && this.identiteFormComponent.form.invalid)
      || this.form.invalid
      || (this.form.pristine && this.identiteFormComponent && this.identiteFormComponent.form.pristine);
  }
}
