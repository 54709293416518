import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {ETAT_VALID, TYPE_ROLE} from '../../../../model/enum.model';
import {LdapAccount, Privilege, Role, User} from '../../../../model/user.model';
import {RoleService} from '../../../../services/role.service';
import {LoaderService} from '../../../../services/loader.service';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-add-admin-moderateur-dialog',
  templateUrl: './add-admin-moderateur-dialog.component.html',
  styleUrls: ['./add-admin-moderateur-dialog.component.scss']
})
export class AddAdminModerateurDialogComponent {

  user: User;

  ldapAccount: LdapAccount;

  constructor(private roleService: RoleService,
              private userService: UserService,
              private loaderService: LoaderService,
              public dialogRef: MatDialogRef<AddAdminModerateurDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();
  }

  onPersonSelected(ldapAccount: LdapAccount) {
    const role: Role = {
      typeRole: this.data.typeRole,
      isExtended:false,
      dateDebut:new Date(),
      etatValid:ETAT_VALID.VALIDE
    };
    const privilege: Privilege = {role, ldapAccountDTO: ldapAccount};

    this.loaderService.start();
    this.roleService.create(privilege).subscribe(() => {
      if (ldapAccount.uid === this.user.individu.idnum) {
        if (this.data.typeRole === TYPE_ROLE.ADMINISTRATEUR) {
          this.user.individu.isAdmin = true;
          this.userService.setUser(this.user);
        } else {
          this.user.individu.isModerateur = true;
          this.userService.setUser(this.user);
        }
      }
      this.loaderService.stop();
      this.dialogRef.close();
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  typeRole: TYPE_ROLE;
  structure: Structure;
}
