import {Component, OnInit, ViewChild} from '@angular/core';
import {Individu, LdapAccount, Privilege, Role, User} from '../../../model/user.model';
import {LoaderService} from '../../../services/loader.service';
import {RoleService} from '../../../services/role.service';
import {UserService} from '../../../services/user.service';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {ETAT_DEMANDE, ETAT_VALID, TYPE_ROLE} from '../../../model/enum.model';
import {StructuresSelectionWithTreeComponent} from '../../../components/ui/structuresSelectionWithTree/structuresSelectionWithTree.component';
import {FormControl, FormGroup} from '@angular/forms';
import {IndividuService} from '../../../services/Individu.service';
import {compare} from '../../../utils/string.utils';
import {isInUserPerimeter} from '../../../utils/user.utils';
import {RemovePrivilegeDialogComponent} from '../../../layout/dialog/privilege/remove-privilege-dialog/remove-privilege-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../layout/dialog/divers/confirm-dialog/confirm-dialog.component';
import {RechercheLdapFormComponent} from '../../../components/ui/recherche-ldap-form/recherche-ldap-form.component';

@Component({
  selector: 'app-privileges-par-personne',
  templateUrl: './privileges-par-personne.component.html',
  styleUrls: ['./privileges-par-personne.component.scss']
})
export class PrivilegesParPersonneComponent implements OnInit {

  @ViewChild(StructuresSelectionWithTreeComponent) structuresSelectionWithTreeComponent: StructuresSelectionWithTreeComponent;
  @ViewChild(RechercheLdapFormComponent) rechercheLdapFormComponent: RechercheLdapFormComponent;


  user: User;
  selectedLdapAccount: LdapAccount;
  selectedIndividu: Individu;
  rolesGest: Role[];
  rolesResp: Role[];
  form: FormGroup;


  constructor(private loaderService: LoaderService,
              private individuService: IndividuService,
              private roleService: RoleService,
              private structureTreeService: StructureTreeService,
              private userService: UserService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.form = new FormGroup({
      droitEtendu: new FormControl(true)
    });
  }

  ngOnInit(): void {
    this.init();
  }

  onPersonSelected(ldapAccount: LdapAccount) {
    this.init();
    this.selectedLdapAccount = ldapAccount;
    this.loadIndividu();
  }

  addPrivilege() {
    this.loaderService.start();
    const promises: Promise<void>[] = [];

    const roles: Role[] = [];
    this.structuresSelectionWithTreeComponent.getStructureIds().forEach(structId =>
      roles.push({
        typeRole: TYPE_ROLE.GESTIONNAIRE,
        structureId: structId,
        dateDebut: new Date(),
        isExtended: this.structuresSelectionWithTreeComponent.getStructureIds().length === 1 && this.form.controls.droitEtendu.value,
        etatValid: ETAT_VALID.VALIDE
      })
    );

    this.structureTreeService.getParentIdsByStructureIds(this.structuresSelectionWithTreeComponent.getStructureIds())
      .subscribe(res => {
        roles.filter(r => res.includes(r.structureId))
          .forEach(role => {
            promises.push(this.createPrivilege({role, ldapAccountDTO: this.selectedLdapAccount}));
          });

        Promise.all(promises).then(() => {
          this.loadIndividu();
          this.structuresSelectionWithTreeComponent.init();

          this.userService.getUpdatedUser().subscribe(user => {
            this.userService.setUser(user);
            this.loaderService.stop();
          });
        });
      });
  }

  removePrivilege(role: Role) {
    this.dialog
      .open(RemovePrivilegeDialogComponent, {
        minWidth: 400,
        data: {
          roleId: role.roleId,
          typeRole: role.typeRole
        }
      })
      .afterClosed()
      .subscribe(() => this.loadIndividu());
  }

  isInUserPerimeter(role: Role): boolean {
    return isInUserPerimeter(role.structureId, this.user);
  }

  deleteIndividu() {
    this.dialog.open(ConfirmDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Suppression de compte',
        question: 'Souhaitez-vous supprimer le compte de ' + this.selectedIndividu.prenom + ' ' + this.selectedIndividu.nom.toUpperCase() + ' ?',
        body: this.selectedIndividu.isResponsable ? '<span class="alert-danger">Attention, cet individu est responsable d\'une structure</span>' : null
      }
    })
      .afterClosed()
      .subscribe(res => {
        if (res.ok) {
          this.loaderService.start();
          this.individuService.deleteIndividu(this.selectedIndividu.individuId).subscribe(() => {
            this.init();
            this.rechercheLdapFormComponent.selectedLdapAccount = null;
            this.loaderService.stop();
          });
        }
      });

  }


  private init() {
    this.rechercheLdapFormComponent?.init();
    this.structuresSelectionWithTreeComponent?.init();
    this.selectedIndividu = null;
    this.selectedLdapAccount = null;
    this.rolesResp = null;
    this.rolesGest = null;
  }

  private createPrivilege(privilege: Privilege): Promise<void> {
    return new Promise(resolve => {
      this.roleService.create(privilege).subscribe(() => resolve());
    });
  }

  private loadIndividu() {
    this.individuService.getIndividuByUid(this.selectedLdapAccount.uid).subscribe(ind => {
      this.selectedIndividu = ind;
      this.rolesGest =[];
      if (this.selectedIndividu?.isGestionnaire) {
        this.rolesGest = ind.roles
          .filter(r => r.typeRole === TYPE_ROLE.GESTIONNAIRE)
          .sort((r1, r2) => compare(r1.structure.identite.libelleLong, r2.structure.identite.libelleLong));
      }

      this.rolesResp =[];
      if (this.selectedIndividu?.isResponsable) {
        this.rolesResp = ind.roles
          .filter(r => r.typeRole === TYPE_ROLE.RESPONSABLE)
          .sort((r1, r2) => compare(r1.structure.identite.libelleLong, r2.structure.identite.libelleLong));
      }
    });
  }

  protected readonly ETAT_DEMANDE = ETAT_DEMANDE;


}
