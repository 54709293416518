import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AssociationRequest, RepartStructSI} from '../model/association.model';

@Injectable()
export class AssociationSiService {

  constructor(private http: HttpClient) {
  }

  public getRepartStructSiByApplicationId(applicationId: number): Observable<RepartStructSI[]> {
    return this.http.get<RepartStructSI[]>('/association-si/application/' + applicationId);
  }

  public getRepartStructSiByStructureId(structureId: number): Observable<RepartStructSI[]> {
    return this.http.get<RepartStructSI[]>('/association-si/structure/' + structureId);
  }

  public getHistoByStructureId(structureId: number): Observable<RepartStructSI[]> {
    return this.http.get<RepartStructSI[]>('/association-si/histo/structure/' + structureId);
  }

  public create(associationRequest: AssociationRequest): Observable<void> {
    return this.http.put<void>('/association-si/create', associationRequest);
  }

  public modifyCode(associationRequest: AssociationRequest): Observable<void> {
    return this.http.put<void>('/association-si/modify-code', associationRequest);
  }

  public enable(repartStructSiId: number, motif: string): Observable<void> {
    return this.http.put<void>('/association-si/enable/' + repartStructSiId, motif);
  }

  public disable(repartStructSiId: number, changeEtat: boolean, motif: string): Observable<void> {
    return this.http.put<void>('/association-si/disable/' + repartStructSiId + '/change-etat/' + changeEtat, motif);
  }


}
