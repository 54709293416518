<div class="row justify-content-center">
  <mat-card class="col-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de recherche</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Structures</mat-label>
          <mat-select formControlName="structures" placeholder="Structures" multiple>
            <mat-option appHighlightStructure [structure]="struct"
                        *ngFor="let struct of structures"
                        [value]="struct.structureId">{{struct | libelleStructure:user}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Etats</mat-label>
          <mat-select formControlName="etats" placeholder="Etats" multiple>
            <mat-option *ngFor="let e of etats" [value]="e.value">{{e.libelle}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Survenu avant le</mat-label>
          <input matInput formControlName="avant" [matDatepicker]="debpicker" placeholder="Survenu avant le">
          <mat-datepicker-toggle matSuffix [for]="debpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #debpicker></mat-datepicker>
          <mat-error *ngIf="form.controls['avant'].invalid">Format non valide</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Survenu après le</mat-label>
          <input matInput formControlName="apres" [matDatepicker]="finpicker" placeholder="Survenu après le">
          <mat-datepicker-toggle matSuffix [for]="finpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #finpicker></mat-datepicker>
          <mat-error *ngIf="form.controls['apres'].invalid">Format non valide</mat-error>
        </mat-form-field>
      </form>

      <div class="row justify-content-center">
        <button class="mt-2 ml-2" mat-raised-button color="primary" matTooltip="Filtrer"
                (click)="search()"><i class="fas fa-search"></i></button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
