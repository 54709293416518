<div class="row justify-content-center">
  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Formulaire de demande de fermeture de la stucture</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de fermeture</mat-label>
          <input matInput formControlName="dateFermeture"
                 [min]="structure.identite.dateOuverture"
                 [matDatepicker]="ferpicker"
                 placeholder="Date de fermeture" required>
          <mat-datepicker-toggle matSuffix [for]="ferpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #ferpicker></mat-datepicker>
          <mat-error *ngIf="form.controls['dateFermeture'].hasError('required')">Format non valide</mat-error>
          <mat-error *ngIf="form.controls['dateFermeture'].hasError('matDatepickerMin')">La date de fermeture de la structure ne peut être antérieure à date d'ouverture</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la fermeture</mat-label>
          <textarea matInput formControlName="motif" placeholder="Saisie d'un motif de fermeture" [maxLength]="200" required></textarea>
          <mat-error
            *ngIf="form.controls['motif'].invalid && form.controls['motif'].touched">Valeur requise</mat-error>
        </mat-form-field>
      </form>

      <div class="row justify-content-center">
        <button class="col-lg-6 mt-2 ml-2" mat-raised-button color="primary" matTooltip="Demande de fermeture"
                (click)="send()" [disabled]="form.invalid">{{'envoyer une demande de fermeture' | uppercase}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
