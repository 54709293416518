<div class="row justify-content-center mt-4">
  <h1 class="title">Privilèges par personne</h1>
</div>

<div class="row justify-content-center buffer">
  <mat-card class="card col-lg-8 p-4">
    <mat-card-header>
      <h4>Choix de la personne</h4>
    </mat-card-header>
    <mat-card-content>
      <div class="row justify-content-center mt-4">
        <app-recherche-ldap-form class="col-lg-12" #rechercheLdapFormComponent (ldapAccountEventEmitter)="onPersonSelected($event)">
        </app-recherche-ldap-form>

        <button class="col-lg-12 mt-3" mat-raised-button color="warn"
                matTooltip="Supprimer ce compte. L'individu ne pourra plus accéder à l'application"
                (click)="deleteIndividu()"
                *ngIf="user.individu.isAdmin && selectedIndividu && selectedIndividu.idnum !== user.individu.idnum && !(selectedIndividu.isAdmin || selectedIndividu.isModerateur || selectedIndividu.isResponsable)"><i
          class="fas fa-user-slash mr-2"></i>Supprimer le compte
          de {{selectedIndividu.prenom + ' ' + selectedIndividu.nom}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card col-lg-8 mt-4 mb-4 p-4" *ngIf="selectedLdapAccount">
    <mat-card-header>
      <h4>Liste des privilèges de {{selectedLdapAccount.prenom + ' ' + selectedLdapAccount.nom}}</h4>
    </mat-card-header>
    <mat-card-content>
      <p class="mt-4 alert-info" *ngIf="(!rolesGest|| rolesGest.length === 0) && (!rolesResp|| rolesResp.length === 0)">Aucun privilège</p>

      <div class="mt-4" *ngIf="rolesGest?.length > 0">
        <h5>{{'Privilèges de gestionnaire'}}</h5>
        <ul *ngFor="let role of rolesGest" class="mt-4">
          <li>
            <a href appHighlightStructure
               [structure]="role.structure"
               [routerLink]="'/structure/' +  role.structure.structureId">
              {{role.structure | libelleStructure:user}}</a><span
            *ngIf="role.isExtended">{{' et ses structures filles'}}</span>
            <button class="button"
                    matTooltip="Supprimer le privilege"
                    (click)="removePrivilege(role)"
                    *ngIf="user.individu.individuId !== selectedIndividu.individuId && isInUserPerimeter(role)">
              <i class="fas fa-trash"></i></button>
          </li>
        </ul>
      </div>
      <div class="mt-4" *ngIf="rolesResp?.length > 0">
        <h5>{{'Privilèges de Responsable'}}</h5>
        <ul *ngFor="let role of rolesResp" class="mt-4">
          <li>
            <a href appHighlightStructure
               [structure]="role.structure"
               [routerLink]="'/structure/' +  role.structure.structureId">
              {{role.structure | libelleStructure:user}}</a><span
            *ngIf="role.isExtended">{{' et ses structures filles'}}</span>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>Choix des nouvelles structures d'affectation</h4>
    </mat-card-header>
    <mat-card-content>
      <app-structures-selection-with-tree #structureSelectionWithTree class="col-lg-6 mt-4"
                                          [single]="false" [multiple]="true">
      </app-structures-selection-with-tree>

      <form [formGroup]="form">
        <mat-checkbox formControlName="droitEtendu" class="col-lg-12"
                      (change)="form.controls.droitEtendu.setValue($event.checked)"
                      [checked]="form.controls.droitEtendu.value"
                      *ngIf="structuresSelectionWithTreeComponent?.selectedStructures.length ===1">Etendre le droit de
          gestionnaire sur toutes les structures filles
        </mat-checkbox>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div class="row justify-content-center buffer">
  <button class="col-lg-8" mat-raised-button color="primary" matTooltip="Attribuer le(s) role(s) de gestionnaire"
          [disabled]="!selectedLdapAccount || structuresSelectionWithTreeComponent?.getStructureIds().length === 0"
          (click)="addPrivilege()">{{'Attribuer le(s) role(s) de gestionnaire' | uppercase}}
  </button>
</div>
