import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {
  ConfirmSyncReferentielAquitainDialogComponent
} from '../../../../layout/dialog/referentiel-aquitain/confirm-sync-referentiel-aquitain-dialog/confirm-sync-referentiel-aquitain-dialog.component';
import {LienService} from '../../../../services/lien.service';
import {LoaderService} from '../../../../services/loader.service';
import {SyncRefAqService} from '../../../../services/syncRefAq.service';
import {MatDialog} from '@angular/material/dialog';
import {SyncRefAqTask} from '../../../../model/referentiel-aquitain.model';
import {getDDMMYYYYHHmm} from '../../../../utils/date.utils';
import {ETAT_SYNC} from '../../../../model/enum.model';
import {User} from '../../../../model/user.model';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-sync-tasks',
  templateUrl: './sync-tasks.component.html',
  styleUrls: ['./sync-tasks.component.scss']
})
export class SyncTasksComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() fromAdmin = false;
  @Input() readonly: boolean;
  @Input() syncRefAqTasks: SyncRefAqTask[];

  user: User;

  datasource: any[];
  cols: any[];
  selectedRow: any;

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  etatSync = ETAT_SYNC;

  constructor(private lienService: LienService,
              private userService: UserService,
              private loaderService: LoaderService,
              private syncRefAqService: SyncRefAqService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'syncId', header: 'Id'},
      {field: 'structure', header: 'Structure'},
      {field: 'nbAttempt', header: 'Essais'},
      {field: 'firstAttempt', header: 'Première tentative'},
      {field: 'lastAttempt', header: 'Dernière tentative'},
      {field: 'operation', header: 'Operation'},
      {field: 'description', header: 'Description'},
      {field: 'etat', header: 'Etat'},
    ];
    this.selectedRow = null;

  }

  ngOnChanges() {
    if (this.syncRefAqTasks) {
      this.datasource = [];
      this.syncRefAqTasks.forEach(t => this.datasource.push(this.convertToDataTable(t)));
    }
  }

  onRowSelected(row: any, isRedoMode: boolean) {
    this.selectedRow = row;
    this.openDialog(isRedoMode);
  }

  openDialog(isRedoMode: boolean) {
    this.dialog.open(ConfirmSyncReferentielAquitainDialogComponent, {
      minWidth: 400,
      data: {
        question: isRedoMode ? 'Etes-vous vraiment sûr de refaire cette action de synchronisation ?' : 'Formulaire d\'ack de la tâche',
        isRedoMode
      }
    }).afterClosed().subscribe(res => {
      if (res.ok) {
        isRedoMode ? this.redoSynchro() : this.forceSynchro(res.commentaire);
      }
    });
  }

  redoSynchro() {
    this.loaderService.start();
    this.syncRefAqService.redoSynchro(this.selectedRow.syncId).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  forceSynchro(commentaire: string) {
    this.loaderService.start();
    this.syncRefAqService.forceSynchro(this.selectedRow.syncId, commentaire).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  convertToDataTable(task: SyncRefAqTask) {
      return {
        structure: task.lien.structure,
        syncId: task.syncRefAqTaskId,
        libelle: task.lien.structure.identite.libelleLong,
        nbAttempt: task.nbAttempt,
        firstAttempt: getDDMMYYYYHHmm(task.firstAttempt),
        lastAttempt: getDDMMYYYYHHmm(task.lastAttempt),
        operation: task.typeSync,
        description: task.description,
        etat: task.etatSync,
      };
  }

}
