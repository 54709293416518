import {Component, OnInit} from '@angular/core';
import {StructureService} from '../../../services/structure.service';
import {AdresseService} from '../../../services/adresse.service';
import {LoaderService} from '../../../services/loader.service';
import {TypeAdresseService} from '../../../services/type-adresse.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {TypeAdresse} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-type-adresse',
  templateUrl: './type-adresse.component.html',
  styleUrls: ['./type-adresse.component.scss']
})
export class TypeAdresseComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: TypeAdresse[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private adresseService: AdresseService,
              private structureService: StructureService,
              private snackBar: MatSnackBar,
              private typeAdresseService: TypeAdresseService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(typeAdresse: TypeAdresse) {
    typeAdresse.hasError = false;

    if (!typeAdresse.typeAdresseLib || typeAdresse.typeAdresseLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeAdresse.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, typeAdresse.typeAdresseLib, 'typeAdresseLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeAdresse.hasError = true;
    }
  }

  onIsMultipleChanged(typeAdresse: TypeAdresse) {
    typeAdresse.isMultiple = !typeAdresse.isMultiple;

    if (!typeAdresse.isMultiple) {
      this.adresseService.existsMultiAdresseByTypeAdresse(typeAdresse.typeAdresseId).subscribe(
        res => {
          if (res) {
            typeAdresse.isMultiple = true;
          }
        });
    }
  }

  onIsValidChanged(typeAdresse: TypeAdresse) {
    typeAdresse.isValid = !typeAdresse.isValid;

    if (!typeAdresse.isValid) {
      if (typeAdresse.typeAdresseId) {
        this.structureService.getStructuresByTypeAdresse(typeAdresse.typeAdresseId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                'Le type d\'adresse ' + typeAdresse.typeAdresseLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
              typeAdresse.isValid = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(typeAdresse, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        typeAdresseId: null,
        typeAdresseLib: null,
        isMultiple: false,
        isValid: true,
        isHidden: false,
        hasError: true
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.typeAdresseService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.typeAdresseService.getAllTypesAdresse().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }

}
