import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CompteAppli} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class CompteAppliService {

  constructor(private http: HttpClient) {
  }

  public getComptesAppliByIndividu(individuId: number): Observable<CompteAppli[]> {
    return this.http.get<CompteAppli[]>('/compte-appli/individu/' + individuId);
  }

  public getAllComptesAppli(): Observable<CompteAppli[]> {
    return this.http.get<CompteAppli[]>('/compte-appli/all');
  }

  public doesLoginAlreadyExist(login: string): Observable<boolean> {
    return this.http.get<boolean>('/compte-appli/login-exists/' + login);
  }

  public generateNewPassword(compteAppliId: number): Observable<boolean> {
    return this.http.get<boolean>('/compte-appli/generate-new-password/' + compteAppliId);
  }

  public updateMulti(compteAppli: CompteAppli[]): Observable<void> {
    return this.http.put<void>('/compte-appli/update-multi', compteAppli);
  }

  public update(compteAppli: CompteAppli, alert: boolean): Observable<void> {
    return this.http.put<void>('/compte-appli/update?alert=' + alert, compteAppli);
  }

  public createCompteAppli(compteAppli: CompteAppli): Observable<CompteAppli> {
    return this.http.put<CompteAppli>('/compte-appli/create', compteAppli);
  }

  public deleteCompteAppli(compteAppliId: number): Observable<void> {
    return this.http.delete<void>('/compte-appli/delete/' + compteAppliId);
  }
}
