import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TypeAdresse, TypeVoierie} from '../model/nomenclature.model';

@Injectable()
export class TypeAdresseService {

  constructor(private http: HttpClient) {
  }

  public getAllTypesAdresse(): Observable<TypeAdresse[]> {
    return this.http.get<TypeAdresse[]>('/type-adresse/all');
  }

  public getAvailablesTypesAdresseByStructure(structureId: number): Observable<TypeAdresse[]> {
    return this.http.get<TypeAdresse[]>('/type-adresse/available/structure/' + structureId);
  }

  public save(typesAdresse: TypeAdresse[]): Observable<TypeVoierie[]> {
    return this.http.put<TypeVoierie[]>('/type-adresse/save', typesAdresse);
  }
}
