import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Adresse, Structure} from '../../../model/structure.model';
import {AdresseService} from '../../../services/adresse.service';
import {MapDialogComponent} from '../../../layout/dialog/divers/map-dialog/map-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-adresse-card',
  templateUrl: './adresse-card.component.html',
  styleUrls: ['./adresse-card.component.scss']
})
export class AdresseCardComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() selected: boolean;
  @Input() structure: Structure;
  @Input() adresse: Adresse;
  @Input() updateMode: boolean;

  constructor(private adresseService: AdresseService,
              public dialog: MatDialog) {
  }

  displayMap(adresse: Adresse) {
    this.dialog.open(MapDialogComponent, {
      minWidth: 400,
      data: {
        adresse
      }
    });
  }

  toggleRemove() {
    this.adresse.isValid = false;
    this.adresseService.save(this.adresse).subscribe(() => this.actionEmitter.emit())
  }

}
