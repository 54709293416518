<div class="row buffer justify-content-center">
  <mat-card class="col-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de recherche</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Structures</mat-label>
          <mat-select formControlName="structures" placeholder="Nouvelles structures" multiple>
            <mat-option appHighlightStructure [structure]="struct"
                        *ngFor="let struct of structures"
                        [value]="struct.structureId">
              {{struct | libelleStructure:user}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Types de lien</mat-label>
          <mat-select formControlName="typesLien" placeholder="Types de lien" multiple>
            <mat-option *ngFor="let t of typesLien" [value]="t.typeLienId">{{t.typeLienLib}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Structures Antécédentes</mat-label>
          <mat-select formControlName="structuresPorteuses" placeholder="Structures antécédentes" multiple>
            <mat-option appHighlightStructure [structure]="struct"
                        *ngFor="let struct of structures"
                        [value]="struct.structureId">
              {{struct | libelleStructure:user}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div class="row justify-content-center">
        <button class="mt-2 ml-2" mat-raised-button color="primary" matTooltip="Filtrer"
                (click)="search()"><i class="fas fa-search"></i></button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
