<h1 mat-dialog-title>Liste des structures en attente de validation
  par {{this.data.individu.prenom + ' ' + this.data.individu.nom}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p class="alert-warning" *ngIf="structures?.length === 0">Aucune structure à afficher</p>

  <div *ngIf="structures?.length > 0">
    <ul>
      <li *ngFor="let struct of structures">
        <a href
           appHighlightStructure
           [structure]="struct"
           [routerLink]="'/structure/' +  struct.structureId">{{struct | libelleStructure:user }}</a>
      </li>
    </ul>
  </div>
</div>
