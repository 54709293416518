<ul [ngClass]="getNavbarClasses()">

  <!--ADMINISTRATION-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    [matTooltipDisabled]="user.individu?.isAdmin">
    <li class="nav-item dropdown" [class.disabled]="!user.individu?.isAdmin">
      <a class="nav-link dropdown-toggle"
         id="admDropdown"
         role="button"
         data-toggle="dropdown"
         tabIndex="0"
         aria-haspopup="true"
         aria-expanded="false">Administration</a>

      <div class="dropdown-menu" aria-labelledby="admDropdown">
        <a class="dropdown-item" [routerLink]="'administration/logs'">Consultation des logs</a>
        <a class="dropdown-item" [routerLink]="'administration/synchro-ref-aq'">Synchronisation du référentiel
          aquitain</a>
        <a class="dropdown-item" [routerLink]="'administration/synchro-appli-si'">Synchronisation des applications du
          SI</a>
        <a class="dropdown-item" [routerLink]="'administration/archivage-structure'">Gestion des structures à
          archiver</a>
        <a class="dropdown-item" [routerLink]="'administration/liens-antecedents'">Gestion des liens antécédents</a>
        <a class="dropdown-item" [routerLink]="'administration/comptes-applicatifs'">Gestion des comptes applicatifs</a>
        <a class="dropdown-item" [routerLink]="'administration/surveillance-connexions'">Surveillance des connexions</a>
      </div>
    </li>
  </div>

  <!--GESTION DES NOMENCLATURES-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    tabIndex="0"
    [matTooltipDisabled]="user.individu?.isAdmin">
    <li class="nav-item dropdown" [class.disabled]="!user.individu?.isAdmin">
      <a class="nav-link dropdown-toggle"
         id="nomDropdown"
         role="button"
         data-toggle="dropdown"
         tabIndex="0"
         aria-haspopup="true"
         aria-expanded="false">Gestion des nomenclatures</a>

      <div class="dropdown-menu ajust-size" aria-labelledby="nomDropdown">
        <a class="dropdown-item" [routerLink]="'nomenclatures/applis-si'">
          Applications du SI
          <span class="float-right">
            <span class="font-primary">{{applisValides}}</span>/<span class="font-accent">{{applisInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/domaines'">
          Domaines d'activité
          <span class="float-right">
            <span class="font-primary">{{domainesValides}}</span>/<span class="font-accent">{{domainesInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/instance'">
          Instances
          <span class="float-right">
            <span class="font-primary">{{instancesValides}}</span>/<span class="font-accent">{{instancesInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/tutelles'">
          Etabl. de tutelle
          <span class="float-right">
            <span class="font-primary">{{tutellesValides}}</span>/<span class="font-accent">{{tutellesInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/types-adresse'">
          Types d'adresse
          <span class="float-right">
            <span class="font-primary">{{typesAdresseValides}}</span>/<span class="font-accent">{{typesAdresseInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/types-code'">
          Types de code
          <span class="float-right">
            <span class="font-primary">{{typesCodeValides}}</span>/<span class="font-accent">{{typesCodeInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/types-lien'">
          Types de lien
          <span class="float-right">
            <span class="font-primary">{{typesLienValides}}</span>/<span class="font-accent">{{typesLienInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/types-structure'">
          Types de structures
          <span class="float-right">
            <span class="font-primary">{{typesStructValides}}</span>/<span class="font-accent">{{typesStructInvalides}}</span>
          </span>
        </a>
        <a class="dropdown-item" [routerLink]="'nomenclatures/types-voierie'">
          Types de voierie
          <span class="float-right">
            <span class="font-primary">{{typesVoierieValides}}</span>/<span class="font-accent">{{typesVoierieInvalides}}</span>
          </span>
        </a>
      </div>
    </li>
  </div>

  <!--GESTION DES DEMANDES-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    tabIndex="0"
    [matTooltipDisabled]="user.individu.isGestionnaire ||  user.individu.isResponsable || user.individu.isAdmin || user.individu.isModerateur">
    <li class="nav-item dropdown" [class.disabled]="!(user.individu.isGestionnaire ||  user.individu.isResponsable || user.individu.isAdmin || user.individu.isModerateur)">
      <a class="nav-link dropdown-toggle"
         id="demDropdown"
         role="button"
         data-toggle="dropdown"
         tabIndex="0"
         aria-haspopup="true"
         aria-expanded="false">Gestion des demandes</a>

      <div class="dropdown-menu" aria-labelledby="demDropdown">
        <a class="dropdown-item" [routerLink]="'demandes/gestion-demandes/' + groupesDemande.DEMANDES_EN_COURS">
          Consultation des demandes</a>
        <div
          [matTooltip]="user.individu.isGestionnaire ||  user.individu.isResponsable ? 'Vous ne disposez pas actuellement de structures actives dans votre périmètre de privilèges pour pouvoir effectuer une action' : 'Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir'"
          [matTooltipDisabled]="user.individu?.isGestionnaire || user.individu?.isResponsable || user.individu?.isAdmin">
          <a class="dropdown-item" [routerLink]="'demandes/creation-structure-organisationnelle'"
             [class.disabled]="!(user.individu?.isGestionnaire || user.individu?.isResponsable || user.individu?.isAdmin)">
            Création d'une structure organisationnelle</a>

          <a class="dropdown-item" [routerLink]="'demandes/creation-structure-matricielle'"
             [class.disabled]="!(user.individu.isGestionnaire || user.individu.isResponsable || user.individu.isAdmin)">
            Création d'une structure matricielle</a>
        </div>
      </div>
    </li>
  </div>

  <!--GESTION DES PRIVILEGES-->
  <div
    [matTooltip]="!user.individu.isGestionnaire && !user.individu.isResponsable ? 'Vous ne disposez pas actuellement de structures actives dans votre périmètre de privilèges pour pouvoir effectuer une action' : 'Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir'"
    [matTooltipDisabled]="user.individu.isAdmin || user.individu?.isGestionnaire || user.individu.isResponsable">
    <li class="nav-item dropdown"
        [class.disabled]="!user.individu.isAdmin && !user.individu.isGestionnaire && !user.individu.isResponsable && ! user.individu.isModerateur">
      <a class="nav-link dropdown-toggle"
         tabIndex="0"
         id="privDropdown" role="button"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false">Gestion des privilèges</a>

      <div class="dropdown-menu" aria-labelledby="privDropdown">
        <a class="dropdown-item" [routerLink]="'privilege/privileges-par-structure'">Privilèges par structure</a>
        <a class="dropdown-item" [routerLink]="'privilege/ajout-gestionnaire'">Privilèges par personne</a>

        <div
          matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
          [matTooltipDisabled]="user.individu.isAdmin">
          <a class="dropdown-item" [class.disabled]="!user.individu.isAdmin"
             [routerLink]="'privilege/gestion-administrateur-moderateur'">
            Gestion des administrateurs et modérateurs</a>
        </div>
      </div>
    </li>
  </div>

  <!--MES STRUCTURES-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    [matTooltipDisabled]="user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable">
    <a class="nav-link" tabIndex="0" href [routerLink]="'structure'"
       [class.disabled]="!(user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable)">
      Mes structures</a>
  </div>

  <!--RECHERCHE-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    [matTooltipDisabled]="user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable">
    <a class="nav-link"
       tabIndex="0"
       href [routerLink]="'recherche'"
       [class.disabled]="!(user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable)">Recherche</a>
  </div>

  <!--AIDE-->
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    [matTooltipDisabled]="user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable">
    <a class="nav-link" tabIndex="0" href [routerLink]="'aide'"
       [class.disabled]="!(user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable)">
      Aide</a>
  </div>
</ul>
