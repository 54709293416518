<div class="row justify-content-center mt-4">
  <h1 class="title">Archivage des structures</h1>
</div>

<div class="row justify-content-center buffer" *ngIf="datasource">
  <mat-card class="col-lg-12" *ngIf="datasource.length === 0">
    <h4 class="text-center">Aucune structures à archiver</h4>
  </mat-card>

  <p-table class="col-lg-8"
           dataKey="structureId"
           [columns]="cols"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rows"
           [showCurrentPageReport]="true"
           [(first)]="first"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptions"
           *ngIf="datasource.length > 0">
    <ng-template pTemplate="caption">Liste des structures à archiver</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" pSortableColumn="libelleLong">Structure
          <p-sortIcon field="libelleLong"></p-sortIcon>
        </th>
        <th class="text-center" pSortableColumn="dateFermeture">Date de fermeture
          <p-sortIcon field="dateFermeture"></p-sortIcon>
        </th>
        <th class="text-center" style="width:6em">Modifier</th>
        <th class="text-center" style="width:6em">Archiver</th>
        <th class="text-center" style="width:6em">Réactiver</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>
          <a href appHighlightStructure
             [structure]="row.structure"
             [routerLink]="'/structure/' +  row.structure.structureId">
            {{row['structure'] | libelleStructure:user}}</a>
        </td>
        <td>{{row['dateFermeture']}}</td>

        <td class="text-center">
          <button class="button" matTooltip="modifier la date de fermeture" (click)="onClick(row, action.UPDATE)">
            <i class="fas fa-edit fa-2x"></i>
          </button>
        </td>
        <td class="text-center">
          <button class="button" matTooltip="archiver" (click)="onClick(row, action.ARCHIVE)">
            <i class="fas fa-redo fa-2x"></i>
          </button>
        </td>
        <td class="text-center">
          <button class="button" matTooltip="reactiver" (click)="onClick(row, action.REACTIVATE)">
            <i class="fas fa-arrow-alt-circle-up fa-2x"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
