import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {Structure} from '../model/structure.model';
import {ETAT_STRUCTURE} from '../model/enum.model';

@Directive({
  selector: '[appHighlightStructure]'
})
export class HighlightStructureDirective implements OnChanges {

  @Input() structure: Structure;
  @Input() active = true;

  constructor(private el: ElementRef) {
  }

  ngOnChanges() {
    this.highlight(this.structure);
  }


  private highlight(structure: Structure) {
    if (this.el.nativeElement.style && structure && this.active) {
      if (structure.etatStructure === ETAT_STRUCTURE.ACTIF) {
        this.el.nativeElement.style.color = '#03A9F4';
      } else {
        this.el.nativeElement.style.color = '#FF9800';
      }
    }
  }
}

