import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InstanceDemande} from '../model/demande.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstanceDemandeService {

  constructor(private http: HttpClient) {
  }

  getInstanceDemandeByDemandeId(demandeId: number): Observable<InstanceDemande> {
    return this.http.get<InstanceDemande>('/instance-demande/demande/' + demandeId);
  }

  getInstancesDemandeByStructureId(structureId: number): Observable<InstanceDemande[]> {
    return this.http.get<InstanceDemande[]>('/instance-demande/structure/' + structureId);
  }
}
