<div>
  <div class="row justify-content-center">
    <h1 class="title title-structure" appHighlightStructure [structure]="structure">
      {{structure.identite.libelleLong}}
    </h1>
  </div>

  <div class="row justify-content-center">
    <h5 class="title subtitle mt-4">
      <span *ngIf="ascList?.length > 0">
          <span class="ml-2 clickable"
                id="ascendant-panel"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"><i class="fas fa-ellipsis-h "></i></span>
          <i class="fas fa-caret-right ml-2 mr-2"></i>

          <ul class="dropdown-menu" aria-labelledby="ascendant-panel">
            <li class="ml-2 mr-2" *ngFor="let asc of ascList">
              <span appHighlightStructure
                    [structure]="asc"
                    *ngIf="!(user.individu.isAdmin || isInUserGestRespPerimeter(asc))">
                {{asc | libelleStructure:user}}</span>
              <a href
                 appHighlightStructure
                 [structure]="asc"
                 [routerLink]="'/structure/' +  asc.structureId"
                 *ngIf="user.individu.isAdmin || isInUserGestRespPerimeter(asc)">
                {{asc | libelleStructure:user}}</a>
            </li>
          </ul>
      </span>

      <ng-container *ngFor="let asc of ascendants">
        <span appHighlightStructure
              [structure]="asc"
              *ngIf="!(user.individu.isAdmin || isInUserGestRespPerimeter(asc))">
                {{asc | libelleStructure:user}}</span>
        <a href
           appHighlightStructure
           [structure]="asc"
           [routerLink]="'/structure/' +  asc.structureId"
           *ngIf="user.individu.isAdmin || isInUserGestRespPerimeter(asc)">
          {{asc | libelleStructure:user}}</a>

        <span class="ml-2 mr-2"><i class="fas fa-caret-right"></i></span>
      </ng-container>

      <span appHighlightStructure [structure]="structure">{{getLibelle()}}</span>

      <span *ngIf="children?.length > 0">
          <i class="fas fa-caret-right ml-2"></i>
          <span class="ml-2 clickable"
                id="children-panel"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"><i class="fas fa-ellipsis-h "></i></span>

          <ul class="dropdown-menu" aria-labelledby="children-panel">
            <li class="ml-2 mr-2" *ngFor="let child of children">
              <span appHighlightStructure
                    [structure]="child"
                    *ngIf="!(user.individu.isAdmin || isInUserGestRespPerimeter(child))">
                {{child | libelleStructure:user}}</span>
              <a href appHighlightStructure
                 [structure]="child"
                 [routerLink]="'/structure/' +  child.structureId"
                 *ngIf="user.individu.isAdmin || isInUserGestRespPerimeter(child)">
                {{child | libelleStructure:user}}</a>
            </li>
          </ul>
    </span>
    </h5>
  </div>
</div>
