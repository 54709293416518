import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Adresse, Structure} from '../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as L from 'leaflet';
import {AdresseService} from '../../../services/adresse.service';
import {Map, Icon, IconOptions, LatLng, Marker} from 'leaflet';
import {TypeVoierieService} from '../../../services/type-voierie.service';
import {TypeAdresseService} from '../../../services/type-adresse.service';
import {TypeAdresse, TypeVoierie} from '../../../model/nomenclature.model';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-adresse-form',
  templateUrl: './adresse-form.component.html',
  styleUrls: ['./adresse-form.component.scss']
})
export class AdresseFormComponent implements OnInit, OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() adresse: Adresse;

  typeAdresses: TypeAdresse[];
  typesVoierie: TypeVoierie[];

  adresses: Adresse[];
  adressesPorteuses: Adresse[];
  adressePreremplie: Adresse;

  map: Map;
  icon: Icon<IconOptions>;
  marker: Marker;
  form: FormGroup;

  constructor(private adresseService: AdresseService,
              private typeAdresseService: TypeAdresseService,
              private typeVoierieService: TypeVoierieService,
              private loaderService: LoaderService) {
    this.form = new FormGroup({
      typeAdresse: new FormControl(null, Validators.required),
      pointGeo: new FormControl(null),
      num: new FormControl(null, [Validators.pattern('^[0-9]{0,5}$')]),
      compl: new FormControl(null),
      typeVoierie: new FormControl(null),
      libelleVoie: new FormControl(null,
        [Validators.required, Validators.pattern('^([^?!.,;()[\\]{}_\\/:][a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\'\\-\\s]{1,30})$')]),
      mentionSpec: new FormControl(null,
        [Validators.pattern('^([^?!.,;()[\\]{}_\\/:][a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\'\\-\\s]{1,30})$')]),
      cp: new FormControl(null, [Validators.required, Validators.pattern('^(([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$')]),
      bureauDistrib: new FormControl(null,
        [Validators.required, Validators.pattern('^([^?!.,;()[\\]{}_\\/:][a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\'\\-\\s]{1,32})$')]),
    });
  }

  ngOnInit() {
    this.typeVoierieService.getAllTypesVoierie().subscribe(res => {
      this.typesVoierie = res.filter(t => t.isValid);
      this.typesVoierie.unshift(new TypeVoierie(null));
    });

    this.initMap();
  }

  ngOnChanges() {
    this.form.reset();

    if (this.structure) {
      Promise.all([this.getAllAdresses(), this.getAllAdressesPorteuses(), this.getTypesAdresse()]).then(() => {
        if (this.adresse.typeAdresse) {
          if (this.typeAdresses.filter(t => t.typeAdresseId === this.adresse.typeAdresse.typeAdresseId).length === 0) {
            this.typeAdresses.push(this.adresse.typeAdresse);
          }
          this.fill(this.adresse);
        }

        this.getAdressePreremplie();
      });
    }
  }

  onTypeAdresseChange() {
    this.getAdressePreremplie();
  }

  onBureauDistribInputChange() {
    const input = this.form.controls.bureauDistrib.value;
    this.form.controls.bureauDistrib.setValue(input.toUpperCase());
  }

  checkBlanks(formControleName: string) {
    const fc = this.form.controls[formControleName];
    if (fc.value && fc.value.includes('  ')) {
      fc.setErrors({checkBlanks: true});
    }
  }

  fill(adresse: Adresse) {
    if (adresse) {
      this.form.controls.typeAdresse.setValue(adresse.typeAdresse ? adresse.typeAdresse.typeAdresseId : null);
      this.form.controls.pointGeo.setValue(adresse.pointGeo);
      this.form.controls.num.setValue(adresse.num);
      this.form.controls.compl.setValue(adresse.compl);
      this.form.controls.typeVoierie.setValue(adresse.typeVoierie ? adresse.typeVoierie.typeVoierieId : null);
      this.form.controls.libelleVoie.setValue(adresse.libelleVoie);
      this.form.controls.mentionSpec.setValue(adresse.mentionSpec);
      this.form.controls.cp.setValue(adresse.cp);
      this.form.controls.bureauDistrib.setValue(adresse.bureauDistrib);

      if (adresse.latitude && adresse.longitude) {
        this.setMapView(new LatLng(adresse.latitude, adresse.longitude));
      }
    }
  }

  initMap() {
    const latLng: LatLng = new LatLng(44.80934357475283, -0.5939936807742718);
    this.map = L.map('map').setView(latLng, 15);
    this.icon = L.icon({
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/marker-icon.png',
      iconSize: [24, 36],
      iconAnchor: [12, 36]
    });

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: 'Université de Bordeaux'}).addTo(this.map);

    this.marker = L.marker(latLng, {
      icon: this.icon,
      draggable: true
    }).bindPopup(this.structure.identite.libelleLong).addTo(this.map).openPopup();

    const markerBounds = L.latLngBounds([this.marker.getLatLng()]);
    this.map.fitBounds(markerBounds);
  }

  setMapView(latLng: LatLng) {
    this.marker.setLatLng(latLng);
    this.map.setView(latLng, 15);
    const markerBounds = L.latLngBounds([this.marker.getLatLng()]);
    this.map.fitBounds(markerBounds);
  }

  save() {
    const adresse: Adresse = {
      adresseId: this.adresse ? this.adresse.adresseId : null,
      structureId: this.structure.structureId,
      typeAdresse: new TypeAdresse(this.form.controls.typeAdresse.value),
      pointGeo: this.form.controls.pointGeo.value,
      num: this.form.controls.num.value,
      compl: this.form.controls.compl.value,
      typeVoierie: this.form.controls.typeVoierie.value ? new TypeVoierie(this.form.controls.typeVoierie.value) : null,
      libelleVoie: this.form.controls.libelleVoie.value,
      mentionSpec: this.form.controls.mentionSpec.value,
      cp: this.form.controls.cp.value,
      bureauDistrib: this.form.controls.bureauDistrib.value,
      latitude: this.marker.getLatLng().lat,
      longitude: this.marker.getLatLng().lng,
      isValid: true
    };

    this.loaderService.start();
    this.adresseService.save(adresse).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  private getAllAdresses(): Promise<void> {
    return new Promise<void>(resolve => {
      this.adresseService.getAdressesByStructure(this.structure.structureId).subscribe(adresses => {
        this.adresses = adresses;
        resolve();
      });
    });
  }

  private getAllAdressesPorteuses(): Promise<void> {
    return new Promise<void>(resolve => {
      this.adresseService.getAdressesPorteusesByStructure(this.structure.structureId).subscribe(adressesPorteuses => {
        this.adressesPorteuses = adressesPorteuses;
        resolve();
      });
    });
  }

  private getTypesAdresse(): Promise<void> {
    return new Promise<void>(resolve => {
      this.typeAdresseService.getAvailablesTypesAdresseByStructure(this.structure.structureId).subscribe(res => {
        this.typeAdresses = res;
        resolve();
      });
    });
  }

  private getAdressePreremplie() {
    let adr: Adresse = null;

    const typeAdresseId = this.form.controls.typeAdresse.value;

    if (this.adresses.length > 0) {
      adr = this.adresses[0];

      if (typeAdresseId) {
        const adressesFiltrees = this.adresses.filter(a => a.typeAdresse.typeAdresseId === this.form.controls.typeAdresse.value);
        if (adressesFiltrees.length > 0) {
          adr = adressesFiltrees[0];
        }
      }

    } else if (this.adressesPorteuses.length > 0) {
      adr = this.adressesPorteuses[0];
      if (typeAdresseId) {
        const adressesPorteusesFiltrees = this.adressesPorteuses.filter(a => a.typeAdresse.typeAdresseId === this.form.controls.typeAdresse.value);
        if (adressesPorteusesFiltrees.length > 0) {
          adr = adressesPorteusesFiltrees[0];
        }
      }
    }
    if (adr) {
      adr.typeAdresse.typeAdresseId = typeAdresseId;
    }
    this.adressePreremplie = adr;
  }


}
