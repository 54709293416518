<mat-card class="card" [class.hover]="updateMode" [class.selected]="selected && updateMode">
  <div class="chip-btn card-btn-delete" (click)="toggleRemove()" *ngIf="updateMode"><i class="fas fa-times"></i></div>

  <span class="float-right" *ngIf="contact.nomPrenom"><i class="fas fa-user fa-2x"></i></span>
  <span class="float-right" *ngIf="!contact.nomPrenom"><i class="fas fa-university fa-2x"></i></span>

  <mat-card-header>
    <h4>{{contact.intituleContact}}</h4>
  </mat-card-header>

  <mat-card-content class="content">
    <table class="no-border">
      <tr>
        <td class="font-weight-bold pr-2">Nom complet :</td>
        <td>{{contact.nomPrenom}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold pr-2">Adresse email :</td>
        <td>{{contact.mail}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold pr-2">Téléphone mobile :</td>
        <td>{{contact.mobile}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold  pr-2">Téléphone fixe :</td>
        <td>{{contact.telephone}}</td>
      </tr>
      <tr>
        <td class="font-weight-bold  pr-2">Fax :</td>
        <td>{{contact.fax}}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
