<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4" >
  <form [formGroup]="form">
      <!--LIBELLE-->
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Libellé</mat-label>
        <input formControlName="libelle" matInput placeholder="Libellé"
               maxlength="250">
        <mat-error *ngIf="form.controls['libelle'].hasError('required') && form.controls['libelle'].touched">
          Valeur requise
        </mat-error>
      </mat-form-field>

      <!--DESCRIPTION-->
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>description</mat-label>
        <textarea matInput formControlName="description" rows="4" placeholder="Description" required></textarea>
        <mat-error *ngIf="form.controls['description'].hasError('required')  && form.controls['description'].touched">
          Valeur requise
        </mat-error>
      </mat-form-field>

      <!--TYPE MIME -->
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Type de media</mat-label>
        <mat-select formControlName="typeMime" placeholder="Type de média" required>
          <mat-option *ngFor="let t of typeMime |keyvalue" [value]="t.value">{{ t.key}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['typeMime'].hasError('required') && form.controls['typeMime'].touched">
          Valeur requise
        </mat-error>
      </mat-form-field>

      <!--URL-->
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Url</mat-label>
        <input formControlName="url" matInput placeholder="Url"
               maxlength="2048">
        <mat-error *ngIf="form.controls['url'].hasError('required') && form.controls['url'].touched">
          Valeur requise
        </mat-error>
      </mat-form-field>

      <!--IS VALID-->
<!--      <mat-checkbox formControlName="isValid" class="col-12"-->
<!--                    (change)="form.controls.isValid.setValue($event.checked)"-->
<!--                    [checked]="form.controls.isValid.value">Afficher-->
<!--      </mat-checkbox>-->
  </form>

  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" [disabled]="form.invalid"
            (click)="validate()">
      <i class="fas fa-check fa-2x"></i>
    </button>
    <button mat-raised-button color="accent" (click)="onCancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</div>
