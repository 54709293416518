<div class="row justify-content-center">
  <mat-card class="col-lg-12 mt-4" *ngIf="adresses?.length === 0 && contacts?.length === 0 && !updateMode">
    <h4 class="text-center">Vous n'avez pas encore saisi de contacts et d'adresses</h4>
  </mat-card>
</div>

<div class="row justify-content-center mt-4">
  <div matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
       [matTooltipDisabled]="user.individu.isAdmin
          || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
    <button class="btn-white ml-2 mt-2" mat-raised-button
            (click)="modify()"
            [disabled]="!(user.individu.isAdmin ||
              (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure))">
      {{!updateMode ? 'Saisir / Modifier les adresses et les contacts' : 'Annuler toutes les modifications'}}
    </button>
  </div>
</div>

<div class="row justify-content-center mt-4" *ngIf="updateMode">
  <button mat-raised-button color="primary" (click)="createAdresse()">Ajouter une
    adresse
  </button>
  <button class="ml-2" mat-raised-button color="primary" (click)="createContact()">
    Ajouter un contact
  </button>
</div>

<app-adresse-form [structure]="structure"
                  [adresse]="selectedAdresse"
                  (actionEmitter)="init()"
                  *ngIf="updateMode && selectedAdresse"></app-adresse-form>

<app-contact-form [readonly]="!updateMode"
                  [structure]="structure"
                  [contact]="selectedContact"
                  (actionEmitter)="init()"
                  *ngIf="updateMode && selectedContact"></app-contact-form>

<!-- ADRESSES -->
<div class="row mt-4" *ngIf="adresses.length > 0">
  <div class="top-buffer left-buffer" *ngFor="let adresse of adresses; let i = index">
    <app-adresse-card [selected]="i === indexAdresse"
                      [structure]="structure"
                      [adresse]="adresse"
                      [updateMode]="updateMode"
                      (actionEmitter)="init()"
                      (click)="selectAdresse(adresse, i)"></app-adresse-card>
  </div>
</div>

<!-- CONTACTS -->
<div class="row mt-4" *ngIf="contacts.length > 0">
  <div class="top-buffer left-buffer" *ngFor="let contact of contacts; let i = index">
    <app-contact-card [selected]="i === indexContact"
                      [contact]="contact"
                      [updateMode]="updateMode"
                      (actionEmitter)="init()"
                      (click)="selectContact(contact, i)"></app-contact-card>
  </div>
</div>
