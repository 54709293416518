import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {LdapAccount, Privilege, Role, User} from '../../../model/user.model';
import {RoleService} from '../../../services/role.service';
import {Structure} from '../../../model/structure.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {ModificationResponsableRequest} from '../../../model/workflow.model';
import {FormControl, Validators} from '@angular/forms';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {ETAT_STRUCTURE, ETAT_VALID, TYPE_ROLE} from '../../../model/enum.model';
import {LienService} from '../../../services/lien.service';
import * as moment from 'moment';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-ajout-responsable',
  templateUrl: './ajout-responsable.component.html',
  styleUrls: ['./ajout-responsable.component.scss']
})
export class AjoutResponsableComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() title: string;
  @Input() structure: Structure;
  @Input() readonly = false;

  user: User;
  etatStructure = ETAT_STRUCTURE;

  dateDebut: FormControl;
  selectedldapAccount: LdapAccount;
  responsables: Role[];
  responsableDemande: Role;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private lienService: LienService,
              private roleService: RoleService,
              private workflowService: WorkflowService) {
    this.user = this.userService.getCurrentUser();
    this.dateDebut = new FormControl(null, Validators.required);
  }

  ngOnChanges() {
    this.init();
  }

  onPersonSelected(ldapAccount: LdapAccount) {
    this.selectedldapAccount = ldapAccount;

   // todo ajouter date début input + bouton valider
    if (this.structure && [ETAT_STRUCTURE.EMBRYON, ETAT_STRUCTURE.NOUVEAU].includes(this.structure.etatStructure)) {
      this.loaderService.start();

      const role: Role = {
        typeRole: TYPE_ROLE.RESPONSABLE,
        structureId: this.structure.structureId,
        dateDebut: this.structure.etatStructure === ETAT_STRUCTURE.EMBRYON ? this.structure.identite.dateOuverture :
          moment(this.structure.identite.dateOuverture).isAfter(new Date()) ? this.structure.identite.dateOuverture : new Date(),
        isExtended: true,
        etatValid: ETAT_VALID.A_VALIDER
      };

      const privilege: Privilege = {role, ldapAccountDTO: this.selectedldapAccount};
      this.roleService.create(privilege).subscribe(() => {
        this.init();
        this.loaderService.stop();
      });
    }
  }

  createDemandeModificationResponsable() {
    const modificationResponsableRequest: ModificationResponsableRequest = {
      demandeId: null,
      motif: null,
      ldapAccountDTO: this.selectedldapAccount,
      structureId: this.structure.structureId,
      dateDebut: formatLocalDateWithUTC(this.dateDebut.value)
    };

    this.loaderService.start();
    this.workflowService.updateResponsable(modificationResponsableRequest).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  init() {
    if (this.structure) {
      this.roleService.getAllRolesByStructureAndTypeRole(this.structure.structureId, TYPE_ROLE.RESPONSABLE).subscribe(res => {
        this.responsables = res.filter(r => r.etatValid === ETAT_VALID.VALIDE && (!r.dateFin || !moment(r.dateFin).isBefore(new Date())));
        this.responsableDemande = res.filter(r => r.etatValid === ETAT_VALID.A_VALIDER)[0];
      });
    }
  }
}
