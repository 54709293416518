import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Aide} from '../../../../model/divers.model';
import {TYPE_MIME} from '../../../../constants/enum.constant';

@Component({
  selector: 'app-aide-display-dialog',
  templateUrl: './aide-display-dialog.component.html',
  styleUrls: ['./aide-display-dialog.component.scss']
})
export class AideDisplayDialogComponent {

  typeMime = TYPE_MIME;

  constructor(public dialogRef: MatDialogRef<AideDisplayDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  close() {
    this.dialogRef.close();
  }


}

export interface DialogData {
  aide: Aide;
}
