import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Instance} from '../model/nomenclature.model';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  constructor(private http: HttpClient) {
  }

  public getAllInstances(): Observable<Instance[]> {
    return this.http.get<Instance[]>('/instance/all');
  }

  public save(instances: Instance[]): Observable<Instance[]> {
    return this.http.put<Instance[]>('/instance/save', instances);
  }


}
