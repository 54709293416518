<form [formGroup]="form">
  <div class="row">
    <!--INSTANCES -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Instance</mat-label>
      <mat-select formControlName="instance" placeholder="Instance">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let i of instances" [value]="i.instanceId">{{ i.instanceLib}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date du déroulement de l'instance</mat-label>
      <input formControlName="dateInstance" matInput [matDatepicker]="ouvpicker"
             placeholder="Date de déroulement de l'instance">
      <mat-datepicker-toggle matSuffix [for]="ouvpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #ouvpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateInstance'].invalid && form.controls['dateInstance'].touched">Format non valide</mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <p-fileUpload #fileUpload class="col-lg-6" name="myFile[]" [disabled]="readonly"
                  chooseLabel="Choisir un fichier"
                  customUpload="true"
                  auto="true"
                  showUploadButton="false"
                  showCancelButton="false"
                  (onRemove)="onRemove()"
                  (uploadHandler)="onUpload($event)">
    </p-fileUpload>
  </div>
</form>


