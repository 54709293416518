import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CompteAppli, Individu, Role} from '../model/user.model';
import {Connexion, LoggedEntity} from '../model/securite.model';

@Injectable({
  providedIn: 'root'
})
export class SecuriteService {

  constructor(private http: HttpClient) {
  }

  public getLoggedUsers(): Observable<LoggedEntity<Individu>[]> {
    return this.http.get<LoggedEntity<Individu>[]>('/securite/logged-users');
  }

  public getLoggedApplis(): Observable<LoggedEntity<CompteAppli>[]> {
    return this.http.get<LoggedEntity<Individu>[]>('/securite/logged-si');
  }

  public getActivityDatabase(): Observable<Connexion[]> {
    return this.http.get<Connexion[]>('/securite/activity-database');
  }
}
