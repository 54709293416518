import {Component, Input, OnChanges} from '@angular/core';
import {Adresse, Contact, Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {AdresseService} from '../../../services/adresse.service';
import {ContactService} from '../../../services/contact.service';
import {LoaderService} from '../../../services/loader.service';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-adresses-contacts',
  templateUrl: './adresses-contacts.component.html',
  styleUrls: ['./adresses-contacts.component.scss']
})
export class AdressesContactsComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  updateMode: boolean;
  adresses: Adresse[];
  contacts: Contact[];

  selectedAdresse: Adresse;
  indexAdresse: number;
  selectedContact: Contact;
  indexContact: number;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private adresseService: AdresseService,
              private contactService: ContactService) {
    this.user = this.userService.getCurrentUser();
    this.adresses = [];
    this.contacts = [];
  }

  ngOnChanges() {
    this.init();
  }

  modify() {
    this.updateMode = !this.updateMode;
    if (!this.updateMode) {
      this.init();
    }
  }

  createAdresse() {
    this.resetSelection();
    this.selectedAdresse ? this.selectedAdresse = null : this.selectedAdresse = new Adresse();
  }

  createContact() {
    this.resetSelection();
    this.selectedContact ? this.selectedContact = null : this.selectedContact = new Contact();
  }

  selectAdresse(adresse: Adresse, index: number) {
    this.resetSelection();
    this.indexAdresse = index;
    this.selectedAdresse = adresse;
  }

  selectContact(contact: Contact, index: number) {
    this.resetSelection();
    if (this.updateMode) {
      this.indexContact = index;
      this.selectedContact = contact;
    }
  }

  init() {
    this.resetSelection();
    this.updateMode = false;
    if (this.structure) {
      this.adresseService.getAdressesByStructure(this.structure.structureId).subscribe(res => this.adresses = res);
      this.contactService.getContactsByStructure(this.structure.structureId).subscribe(res => this.contacts = res);
    }
  }

  resetSelection() {
    this.selectedAdresse = null;
    this.indexAdresse = null;
    this.selectedContact = null;
    this.indexContact = null;
  }

  isInUserGestRespPerimeter(structure: Structure): boolean {
    return isInGestRespActionPerimeter(structure.structureId, this.user);
  }
}
