import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {AssociationSiService} from '../../../../services/association-si.service';
import {LoaderService} from '../../../../services/loader.service';
import {Application} from '../../../../model/nomenclature.model';
import {UserService} from '../../../../services/user.service';
import {User} from '../../../../model/user.model';
import {compareNumbers} from '@fullcalendar/core/internal';

@Component({
  selector: 'app-structures-associees-dialog',
  templateUrl: './structures-associees-dialog.component.html',
  styleUrls: ['./structures-associees-dialog.component.scss']
})
export class StructuresAssocieesDialogComponent implements OnInit {

  user: User;

  display: boolean;

  cols: any[];
  datasource: DataSource[] = [];

  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private associationSiService: AssociationSiService,
              private userService: UserService,
              private loaderService: LoaderService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'structure', header: 'Structure'},
      {field: 'codeSi', header: 'Code SI'}
    ];
  }

  ngOnInit() {
    this.display = false;
    this.loaderService.start();
    this.associationSiService.getRepartStructSiByApplicationId(this.data.application.applicationId).subscribe(res => {
      this.loaderService.stop();
      res
        .filter(e => e.isValid)
        .sort((s1, s2) => compareNumbers(s1.structureId, s2.structureId))
        .forEach(e => {
        this.datasource.push({
          structure: e.structure,
          libelle: e.structure.identite.libelleLong,
          codeSi: e.codeStructSI
        });
      });
      this.display = true;
    });
  }
}

export interface DataSource {
  structure: Structure;
  libelle: string;
  codeSi: string;
}

export interface DialogData {
  name: string;
  application: Application;
}
