import {Component, OnInit, ViewChild} from '@angular/core';
import {Pagination} from '../../../model/divers.model';
import {Log} from '../../../model/log.model';
import {X_DATA} from '../../../constants/http.constant';
import {LogService} from '../../../services/log.service';
import {HttpParams} from '@angular/common/http';
import {LogComponent} from '../../../components/administration/log/log.component';
import {RechercheLogFormComponent} from '../../../components/ui/recherche-log-form/recherche-log-form.component';
import {Paginator} from 'primeng/paginator';

@Component({
  selector: 'app-administration-logs',
  templateUrl: './administration-logs.component.html',
  styleUrls: ['./administration-logs.component.scss']
})
export class AdministrationLogsComponent implements OnInit {

  @ViewChild(LogComponent) logComponent: LogComponent;
  @ViewChild(RechercheLogFormComponent) rechercheLogFormComponent: RechercheLogFormComponent;

  logs: Log[];
  first = 0;
  rows = 25;
  totalRecords: number;

  params: HttpParams;

  constructor(private logService: LogService) {
  }

  ngOnInit() {
    this.params = new HttpParams();
    this.getLogs();
  }

  onPageChange(paginator: Paginator) {
    this.first = paginator.first;
    this.rows = paginator.rows;
    this.getLogs();
  }

  search(params: HttpParams) {
    this.params = params;
    this.first = 0;
    this.getLogs();
  }

  getLogs() {
    this.logService.search(this.params, new Pagination(this.first, this.rows)).subscribe(res => {
      this.logs = res.body;
      this.totalRecords = +res.headers.get(X_DATA);
    });
  }

}

