<app-demande-struct-mat-form #demandeStructMatComponent class="col-12" [structurePorteuse]="structurePorteuse"
                             [demande]="demande"></app-demande-struct-mat-form>

<div class="row justify-content-center"
     *ngIf="demande && (user.individu.isResponsable || user.individu.isGestionnaire) && [etatDemande.ATTENTE_MODIFICATION].includes(demande.etatDemande)">
  <mat-card class="col-lg-8 mt-4 mb-4 p-4">

    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Commentaire</mat-label>
      <textarea matInput [(ngModel)]="motif" [maxLength]="200"
                placeholder="Saisie obligatoire d'un commentaire à destination des administrateurs"></textarea>
    </mat-form-field>
  </mat-card>
</div>

<div class="row justify-content-center"
     *ngIf="(user.individu.isAdmin && ![etatDemande.ATTENTE_MODIFICATION.valueOf()].includes(demande.etatDemande))
     || (motif && (user.individu.isResponsable || user.individu.isGestionnaire) && isInGestRespPerimeter(demande.lien.structureId)
                  && [etatDemande.ATTENTE_MODIFICATION.valueOf()].includes(demande.etatDemande))">

  <button class="col-lg-8" mat-raised-button color="primary" matTooltip="Demande de modification"
          [disabled]="demandeStructMatComponent.isFormDisabled()"
          (click)="validate()">{{'envoyer une demande de modification' | uppercase}}
  </button>
</div>

