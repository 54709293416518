<h1 mat-dialog-title>Se connecter en tant que</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>Utilisateur</mat-label>
    <input matInput type="text" placeholder="Utilisateur" aria-label="Utilisateur"
           [formControl]="userFC" [matAutocomplete]="auto" (input)="onSearchTape()"
           (keyup.enter)="onKeyEnterSelect()" [disabled]="options.length ===0">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let ind of filteredOptions | async" [value]="getFieldLabel(ind)"
                  (click)="onIndividuSelect(ind)" (onSelectionChange)="onIndividuSelect(ind)">
        {{getFieldLabel(ind)}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="onConfirm()">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
