import {Component, Inject, Input, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Lien} from '../../../../model/structure.model';
import {ReferentielAquitainFormComponent} from '../../../../components/administration/referentielAquitain/referentiel-aquitain-form/referentiel-aquitain-form.component';
import {SyncRefAq} from '../../../../model/referentiel-aquitain.model';

@Component({
  selector: 'app-referentiel-aquitain-form-dialog',
  templateUrl: './referentiel-aquitain-form-dialog.component.html',
  styleUrls: ['./referentiel-aquitain-form-dialog.component.scss']
})
export class ReferentielAquitainFormDialogComponent {

  @Input() refAqStructure: SyncRefAq;
  @Input() readonly: boolean;

  @ViewChild(ReferentielAquitainFormComponent) referentielAquitainFormComponent: ReferentielAquitainFormComponent;

  constructor(public dialogRef: MatDialogRef<ReferentielAquitainFormDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  validate() {
    this.dialogRef.close(this.referentielAquitainFormComponent.getSyncRefAq());
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  lien: Lien;
}
