<h1 mat-dialog-title>{{data.question}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
    <mat-form-field class="col-lg-12" appearance="outline" *ngIf="!data.isRedoMode">
      <mat-label>Commentaire</mat-label>
      <textarea matInput [(ngModel)]="commentaire" placeholder="Commentaire"  [maxLength]="200"></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="onConfirm()"
          [disabled]="!data.isRedoMode&& (!this.commentaire || this.commentaire.trim() === '')">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
