<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">

  <div *ngIf="data.type === caracterisation.CODE">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Nouveau code</mat-label>
      <input matInput [formControl]="codeValue" placeholder="Nouveau code" maxlength="200" required>
      <mat-error *ngIf="codeValue.hasError('required')">Valeur requise</mat-error>
    </mat-form-field>

    <mat-radio-group class="col-lg-12" [(ngModel)]="isHisto" aria-label="Historiser l'ancienne valeur" required>
      Historiser l'ancienne valeur ?
      <mat-radio-button class="ml-4" [value]="true" [checked]="isHisto">Oui</mat-radio-button>
      <mat-radio-button class="ml-4" [value]="false" [checked]="!isHisto">Non</mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="col-lg-12" appearance="outline" [hidden]="!isHisto">
      <mat-label>Date de fin</mat-label>
      <input matInput [formControl]="dateFin" [max]="now" [matDatepicker]="finPicker" placeholder="Date de fin"
             required>
      <mat-datepicker-toggle matSuffix [for]="finPicker">
      </mat-datepicker-toggle>
      <mat-datepicker #finPicker></mat-datepicker>
      <mat-error *ngIf="dateFin.hasError('matDatepickerMax')">
        {{'La date de fin de validité ne peut être supérieure à la date du jour moins un jour (pour une prise d\'effet de la nouvelle valeur du code à la date du jour'}}</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" [disabled]="codeValue.pristine || codeValue.invalid" (click)="onUpdate()">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
