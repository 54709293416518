<h1 mat-dialog-title>Formulaire de resynchronisation de l'application {{data.rss.application.applicationLib.toUpperCase()}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p class="col-lg-12">Vous souhaitez réactiver cette association.
    Assurez-vous que les données dans cette dernière sont bien en cohérence avec celle présentes dans le référentiel.</p>

    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Commentaire</mat-label>
      <textarea matInput
                [(ngModel)]="motif"
                [maxLength]="200"
                placeholder="Commentaire"
                required></textarea>
      <mat-error *ngIf="!motif || motif === ''">Valeur requise</mat-error>
    </mat-form-field>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="réactiver association"
          [disabled]="!motif || motif.trim() === ''"
          (click)="onConfirm()"><i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>

