<h1 mat-dialog-title>Ajout d'un {{ data.typeRole | lowercase}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <app-recherche-ldap-form (ldapAccountEventEmitter)="onPersonSelected($event)"></app-recherche-ldap-form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
