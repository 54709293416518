<h1 mat-dialog-title>{{this.data.role?.roleId ? 'Mise à jour du responsable' : 'Ajout du responsable'}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <app-recherche-ldap-form [selectedLdapAccount]="ldapAccount"
                           (ldapAccountEventEmitter)="getLdapAccount($event)"></app-recherche-ldap-form>

  <form class="row" [formGroup]="form">
    <!--DATE DEBUT -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de début</mat-label>
      <input formControlName="dateDebut" matInput [matDatepicker]="debpicker" placeholder="Date de début"
             (change)="isDateRangeValid()" required>
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateDebut'].invalid && form.controls['dateDebut'].touched">{{'Format non valide'}}</mat-error>
    </mat-form-field>

    <!--DATE FIN -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de fin</mat-label>
      <input formControlName="dateFin" matInput [matDatepicker]="finpicker" placeholder="Date de fin"
             (change)="isDateRangeValid()" required>
      <mat-datepicker-toggle matSuffix [for]="finpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #finpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateFin'].invalid && form.controls['dateFin'].touched">{{'Format non valide'}}</mat-error>
    </mat-form-field>
  </form>

  <p class="alert-warning">{{warningDates}}</p>

  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" (click)="onValidate()"
            [disabled]="form.invalid || !isDatesValid || !ldapAccount">
      <i class="fas fa-check fa-2x"></i>
    </button>
    <button mat-raised-button color="accent" (click)="onCancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</div>
