<div *ngIf="datasource">
  <div class="row justify-content-center">
    <mat-card class="col-lg-12" *ngIf="structure && datasource.length === 0">
      <h4 class="text-center">Aucun logs à afficher</h4>
    </mat-card>
  </div>

  <div class="row justify-content-center mt-4" *ngIf="!structure && datasource.length > 0">
    <p-paginator class="col-lg-12"
                 [rows]="rows"
                 [totalRecords]="totalRecords"
                 [pageLinkSize]="pageLinks"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [first]="first"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                 (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>

  <div class="row justify-content-center mt-4" *ngIf="datasource.length > 0">
    <p-table class="col-lg-12"
             [value]="datasource"
             [columns]="cols"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liste des logs</ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [hidden]="structure && col.field === 'structure'">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td [hidden]="structure">
            <a href appHighlightStructure
               [structure]="row.structure"
               [routerLink]="'/structure/' +  row.structure.structureId">
              {{row.structure | libelleStructure:user}}</a>
          </td>
          <td>{{row.typeLog}}</td>
          <td>{{row.date}}</td>
          <td>{{row.acteur}}</td>
          <td>{{row.commentaire}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
