import {Component, EventEmitter, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {Structure} from '../../../model/structure.model';
import {data} from '../../../constants/type-libelle.constant';
import {StructureService} from '../../../services/structure.service';
import * as moment from 'moment';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-recherche-todo-form',
  templateUrl: './recherche-todo-form.component.html',
  styleUrls: ['./recherche-todo-form.component.scss']
})
export class RechercheTodoFormComponent {

  @Output() searchEmitter: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  user: User;
  form: FormGroup;

  structures: Structure[];
  etats = data.etat_todo;
  types = data.type_todo;

  constructor(private structureService: StructureService,
              private userService: UserService) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      structures: new FormControl(null),
      etats: new FormControl(null),
      avant: new FormControl(null),
      apres: new FormControl(null),
    });
    structureService.getAllStructures().subscribe(res => this.structures = res);
  }

  search() {
    let params = new HttpParams();

    if (this.form.controls.structures.value && this.form.controls.structures.value.length > 0) {
      params = params.append('structures', this.form.controls.structures.value);
    }
    if (this.form.controls.etats.value && this.form.controls.etats.value.length > 0) {
      params = params.append('etats', this.form.controls.etats.value);
    }
    if (this.form.controls.avant.value) {
      params = params.append('avant', moment(this.form.controls.avant.value).format('DD/MM/YYYY HH:mm'));
    }
    if (this.form.controls.apres.value) {
      params = params.append('apres', moment(this.form.controls.apres.value).format('DD/MM/YYYY HH:mm'));
    }

    this.searchEmitter.emit(params);
  }

}
