import {Component, OnInit, ViewChild} from '@angular/core';
import {Pagination} from '../../../model/divers.model';
import {Lien} from '../../../model/structure.model';
import {LienService} from '../../../services/lien.service';
import {LiensAntecedentsComponent} from '../../../components/administration/liens-antecedents/liens-antecedents.component';
import {HttpParams} from '@angular/common/http';
import {X_DATA} from '../../../constants/http.constant';
import {RechercheLienFormComponent} from '../../../components/ui/recherche-lien-form/recherche-lien-form.component';
import {ACTION} from '../../../constants/enum.constant';
import {Paginator} from 'primeng/paginator';

@Component({
  selector: 'app-administration-liens-antecedents',
  templateUrl: './administration-liens-antecedents.component.html',
  styleUrls: ['./administration-liens-antecedents.component.scss']
})
export class AdministrationLiensAntecedentsComponent implements OnInit {

  @ViewChild(LiensAntecedentsComponent) liensAntecedentsComponent: LiensAntecedentsComponent;
  @ViewChild(RechercheLienFormComponent) rechercheLienFormComponent: RechercheLienFormComponent;

  liens: Lien[];
  first = 0;
  rows = 25;
  totalRecords: number;

  params: HttpParams;

  constructor(private lienService: LienService) {
  }

  ngOnInit() {
    this.params = new HttpParams();
    this.getLiens();
  }

  addLienAntecedent() {
    this.liensAntecedentsComponent.openDialog(null, ACTION.ADD);
  }

  onAction() {
    this.rechercheLienFormComponent.search();
  }

  OnPageChange(paginator: Paginator) {
    this.first = paginator.first;
    this.rows = paginator.rows;
    this.getLiens();
  }

  search(params: HttpParams) {
    this.params = params;
    this.first = 0;
    this.getLiens();
  }

  getLiens() {
    this.lienService.search(this.params, new Pagination(this.first, this.rows)).subscribe(res => {
      this.liens = res.body;
      this.totalRecords = +res.headers.get(X_DATA);
    });
  }


}
