import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/user.model';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() numVersion: string;
  user: User;

  constructor(private userService: UserService) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit(): void {
  }

}
