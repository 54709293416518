import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {AuthenticationService} from './services/authentication.service';
import {ConfigAssetLoaderService, Configuration} from './configAssetLoader.service';
import {NOTIF_STYLE, X_MESSAGE, X_TYPE} from './constants/http.constant';
import {LoaderService} from './services/loader.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from './services/user.service';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  conf: Configuration;

  constructor(public authenticationService: AuthenticationService,
              private configAssetLoaderService: ConfigAssetLoaderService,
              private snackBar: MatSnackBar,
              private userService: UserService,
              private loaderService: LoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.conf = res);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.conf) {
      let jwt = this.authenticationService.getJwt();
      if (this.userService.getJwtLoginAs()) {
        jwt = this.userService.getJwtLoginAs();
      }

      request = Object.assign(request,
        new HttpRequest(
          request.method as any,
          this.conf.apiUrl + request.url,
          request.body,
          {
            headers: request.headers,
            reportProgress: request.reportProgress,
            params: request.params,
            responseType: request.responseType,
            withCredentials: request.withCredentials
          }
        )
      );

      if (jwt) {
        request = request.clone({
          setHeaders: {
            Authorization: jwt
          }
        });
      }
    }

    return next.handle(request).pipe(
      tap(event => {
          if (event instanceof HttpResponse) {
            if (event.headers.get(X_MESSAGE)) {
              this.snackBar.open(event.headers.get(X_MESSAGE), 'X', {
                panelClass: NOTIF_STYLE.get(event.headers.get(X_TYPE))
              });
            }
          }
        }, error => {
          this.loaderService.stop();
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403 || error.status === 0) {
              this.snackBar.open('Vous n\'avez pas les droits nécessaires pour utiliser cette application. S\'il s\'agit d\'une erreur, veuillez en informer un administrateur.', 'X', {panelClass: 'notif-error'});
              localStorage.clear();
              setTimeout(() => this.authenticationService.redirectToCasLogin(), 2000);

            } else if (error.status === 418) {
              this.snackBar.open(error.headers.get(X_MESSAGE), 'X', {
                panelClass: NOTIF_STYLE.get(error.headers.get(X_TYPE))
              });

            } else if (error.status === 500) { //  signature invalid ou expired token
              localStorage.clear();
              this.authenticationService.redirectToCasLogin();

            } else {
              this.snackBar.open(error.message, 'X', {
                panelClass: 'notif-error'
              });
            }
          }
        }
      ));
  }
}
