import {Component, Input, OnChanges} from '@angular/core';
import {Structure} from '../../../../model/structure.model';
import {User} from '../../../../model/user.model';
import {SyncRefAqTask} from '../../../../model/referentiel-aquitain.model';
import {MatDialog} from '@angular/material/dialog';
import {SyncRefAqService} from '../../../../services/syncRefAq.service';
import {
  SynchronizeReferentielAquitainDialogComponent
} from '../../../../layout/dialog/referentiel-aquitain/synchronize-referentiel-aquitain-dialog/synchronize-referentiel-aquitain-dialog.component';
import {StructureService} from '../../../../services/structure.service';
import {ETAT_SYNC} from '../../../../model/enum.model';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-referentiel-aquitain',
  templateUrl: './synchro-referentiel-aquitain.component.html',
  styleUrls: ['./synchro-referentiel-aquitain.component.scss']
})
export class SynchroReferentielAquitainComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  readonly = false;
  syncRefAqTasks: SyncRefAqTask[];
  commentaire: string;
  action = ACTION;

  constructor(private syncRefAqService: SyncRefAqService,
              private structureService: StructureService,
              private userService: UserService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.syncRefAqTasks = [];
  }

  ngOnChanges() {
    this.init();
  }

  onClick(action: string) {
    this.dialog.open(SynchronizeReferentielAquitainDialogComponent, {
      minWidth: 400,
      data: {
        structure: this.structure,
        action
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.init();
      }
    });
  }

  init() {
    this.structureService.getStructureByStructureId(this.structure.structureId).subscribe(res => {
      this.structure = res;
      if (this.user.individu.isAdmin) {
        this.getSyncTaskByStructure(this.structure.structureId);
      }
    });
  }

  getSyncTaskByStructure(structureId: number): void {
    this.syncRefAqService.getSyncTasksByStructure(structureId).subscribe(res => {
      this.syncRefAqTasks = res.filter(s => s.etatSync !== ETAT_SYNC.OK);
      if (this.syncRefAqTasks.length === 0) {
        this.readonly = true;
      }
    });
  }
}

export enum ACTION {
  ACTIVATE = 'ACTIVATE',
  STOP = 'STOP',
  REACTIVATE = 'REACTIVATE'
}
