<h1 mat-dialog-title>Formulaire de modification d'un lien</h1>

<mat-dialog-content class="row justify-content-center mt-4">
  <form [formGroup]="form">
    <!--STRUCTURE-->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Structure</mat-label>
      <input type="text" placeholder="Recherche" aria-label="Structure" matInput
             formControlName="structure" [matAutocomplete]="struct">
      <mat-autocomplete #struct="matAutocomplete">
        <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of filteredStructures | async"
                    (click)="onStructureSelect(struct)"
                    (onSelectionChange)="onStructureSelect(struct)"
                    [value]="struct.identite.libelleLong">
          {{struct | libelleStructure:user}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!--STRUCTURE PORTEUSE-->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Structure porteuse</mat-label>
      <input type="text" placeholder="Recherche" aria-label="StructurePorteuse" matInput (input)="onInput()"
             formControlName="structurePorteuse" [matAutocomplete]="structPorteuse" required>
      <mat-autocomplete #structPorteuse="matAutocomplete">
        <mat-option appHighlightStructure [structure]="struct"
                    *ngFor="let struct of filteredStructuresPorteuses | async"
                    (click)="onStructurePorteuseSelect(struct)"
                    (onSelectionChange)="onStructurePorteuseSelect(struct)"
                    [value]="struct.identite.libelleLong">
          {{struct | libelleStructure:user}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="form.controls['structurePorteuse'].hasError('required') && form.controls['structurePorteuse'].touched">
        Valeur requise
      </mat-error>
      <mat-error *ngIf="form.controls['structurePorteuse'].hasError('invalid-structure')">Vous devez sélectionner une
        structure parmi la liste déroulante
      </mat-error>
    </mat-form-field>

    <!--TYPE DE LIEN -->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Type de lien</mat-label>
      <mat-select formControlName="typeLien" placeholder="Type de lien" required>
        <mat-option *ngFor="let t of typesLien" [value]="t.typeLienId">{{t.typeLienLib}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['typeLien'].hasError('required') && form.controls['typeLien'].touched">
        Valeur requise
      </mat-error>
    </mat-form-field>

    <!--DATE DEBUT -->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Date de début</mat-label>
      <input formControlName="dateDebut" matInput [matDatepicker]="debpicker" placeholder="Date de début" required>
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateDebut'].invalid && form.controls['dateDebut'].touched">Format non valide
      </mat-error>
    </mat-form-field>

    <!--DATE DE FIN -->
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Date de fin</mat-label>
      <input formControlName="dateFin" matInput [matDatepicker]="finpicker" placeholder="Date de fin">
      <mat-datepicker-toggle matSuffix [for]="finpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #finpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateFin'].invalid && form.controls['dateFin'].touched">Format non valide
      </mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" (click)="validate()" [disabled]="!form.valid">
      <i class="fas fa-check fa-2x"></i>
    </button>
    <button mat-raised-button color="accent" (click)="cancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</mat-dialog-content>
