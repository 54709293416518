import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../model/user.model';
import {NavigationEnd, Router} from '@angular/router';
import {Structure} from '../../model/structure.model';
import * as type from '../../constants/type-libelle.constant';
import {Pagination} from '../../model/divers.model';
import {HttpParams} from '@angular/common/http';
import {X_DATA} from '../../constants/http.constant';
import {RechercheStructureFormComponent} from '../../components/ui/recherche-structure-form/recherche-structure-form.component';
import {StructureService} from '../../services/structure.service';
import {Paginator} from 'primeng/paginator';
import {UserService} from '../../services/user.service';
import {StructureTreeComponent} from '../../components/ui/structureTree/structureTree.component';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent implements OnInit, OnDestroy {

  @ViewChild(RechercheStructureFormComponent) rechercheStructureFormComponent: RechercheStructureFormComponent;
  @ViewChild(StructureTreeComponent) structureTreeComponent: StructureTreeComponent;

  user: User;

  navigationSubscription: any;

  categoriesStructure = type.data.cat_structure;
  selectedRow: any;
  cols: any[];
  datasource: any[];

  first = 0;
  rows = 50;
  rowsPerPageOptions = [50, 75, 100];
  totalRecords: number;
  pageLinks: number;

  params: HttpParams;

  constructor(private router: Router,
              private userService: UserService,
              private structureService: StructureService) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'libelleLong', header: 'Libellé long'},
      {field: 'libelleCourt', header: 'Libellé court'},
      {field: 'catStructure', header: 'Catégorie'},
      {field: 'typeStructure', header: 'Type'},
      {field: 'interne', header: 'Interne/Externe'},
      {field: 'domaines', header: 'Domaines'},
      {field: 'tutelles', header: 'Tutelles'},
      {field: 'etat', header: 'Etat'},
    ];

    this.navigationSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.datasource = [];
    this.selectedRow = null;
    this.first = 0;
    this.getStructures();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onPageChange(paginator: Paginator) {
    this.first = paginator.first;
    this.rows = paginator.rows;
    this.getStructures();
  }

  search(params: HttpParams) {
    params = params.append('libelle', this.structureTreeComponent.structCtrl.value);
    this.params = params;
    this.first = 0;
    this.getStructures();
  }

  getStructures() {
    this.structureService.search(this.params, new Pagination(this.first, this.rows))
      .subscribe(response => {
        this.totalRecords = +response.headers.get(X_DATA);
        this.pageLinks = Math.ceil(this.totalRecords / this.rows);
        this.datasource = response.body.map(s => this.convertToDatasource(s));
      });
  }

  onStructureSelected(structure: Structure) {
    this.router.navigate(['structure', structure.structureId]);
  }

  convertToDatasource(structure: Structure) {
    return {
      structure,
      structureId: structure.structureId,
      libelleLong: structure.identite.libelleLong,
      libelleCourt: structure.identite.libelleCourt,
      catStructure: this.categoriesStructure.filter(c => c.value === structure.catStructure)[0].libelle,
      typeStructure: structure.identite.typeStructure.typeStructureLib,
      interne: structure.interne ? 'Interne' : 'Externe',
      domaines: structure.domaines.map(e => e.domaineLib).toString().replace(',', ', '),
      tutelles: structure.tutelles.map(e => e.tutelleLib).toString().replace(',', ', '),
      etat: structure.etatStructure
    };
  }
}
