<div class="row justify-content-center mt-4">
  <h1 class="title">Gestion des administrateurs et des modérateurs</h1>
</div>

<div class="row justify-content-center buffer">
  <p-table class="col-lg-6 mt-4"
           dataKey="individuId"
           [value]="adminList"
           [columns]="cols" resizableColumns="true"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rowsAdmin"
           [showCurrentPageReport]="true"
           [(first)]="firstAdmin"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptionsAdmin">
    <ng-template pTemplate="caption">Administrateurs
      <button class="button float-right" matTooltip="Ajouter un administrateur"
              (click)="onAddButton(typeRole.ADMINISTRATEUR)">
        <span><i class="fas fa-plus fa-2x"></i></span>
      </button>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="text-center" pResizableColumn>Suppression</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-ind>
      <tr>
        <td *ngFor="let col of columns">
          {{ind[col.field]}}
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Supprimer le rôle d'administrateur"
                  (click)="openRemoveDialog(typeRole.ADMINISTRATEUR, ind.individu)"
                  *ngIf=" ind.individu.idnum !== this.user.username">
            <i class="fas fa-trash fa-2x"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="row justify-content-center buffer">
  <p-table class="col-lg-6 mt-4"
           dataKey="individuId"
           [value]="moderList"
           [columns]="cols" selectionMode="single"
           [(selection)]="selectedResp"
           [responsive]="true"
           [resizableColumns]="true"
           [paginator]="true"
           [rows]="rowsModer"
           [showCurrentPageReport]="true"
           [(first)]="firstModer"
           currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
           [rowsPerPageOptions]="rowsPerPageOptionsModer">
    <ng-template pTemplate="caption">Modérateurs
      <button class="button float-right" matTooltip="Ajouter un modérateur"
              (click)="onAddButton(typeRole.MODERATEUR)">
        <span><i class="fas fa-plus fa-2x"></i></span>
      </button>

    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="text-center" pResizableColumn>Structures</th>
        <th class="text-center" pResizableColumn>Suppression</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-ind>
      <tr>
        <td *ngFor="let col of columns">
          {{ind[col.field]}}
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Afficher la liste des structures"
                  (click)="openStructuresDialog(ind.individu)">
            <i class="fas fa-list fa-2x"></i></button>
        </td>
        <td class="text-center">
          <button class="button" matTooltip="Supprimer le rôle modérateur"
                  (click)="openRemoveDialog(typeRole.MODERATEUR, ind.individu)"
                  *ngIf=" ind.individu.idnum !== user.username">
            <i class="fas fa-trash fa-2x"></i></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


