import {Component, Inject, OnInit} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {WorkflowService} from '../../../../services/workflow.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {RoleService} from '../../../../services/role.service';
import {StructureService} from '../../../../services/structure.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReactivationRequest} from '../../../../model/workflow.model';
import {TypeLien} from '../../../../model/nomenclature.model';
import {Role} from '../../../../model/user.model';
import {CAT_STRUCTURE, ETAT_VALID, TYPE_ROLE} from '../../../../model/enum.model';
import {LIEN_HIERARCHIQUE} from '../../../../constants/datas.constant';

@Component({
  selector: 'app-reactivate-structure-dialog',
  templateUrl: './reactivate-structure-dialog.component.html',
  styleUrls: ['./reactivate-structure-dialog.component.scss']
})
export class ReactivateStructureDialogComponent implements OnInit {

  structures: Structure[];
  gestionnaires: Role[];
  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private roleService: RoleService,
              private structureService: StructureService,
              private workFlowService: WorkflowService,
              public dialogRef: MatDialogRef<ReactivateStructureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.form = new FormGroup({
      structurePorteuse: new FormControl(null),
      privilegesASuppr: new FormControl([]),
      motif: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    this.loaderService.start();
    this.structureService.getStructuresByCategorie(CAT_STRUCTURE.ORGA).subscribe(res => {
      this.loaderService.stop();
      this.structures = res;
      this.form.controls.structurePorteuse.setValue(this.data.selectedStructure.liensParent[0].structPorteuseId);
    });

    this.roleService.getRolesValidesByStructureAndTypeRole(this.data.selectedStructure.structureId, TYPE_ROLE.GESTIONNAIRE)
      .subscribe(res => this.gestionnaires = res);
  }

  reactivate() {
    const reactivationRequest: ReactivationRequest = {
      lien: {
        lienId: null,
        typeLien: new TypeLien(LIEN_HIERARCHIQUE),
        structureId: this.data.selectedStructure.structureId,
        structPorteuseId: this.form.controls.structurePorteuse.value,
        dateDebut: new Date(),
        dateFin: null,
        etatValid: ETAT_VALID.VALIDE,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false
      },
      rolesACloturer: this.form.controls.privilegesASuppr.value,
      motif: this.form.controls.motif.value
    };

    this.workFlowService.reactivateStructure(reactivationRequest)
      .subscribe(res => this.dialogRef.close(res));
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  selectedStructure: Structure;
}
