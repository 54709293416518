<div class="row justify-content-center mt-4">
  <h1 class="title">Demande de création d'une structure matricielle</h1>
</div>

<div class="row justify-content-center">
  <mat-accordion class="col-lg-8 mt-4 mb-4 p-4">
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title><h4>{{structurePorteuse ? 'Rattachement : ' + structurePorteuse.identite.libelleLong : 'Choix de la structure parente'}}</h4></mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree [title]="null"
                          [structuresActivesOnly]="true"
                          [displayOption]="false"
                          [searchMode]="!user.individu?.isAdmin"
                          (selectedStructureEmitter)="onSelectedStructure($event)">
      </app-structure-tree>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div *ngIf="structurePorteuse">
  <app-demande-struct-mat-form #demandeStructMatComponent
                               [structurePorteuse]="structurePorteuse"></app-demande-struct-mat-form>

  <div class="row justify-content-center">
    <button class="col-8" mat-raised-button color="primary" matTooltip="création d'une structure matricielle"
            [disabled]="demandeStructMatComponent?.isFormDisabled()"
            (click)="createNewDemande()">ENVOYER LA DEMANDE DE CREATION
    </button>
  </div>
</div>
