<div class="row justify-content-center">
  <mat-accordion class="col-lg-8 mt-4 mb-4">
    <mat-expansion-panel [expanded]="!structRattach">
      <mat-expansion-panel-header>
        <mat-panel-title><h4>Sélection de la structure</h4></mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree title="Choisissez une structure de rattachement" [displayOption]="false"
                          (selectedStructureEmitter)="onSelectedStructure($event)"></app-structure-tree>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row justify-content-center" *ngIf="displayForm">
  <mat-card class="col-lg-8 mt-4 mb-4 p-4">
    <mat-card-header>
      <h4>{{'Ajout de la structure de rattachement : '}}
        <span appHighlightStructure [structure]="structRattach">{{structRattach.identite.libelleLong}}</span></h4>
    </mat-card-header>

    <mat-card-content class="mt-4">
      <form [formGroup]="form">
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de début</mat-label>
          <input matInput formControlName="dateDebut"
                 [matDatepicker]="debpicker"
                 placeholder="Date de début" required>
          <mat-datepicker-toggle matSuffix [for]="debpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #debpicker></mat-datepicker>
          <mat-error *ngIf="form.controls['dateDebut'].hasError('required')">Format non valide</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la demande</mat-label>
          <textarea matInput formControlName="motif" placeholder="Motif de la demande" [maxLength]="200" required></textarea>
          <mat-error *ngIf="form.controls['motif'].invalid && form.controls['motif'].touched">Valeur requise
          </mat-error>
        </mat-form-field>

        <div class="row justify-content-center">
          <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary"
                  matTooltip="Création d'un lien hiérarchique"
                  (click)="createLien()" [disabled]="form.invalid">{{'envoyer votre demande de création de lien' |uppercase}}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>




