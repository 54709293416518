<div>
  <div class="row justify-content-center">
    <mat-card class="col-lg-12">
      <mat-card-header>
        <h4>Référentiel aquitain</h4>
      </mat-card-header>
      <mat-card-content>
        <div class="row justify-content-center" *ngIf="structure.idRefAq">
          <span>Id du référentiel Aquitain : {{structure.idRefAq}}</span>
        </div>

        <div class="row justify-content-center mt-4 mb-4" *ngIf="user.individu.isAdmin">
          <!--BOUTON INITIER LA SYNCRHONISATION-->
          <button class="d-inline-block ml-2 mt-2 btn-brown" mat-raised-button
                  (click)="onClick(action.ACTIVATE)"
                  *ngIf="!structure.useRefAq && !structure.idRefAq">Activer la synchronisation
          </button>

          <!--BOUTON REDEMARRER LA SYNCHRONISATION-->
          <button class="d-inline-block ml-2 mt-2 btn-brown" mat-raised-button
                  (click)="onClick(action.REACTIVATE)"
                  *ngIf="!structure.useRefAq && structure.idRefAq">Réactiver la synchronisation
          </button>

          <!--BOUTON ARRETER LA SYNCHRONISATION-->
          <button class="d-inline-block ml-2 mt-2 btn-brown" mat-raised-button
                  (click)="onClick(action.STOP)"
                  *ngIf="structure.useRefAq && structure.idRefAq">Arrêter la synchronisation
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="row justify-content-center" *ngIf="syncRefAqTasks.length > 0 && user.individu.isAdmin" >
    <app-sync-tasks class="col-lg-12 mt-4"
                    [readonly]="readonly"
                    [syncRefAqTasks]="syncRefAqTasks"
    ></app-sync-tasks>
  </div>
</div>
