import {Component, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {DemandeStructMatFormComponent} from '../../../components/demande/demande-struct-mat-form/demande-struct-mat-form.component';
import {CreationRequest} from '../../../model/workflow.model';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {StructureService} from '../../../services/structure.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {CAT_STRUCTURE, TYPE_CREATION} from '../../../model/enum.model';

@Component({
  selector: 'app-creation-structure-matricielle',
  templateUrl: './creation-structure-matricielle.component.html',
  styleUrls: ['./creation-structure-matricielle.component.scss']
})
export class CreationStructureMatricielleComponent implements OnChanges, OnDestroy {

  @ViewChild(DemandeStructMatFormComponent) demandeStructMatComponent: DemandeStructMatFormComponent;

  user: User;
  navigationSubscription: any;

  expanded = true;
  structurePorteuse: Structure;

  constructor(private workflowService: WorkflowService,
              private userService: UserService,
              private structureService: StructureService,
              private loaderService: LoaderService,
              private router: Router,
              private route: ActivatedRoute) {
    this.user = this.userService.getCurrentUser();
    this.navigationSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  ngOnChanges(): void {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onSelectedStructure(structure: Structure) {
    this.expanded = false;
    this.structurePorteuse = structure;
  }

  createNewDemande() {
    const creationRequest: CreationRequest = this.initNewDemande();
    this.loaderService.start();
    this.workflowService.create(creationRequest).subscribe(res => {
      this.userService.getUpdatedUser().subscribe(user => {
        this.userService.setUser(user);
        this.loaderService.stop();
        this.router.navigate(['structure', res.lien.structureId], {queryParams: {lien: res.lien.lienId}});
      });
    });
  }

  private init() {
    this.structurePorteuse = null;

    if (this.demandeStructMatComponent) {
      this.demandeStructMatComponent.init();
    }

    this.route.queryParams.subscribe(params => {
      const structPorteuseId = +params.structurePorteuse || 0;
      if (structPorteuseId) {
        this.structureService.getStructureByStructureId(structPorteuseId).subscribe(res => this.structurePorteuse = res);
      }
    });
  }

  private initNewDemande(): CreationRequest {
    return {
      isNewSubmission: false,
      demandeId: null,
      typeDemandeId: 3,
      motif: this.demandeStructMatComponent.form.controls.motif.value,
      catStructure: CAT_STRUCTURE.MAT,
      typeStructureId: this.demandeStructMatComponent.identiteFormComponent.getIdentite().typeStructure.typeStructureId,
      interne: this.demandeStructMatComponent.form.controls.interne.value,
      typeLienId: this.demandeStructMatComponent.form.controls.typeLien.value,
      structurePorteuseId: this.structurePorteuse.structureId,
      libelleLong: this.demandeStructMatComponent.identiteFormComponent.getIdentite().libelleLong,
      libelleCourt: this.demandeStructMatComponent.identiteFormComponent.getIdentite().libelleCourt,
      dateOuverture: this.demandeStructMatComponent.identiteFormComponent.getIdentite().dateOuverture,
      dateFermeture: this.demandeStructMatComponent.identiteFormComponent.getIdentite().dateFermeture,
      domaineIds: this.demandeStructMatComponent.form.controls.domaines.value,
      tutelleId: null,
      typeCreation: TYPE_CREATION.NEW,
      typeCreationStruct: [],
      responsable: null,
      instanceDemande: null
    };
  }
}
