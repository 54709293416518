import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LdapAccount} from '../model/user.model';

@Injectable()
export class LdapService {

  constructor(private http: HttpClient) {
  }

  public getLdapSearchByUid(search: string): Observable<LdapAccount> {
    return this.http.get<LdapAccount>('/ldap/search-by-uid/' + search);
  }

  public getLdapSearchByName(search: string): Observable<LdapAccount[]> {
    return this.http.get<LdapAccount[]>('/ldap/search-by-name/' + search);
  }

  public getLdapSearchByMail(search: string): Observable<LdapAccount[]> {
    return this.http.get<LdapAccount[]>('/ldap/search-by-mail/' + search);
  }


}
