import {Component, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {CreationRequest} from '../../../model/workflow.model';
import {DemandeStructOrgaFormComponent} from '../../../components/demande/demande-struct-orga-form/demande-struct-orga-form.component';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {StructureService} from '../../../services/structure.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {StructureTreeComponent} from '../../../components/ui/structureTree/structureTree.component';
import {CAT_STRUCTURE, TYPE_DEMANDE} from '../../../model/enum.model';

@Component({
  selector: 'app-creation-structure-organisationnelle',
  templateUrl: './creation-structure-organisationnelle.component.html',
  styleUrls: ['./creation-structure-organisationnelle.component.scss']
})
export class CreationStructureOrganisationnelleComponent implements OnChanges, OnDestroy {

  @ViewChild(DemandeStructOrgaFormComponent) demandeStructOrgaComponent: DemandeStructOrgaFormComponent;
  @ViewChild(StructureTreeComponent) structureTreeComponent: StructureTreeComponent;

  user: User;
  navigationSubscription: any;
  expanded = true;

  structurePorteuse: Structure;

  constructor(private workflowService: WorkflowService,
              private userService: UserService,
              private structureService: StructureService,
              private loaderService: LoaderService,
              private router: Router,
              private route: ActivatedRoute) {
    this.user = this.userService.getCurrentUser();

    this.navigationSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  ngOnChanges(): void {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onSelectedStructure(structure: Structure) {
    this.structurePorteuse = structure;
    this.expanded = false;
  }

  createNewDemande() {
    this.loaderService.start();
    this.initNewDemande().then(creationRequest => {
      this.workflowService.create(creationRequest).subscribe(res => {
        this.userService.getUpdatedUser().subscribe(user => {
          this.userService.setUser(user);
          this.loaderService.stop();
          this.router.navigate(['structure', res.lien.structureId], {queryParams: {lien: res.lien.lienId}});
        });
      });
    });
  }

  private init() {
    this.structurePorteuse = null;

    if (this.demandeStructOrgaComponent) {
      this.demandeStructOrgaComponent.init();
    }

    this.route.queryParams.subscribe(params => {
      const structPorteuseId = +params.structurePorteuse || 0;
      if (structPorteuseId) {
        this.structureService.getStructureByStructureId(structPorteuseId).subscribe(res => this.structurePorteuse = res);
      }
    });
  }

  private initNewDemande(): Promise<CreationRequest> {
    return new Promise<CreationRequest>(resolve => {
      this.demandeStructOrgaComponent.instanceDemandeFormComponent.getInstanceDemande().then(instanceDemande => {
        const creationRequest: CreationRequest = {
          isNewSubmission: false,
          demandeId: null,
          typeDemandeId: this.demandeStructOrgaComponent.form.controls.interne.value ? TYPE_DEMANDE.CREATION_STRUCT_ORGA_INTERNE : TYPE_DEMANDE.CREATION_STRUCT_ORGA_EXT,
          motif: this.demandeStructOrgaComponent.form.controls.motif.value,
          catStructure: CAT_STRUCTURE.ORGA,
          typeStructureId: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().typeStructure.typeStructureId,
          interne: this.demandeStructOrgaComponent.form.controls.interne.value,
          typeLienId: this.demandeStructOrgaComponent.form.controls.typeLien.value,
          structurePorteuseId: this.structurePorteuse.structureId,
          libelleLong: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().libelleLong,
          libelleCourt: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().libelleCourt,
          dateOuverture: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().dateOuverture,
          dateFermeture: this.demandeStructOrgaComponent.identiteFormComponent.getIdentite().dateFermeture,
          domaineIds: this.demandeStructOrgaComponent.form.controls.domaines.value,
          tutelleId: 1,
          typeCreation: this.demandeStructOrgaComponent.form.controls.typeCreation.value,
          typeCreationStruct: this.demandeStructOrgaComponent.form.controls.interne.value ?
            this.demandeStructOrgaComponent.structuresSelectionWithTreeComponent.getStructureIds() : null,
          responsable: this.demandeStructOrgaComponent.form.controls.interne.value ? this.demandeStructOrgaComponent.ajoutResponsableComponent.selectedldapAccount : null,
          instanceDemande
        };
        resolve(creationRequest);
      });
    });


  }
}

