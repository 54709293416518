import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Parametre} from '../model/divers.model';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<Parametre[]> {
    return this.http.get<Parametre[]>('/parametre/all');
  }

  public update(parametre: Parametre): Observable<void> {
    return this.http.put<void>('/parametre/update', parametre);
  }
}
