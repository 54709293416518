import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TypeStructure} from '../model/nomenclature.model';

@Injectable()
export class TypeStructureService {

  constructor(private http: HttpClient) {
  }

  public getAllTypesStructure(): Observable<TypeStructure[]> {
    return this.http.get<TypeStructure[]>('/type-structure/all');
  }

  public getAllTypeStructureByCategorie(categorie: string): Observable<TypeStructure[]> {
    return this.http.get<TypeStructure[]>('/type-structure/categorie/' + categorie);
  }

  public save(typeStructures: TypeStructure[]): Observable<TypeStructure[]> {
    return this.http.put<TypeStructure[]>('/type-structure/save', typeStructures);
  }



}
