import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-prendre-en-charge',
  templateUrl: './prendre-en-charge.component.html',
  styleUrls: ['./prendre-en-charge.component.scss']
})
export class PrendreEnChargeComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;

  commentaire: string;
  avertir: boolean;

  user: User;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService) {
    this.avertir = true;
  }

  takeOver() {
    this.loaderService.start();
    this.workflowService.takeOver(
      {
        demandeId: this.demande.demandeId,
        motif: this.commentaire,
        etatDemande: this.demande.etatDemande,
        avertir: this.avertir
      }
    ).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }
}
