import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigAssetLoaderService, Configuration} from '../configAssetLoader.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  conf: Configuration;

  constructor(private http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => this.conf = res);
  }

  public download(documentId: number) {
    this.getKeyByDocumentId(documentId).subscribe(timedKey => {
       window.open(this.conf.apiUrl + '/download/public/' + timedKey.key, '_self');
    });
  }

  private getKeyByDocumentId(documentId: number): Observable<any> {
    return this.http.get<any>('/download/document/' + documentId);
  }

}
