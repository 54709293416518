import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Event} from '../model/divers.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) {
  }

  public getAllEvents(structureId: number): Observable<Event[]> {
    return this.http.get<Event[]>('/event/' + structureId);
  }
}
