import {Component, Inject} from '@angular/core';
import {RepartStructSI} from '../../../../model/association.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-histo-associations-dialog',
  templateUrl: './histo-associations-dialog.component.html',
  styleUrls: ['./histo-associations-dialog.component.scss']
})
export class HistoAssociationsDialogComponent {

  constructor( public dialogRef: MatDialogRef<HistoAssociationsDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  first = 0;
  rows = 10;
  rowsPerPageOptions = [10, 25, 50];

}

export interface DialogData {
  histo: RepartStructSI[];
}
