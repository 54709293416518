<h1 mat-dialog-title>{{data.question}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <form [formGroup]="form">
    <mat-form-field class="col-lg-12" appearance="outline" *ngIf="data.isStart">
      <mat-label>Nouveau code</mat-label>
      <input matInput placeholder="Nouveau code" formControlName="codeSI" required>
    </mat-form-field>

    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Commentaire</mat-label>
      <textarea matInput formControlName="motif" placeholder="Commentaire"
                [maxLength]="200" required></textarea>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="onConfirm()"
          [disabled]="form.invalid">
    <i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
