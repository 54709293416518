<div class="row justify-content-center">ol-lg-8
  <mat-card class="col-lg-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Formulaire de création d'un autre type de lien</h4>
    </mat-card-header>

    <mat-card-content  class="mt-4">
      <form [formGroup]="form">
        <app-lien-form #lienFormComponent [structure]="structure" [categorie]="categorie"></app-lien-form>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la demande</mat-label>
          <textarea matInput formControlName="motif" placeholder="Motif de la demande" [maxLength]="200" required></textarea>
          <mat-error *ngIf="form.controls['motif'].invalid && form.controls['motif'].touched">Valeur requise
          </mat-error>
        </mat-form-field>

        <div class="row justify-content-center">
          <button class="col-lg-6 mt-2" mat-raised-button color="primary"
                  matTooltip="création d'un lien non-hiérarchique"
                  (click)="createLien()" [disabled]="lienFormComponent.isDisabled() || form.invalid">
            {{'envoyer votre demande de création de lien' | uppercase}}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
