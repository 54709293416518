import {Component, ViewChild} from '@angular/core';
import {User} from '../../../../model/user.model';
import {MatDialogRef} from '@angular/material/dialog';
import {Structure} from '../../../../model/structure.model';
import {StructureTreeComponent} from '../../../../components/ui/structureTree/structureTree.component';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-structure-tree-dialog',
  templateUrl: './structure-tree-dialog.component.html',
  styleUrls: ['./structure-tree-dialog.component.scss']
})
export class StructureTreeDialogComponent {

  @ViewChild(StructureTreeComponent) structureTreeComponent: StructureTreeComponent;

  user: User;

  constructor(private userService: UserService, public dialogRef: MatDialogRef<StructureTreeDialogComponent>) {
    this.user = this.userService.getCurrentUser();
  }

  onCancel(): void {
    this.dialogRef.close({structure: null});
  }

  onSelectedStructure(structure: Structure) {
    this.dialogRef.close({structure});
  }
}
