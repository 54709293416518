<div class="row justify-content-center mt-4">
  <h1 class="title">Consultation des logs</h1>
</div>

<div class="row justify-content-center mt-4">
  <div class="col-lg-4">
    <mat-accordion>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>{{'Filtrer les logs'}}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-recherche-log-form (searchEmitter)="search($event)"></app-recherche-log-form>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-12" *ngIf="logs?.length === 0">
    <h4 class="text-center">Votre recherche n'a retourné aucun résultat</h4>
  </mat-card>
</div>

<div class="row buffer" *ngIf="logs?.length > 0">
  <app-log class="col-lg-12"
           [logs]="logs"
           [first]=first
           [totalRecords]="totalRecords"
           [rows]=rows
           (pageEmitter)="onPageChange($event)"></app-log>
</div>
