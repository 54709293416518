import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Contact} from '../../../model/structure.model';
import {ContactService} from '../../../services/contact.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() selected: boolean;
  @Input() contact: Contact;
  @Input() updateMode: boolean;

  constructor(private contactService: ContactService) {
  }

  toggleRemove() {
    this.contact.isValid = false;
    this.contactService.save(this.contact).subscribe(() => this.actionEmitter.emit())
  }

}
