import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Aide} from '../model/divers.model';

@Injectable({
  providedIn: 'root'
})
export class AideService {

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<Aide[]> {
    return this.http.get<Aide[]>('/aide/all');
  }

  public getAideByid(aideId: number): Observable<Aide> {
    return this.http.get<Aide>('/aide/' + aideId);
  }

  public insert(aide: Aide): Observable<Aide> {
    return this.http.put<Aide>('/aide/insert', aide);
  }

  public update(aide: Aide): Observable<void> {
    return this.http.put<void>('/aide/update', aide);
  }

  public setDisplay(aide: Aide): Observable<void> {
    return this.http.put<void>('/aide/display', aide);
  }

  public delete(aideId: number): Observable<void> {
    return this.http.delete<void>('/aide/delete/' + aideId);
  }

}
