import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {LienService} from '../../../services/lien.service';
import {MatDialog} from '@angular/material/dialog';
import {LiensAntecedentsDialogComponent} from '../../../layout/dialog/structure/liens-antecedents-dialog/liens-antecedents-dialog.component';
import {LoaderService} from '../../../services/loader.service';
import {StructureService} from '../../../services/structure.service';
import {ACTION} from '../../../constants/enum.constant';
import {Paginator} from 'primeng/paginator';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {User} from '../../../model/user.model';

@Component({
  selector: 'app-liens-antecedents',
  templateUrl: './liens-antecedents.component.html',
  styleUrls: ['./liens-antecedents.component.scss']
})
export class LiensAntecedentsComponent implements OnChanges {

  @Output() pageEmitter: EventEmitter<Paginator> = new EventEmitter<Paginator>();
  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() liens: Lien[];
  @Input() structure: Structure;

  @Input() first: number;
  @Input() rows: number;
  @Input() totalRecords: number;

  datasource: any[];
  cols: any[];
  user: User;

  rowsPerPageOptions = [10, 25, 50];
  pageLinks: number;

  action = ACTION;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private lienService: LienService,
              private structureService: StructureService,
              private router: Router,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
    this.cols = [
      {field: 'structure', header: 'Structure nouvelle'},
      {field: 'typeLien', header: 'Type de lien'},
      {field: 'structurePorteuse', header: 'Structure antécédente'},
      {field: 'dateDebut', header: 'Date de début'},
    ];
  }

  ngOnChanges(): void {
    if (this.totalRecords && this.rows) {
      this.pageLinks = Math.ceil(this.totalRecords / this.rows);
    }

    if (this.liens) {
      this.datasource = [];
      this.liens.forEach(l => this.datasource.push(this.convertToDataSource(l)));
    }
  }

  openDialog(lien: Lien | any, action: string) {
    this.dialog
      .open(LiensAntecedentsDialogComponent, {
        minWidth: 400,
        data: {
          lien,
          action,
          fromStructure: action === ACTION.ADD
        }
      }).afterClosed().subscribe(() => this.actionEmitter.emit());
  }

  onPageChange(paginator: Paginator) {
    this.pageEmitter.emit(paginator);
  }

  onStructureSelected(structure: Structure) {
    this.router.navigate(['structure', structure.structureId]);
  }

  convertToDataSource(lien: Lien) {
    return {
      lien,
      structure: lien.structure,
      typeLien: lien.typeLien.typeLienLib,
      structurePorteuse: lien.structurePorteuse,
      dateDebut: lien.dateDebut,
    };
  }

}
