import {Component, OnInit, ViewChild} from '@angular/core';
import {TodoService} from '../../../services/todo.service';
import {Todo} from '../../../model/association.model';
import {Pagination} from '../../../model/divers.model';
import {TodoComponent} from '../../../components/administration/todo/todo.component';
import {HttpParams} from '@angular/common/http';
import {X_DATA} from '../../../constants/http.constant';
import {RechercheTodoFormComponent} from '../../../components/ui/recherche-todo-form/recherche-todo-form.component';
import {Paginator} from 'primeng/paginator';

@Component({
  selector: 'app-administration-synchro-applications-si',
  templateUrl: './administration-synchro-applications-si.component.html',
  styleUrls: ['./administration-synchro-applications-si.component.scss']
})
export class AdministrationSynchroApplicationsSiComponent implements OnInit {

  @ViewChild(TodoComponent) todoComponent: TodoComponent;
  @ViewChild(RechercheTodoFormComponent) rechercheTodoFormComponent: RechercheTodoFormComponent;

  todos: Todo[];
  first = 0;
  rows = 25;
  totalRecords: number;

  params: HttpParams;

  constructor(private todoService: TodoService) {
  }

  ngOnInit() {
    this.params = new HttpParams();
    this.getTodos();
  }

  onPageChange(paginator: Paginator) {
    this.first = paginator.first;
    this.rows = paginator.rows;
    this.getTodos();
  }

  onAction() {
    this.rechercheTodoFormComponent.search();
  }

  search(params: HttpParams) {
    this.params = params;
    this.first = 0;
    this.getTodos();
  }

  getTodos() {
    this.todoService.search(this.params, new Pagination(this.first, this.rows)).subscribe(res => {
      this.todos = res.body;
      this.totalRecords = +res.headers.get(X_DATA);
    });
  }
}
