import {Component, OnInit} from '@angular/core';
import {Aide} from '../../model/divers.model';
import {AideService} from '../../services/aide.service';
import {User} from '../../model/user.model';
import {UserService} from '../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../layout/dialog/divers/confirm-dialog/confirm-dialog.component';
import {LoaderService} from '../../services/loader.service';
import {AideDialogComponent} from '../../layout/dialog/aide/aide-dialog/aide-dialog.component';
import {TYPE_MIME} from '../../constants/enum.constant';
import {AideDisplayDialogComponent} from '../../layout/dialog/aide/aide-display-dialog/aide-display-dialog.component';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.scss']
})
export class AideComponent implements OnInit {

  user: User;
  aides: Aide[];

  typeMime = TYPE_MIME;

  typeMimeMap: Map<string, string> = new Map<string, string>([
    [TYPE_MIME.PDF.valueOf(), 'Pdf'],
    [TYPE_MIME.JPEG.valueOf(), 'Image'],
    [TYPE_MIME.MPEG.valueOf(), 'Vidéo'],
  ]);

  constructor(private aideService: AideService,
              private loaderService: LoaderService,
              private userService: UserService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.init();
  }

  afficher(aide: Aide) {
    window.open(aide.url , "_blank", 'toolbar=0,location=0,menubar=0,height=auto,width=400');
    // this.dialog.open(AideDisplayDialogComponent, {
    //   hasBackdrop:false,
    //   disableClose:true,
    //   minWidth: 400,
    //   data: {
    //     aide: aide,
    //   }
    // });
  }

  save(aide: Aide) {
    this.dialog.open(AideDialogComponent, {
      minWidth: 400,
      data: {
        title: aide ? 'Modifier l\'aide ' + aide.libelle : 'Ajout d\'une nouvelle aide',
        aide: aide,
      }
    }).afterClosed().subscribe(res => {
      if (res?.aide) {
        this.loaderService.start();
        if (aide) {
          this.aideService.update(res.aide).subscribe(() => {
            this.loaderService.stop();
            this.init();
          });
        } else {
          this.aideService.insert(res.aide).subscribe(() => {
            this.loaderService.stop();
            this.init();
          });
        }
      }
    });
  }

  supprimer(aide: Aide) {
    this.dialog.open(ConfirmDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Suppression de l\'aide ' + aide.libelle,
        question: 'Souhaitez-vous supprimer l\'aide ' + aide.libelle + ' ?'
      }
    }).afterClosed().subscribe(res => {
      if (res.ok) {
        this.loaderService.start();
        this.aideService.delete(aide.aideId).subscribe(() => {
          this.loaderService.stop();
          this.init();
        });
      }
    });
  }

  isVisible(aide: Aide) {
    aide.isValid = !aide.isValid;
    this.aideService.setDisplay(aide).subscribe(() => {
    });
  }

  private init() {
    this.aideService.getAll().subscribe({next: (res) => this.aides = res});
  }
}
