import {Component, OnInit} from '@angular/core';
import {StructureService} from '../../../services/structure.service';
import {ApplicationService} from '../../../services/application.service';
import {MatDialog} from '@angular/material/dialog';
import {StructuresAssocieesDialogComponent} from '../../../layout/dialog/synchro-application-si/structures-associees-dialog/structures-associees-dialog.component';
import {LoaderService} from '../../../services/loader.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {Application} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-application-si',
  templateUrl: './application-si.component.html',
  styleUrls: ['./application-si.component.scss']
})
export class ApplicationSIComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: Application[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private applicationService: ApplicationService,
              private structureService: StructureService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(application: Application) {
    application.hasError = false;

    if (!application.applicationLib || application.applicationLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      application.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, application.applicationLib, 'applicationLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      application.hasError = true;
    }
  }

  onSyncRespChanged(application: Application) {
    application.syncResp = !application.syncResp;

    application.displayTodoBox = false;
    application.todosACreer = false;

    if (application.syncResp) {
      if (application.applicationId) {
        this.structureService.getStructuresByApplication(application.applicationId).subscribe(
          res => {
            if (res.length > 10) {
              this.snackBar.open(
                'La synchronisation (génération de tâche d\'associations) des modifications sur le responsable des structures associées à cette application se fera à partir de l\'instant où le formulaire sera validé. ' +
                'On considère que l\'on part d\'une situation où le formulaire sera validé et dans laquelle les données actuelles sont effectivement en phase dans les deux systèmes.',
                'X',
                {panelClass: 'notif-warn'}
              );
            } else {
              application.displayTodoBox = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(application, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  onIsValidChanged(application: Application) {
    application.isValid = !application.isValid;

    if (!application.isValid) {
      if (application.applicationId) {
        this.structureService.getStructuresByApplication(application.applicationId).subscribe(
          res => {
            if (res.length > 0) {
              this.snackBar.open(
                application.applicationLib + ' est actuellement associé à au moins une structure active',
                'X',
                {panelClass: 'notif-warn'}
              );
              application.isValid = true;
            }
          });

      } else {
        const index = this.dataSource.indexOf(application, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  openStructuresAssocieesDialog(application: Application) {
    this.dialog.open(StructuresAssocieesDialogComponent, {
      minWidth: 400,
      data: {
        name: 'Liste des associations valides de l\'application ' + application.applicationLib.toUpperCase(),
        application,
      }
    });
  }

  add() {
    this.snackBar.open(
      'Attention, il conviendra d\'associer ensuite explicitement chaque structure effectivement présente dans cette application (depuis le détail des structures).',
      'X',
      {panelClass: 'notif-warn'}
    );
    this.dataSource.unshift(
      {
        applicationId: null,
        applicationLib: null,
        syncResp: false,
        todosACreer: false,
        isValid: true,
        displayTodoBox: false,
        isHidden: false,
        hasError: true,
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.applicationService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.applicationService.getAllAppliSI().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }
}
