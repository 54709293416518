import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {User} from '../../../model/user.model';
import {DemandeService} from '../../../services/demande.service';
import {Pagination} from '../../../model/divers.model';
import {DemandeComponent} from '../../../components/demande/demande/demande.component';
import {RechercheDemandeFormComponent} from '../../../components/ui/recherche-demande-form/recherche-demande-form.component';
import {HttpParams} from '@angular/common/http';
import {X_DATA} from '../../../constants/http.constant';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';
import {DEMANDE, ETAT_DEMANDE} from '../../../model/enum.model';
import {Paginator} from 'primeng/paginator';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-consultation-demandes',
  templateUrl: './consultation-demandes.component.html',
  styleUrls: ['./consultation-demandes.component.scss']
})
export class ConsultationDemandesComponent implements OnInit, AfterViewInit {

  @ViewChild(DemandeComponent) consultationDemandesComponent: DemandeComponent;
  @ViewChild(RechercheDemandeFormComponent) rechercheDemandeFormComponent: RechercheDemandeFormComponent;

  user: User;

  demandeEnum = DEMANDE;
  formControl: FormControl;
  paramGroupe: string;

  demandes: Demande[];
  first = 0;
  rows = 25;
  totalRecords: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private loaderService: LoaderService,
              private demandeService: DemandeService
  ) {
    this.user = this.userService.getCurrentUser();
    this.formControl = new FormControl(DEMANDE.DEMANDES_EN_COURS, Validators.required);
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.paramGroupe = params.groupeDemande);
  }

  ngAfterViewInit(): void {
    if (this.paramGroupe) {
      switch (this.paramGroupe) {
        case DEMANDE.DEMANDES_EN_COURS: {
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_EN_COURS);
          break;
        }
        case DEMANDE.DEMANDES_A_TRAITER: {
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_A_TRAITER);
          break;
        }
        case DEMANDE.DEMANDES_EN_ATTENTE: {
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_EN_ATTENTE);
          break;
        }
        case DEMANDE.MES_DEMANDES_NOUVELLES: {
          this.rechercheDemandeFormComponent.form.controls.isMine.setValue(true);
          this.rechercheDemandeFormComponent.form.controls.etats.setValue([ETAT_DEMANDE.NOUVEAU]);
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_EN_COURS);
          break;
        }
        case DEMANDE.MES_DEMANDES_EN_COURS: {
          this.rechercheDemandeFormComponent.form.controls.isMine.setValue(true);
          this.rechercheDemandeFormComponent.form.controls.etats.setValue([
            ETAT_DEMANDE.PRISE_EN_CHARGE_NIV_1,
            ETAT_DEMANDE.ATTENTE_MODIFICATION,
            ETAT_DEMANDE.A_TRAITER,
            ETAT_DEMANDE.A_VALIDER,
            ETAT_DEMANDE.PRISE_EN_CHARGE_NIV_2
          ]);
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_EN_COURS);
          break;
        }
        case DEMANDE.MES_DEMANDES_TRAITEES: {
          this.rechercheDemandeFormComponent.form.controls.isMine.setValue(true);
          this.onGroupeDemandesChange(DEMANDE.DEMANDES_TRAITEES);
          break;
        }
      }

    } else {
      this.search(new HttpParams());
    }
  }

  onAction() {
    this.userService.getUpdatedUser().subscribe(user => {
      this.userService.setUser(user)
      this.onGroupeDemandesChange(this.formControl.value);
    });
  }

  onGroupeDemandesChange(groupeDemande: string) {
    this.router.navigate(['/demandes/gestion-demandes/', groupeDemande]).then(() => {
      this.formControl.setValue(groupeDemande);
      this.first = 0;
      this.rechercheDemandeFormComponent?.search();
    });
  }

  onChangePage(paginator: Paginator) {
    this.first = paginator.first;
    this.rows = paginator.rows;
    this.rechercheDemandeFormComponent?.search();
  }

  search(params: HttpParams) {
    params = params.append('groupeDemandes', this.formControl.value);
    this.loaderService.start();
    this.demandeService.search(params, new Pagination(this.first, this.rows)).subscribe(res => {
      this.loaderService.stop();
      this.demandes = res.body;
      this.totalRecords = +res.headers.get(X_DATA);
    });
  }

  getTitle(): string {
    switch (this.formControl.value) {
      case DEMANDE.DEMANDES_EN_COURS:
        return 'Demandes en cours';
      case DEMANDE.DEMANDES_A_TRAITER:
        return 'Demandes à traiter';
      case DEMANDE.DEMANDES_EN_ATTENTE:
        return 'Demandes en attente';
      case DEMANDE.DEMANDES_TRAITEES:
        return 'Demandes traitées';
    }
  }
}
