import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {getDDMMYYYYHHmm} from '../../../utils/date.utils';
import {Log} from '../../../model/log.model';
import {Paginator} from 'primeng/paginator';
import {Router} from '@angular/router';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnChanges {

  @Output() pageEmitter: EventEmitter<Paginator> = new EventEmitter<Paginator>();

  @Input() logs: Log[];
  @Input() structure: Structure;

  @Input() first: number;
  @Input() rows: number;
  @Input() totalRecords: number;

  user: User;
  datasource: any[];
  cols: any[];
  selectedRow: any;

  rowsPerPageOptions = [10, 25, 50];
  pageLinks: number;

  constructor(private userService: UserService, private router: Router) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'structure', header: 'Structure'},
      {field: 'typeLog', header: 'Type de log'},
      {field: 'date', header: 'Date'},
      {field: 'acteur', header: 'Acteur'},
      {field: 'commentaire', header: 'Commentaire'},
    ];
  }

  ngOnChanges() {
    if (this.totalRecords && this.rows) {
      this.pageLinks = Math.ceil(this.totalRecords / this.rows);
    }

    if (this.logs) {
      this.datasource = [];
      this.logs.forEach(l => this.datasource.push(this.convertToDataTable(l)));
    }
  }

  onRowSelected() {
    if (this.selectedRow) {
      this.router.navigate(['structure', this.selectedRow.structure.structureId]);
    }
  }

  onPageChange(paginator: Paginator) {
    this.pageEmitter.emit(paginator);
  }

  convertToDataTable(log: Log) {
    return {
      structure: log.structure,
      libelleLong: log.structure.identite.libelleLong,
      typeLog: log.typeLog.typeLogLib,
      date: getDDMMYYYYHHmm(log.dateLog),
      acteur: log.individu.nom + ' ' + log.individu.prenom,
      commentaire: log.commentaire
    };
  }
}
