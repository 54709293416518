import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {User} from '../../../model/user.model';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {LienRequest} from '../../../model/workflow.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {TypeLien} from '../../../model/nomenclature.model';
import {CAT_STRUCTURE, ETAT_VALID} from '../../../model/enum.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {LienService} from '../../../services/lien.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-ajout-structure-rattachement',
  templateUrl: './ajout-structure-rattachement.component.html',
  styleUrls: ['./ajout-structure-rattachement.component.scss']
})
export class AjoutStructureRattachementComponent {

  @Output() demandeEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;

  displayForm: boolean;
  user: User;

  structRattach: Structure;
  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private snackBar: MatSnackBar,
              private workflowService: WorkflowService,
              private lienService: LienService,
              private structTreeService: StructureTreeService) {
    this.user = this.userService.getCurrentUser();
    this.displayForm = false;
    this.structRattach = null;
    this.form = new FormGroup({
      dateDebut: new FormControl(null, [Validators.required]),
      motif: new FormControl(null, Validators.required),
    });
  }

  onSelectedStructure(structure: Structure) {
    this.displayForm = false;
    this.structRattach = null;
    if (structure) {
      if (structure.catStructure !== CAT_STRUCTURE.ORGA || !structure.interne) {
        this.snackBar.open(
          'Attention, la structure de rattachement doit être une structure organisationnelle interne.',
          'X',
          {panelClass: 'notif-warn'}
        );
      } else {
        this.loaderService.start();
        this.structTreeService.checkStructureRattachement(structure.structureId, this.structure.structureId).subscribe(() => {
          this.loaderService.stop();
          this.displayForm = true;
          this.structRattach = structure;
        });
      }
    }
  }

  createLien() {
    const requestLien: LienRequest = {
      lienDemande: this.structure.liensParent[0],
      lienContexte: {
        lienId: null,
        typeLien: new TypeLien(1),
        structureId: this.structure.structureId,
        structPorteuseId: this.structRattach.structureId,
        dateDebut: formatLocalDateWithUTC(this.form.controls.dateDebut.value),
        dateFin: null,
        etatValid: ETAT_VALID.A_VALIDER,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false
      },
      motif: this.form.controls.motif.value
    };

    this.loaderService.start();
    this.workflowService.insertLien(requestLien).subscribe(() => {
      this.displayForm = false;
      this.structRattach = null;
      this.form.reset();
      this.loaderService.stop();
      this.demandeEmitter.emit();
    });
  }
}
