import {Component, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {LoaderService} from '../../../services/loader.service';
import {MatDialog} from '@angular/material/dialog';
import {ResultLdapDialogComponent} from '../../../layout/dialog/ldap/result-ldap-dialog/result-ldap-dialog.component';
import {LdapAccount} from '../../../model/user.model';
import {LdapService} from '../../../services/ldap.service';

@Component({
  selector: 'app-recherche-ldap-form',
  templateUrl: './recherche-ldap-form.component.html',
  styleUrls: ['./recherche-ldap-form.component.scss']
})
export class RechercheLdapFormComponent implements OnChanges {

  @Output() ldapAccountEventEmitter: EventEmitter<LdapAccount> = new EventEmitter<LdapAccount>();

  @Input() selectedLdapAccount: LdapAccount;
  @Input() displaySelection = true;
  @Input() title;
  @Input() required = true;

  searchForm: FormGroup;
  cols: any[];

  constructor(private loaderService: LoaderService,
              private ldapService: LdapService,
              public dialog: MatDialog) {
    this.searchForm = new FormGroup({
      search: new FormControl(null, [Validators.required, Validators.minLength(2)]),
    });

    this.cols = [
      {field: 'nomComplet', header: 'NOM Prénom'},
      {field: 'dateNaissance', header: 'Date de Naissance'},
      {field: 'affectations', header: 'Affectations'},
      {field: 'observations', header: 'Observations'},
    ];

    this.title = 'Recherche dans l\'annuaire LDAP';
  }

  ngOnChanges() {
    if (!this.selectedLdapAccount) {
      this.searchForm.reset();

    } else {
      this.searchForm.controls.search.setValue(this.selectedLdapAccount.nomComplet);
      if (!this.selectedLdapAccount.bloque) {
        this.ldapAccountEventEmitter.emit(this.selectedLdapAccount);
      }
    }
  }

  init() {
    this.searchForm.reset();
  }

  openResultsDialog() {
    if (this.searchForm.valid) {
      this.loaderService.start();
      this.ldapService.getLdapSearchByName(this.searchForm.controls.search.value).subscribe(ldapAccounts => {
        this.loaderService.stop();
        this.dialog.open(ResultLdapDialogComponent, {
          minWidth: 400,
          data: {
            ldapAccounts
          }
        }).afterClosed().subscribe(ldapAccount => {
          if (ldapAccount) {
            this.selectedLdapAccount = ldapAccount;
            this.ldapAccountEventEmitter.emit(ldapAccount);
          }
        });
      });
    }
  }
}
