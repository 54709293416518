<div class="row justify-content-center">
  <mat-card class="col-lg-8 mt-4">
    <mat-card-header>
      <h4>Identité de la structure</h4>
    </mat-card-header>

    <mat-card-content>
      <app-identite-form [categorie]="catStructure.MAT"
                         [identiteStructure]="demande?.lien.structure.identite"
                         [structurePorteuseIds]="[this.structurePorteuse?.structureId]"
                         [readonly]="readonly"></app-identite-form>
    </mat-card-content>
  </mat-card>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-8 mt-4 mb-4">
    <mat-card-header>
      <h4>Informations complémentaires</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <!--STRUCTURE PORTEUSE -->
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Structure porteuse</mat-label>
          <input matInput
                 type="text"
                 placeholder="Libellé de la structure porteuse"
                 [matAutocomplete]="auto"
                 formControlName="structurePorteuse"
                 required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option appHighlightStructure [structure]="structure" *ngFor="let structure of filteredOptions | async"
                        [value]="structure.identite.libelleLong">
              {{structure | libelleStructure:user}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!--TYPE DE LIEN -->
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Type de relation</mat-label>
          <mat-select formControlName="typeLien" placeholder="Type de relation" required>
            <mat-option *ngFor="let t of typeLiens" [value]="t.typeLienId">{{ t.typeLienLib}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['typeLien'].hasError('required') && form.controls['typeLien'].touched">Valeur requise</mat-error>
        </mat-form-field>

        <!--DOMAINES D'ACTIVITE -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Domaines d'activités</mat-label>
          <mat-select formControlName="domaines" placeholder="Domaines d'activités" multiple>
            <mat-option *ngFor="let d of domaines" [value]="d.domaineId">{{ d.domaineLib}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline" *ngIf="!demande">
          <mat-label>Motif de la demande</mat-label>
          <textarea formControlName="motif" matInput rows="1" [maxLength]="200" placeholder="Motif de la demande" required></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="interne" class="col-lg-12 mt-4 mb-4"
                      (change)="form.controls.interne.setValue($event.checked)"
                      [checked]="form.controls.interne.value">Structure interne de l'université
        </mat-checkbox>
      </form>
    </mat-card-content>
  </mat-card>
</div>
