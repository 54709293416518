import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {WorkflowService} from '../../../services/workflow.service';
import {User} from '../../../model/user.model';
import {LienRequest} from '../../../model/workflow.model';
import {LoaderService} from '../../../services/loader.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';

@Component({
  selector: 'app-suppression-lien',
  templateUrl: './suppression-lien.component.html',
  styleUrls: ['./suppression-lien.component.scss']
})
export class SuppressionLienComponent {

  @Output() demandeEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() lienContexte: Lien;
  @Input() structure: Structure;

  user: User;

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private workflowService: WorkflowService) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      dateFin: new FormControl(null, Validators.required),
      motif: new FormControl(null, Validators.required),
    });
  }

  removeLien() {
    const lienContexte = this.lienContexte;
    lienContexte.dateFin = formatLocalDateWithUTC(this.form.controls.dateFin.value);
    lienContexte.structure = null;
    lienContexte.structurePorteuse = null;

    const requestLien: LienRequest = {
      lienDemande: this.structure.liensParent[0],
      lienContexte,
      motif: this.form.controls.motif.value
    };

    this.loaderService.start();
    this.workflowService.removeLien(requestLien).subscribe(() => {
      this.form.reset();
      this.loaderService.stop();
      this.demandeEmitter.emit();
    });
  }
}
