<h1 mat-dialog-title>Formulaire d'archivage de la structure {{data.selectedStructure.identite.libelleLong}}</h1>


<div mat-dialog-content class="row justify-content-center mt-4">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Motif de la demande</mat-label>
      <textarea matInput [(ngModel)]="motif" placeholder="Motif de la demande" [maxLength]="200"></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="Demande d'archivage"
          (click)="archive()" [disabled]="!motif || motif.trim() === ''">
    <i class="fas fa-check fa-2x"></i>
  </button>

  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>


