import {Component, Input, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SyncRefAqService} from '../../../../services/syncRefAq.service';
import {SyncRefAq} from '../../../../model/referentiel-aquitain.model';
import {Structure} from '../../../../model/structure.model';
import {LoaderService} from '../../../../services/loader.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StructureService} from '../../../../services/structure.service';

@Component({
  selector: 'app-referentiel-aquitain-form',
  templateUrl: './referentiel-aquitain-form.component.html',
  styleUrls: ['./referentiel-aquitain-form.component.scss']
})
export class ReferentielAquitainFormComponent implements OnChanges {

  @Input() structure: Structure;
  @Input() readonly = false;

  form: FormGroup;

  typesStructure: any[];
  categoriesStructure: any[];
  typesLien: any[];
  structures: any[];
  filteredStructures: Observable<any[]>;

  constructor(
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private structureService: StructureService,
    private syncRefAqService: SyncRefAqService) {
    this.form = new FormGroup({
      id: new FormControl(null),
      typeStructure: new FormControl(null, Validators.required),
      catStructure: new FormControl(null, Validators.required),
      typeLien: new FormControl(null, Validators.required),
      structurePorteuse: new FormControl(null, Validators.required)
    });
  }

  ngOnChanges() {
    this.loaderService.start();
    if (this.structure) {
      return Promise.all([this.getStructure(), this.getRefTypeStructure(), this.getAllRefCatStructure(), this.getAllRefTypesLien(), this.getAllRefStructures()]).then(() => {
        this.readonly ? this.form.disable() : this.form.enable();
        this.structureService.getStructureByStructureId(this.structure.liensParent[0].structPorteuseId).subscribe(structurePorteuse => {
          if (!structurePorteuse.idRefAq) {
            this.snackBar.open(
              'Attention, la structure ' + structurePorteuse.identite.libelleLong + ' n\'existe pas dans le Référentiel aquitain. ' +
              'Vous pouvez soit préalablement la synchroniser ou soit choisir une autre structure de rattachement dans le Référentiel aquitain via le formulaire ci-dessous.',
              'X',
              {panelClass: 'notif-warn'}
            );
          }

          this.syncRefAqService.getSyncRefAqByLienId(this.structure.liensParent[0].lienId).subscribe(syncRefAq => {
            this.loaderService.stop();
            if (syncRefAq) {
              this.form.controls.id.setValue(syncRefAq.syncRefAqId);
              this.form.controls.typeStructure.setValue(syncRefAq.refTypeStructureId);
              this.form.controls.catStructure.setValue(syncRefAq.refCatStructureId);
              this.form.controls.typeLien.setValue(syncRefAq.refTypeLienId);
              const structures = this.structures.filter(s => s.id === syncRefAq.refStructurePorteuseId);
              if (structures.length === 1) {
                this.form.controls.structurePorteuse.setValue(structures[0].libelleLong);
              }
              syncRefAq.refTypeStructureId && syncRefAq.refCatStructureId && syncRefAq.refTypeLienId && syncRefAq.refStructurePorteuseId ? this.form.disable() : this.form.enable();
            }
          });
        });
      });
    }
  }

  getRefTypeStructure(): Promise<void> {
    return new Promise(resolve => {
        this.syncRefAqService.getAllRefTypesStructure().subscribe(res => {
          this.typesStructure = res.records;
          resolve();
        });
      }
    );
  }

  getAllRefCatStructure(): Promise<void> {
    return new Promise(resolve => {
        this.syncRefAqService.getAllRefCatStructure().subscribe(res => {
          this.categoriesStructure = res;
          resolve();
        });
      }
    );
  }

  getStructure(): Promise<void> {
    return new Promise(resolve => {
        this.structureService.getStructureByStructureId(this.structure.structureId).subscribe(res => {
          this.structure = res;
          resolve();
        });
      }
    );
  }

  getAllRefTypesLien(): Promise<void> {
    return new Promise(resolve => {
        this.syncRefAqService.getAllRefTypesLien().subscribe(res => {
          this.typesLien = res.records;
          resolve();
        });
      }
    );
  }

  getAllRefStructures(): Promise<void> {
    return new Promise(resolve => {
        this.syncRefAqService.getAllRefStructures().subscribe(res => {
          this.structures = res;
          this.filteredStructures = this.form.controls.structurePorteuse.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
          resolve();
        });
      }
    );
  }

  onStructureSelect(libelleLong: string) {
    if (libelleLong) {
      this.form.controls.structurePorteuse.setValue(libelleLong);
    }
  }

  private _filter(libelleLong: string): any[] {
    return libelleLong ? this.structures.filter(str => str.libelleLong.toLowerCase().includes(libelleLong.toLowerCase())) : [];
  }

  getSyncRefAq(): SyncRefAq {
    return {
      syncRefAqId: this.form.controls.id.value,
      lienId: this.structure.liensParent[0].lienId,
      refStructureId: this.structure.idRefAq,
      refTypeStructureId: this.form.controls.typeStructure.value,
      refCatStructureId: this.form.controls.catStructure.value,
      refStructurePorteuseId: this.structures.filter(s => s.libelleLong === this.form.controls.structurePorteuse.value)[0].id,
      refTypeLienId: this.form.controls.typeLien.value
    };
  }
}
