import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {IdentiteStructure, Structure} from '../../../../model/structure.model';
import {ETAT_VALID} from '../../../../model/enum.model';
import {Role, User} from '../../../../model/user.model';
import {RoleService} from '../../../../services/role.service';
import {ConfirmDialogComponent} from '../../divers/confirm-dialog/confirm-dialog.component';
import {isCurrentRole} from '../../../../utils/user.utils';
import * as moment from 'moment';
import {ResponsableHistoFormDialogComponent} from '../../privilege/responsable-histo-form-dialog/responsable-histo-form-dialog.component';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-histo-identite-dialog',
  templateUrl: './histo-structure-dialog.component.html',
  styleUrls: ['./histo-structure-dialog.component.scss']
})
export class HistoStructureDialogComponent {

  user: User;

  identites: IdentiteStructure[];


  first = 0;
  rows = 10;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private roleService: RoleService,
              private userService: UserService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();
    this.identites = data.structure.identites.filter(i => i.etatValid === ETAT_VALID.VALIDE);
  }


  addResponsable() {
    this.dialog.open(ResponsableHistoFormDialogComponent, {
      minWidth: 400,
      data: {
        role: {},
        structure: this.data.structure,
        roles: this.data.roles
      }
    }).afterClosed().subscribe(res => {
      if (res.role) {
        this.roleService.insert(res.role, res.ldapAccount).subscribe(insered => {
          this.data.roles.push(insered);
          this.data.roles.sort((a, b) => {
            if (moment(a.dateDebut).isBefore(b.dateDebut)) {
              return -1;
            } else if (moment(a.dateDebut).isAfter(b.dateDebut)) {
              return 1;
            } else {
              return 0;
            }
          });
        });
      }
    });
  }

  updateResponsable(role: Role) {
    this.dialog.open(ResponsableHistoFormDialogComponent, {
      minWidth: 400,
      data: {
        role,
        structure: this.data.structure,
        roles: this.data.roles.filter(r => r.roleId !== role.roleId)
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.roleService.update(res.role, res.ldapAccount);
      }
    });
  }

  deleteResponsable(role: Role) {
    this.dialog.open(ConfirmDialogComponent, {
      minWidth: 400,
      data: {
        title: 'Suppression d\'un responsable de l\'historique de la structure',
        question: 'Souhaitez-vous supprimer ' + role.individu.prenom + ' ' + role.individu.nom.toUpperCase() + ' de l\'historique de la structure ?'
      }
    }).afterClosed().subscribe(res => {
      if (res.ok) {
        this.roleService.delete(role.roleId).subscribe(() => this.data.roles = this.data.roles.filter(r => r.roleId !== role.roleId));
      }
    });
  }

  isCurrent(role: Role): boolean {
    return isCurrentRole(role);
  }
}

export interface DialogData {
  name: string;
  structure: Structure;
  roles: Role[];
}
