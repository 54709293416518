import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contact} from '../model/structure.model';

@Injectable()
export class ContactService {

  constructor(private http: HttpClient) {
  }

  public getContactsByStructure(structureId: number): Observable<Contact[]> {
    return this.http.get<Contact[]>('/contact/structure/' + structureId);
  }

  public save(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>('/contact/save', contact);
  }
}
