<div class="d-inline-block">
  <button class="button" *ngIf="(single && selectedStructures.length === 0) || multiple"
          (click)="openDialog()">Ajouter une structure
  </button>
  <span class="ml-2 alert-danger" *ngIf="selectedStructures.length === 0">Vous devez sélectionner au moins une structure</span>
</div>

<ul class="d-inline-block">
  <li appHighlightStructure [structure]="struct" *ngFor="let struct of selectedStructures">
    {{struct | libelleStructure:user}}
    <span class="clickable ml-2"
          *ngIf="!readonly"
          (click)="remove(struct)">
    <i class="fas fa-trash"></i></span>
  </li>
</ul>


