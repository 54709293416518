import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ErrorAuthenticationComponent} from './components/ui/error-authentication/error-authentication.component';
import {CreationStructureOrganisationnelleComponent} from './pages/gestion-des-demandes/creation-structure-organisationnelle/creation-structure-organisationnelle.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {CreationStructureMatricielleComponent} from './pages/gestion-des-demandes/creation-structure-matricielle/creation-structure-matricielle.component';
import {ConsultationDemandesComponent} from './pages/gestion-des-demandes/consultation-demandes/consultation-demandes.component';
import {RechercheComponent} from './pages/recherche/recherche.component';
import {ApplicationSIComponent} from './pages/nomenclature/application-si/application-si.component';
import {TypeAdresseComponent} from './pages/nomenclature/type-adresse/type-adresse.component';
import {TypeCodeComponent} from './pages/nomenclature/type-code/type-code.component';
import {TypeStructureComponent} from './pages/nomenclature/type-structure/type-structure.component';
import {TypeLienComponent} from './pages/nomenclature/type-lien/type-lien.component';
import {DomaineComponent} from './pages/nomenclature/domaine/domaine.component';
import {TutelleComponent} from './pages/nomenclature/tutelle/tutelle.component';
import {
  AdministrationSynchroReferentielAquitainComponent
} from './pages/administration/administration-synchro-referentiel-aquitain/administration-synchro-referentiel-aquitain.component';
import {AdministrationLogsComponent} from './pages/administration/administration-logs/administration-logs.component';
import {AdministrationSynchroApplicationsSiComponent} from './pages/administration/administration-synchro-applications-si/administration-synchro-applications-si.component';
import {AdministrationArchivageStructureComponent} from './pages/administration/administration-archivage-structure/administration-archivage-structure.component';
import {AdministrationComptesApplicatifsComponent} from './pages/administration/administration-comptes-applicatifs/administration-comptes-applicatifs.component';
import {MesStructuresComponent} from './pages/mes-structures/mes-structures.component';
import {TypeVoierieComponent} from './pages/nomenclature/type-voierie/type-voierie.component';
import {InstanceComponent} from './pages/nomenclature/instance/instance.component';
import {AdministrationLiensAntecedentsComponent} from './pages/administration/administration-liens-antecedents/administration-liens-antecedents.component';
import {PrivilegesParStructureComponent} from './pages/gestion-des-privileges/privileges-par-structure/privileges-par-structure.component';
import {GestionAdministrateurModerateurComponent} from './pages/gestion-des-privileges/gestion-administrateur-moderateur/gestion-administrateur-moderateur.component';
import {PrivilegesParPersonneComponent} from './pages/gestion-des-privileges/privileges-par-personne/privileges-par-personne.component';
import {AideComponent} from './pages/aide/aide.component';
import {AdministrationSurveillanceConnexionsComponent} from './pages/administration/administration-surveillance-connexions/administration-surveillance-connexions.component';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'demandes/creation-structure-organisationnelle', component: CreationStructureOrganisationnelleComponent, runGuardsAndResolvers: 'always'},
  {path: 'demandes/creation-structure-matricielle', component: CreationStructureMatricielleComponent, runGuardsAndResolvers: 'always'},
  {path: 'demandes/gestion-demandes/:groupeDemande', component: ConsultationDemandesComponent, runGuardsAndResolvers: 'always'},
  {path: 'structure', component: MesStructuresComponent, runGuardsAndResolvers: 'always'},
  {path: 'structure/:structureId', component: MesStructuresComponent, runGuardsAndResolvers: 'always'},
  {path: 'recherche', component: RechercheComponent, runGuardsAndResolvers: 'always'},
  {path: 'aide', component: AideComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/tutelles', component: TutelleComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/domaines', component: DomaineComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/instance', component: InstanceComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/types-lien', component: TypeLienComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/types-code', component: TypeCodeComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/types-adresse', component: TypeAdresseComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/types-structure', component: TypeStructureComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/types-voierie', component: TypeVoierieComponent, runGuardsAndResolvers: 'always'},
  {path: 'nomenclatures/applis-si', component: ApplicationSIComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/logs', component: AdministrationLogsComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/synchro-ref-aq', component: AdministrationSynchroReferentielAquitainComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/synchro-appli-si', component: AdministrationSynchroApplicationsSiComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/archivage-structure', component: AdministrationArchivageStructureComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/liens-antecedents', component: AdministrationLiensAntecedentsComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/comptes-applicatifs', component: AdministrationComptesApplicatifsComponent, runGuardsAndResolvers: 'always'},
  {path: 'administration/surveillance-connexions', component: AdministrationSurveillanceConnexionsComponent, runGuardsAndResolvers: 'always'},
  {path: 'privilege/privileges-par-structure', component: PrivilegesParStructureComponent, runGuardsAndResolvers: 'always'},
  {path: 'privilege/gestion-administrateur-moderateur', component: GestionAdministrateurModerateurComponent, runGuardsAndResolvers: 'always'},
  {path: 'privilege/ajout-gestionnaire', component: PrivilegesParPersonneComponent, runGuardsAndResolvers: 'always'},
  {path: 'error-authenticationService', component: ErrorAuthenticationComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
