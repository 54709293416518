<div class="row justify-content-center mt-4">
  <h1 class="title">Gestion des liens antécédents</h1>
</div>

<app-recherche-lien-form (searchEmitter)="search($event)"></app-recherche-lien-form>

<div class="row justify-content-center">
  <button class="btn-white float-right" mat-raised-button (click)="addLienAntecedent()">Ajouter un lien antécédent
  </button>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-12" *ngIf="liens?.length === 0">
    <h4 class="text-center">Votre recherche n'a retourné aucun résultat</h4>
  </mat-card>
</div>

<div class="row justify-content-center buffer" *ngIf="liens?.length > 0">
  <app-liens-antecedents class="col-lg-12"
                         [liens]="liens"
                         [first]=first
                         [totalRecords]="totalRecords"
                         [rows]=rows
                         (pageEmitter)="OnPageChange($event)"
                         (actionEmitter)="onAction()">
  </app-liens-antecedents>
</div>
