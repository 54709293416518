<form class="row justify-content-center" [formGroup]="searchForm">
  <mat-form-field class="col-lg-10" appearance="outline">
    <mat-label>Recherche dans l'annuaire</mat-label>
    <input matInput formControlName="search" placeholder="Nom Prénom" [required]="required">
    <mat-error *ngIf="searchForm.controls['search'].hasError('required')
          && searchForm.controls['search'].touched">Valeur requise
    </mat-error>
    <mat-error *ngIf="searchForm.controls['search'].hasError('minlength')">Vous devez saisir au moins 2 caractères dans
      le champ de recherche proposé
    </mat-error>
  </mat-form-field>

  <button mat-raised-button class="ml-2 mt-2" color="primary" matTooltip="Chercher dans l'annuaire LDAP"
          (click)="openResultsDialog()"
          [disabled]="searchForm.invalid || searchForm.controls.search.value.trim() === ''"><i
    class="fas fa-search"></i>
  </button>

  <p *ngIf="displaySelection">
    <span class="alert-danger" *ngIf="!selectedLdapAccount">{{'Aucune personne n\'a été sélectionnée.'}}</span>
    <span *ngIf="selectedLdapAccount">Personne selectionnée : <span
      class="alert-info"><b>{{selectedLdapAccount.prenom}} {{selectedLdapAccount.nom | uppercase}}</b></span></span>
  </p>
</form>
