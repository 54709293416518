<div class="row justify-content-center mt-4">
  <h1 class="title">Synchronisation des applications du SI</h1>
</div>

<app-recherche-todo-form (searchEmitter)="search($event)"></app-recherche-todo-form>

<div class="row justify-content-center">
  <mat-card class="col-lg-12" *ngIf="todos?.length === 0">
    <h4 class="text-center">Votre recherche n'a retourné aucun résultat</h4>
  </mat-card>
</div>

<div class="row buffer justify-content-center" *ngIf="todos?.length > 0">
  <app-todo [todos]="todos"
            [first]=first
            [totalRecords]="totalRecords"
            [rows]=rows
            (actionEmitter)="onAction()"
            (pageEmitter)="onPageChange($event)"></app-todo>
</div>
