<div class="row justify-content-center mt-4 mb-4">
  <mat-card class="card col-lg-4">
    <span class="float-right clickable ml-2"
          matTooltip="Pré-remplir le formulaire avec une adresse existante ou à défaut l'adresse de la structure porteuse"
          (click)="fill(adressePreremplie)"
          *ngIf="adressePreremplie"><i class="fas fa-file-alt fa-2x"></i></span>
    <mat-card-header>
      <h4>Formulaire d'adresse</h4>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form">
        <!-- TYPE ADRESSE -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Type d'adresse</mat-label>
          <mat-select formControlName="typeAdresse" placeholder="Type d'adresse"
                      (selectionChange)="onTypeAdresseChange()" required>
            <mat-option *ngFor="let t of typeAdresses" [value]="t.typeAdresseId">{{ t.typeAdresseLib}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls['typeAdresse'].hasError('required') && form.controls['typeAdresse'].touched">Valeur
            requise
          </mat-error>
        </mat-form-field>

        <!-- POINT GEO -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Point géographique</mat-label>
          <input formControlName="pointGeo" matInput placeholder="Ex. Campus, Bât, Etage, Bureau, ..." maxlength="200">
        </mat-form-field>

        <!-- NUM -->
        <mat-form-field class="col-lg-3" appearance="outline">
          <mat-label>Numéro</mat-label>
          <input formControlName="num" matInput placeholder="N°" maxlength="38">
          <mat-error
            *ngIf="form.controls['num'].hasError('pattern') && form.controls['num'].touched">Format non valide
          </mat-error>
        </mat-form-field>

        <!-- COMPL -->
        <mat-form-field class="col-lg-3" appearance="outline" *ngIf="form.controls.num.value">
          <mat-label>Bis/Ter</mat-label>
          <mat-select formControlName="compl" placeholder="Bis/Ter">
            <mat-option [value]="null"></mat-option>
            <mat-option [value]="'B'">B</mat-option>
            <mat-option [value]="'T'">T</mat-option>
            <mat-option [value]="'Q'">Q</mat-option>
            <mat-option [value]="'C'">C</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- TYPE DE VOIERIE -->
        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Type de voierie</mat-label>
          <mat-select formControlName="typeVoierie" placeholder="Type de voierie">
            <mat-option *ngFor="let t of typesVoierie" [value]="t.typeVoierieId">{{t.typeVoierieLib}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- LIBELLE VOIERIE -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Libellé de la voierie</mat-label>
          <input formControlName="libelleVoie" matInput placeholder="Libellé de la voierie (sans signe de ponctuation)"
                 maxlength="30" (input)="checkBlanks('libelleVoie')" required>
          <mat-error
            *ngIf="form.controls['libelleVoie'].hasError('required') && form.controls['libelleVoie'].touched">Valeur
            requise
          </mat-error>
          <mat-error
            *ngIf="form.controls['libelleVoie'].hasError('pattern') && form.controls['libelleVoie'].touched">Format non
            valide
          </mat-error>
          <mat-error
            *ngIf="form.controls['libelleVoie'].hasError('checkBlanks') && form.controls['libelleVoie'].touched">Un seul
            espace doit figurer entre chaque mot
          </mat-error>
        </mat-form-field>

        <!-- MENTION SPEC -->
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Mention spéciale</mat-label>
          <input formControlName="mentionSpec" matInput placeholder="Ex. BP" maxlength="38"
                 (input)="checkBlanks('mentionSpec')">
          <mat-error
            *ngIf="form.controls['mentionSpec'].hasError('pattern') && form.controls['mentionSpec'].touched">Format non
            valide
          </mat-error>
          <mat-error
            *ngIf="form.controls['mentionSpec'].hasError('checkBlanks') && form.controls['mentionSpec'].touched">Un seul
            espace doit figurer entre chaque mot
          </mat-error>
        </mat-form-field>

        <!-- CP -->
        <mat-form-field class="col-lg-4" appearance="outline">
          <mat-label>Code postal</mat-label>
          <input formControlName="cp" matInput placeholder="Ex. 33400" required>
          <mat-error
            *ngIf="form.controls['cp'].hasError('required') && form.controls['cp'].touched">Valeur requise
          </mat-error>
          <mat-error
            *ngIf="form.controls['cp'].hasError('pattern') && form.controls['cp'].touched">Format non valide
          </mat-error>
        </mat-form-field>

        <!-- BUREAU DISTRIB -->
        <mat-form-field class="col-lg-8" appearance="outline">
          <mat-label>Bureau de distribution</mat-label>
          <input formControlName="bureauDistrib" matInput placeholder="Ex. TALENCE CEDEX"
                 (change)="onBureauDistribInputChange()"
                 maxlength="32" (input)="checkBlanks('bureauDistrib')" required>
          <mat-error
            *ngIf="form.controls['bureauDistrib'].hasError('required') && form.controls['bureauDistrib'].touched">Valeur
            requise
          </mat-error>
          <mat-error
            *ngIf="form.controls['bureauDistrib'].hasError('pattern') && form.controls['bureauDistrib'].touched">Format
            non valide
          </mat-error>
          <mat-error
            *ngIf="form.controls['bureauDistrib'].hasError('checkBlanks') && form.controls['bureauDistrib'].touched">Un
            seul espace doit figurer entre chaque mot
          </mat-error>
        </mat-form-field>
      </form>

      <div class="row justify-content-center">
        <button class="ml-2 mt-2 mb-2" mat-raised-button color="primary"
                [matTooltip]="adresse?.adresseId ? 'Modifier l\'adresse' : 'Ajouter l\'adresse'"
                (click)="save()" [disabled]="form.invalid"><i class="fas fa-check"></i></button>
      </div>
    </mat-card-content>
  </mat-card>

  <div id="map" class="ml-2 col-lg-6"><h4 id="map-title">Déplacer le marqueur sur la carte</h4></div>
</div>




