<div class="row justify-content-center" *ngIf="responsables || !structure">
  <mat-card class="col-lg-12 mt-4 mb-4">
    <mat-card-header>
      <h4>Responsable</h4>
    </mat-card-header>

    <mat-card-content>
      <!--Affichage du responsable-->
      <div class="row col-lg-12 ml-4">
        <li appHighlightResponsable [resp]="resp" *ngFor="let resp of responsables">
          {{resp.individu.nom | uppercase}} {{resp.individu.prenom}} ({{resp.dateDebut | date}} - <span
          *ngIf="resp.dateFin">{{resp.dateFin |date}}</span>)
        </li>
      </div>

      <div class="row justify-content-center col-lg-12" *ngIf="responsableDemande">
        <span class="alert-warning mt-2"><i class="fas fa-exclamation-triangle mr-2"></i>Demande en cours<br>
          {{responsableDemande.individu.nom | uppercase}} {{responsableDemande.individu.prenom}}, à partir du {{responsableDemande.dateDebut | date}}
        </span>
      </div>


      <!--Formulaire de mise à jour du responsable-->
      <div class="mt-4" *ngIf="!readonly">
        <h5 class="ml-4 col-lg-12" *ngIf="selectedldapAccount && structure?.etatStructure === etatStructure.ACTIF">
          Mise à jour du responsable
          <span>{{selectedldapAccount.nomComplet}}
            <span class="clickable ml-2" (click)="selectedldapAccount = null"><i class="fa fa-times"></i></span>
          </span>
        </h5>

        <div class="row justify-content-center mt-4">
          <div class="col-lg-12">
            <app-recherche-ldap-form (ldapAccountEventEmitter)="onPersonSelected($event)"
                                     [displaySelection]="!structure"></app-recherche-ldap-form>
          </div>
        </div>

        <div *ngIf="structure?.etatStructure === etatStructure.ACTIF">
          <div class="row justify-content-center">
            <mat-form-field class="col-lg-12" appearance="outline">
              <mat-label>Date de début</mat-label>
              <input matInput [formControl]="dateDebut" required
                     [matDatepicker]="debpicker" placeholder="Date de début">
              <mat-datepicker-toggle matSuffix [for]="debpicker">
              </mat-datepicker-toggle>
              <mat-datepicker #debpicker></mat-datepicker>
              <mat-error *ngIf="dateDebut.invalid && dateDebut.touched">Format non valide</mat-error>
            </mat-form-field>
          </div>

          <div class="row justify-content-center">
            <button mat-raised-button color="primary" matTooltip="Envoyer une demande de modification"
                    (click)="createDemandeModificationResponsable()"
                    [disabled]="!selectedldapAccount || dateDebut.invalid"><i class="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
