import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Adresse} from '../model/structure.model';

@Injectable()
export class AdresseService {

  constructor(private http: HttpClient) {
  }

  public getAdressesByStructure(structureId: number): Observable<Adresse[]> {
    return this.http.get<Adresse[]>('/adresse/structure/' + structureId);
  }

  public getAdressesPorteusesByStructure(structureId: number): Observable<Adresse[]> {
    return this.http.get<Adresse[]>('/adresse/adresses-porteuses/structure/' + structureId);
  }

  public getAdressesByTypeVoierie(typeVoierieId: number): Observable<Adresse[]> {
    return this.http.get<Adresse[]>('/adresse/type-voierie/' + typeVoierieId);
  }

  public existsMultiAdresseByTypeAdresse(typeAdresseId: number): Observable<boolean> {
    return this.http.get<boolean>('/adresse/exists-multi/type-adresse/' + typeAdresseId);
  }

  public save(adresse: Adresse): Observable<Adresse> {
    return this.http.put<Adresse>('/adresse/save', adresse);
  }
}
