<h1 mat-dialog-title>Formulaire de modification du code structure dans
  l'application {{data.rss.application.applicationLib.toUpperCase()}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <p>Attention, vous êtes sur le point de modifier le code de la structure dans l'application.
    Ce dernier pourrait être utilisé par des programmes tiers, assurez-vous de ne pas commettre d'erreur.</p>

  <form [formGroup]="form">
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Nouveau code SI</mat-label>
      <input matInput placeholder="Nouveau code SI" formControlName="codeSI" maxlength="200" required>
      <mat-error *ngIf="form.controls['codeSI'].hasError('required') && form.controls['codeSI'].touched">
        Valeur requise
      </mat-error>
    </mat-form-field>

    <!--DATE DEBUT -->
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de début</mat-label>
      <input formControlName="dateDebut" matInput [matDatepicker]="debpicker" placeholder="Date de début">
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error
        *ngIf="form.controls['dateDebut'].invalid && form.controls['dateDebut'].touched">Format non valide
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" matTooltip="associer" [disabled]="form.invalid"
          (click)="onConfirm()"><i class="fas fa-check fa-2x"></i>
  </button>
  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>



