<div class="row justify-content-center">
  <mat-accordion class="col-lg-8 mt-4 mb-4">
    <mat-expansion-panel [expanded]="!structRattach">
      <mat-expansion-panel-header>
        <mat-panel-title><h4>Sélection de la structure de rattachement</h4></mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree [displayOption]="false"
                          (selectedStructureEmitter)="onSelectedStructure($event)"></app-structure-tree>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row justify-content-center" *ngIf="displayForm">
  <mat-card class="col-lg-8 mt-4 p-4">
    <mat-card-header>
      <h4>Formulaire de modification de la structure de rattachement</h4>
    </mat-card-header>

    <mat-card-content class="mt-4">
      <form [formGroup]="form">

        <p class="col-lg-12">Structure choisie :
          <span appHighlightStructure [structure]="structRattach">{{structRattach.identite.libelleLong}}</span>
        </p>

        <mat-form-field class="col-lg-6" appearance="outline">
          <mat-label>Date de début</mat-label>
          <input matInput formControlName="dateDebut"
                 [matDatepicker]="debpicker"
                 placeholder="Date de début" required>
          <mat-datepicker-toggle matSuffix [for]="debpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #debpicker></mat-datepicker>
          <mat-error *ngIf="form.controls['dateDebut'].hasError('required')">Format non valide</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>Motif de la demande</mat-label>
          <textarea matInput formControlName="motif" [maxLength]="200" placeholder="Motif de la demande" required></textarea>
          <mat-error *ngIf="form.controls['motif'].invalid && form.controls['motif'].touched">Valeur requise
          </mat-error>
        </mat-form-field>

        <div class="row justify-content-center">
          <button class="col-lg-6 mt-2 mb-2" mat-raised-button color="primary"
                  matTooltip="Demande de modification de la structure de rattachement"
                  (click)="modifyStructureRattachement()" [disabled]="form.invalid">
            {{'envoyer une demande de modification' | uppercase}}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
