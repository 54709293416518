  <h1 mat-dialog-title *ngIf="[typeRole.ADMINISTRATEUR.valueOf(), typeRole.MODERATEUR.valueOf()].includes(data.typeRole)">
    Souhaitez-vous supprimer le role de {{data.typeRole}} pour {{data.individu.nom}} {{data.individu.prenom}}
    <span *ngIf="data.structure">sur la structure <span appHighlightStructure [structure]="data.structure">{{data.structure.identite.libelleLong}}</span>
    </span> ?
  </h1>

  <h1 mat-dialog-title *ngIf="[typeRole.GESTIONNAIRE.valueOf()].includes(data.typeRole) && role">
    Souhaitez-vous supprimer le privilège de gestionnaire sur la structure {{role.structure.identite.libelleLong}}
    <span *ngIf="role.isExtended">{{' et ses structures filles'}}</span> pour l'utilisateur {{role.individu.nom}} {{role.individu.prenom}} ?</h1>

<div mat-dialog-content class="row justify-content-center mt-4" *ngIf="display">
  <div mat-dialog-actions class="row justify-content-center">
    <button mat-raised-button color="primary" (click)="onDelete()">
      <i class="fas fa-check fa-2x"></i>
    </button>
    <button mat-raised-button color="accent" (click)="onCancel()">
      <i class="fas fa-times fa-2x"></i>
    </button>
  </div>
</div>



