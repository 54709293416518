<h1 mat-dialog-title>Formulaire de réactivation de la structure {{data.selectedStructure?.identite.libelleLong}}</h1>

<div mat-dialog-content class="row justify-content-center mt-4">
  <form [formGroup]="form">
    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Structure porteuse</mat-label>
      <mat-select formControlName="structurePorteuse" placeholder="structure porteuse" required>
        <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of structures"
                    [value]="struct.structureId">
          {{struct.identite.libelleLong}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Gestionnaires à désactiver</mat-label>
      <mat-select formControlName="privilegesASuppr" placeholder="Gestionnaires à désactiver" multiple>
        <mat-option *ngFor="let p of gestionnaires" [value]="p">
          {{p.individu.nom}} {{p.individu.prenom}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-lg-12" appearance="outline">
      <mat-label>Motif de la réactivation</mat-label>
      <textarea matInput formControlName="motif"
                [maxLength]="200"
                placeholder="Saisie du motif de la réactivation de la structure"
                required></textarea>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="reactivate()" [disabled]="form.invalid">
    <i class="fas fa-check fa-2x"></i>
  </button>

  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>


