<div>
  <div class="row justify-content-center mt-4">
    <!-- BOUTON VOIR HISTORIQUE-->
    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            (click)="displayVersionHisto()" *ngIf="descriptions.length > 1">
      Voir l'historique
    </button>

    <!-- BOUTON SAISIR / MODIFIER DESCRIPTION -->
    <div matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
         [matTooltipDisabled]="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
      <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
              (click)="modify()"
              [disabled]="!(user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure))">
        {{!updateMode ? 'Saisir / Modifier la description' : 'Annuler toutes les modifications'}}
      </button>
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <mat-card class="col-lg-8 p-4">
      <mat-card-header>
        <h4>Description de la structure</h4>
      </mat-card-header>

      <form [formGroup]="form">
        <div class="row justify-content-center mt-4">
          <div class="chip-btn logo-delete" (click)="deleteLogo()" *ngIf="updateMode && logoBase64">
            <i class="fas fa-times"></i>
          </div>

          <figure *ngIf="logoBase64">
            <img class="rounded img-thumbnail" [src]="logoBase64" [height]="200" [width]="200" alt="logo">
            <figcaption class="text-center" *ngIf="!updateMode">Logo de la structure</figcaption>
          </figure>
        </div>

        <div class="row justify-content-center mt-4" *ngIf="updateMode">
          <span class="d-inline-block">Choix du logo de la structure :</span>
          <input type="file" class="d-inline-block ml-2" accept="image/*" (change)="onLogoChange($event.target)">
        </div>

        <div class="mt-4">
          <p-editor formControlName="description" [style]="{'height':'250px'}" [readonly]="!updateMode"
                    placeholder="Description de la structure"></p-editor>
          <mat-error
            *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">Valeur requise</mat-error>
          <mat-error
            *ngIf="form.controls['description'].hasError('desc-length-invalid')">La description doit être comprise entre 25 et 1000 caractères</mat-error>
        </div>


        <mat-form-field appearance="outline" class="col-lg-12 mt-4">
          <mat-label>Site internet de la structure</mat-label>
          <input [disabled]="!updateMode" formControlName="url" matInput placeholder="Site internet de la structure"
                 required>
          <mat-error
            *ngIf="form.controls['url'].hasError('required') && form.controls['url'].touched">Valeur requise</mat-error>
          <mat-error *ngIf="form.controls['url'].hasError('pattern')">Format non valide</mat-error>
        </mat-form-field>
      </form>
    </mat-card>
  </div>

  <div class="row justify-content-center" *ngIf="updateMode">
    <button class="mt-4" mat-raised-button color="primary" matTooltip="Valider la description"
            (click)="validate()"
            [disabled]="form.invalid"><i class="fas fa-check"></i></button>
  </div>
</div>


