<div class="row justify-content-center" *ngIf="datasource">
  <p-table class="col-lg-12"
           dataKey="structureId"
           [columns]="cols"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true"
           *ngIf="datasource?.length > 0">
    <ng-template pTemplate="caption">Correspondance du code structure dans les Systèmes d'Information
      <button *ngIf="histo?.length > 0" class="button float-right" matTooltip="historique des associations" (click)="openHistoDialog()">
        <span><i class="fas fa-info-circle fa-2x"></i></span>
      </button>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="text-center" style="width:8em" *ngIf="user.individu.isAdmin">Modifier le code</th>
        <th class="text-center" style="width:8em" *ngIf="user.individu.isAdmin">Activer / Désactiver</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-row>
      <tr>
        <td *ngFor="let col of columns">{{row[col.field]}}</td>

        <td class="text-center" *ngIf="user.individu.isAdmin">
          <button class="button" matTooltip="Mettre à jour le code" (click)="openUpdateCodeSiDialog(row.rss)" *ngIf="!row.rss.dateFin">
            <i class="fas fa-edit fa-2x"></i>
          </button>
        </td>
        <td class="text-center" *ngIf="user.individu.isAdmin">
          <button class="button" matTooltip="Désynchroniser" (click)="openDesynchroSiDialog(row.rss)"
                  *ngIf="row['etat'] === 'Synchro'">
            <i class="fas fa-unlink fa-2x"></i>
          </button>
          <button class="button" matTooltip="Synchroniser" (click)="openSynchroSiDialog(row.rss)"
                  *ngIf="row['etat'] === 'Désynchro'">
            <i class="fas fa-link fa-2x"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
