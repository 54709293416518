import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LdapAccount, Privilege, Role} from '../../../../model/user.model';
import {RoleService} from '../../../../services/role.service';
import {Structure} from '../../../../model/structure.model';
import {LoaderService} from '../../../../services/loader.service';
import {StructureTreeService} from '../../../../services/structure-tree.service';

@Component({
  selector: 'app-add-gestionnaire-dialog',
  templateUrl: './add-gestionnaire-dialog.component.html',
  styleUrls: ['./add-gestionnaire-dialog.component.scss']
})
export class AddGestionnaireDialogComponent implements OnInit {

  selectedLdapAccount: LdapAccount;

  constructor(private loaderService: LoaderService,
              private structureTreeService: StructureTreeService,
              private roleService: RoleService,
              public dialogRef: MatDialogRef<AddGestionnaireDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    if (this.data.ldapAccount) {
      this.selectedLdapAccount = this.data.ldapAccount;
    }
  }

  onStructureSelected(roles: Role[]) {
    this.loaderService.start();
    const promises: Promise<void>[] = [];

    this.structureTreeService.getParentIdsByStructureIds(roles.map(r => r.structureId)).subscribe(res => {
      roles.filter(r => res.includes(r.structureId))
        .forEach(role => {
          promises.push(this.createPrivilege({role, ldapAccountDTO: this.selectedLdapAccount}));
        });

      Promise.all(promises).then(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });
    });
  }

  onAccountSelected(ldapAccount: LdapAccount) {
    this.selectedLdapAccount = ldapAccount;
  }

  onCancel() {
    this.dialogRef.close();
  }

  private createPrivilege(privilege: Privilege): Promise<void> {
    return new Promise(resolve => {
      this.roleService.create(privilege).subscribe(() => resolve());
    });
  }
}

export interface DialogData {
  ldapAccount: LdapAccount;
  structure: Structure;
}
