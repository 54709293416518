<div *ngIf="datasource">
  <div class="row justify-content-center mt-4" *ngIf="!structure && datasource.length > 0">
    <p-paginator class="col-lg-12"
                 [rows]="rows"
                 [totalRecords]="totalRecords"
                 [pageLinkSize]="pageLinks"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [first]="first"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                 (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>

  <div class="row justify-content-center mt-4" *ngIf="datasource.length > 0">
    <p-table class="col-lg-12"
             [columns]="cols"
             [value]="datasource"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liens antécédents</ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">{{col.header}}</th>
          <th class="text-center" style="width:8em" *ngIf="user.individu.isAdmin">Modifier</th>
          <th class="text-center" style="width:8em" *ngIf="user.individu.isAdmin">Supprimer</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td><a appHighlightStructure
                 [structure]="row.lien.structure"
                 [routerLink]="'/structure/' +  row.lien.structure.structureId">
            {{row['structure'] | libelleStructure:user}}</a>
          </td>
          <td>{{row['typeLien']}}</td>
          <td>
            <a href
               appHighlightStructure
               [structure]="row.lien.structurePorteuse"
               [routerLink]="'/structure/' +  row.lien.structurePorteuse.structureId">{{row['structurePorteuse'] | libelleStructure:user }}</a>
          </td>
          <td>{{row['dateDebut'] | date:'dd/MM/yyyy'}}</td>

          <td class="text-center" *ngIf="user.individu.isAdmin">
            <button class="button" matTooltip="Modifier" (click)="openDialog(row.lien, action.UPDATE)">
              <i class="fas fa-edit fa-2x"></i>
            </button>
          </td>
          <td class="text-center" *ngIf="user.individu.isAdmin">
            <button class="button" matTooltip="Supprimer le lien" (click)="openDialog(row.lien, action.REMOVE)">
              <i class="fas fa-times fa-2x"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
