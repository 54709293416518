import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CloseStructureRequest} from '../../../model/workflow.model';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {MatDialog} from '@angular/material/dialog';
import {
  CloseStructureWithActiveLinksDialogComponent
} from '../../../layout/dialog/structure-lifecycle/close-structure-with-active-links-dialog/close-structure-with-active-links-dialog.component';

@Component({
  selector: 'app-fermeture-structure',
  templateUrl: './fermeture-structure.component.html',
  styleUrls: ['./fermeture-structure.component.scss']
})
export class FermetureStructureComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() hasLiensParentsOnly: boolean;

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService,
              public dialog: MatDialog) {
    this.form = new FormGroup({
      dateFermeture: new FormControl(null, [Validators.required]),
      motif: new FormControl(null, Validators.required),
    });
  }

  ngOnChanges() {
    if (this.structure) {
      this.form.controls.dateFermeture.setValue(this.structure.identite.dateFermeture);
    }
  }

  send() {
    if (!this.hasLiensParentsOnly) {
      this.dialog.open(CloseStructureWithActiveLinksDialogComponent, {
        minWidth: 400,
        data: {
          structure: this.structure
        }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.validate();
        }
      });
    } else {
      this.validate();
    }
  }

  private validate() {

    const closeStructureRequest: CloseStructureRequest = {
      demandeId: null,
      motif: this.form.controls.motif.value,
      structureId: this.structure.structureId, // todo
      dateFermeture: formatLocalDateWithUTC(this.form.controls.dateFermeture.value)
    };

    this.loaderService.start();
    this.workflowService.closeStructure(closeStructureRequest).subscribe(() => {
      this.loaderService.stop();
      this.structure.identite.dateFermeture = this.form.controls.dateFermeture.value;
      this.form.reset();
      this.actionEmitter.emit();
    });
  }
}
