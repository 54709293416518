<div class="row justify-content-center mt-4">
  <button class="btn-white ml-2 mt-2" mat-raised-button
          (click)="openAddDialog(caracterisation.TUTELLE)"
          *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable)
            && isInUserGestRespPerimeter(structure)">Ajouter une tutelle
  </button>

  <button class="btn-white ml-2 mt-2" mat-raised-button
          (click)="openAddDialog(caracterisation.DOMAINE)"
          *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable)
                 && isInUserGestRespPerimeter(structure)">Ajouter un domaine
  </button>

  <button class="btn-white ml-2 mt-2" mat-raised-button
          (click)="openAddDialog(caracterisation.CODE)"
          *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable)
                      && isInUserGestRespPerimeter(structure)">Ajouter un code
  </button>
</div>

<div class="row justify-content-center">
  <!--TUTELLES-->
  <div class="col-lg-4 buffer" *ngIf="tutelles.length > 0 && structure?.catStructure === catStructure.ORGA">
    <p-table class="col-12"
             dataKey="tutelleId"
             [value]="tutelles"
             [columns]="colsTutelles"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liste des tutelles</ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="col.field" *ngFor="let col of colsTutelles">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th
            *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            Supprimer
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-columns="columns" let-row>
        <tr>
          <td *ngFor="let col of colsTutelles">
            {{row[col.field]}}
          </td>
          <td class="text-center"
              *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            <button class="button" matTooltip="Supprimer une tutelle" (click)="openRemoveDialog(row, caracterisation.TUTELLE)"
                    *ngIf="row.tutelleId !== 1"><i class="fas fa-trash fa-2x"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--DOMAINES-->
  <div class="col-lg-4 buffer" *ngIf="domaines.length > 0">
    <p-table class="col-12"
             dataKey="domaineId"
             [value]="domaines"
             [columns]="colsDomaines"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liste des domaines</ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="col.field" *ngFor="let col of colsDomaines">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th
            *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            Supprimer
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-columns="columns" let-row>
        <tr>
          <td *ngFor="let col of colsDomaines">
            {{row[col.field]}}
          </td>
          <td class="text-center"
              *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            <button class="button" matTooltip="Supprimer une tutelle"
                    (click)="openRemoveDialog(row, caracterisation.DOMAINE)">
              <i class="fas fa-trash fa-2x"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="row justify-content-center">
  <!--CODES-->
  <div class="col-lg-8 buffer" *ngIf="codes.length > 0">
    <p-table class="col-12"
             dataKey="codeId"
             [value]="codes"
             [columns]="colsCodes"
             [responsive]="true"
             [resizableColumns]="true">
      <ng-template pTemplate="caption">Liste des codes</ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="col.field" *ngFor="let col of colsCodes">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th
            *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            Modifier
          </th>
          <th
            *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            Supprimer
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-columns="columns" let-row>
        <tr>
          <td *ngFor="let col of colsCodes">
            {{row[col.field]}}
          </td>
          <td class="text-center"
              *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            <button class="button" matTooltip="modifier un code" (click)="openUpdateDialog(row.code, caracterisation.CODE)">
              <i class="fas fa-edit fa-2x"></i>
            </button>
          </td>
          <td class="text-center"
              *ngIf="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespPerimeter(structure)">
            <button class="button" matTooltip="Supprimer un code" (click)="openRemoveDialog(row.code, caracterisation.CODE)">
              <i class="fas fa-trash fa-2x"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
