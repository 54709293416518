import {Component, Input, OnChanges} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {LienService} from '../../../services/lien.service';
import {LoaderService} from '../../../services/loader.service';
import {StructureTreeService} from '../../../services/structure-tree.service';
import {User} from '../../../model/user.model';
import {isInGestRespAccessPerimeter} from '../../../utils/user.utils';
import {StructureService} from '../../../services/structure.service';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-structure-details-titre',
  templateUrl: './structure-details-titre.component.html',
  styleUrls: ['./structure-details-titre.component.scss']
})
export class StructureDetailsTitreComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  ascendants: Structure[];
  ascList: Structure[];
  children: Structure[];

  constructor(private lienService: LienService,
              private userService: UserService,
              private structureService: StructureService,
              private loaderService: LoaderService,
              private router: Router,
              private structureTreeService: StructureTreeService) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnChanges(): void {
    if (this.structure) {
      this.loaderService.start();
      Promise.all([
        this.getAscendants(),
        this.getChildren()
      ]).then(() => {
        this.loaderService.stop();
      });
    }
  }

  isInUserGestRespPerimeter(structure: Structure): boolean {
    return isInGestRespAccessPerimeter(structure.structureId, this.user);
  }

  getLibelle(): string {
    let res: string;
    if (this.structure.identite.libelleLong.length < 30) {
      res = this.structure.identite.libelleLong;
    } else if (this.structure.identite.libelleCourt && this.structure.identite.libelleCourt.length < 30) {
      res = this.structure.identite.libelleCourt;
    } else {
      res = this.structure.identite.libelleLong.substr(0, 30);
    }
    return res;
  }

  getAscendants(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.structureTreeService.getAscendantsByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          if (res.length > 3) {
            this.ascList = res.slice(0, res.length - 3);
            this.ascendants = res.slice(res.length - 3);
          } else {
            this.ascList = [];
            this.ascendants = res;
          }
          resolve(true);
        })
        .catch(() => {
          this.ascendants = null;
          resolve(false);
        });
    });
  }

  getChildren(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.lienService.getChildrenByStructureId(this.structure.structureId).toPromise()
        .then(res => {
          this.children = res.map(l => l.structure);
          resolve(true);
        })
        .catch(() => {
          this.children = [];
          resolve(false);
        });
    });
  }
}
