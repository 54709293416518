import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Structure} from '../../../model/structure.model';
import * as type from '../../../constants/type-libelle.constant';
import {StructureService} from '../../../services/structure.service';
import {DomaineService} from '../../../services/domaine.service';
import {TutelleService} from '../../../services/tutelle.service';
import {TypeStructureService} from '../../../services/type-structure.service';
import {Domaine, Tutelle, TypeStructure} from '../../../model/nomenclature.model';
import {HttpParams} from '@angular/common/http';
import {formatLocalDateWithUTC, getYYYYMMDD} from '../../../utils/date.utils';

@Component({
  selector: 'app-recherche-structure-form',
  templateUrl: './recherche-structure-form.component.html',
  styleUrls: ['./recherche-structure-form.component.scss']
})
export class RechercheStructureFormComponent implements OnInit {

  @Output() searchEmitter: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  form: FormGroup;

  structures: Structure[];
  categoriesStructure = type.data.cat_structure;
  etatsStructure = type.data.etat_structure;
  typesStructure: TypeStructure[];
  domaines: Domaine[];
  tutelles: Tutelle[];

  constructor(private structureService: StructureService,
              private domaineService: DomaineService,
              private tutelleService: TutelleService,
              private typeStructureService: TypeStructureService) {

    this.form = new FormGroup({
      categories: new FormControl(null),
      types: new FormControl(null),
      intExt: new FormControl(null),
      domaines: new FormControl(null),
      tutelles: new FormControl(null),
      etats: new FormControl(null),
      dateOuverture: new FormControl(null),
      dateFermeture: new FormControl(null),
    });
  }

  ngOnInit() {
    this.structureService.getAllStructuresDTO().subscribe(res => this.structures = res);
    this.typeStructureService.getAllTypesStructure().subscribe(res => this.typesStructure = res);
    this.domaineService.getAllDomaines().subscribe(res => this.domaines = res);
    this.tutelleService.getAllTutelles().subscribe(res => this.tutelles = res);
  }

  search() {
    let params = new HttpParams();

    if (this.form.controls.categories.value && this.form.controls.categories.value.length > 0) {
      params = params.append('categories', this.form.controls.categories.value);
    }
    if (this.form.controls.types.value && this.form.controls.types.value.length > 0) {
      params = params.append('types', this.form.controls.types.value);
    }
    if (this.form.controls.etats.value && this.form.controls.etats.value.length > 0) {
      params = params.append('etats', this.form.controls.etats.value);
    }
    if (this.form.controls.intExt.value && this.form.controls.intExt.value.includes('INT')) {
      params = params.append('interne', String(this.form.controls.intExt.value.includes('INT')));
    }
    if (this.form.controls.intExt.value && this.form.controls.intExt.value.includes('EXT')) {
      params = params.append('externe', String(this.form.controls.intExt.value.includes('EXT')));
    }
    if (this.form.controls.domaines.value && this.form.controls.domaines.value.length > 0) {
      params = params.append('domaines', this.form.controls.domaines.value);
    }
    if (this.form.controls.tutelles.value && this.form.controls.tutelles.value.length > 0) {
      params = params.append('tutelles', this.form.controls.tutelles.value);
    }
    if (this.form.controls.dateOuverture.value) {
      params = params.append('dateOuverture', getYYYYMMDD(formatLocalDateWithUTC(this.form.controls.dateOuverture.value)));
    }
    if (this.form.controls.dateFermeture.value) {
      params = params.append('dateFermeture', getYYYYMMDD(formatLocalDateWithUTC(this.form.controls.dateFermeture.value)));
    }

    this.searchEmitter.emit(params);
  }
}
