import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Lien, Structure} from '../../../../model/structure.model';
import {TypeLienService} from '../../../../services/type-lien.service';
import {LienService} from '../../../../services/lien.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {StructureService} from '../../../../services/structure.service';
import {LoaderService} from '../../../../services/loader.service';
import {TypeLien} from '../../../../model/nomenclature.model';
import {normalize} from '../../../../utils/string.utils';
import {ACTION} from '../../../../constants/enum.constant';
import {ETAT_VALID} from '../../../../model/enum.model';
import {formatLocalDateWithUTC} from '../../../../utils/date.utils';
import {User} from '../../../../model/user.model';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-liens-antecedents-dialog',
  templateUrl: './liens-antecedents-dialog.component.html',
  styleUrls: ['./liens-antecedents-dialog.component.scss']
})
export class LiensAntecedentsDialogComponent implements OnInit {
  form: FormGroup;
  action = ACTION;

  user: User;

  typeLiens: TypeLien[];
  structures: Structure[];
  structure: Structure;
  structurePorteuse: Structure;
  filteredStructures: Observable<Structure[]>;
  filteredStructuresPorteuses: Observable<Structure[]>;

  title: string;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private typeLiensService: TypeLienService,
              private structureService: StructureService,
              private lienService: LienService,
              public dialogRef: MatDialogRef<LiensAntecedentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      structure: new FormControl(null, Validators.required),
      typeLien: new FormControl(null, Validators.required),
      structurePorteuse: new FormControl(null, Validators.required),
      dateDebut: new FormControl(null, Validators.required),
    });

    if (this.data.action === ACTION.ADD) {
      this.title = 'Ajout d\'un lien antécédent';
    } else if (this.data.action === ACTION.UPDATE) {
      this.title = 'Mise à jour d\'un lien de caracterisation ' + this.data.lien.typeLien.typeLienLib;
    } else if (this.data.action === ACTION.REMOVE) {
      this.title = 'Suppression définitive d\'un lien de caracterisation ' + this.data.lien.typeLien.typeLienLib;
    }
  }

  ngOnInit() {
    if (this.data.action !== ACTION.REMOVE) {
      this.init();
    }
  }

  onInput() {
    this.structurePorteuse = null;
    this.form.controls.structurePorteuse.setErrors({'invalid-structure': 'Vous devez sélectionner une structure parmi la liste déroulante'});
  }

  onStructureSelect(structure: Structure) {
    this.structure = structure;
  }

  onStructurePorteuseSelect(structure: Structure) {
    this.structurePorteuse = structure;
  }

  validate() {
    this.loaderService.start();
    if (this.data.action === ACTION.ADD) {
      const lien: Lien = {
        lienId: null,
        typeLien: this.form.controls.typeLien.value,
        structureId: this.structure.structureId,
        structPorteuseId: this.structurePorteuse.structureId,
        dateDebut: formatLocalDateWithUTC(this.form.controls.dateDebut.value),
        dateFin: null,
        etatValid: ETAT_VALID.VALIDE,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false,
      };
      this.lienService.create(lien).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.action === ACTION.UPDATE) {
      const lien: Lien = {
        lienId: this.data.lien.lienId,
        typeLien: this.form.controls.typeLien.value,
        structureId: this.structure.structureId,
        structPorteuseId: this.structurePorteuse.structureId,
        dateDebut: formatLocalDateWithUTC(this.form.controls.dateDebut.value),
        dateFin: null,
        etatValid: ETAT_VALID.VALIDE,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false,
      };
      this.lienService.update(lien).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });

    } else if (this.data.action === ACTION.REMOVE) {
      this.lienService.delete(this.data.lien.lienId).subscribe(() => {
        this.loaderService.stop();
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  private init() {
    this.loaderService.start();
    Promise.all([this.getAllStructures(), this.getAllTypes(), this.getStructure(), this.getStructurePorteuse()])
      .then(() => {
        this.loaderService.stop();
        this.filteredStructures = this.form.controls.structure.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        this.filteredStructuresPorteuses = this.form.controls.structurePorteuse.valueChanges.pipe(startWith(''), map(value => this._filter(value)));

        if (this.data.action === ACTION.UPDATE) {
          this.form.controls.structure.setValue(this.data.lien.structure.identite.libelleLong);
          this.form.controls.typeLien.setValue(this.data.lien.typeLien.typeLienId);
          this.form.controls.structurePorteuse.setValue(this.data.lien.structurePorteuse.identite.libelleLong);
          this.form.controls.dateDebut.setValue(this.data.lien.dateDebut);

        } else if (this.data.fromStructure && this.data.action === ACTION.ADD) {
          this.form.controls.structure.setValue(this.structure.identite.libelleLong);
        }

        if (this.structure.structureId === this.structure?.structureId) {
          this.form.controls.structure.disable();
        }
        if (this.structure.structureId === this.structurePorteuse?.structureId) {
          this.form.controls.structurePorteuse.disable();
        }
      })
      .catch(() => {
        this.loaderService.stop();
      });
  }

  private getAllStructures(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getAllStructures().subscribe(res => {
        this.structures = res;
        this.filteredStructures = this.form.controls.structure.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        this.filteredStructuresPorteuses = this.form.controls.structurePorteuse.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        resolve();
      });
    });
  }

  private getStructure(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getStructureByStructureId(this.data.lien.structureId).subscribe(res => {
        this.structure = res;
        resolve();
      });
    });
  }

  private getStructurePorteuse(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.data.lien.structPorteuseId) {
        this.structureService.getStructureByStructureId(this.data.lien.structPorteuseId).subscribe(res => {
          this.structurePorteuse = res;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  private getAllTypes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.typeLiensService.getAllTypesLien().subscribe(res => {
        this.typeLiens = res.filter(l => l.isAntecedent);
        resolve();
      });
    });
  }

  private _filter(value: string): Structure[] {
    let options: Structure[];
    if (value && value.trim().length > 0) {
      const values: string[] = value.split(' ');
      options = this.structures.filter(structure => values.every(v =>
          normalize(structure.identite.libelleLong).includes(normalize(v))
          || (structure.identite.libelleCourt &&
            normalize(structure.identite.libelleCourt).includes(normalize(v)))
        )
      );
    }
    return options;
  }

}

export interface DialogData {
  action: string;
  lien: Lien;
  fromStructure: boolean;
}
