import {Component, Input, OnChanges} from '@angular/core';
import {Lien, Structure} from '../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../model/user.model';
import {StructureService} from '../../../services/structure.service';
import {TypeLienService} from '../../../services/type-lien.service';
import {TypeLien} from '../../../model/nomenclature.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-lien-form',
  templateUrl: './lien-form.component.html',
  styleUrls: ['./lien-form.component.scss']
})
export class LienFormComponent implements OnChanges {

  @Input() structure: Structure;
  @Input() lienContexte: Lien;
  @Input() categorie: string;

  user: User;
  form: FormGroup;

  typeLienList: TypeLien[];
  structRattachList: Structure[];

  constructor(private structureService: StructureService,
              private userService: UserService,
              private typeLienService: TypeLienService) {
    this.user = this.userService.getCurrentUser();

    this.form = new FormGroup({
      structure: new FormControl(null, Validators.required),
      typeLien: new FormControl(null, Validators.required),
      structureRattach: new FormControl(null, Validators.required),
      dateDebut: new FormControl(null, Validators.required),
      dateFin: new FormControl(null),
    });

    this.typeLienService.getAllTypesLienNonHierarchique().subscribe(res => this.typeLienList = res);
  }

  ngOnChanges() {
    if (this.structure) {
      this.form.controls.structure.setValue(this.structure.identite.libelleLong);
      this.form.controls.structure.disable();
      this.form.markAsPristine();
    }

    if (this.lienContexte) {
      this.form.controls.structure.setValue(this.lienContexte.structure.identite.libelleLong);
      this.form.controls.typeLien.setValue(this.lienContexte.typeLien.relation);
      this.form.controls.structureRattach.setValue(this.lienContexte.structurePorteuse.identite.libelleLong);
      this.form.controls.dateDebut.setValue(this.lienContexte.dateDebut);
      this.form.controls.dateFin.setValue(this.lienContexte.dateFin);
      this.form.disable();
    }
  }

  onTypeLienChange() {
    if (this.form.controls.typeLien.valid) {
      this.structureService.getStructuresForAddingAutreLien(this.structure.structureId, this.categorie, this.form.controls.typeLien.value)
        .subscribe(res => {
          this.structRattachList = res;
        });
    }
  }

  isDisabled(): boolean {
    return this.form.invalid;
  }

}
