import {Observable} from 'rxjs';
import {Structure} from '../model/structure.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StructNode} from '../model/divers.model';


@Injectable()
export class StructureTreeService {

  constructor(private http: HttpClient) {
  }

  public getLazyStructTree(): Observable<StructNode[]> {
    return this.http.get<StructNode[]>('/structure-tree/lazy-structure-tree');
  }

  public getLazyNodeChildren(structNode: string): Observable<StructNode[]> {
    return this.http.post<StructNode[]>('/structure-tree/lazy-node-children', structNode);
  }

  public getChildrenIdByStructureId(structureId: number): Observable<number[]> {
    return this.http.get<number[]>('/structure-tree/' + structureId + '/children');
  }

  public getParentIdsByStructureIds(structureIds: number[]): Observable<number[]> {
    return this.http.post<number[]>('/structure-tree/parents', structureIds);
  }

  public getAscendantsByStructureId(structureId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure-tree/' + structureId + '/ascendants');
  }

  public isRootStructure(structureId: number): Observable<boolean> {
    return this.http.get<boolean>('/structure-tree/is-root-structure/' + structureId);
  }

  public checkStructureRattachement(structRattachId: number, structureId: number) {
    return this.http.get<boolean>('/structure-tree/' + structureId + '/rattachement/' + structRattachId);
  }
}
