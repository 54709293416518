import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Application} from '../model/nomenclature.model';

@Injectable()
export class ApplicationService {

  constructor(private http: HttpClient) {
  }

  public getAllAppliSI(): Observable<Application[]> {
    return this.http.get<Application[]>('/application/all');
  }

  public getApplicationsByStructure(structureId: number): Observable<Application[]> {
    return this.http.get<Application[]>('/application/all/structure/' + structureId);
  }

  public getAvailableApplications(structureId: number): Observable<Application[]> {
    return this.http.get<Application[]>('/application/available/structure/' + structureId);
  }

  public save(applis: Application[]): Observable<Application[]> {
    return this.http.put<Application[]>('/application/save', applis);
  }
}
