import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LdapAccount} from '../../../../model/user.model';

@Component({
  selector: 'app-result-ldap-dialog',
  templateUrl: './result-ldap-dialog.component.html',
  styleUrls: ['./result-ldap-dialog.component.scss']
})
export class ResultLdapDialogComponent {

  cols: any[];

  first = 0;
  rows = 10;
  rowsPerPageOptions = [10, 25, 50];

  constructor(public dialogRef: MatDialogRef<ResultLdapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.cols = [
      {field: 'nomComplet', header: 'NOM Prénom'},
      {field: 'structures', header: 'Affectations'},
      {field: 'observations', header: 'Observations'},
    ];
  }

  onSelect(ldapAccount: LdapAccount) {
    this.dialogRef.close(ldapAccount);
  }
}

export interface DialogData {
  ldapAccounts: LdapAccount[];
}

