import {Component, Input, OnChanges} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {Demande} from '../../../model/demande.model';
import {DemandeService} from '../../../services/demande.service';
import {LienService} from '../../../services/lien.service';
import * as moment from 'moment';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {User} from '../../../model/user.model';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {CAT_STRUCTURE, ETAT_STRUCTURE, ETAT_VALID} from '../../../model/enum.model';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-structure-details',
  templateUrl: './structure-details.component.html',
  styleUrls: ['./structure-details.component.scss']
})
export class StructureDetailsComponent implements OnChanges {

  @Input() structure: Structure;

  user: User;
  action = ACTION;
  selectedAction: ACTION;

  demandesAssociees: Demande[];
  hasDemandeEnCours: boolean;
  hasDemandeFermeture: boolean;
  hasLiensParentsOnly: boolean;

  catStructure = CAT_STRUCTURE;
  etatStructure = ETAT_STRUCTURE;

  constructor(
    private demandeService: DemandeService,
    private userService: UserService,
    private lienService: LienService,
    private structureService: StructureService,
    private loaderService: LoaderService,
    private router: Router) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.structure) {
      this.loaderService.start();
      Promise.all([
        this.getStructure(),
        this.getHasLiensParentsOnly(),
        this.hasDemandesEnCoursDescendantes(),
        this.hasDemandesFermetureAscencantes(),
        this.getDemandesAssociees(),
      ]).then(() => {
        this.selectedAction = ACTION.DETAILS;
        this.loaderService.stop();
      });
    }
  }

  getHasLiensParentsOnly(): Promise<void> {
    return new Promise<void>(resolve => {
      this.lienService.getLiensOuvertsByStructureId(this.structure.structureId).subscribe(res => {
        this.hasLiensParentsOnly = res.filter(l =>
          (!l.typeLien.isHierarchique || l.typeLien.isHierarchique && l.structureId !== this.structure.structureId)
          && !moment(l.dateDebut).isAfter(new Date())
          && (l.dateFin == null || !moment(l.dateFin).isBefore(new Date()))
          && l.etatValid === ETAT_VALID.VALIDE
        ).length === 0;
        resolve();
      });
    });
  }

  getDemandesAssociees(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.getDemandesByStructureId(this.structure.structureId).subscribe(demandes => {
        this.demandesAssociees = demandes;
        resolve();
      });
    });
  }

  hasDemandesEnCoursDescendantes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.hasDemandeEnCoursByTree(this.structure.structureId).subscribe(res => {
        this.hasDemandeEnCours = res;
        resolve();
      });
    });
  }

  hasDemandesFermetureAscencantes(): Promise<void> {
    return new Promise<void>(resolve => {
      this.demandeService.hasDemandesFermetureFromParents(this.structure.structureId).subscribe(res => {
        this.hasDemandeFermeture = res;
        resolve();
      });
    });
  }

  getStructure(): Promise<void> {
    return new Promise<void>(resolve => {
      this.structureService.getStructureByStructureId(this.structure.structureId).subscribe(res => {
        if (res) {
          this.structure = res;
        }
        resolve();
      });
    });
  }

  createSousStructure(catStructure: string) {
    if (catStructure === CAT_STRUCTURE.ORGA) {
      this.router.navigate(['demandes/creation-structure-organisationnelle'], {queryParams: {structurePorteuse: this.structure.structureId}});
    } else {
      this.router.navigate(['demandes/creation-structure-matricielle'], {queryParams: {structurePorteuse: this.structure.structureId}});
    }
  }

  isInUserGestRespPerimeter(): boolean {
    return isInGestRespActionPerimeter(this.structure.structureId, this.user);
  }
}

export enum ACTION {
  DETAILS,
  CONSULTER_DEMANDE,
  ARCHIVER,
  FERMER,
  REACTIVER
}
