import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Structure} from '../../../model/structure.model';
import {WorkflowService} from '../../../services/workflow.service';
import {LienRequest} from '../../../model/workflow.model';
import {LienFormComponent} from '../../structure/lien-form/lien-form.component';
import {LoaderService} from '../../../services/loader.service';
import {formatLocalDateWithUTC} from '../../../utils/date.utils';
import {TypeLien} from '../../../model/nomenclature.model';
import {ETAT_VALID} from '../../../model/enum.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-ajout-lien-non-hierarchique',
  templateUrl: './ajout-lien-non-hierarchique.component.html',
  styleUrls: ['./ajout-lien-non-hierarchique.component.scss']
})
export class AjoutLienNonHierarchiqueComponent {

  @Output() demandeEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() categorie: string;

  @ViewChild(LienFormComponent) lienFormComponent: LienFormComponent;

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private workflowService: WorkflowService) {
    this.form = new FormGroup({
      motif: new FormControl(null, Validators.required),
    });
  }

  createLien() {
    const requestLien: LienRequest = {
      lienDemande : this.structure.liensParent[0],
      lienContexte: {
        lienId: null,
        typeLien: new TypeLien(this.lienFormComponent.form.controls.typeLien.value),
        structureId: this.structure.structureId,
        structPorteuseId: this.lienFormComponent.form.controls.structureRattach.value,
        dateDebut: formatLocalDateWithUTC(this.lienFormComponent.form.controls.dateDebut.value),
        dateFin: null,
        etatValid: ETAT_VALID.A_VALIDER,
        structure: null,
        structurePorteuse: null,
        hasDemandeSuppressionEnCours: false
      },
      motif: this.form.controls.motif.value
    };

    this.loaderService.start();
    this.workflowService.insertLien(requestLien).subscribe(() => {
      this.lienFormComponent.form.reset();
      this.form.reset();
      this.loaderService.stop();
      this.demandeEmitter.emit();
    });
  }
}
