<div class="row justify-content-center mt-4" *ngIf="structure.etatStructure === etatStructure.ACTIF">
  <div
    matTooltip="Vous ne disposez pas des privilèges attendus pour effectuer cette action. Votre demande ne pourra aboutir"
    [matTooltipDisabled]="user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespActionPerimeter(structure)">

    <!-- AJOUTER UNE STRUCTURE DE RATTACHEMENT -->
    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            (click)="selectedAction = action.ADD_STRUCT_RATT"
            [disabled]="!(user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespActionPerimeter(structure))"
            *ngIf="structure.catStructure === catStructure.ORGA
              && structure.structureId !== rootStructureId
              && structure.interne
              && !hasLienHierarchiqueEnAttente
              &&  selectedAction === action.READ">
      Ajouter une structure de rattachement
    </button>

    <!-- AJOUTER UN LIEN VERS UNE STRUCTURE MATRICIELLE -->
    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            (click)="selectedAction = action.ADD_STRUCT_MAT"
            [disabled]="!(user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespActionPerimeter(structure))"
            *ngIf="structure.catStructure === catStructure.ORGA
              && structure.structureId !== rootStructureId
              && structure.interne
              && selectedAction === action.READ">
      Ajouter un lien avec une structure matricielle
    </button>

    <!-- AJOUTER UN LIEN VERS UNE STRUCTURE ORGANISATIONNELLE -->
    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            (click)="selectedAction = action.ADD_STRUCT_ORGA"
            [disabled]="!(user.individu.isAdmin || (user.individu.isGestionnaire || user.individu.isResponsable) && isInUserGestRespActionPerimeter(structure))"
            *ngIf="structure.structureId !== rootStructureId
              && selectedAction === action.READ">
      Ajouter un lien non hiérarchique avec une structure organisationnelle interne
    </button>
  </div>

  <button class="btn-white float-right" mat-raised-button
          (click)="selectedAction = action.READ"
          *ngIf="selectedAction !== action.READ">Retour au détail de la structure
  </button>
</div>

<!-- TABLEAUX DES STRUCTURES ASSOCIEES -->
<div class="row" *ngIf="selectedAction === action.READ">
  <ng-container *ngFor="let value of tabValues, let i = index">

    <p-table class="col-lg-12 mt-4"
             [value]="value"
             [columns]="cols"
             [responsive]="true"
             [resizableColumns]="true"
             [paginator]="true"
             [rows]="rows"
             [showCurrentPageReport]="true"
             [(first)]="first"
             currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
             [rowsPerPageOptions]="rowsPerPageOptions"
             *ngIf="value.length > 0">
      <ng-template pTemplate="caption">
        {{i === 0 ? 'Liens avec structures organisationnelles interne ascendantes' :
        i === 1 ? 'Liens avec structures organisationnelles interne descendantes' : 'Autres liens' }}
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="text-center" *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="text-center" style="width:120px" pResizableColumn *ngIf="user.individu.isAdmin">
            Modifier
          </th>
          <th class="text-center" style="width:120px" pResizableColumn
              *ngIf="i !== 1
              && (user.individu.isAdmin || isInUserGestRespActionPerimeter(structure))
              && structure.structureId !==rootStructureId">Demande de modification
          </th>
          <th class="text-center" style="width:120px" pResizableColumn
              *ngIf="i !== 1
              && (user.individu.isAdmin || isInUserGestRespActionPerimeter(structure))
              && structure.structureId !== rootStructureId">Demande de clôture
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td>
            <span appHighlightStructure
                  [structure]="row.lien.structure"
                  *ngIf="!(user.individu.isAdmin || isInUserGestRespAccessPerimeter(row.lien.structure))">
              {{row['structure'] | libelleStructure:user}}</span>
            <a href appHighlightStructure
               [structure]="row.lien.structure"
               [routerLink]="'/structure/' +  row.lien.structure.structureId"
               *ngIf="user.individu.isAdmin || isInUserGestRespAccessPerimeter(row.lien.structure)">
              {{row['structure'] | libelleStructure:user}}</a>
          </td>
          <td>{{row['relation']}}</td>
          <td>
            <span appHighlightStructure
                  [structure]="row.lien.structurePorteuse"
                  *ngIf="!(user.individu.isAdmin || isInUserGestRespAccessPerimeter(row.lien.structurePorteuse))">
              {{row['structurePorteuse'] | libelleStructure:user}}</span>
            <a href appHighlightStructure
               [structure]="row.lien.structurePorteuse"
               [routerLink]="'/structure/' +  row.lien.structurePorteuse.structureId"
               *ngIf="user.individu.isAdmin || isInUserGestRespAccessPerimeter(row.lien.structurePorteuse)">
              {{row['structurePorteuse'] | libelleStructure:user}}</a>
          </td>
          <td>{{row['dateDebut']}}</td>
          <td>{{row['dateFin']}}</td>
          <td>{{row['etatValid']}}</td>


          <td class="text-center" *ngIf="user.individu.isAdmin">
            <div [matTooltip]="'Modifier le lien'">
              <button class="button" (click)="update(row.lien)">
                <i class="fas fa-pencil-alt fa-2x"></i></button>
            </div>
          </td>

          <td class="text-center"
              *ngIf="i !== 1
              && (user.individu.isAdmin || isInUserGestRespActionPerimeter(structure))
              && structure.structureId !== rootStructureId">
            <div
              [matTooltip]="!isUpdateButtonVisible(row.lien) ? 'Le lien n\'est pas modifiable' : 'Créer une demande de modification du lien'">
              <button class="button"
                      (click)="modifierStructureRattachement(row.lien)"
                      [disabled]="!(structure.catStructure === catStructure.ORGA
                      && structure.etatStructure === etatStructure.ACTIF)"
                      *ngIf="isUpdateButtonVisible(row.lien)">
                <i class="fas fa-edit fa-2x"></i></button>
            </div>
          </td>

          <td class="text-center"
              *ngIf="i !== 1
              && (user.individu.isAdmin || isInUserGestRespActionPerimeter(structure))
              && structure.structureId !== rootStructureId">
            <div [matTooltip]="row.hasDemandeSuppressionEnCours ? 'Il existe déjà une demande de suppression pour ce lien' :
                    hasSuppressionStructureRattachementEnAttente ? 'Il y a une autre demande de suppression en attente. Vous devez valider celle-ci avant d\'en générer une nouvelle' :
                    row.typeLien.isHierarchique && countStructureRattachementValides <= 1 ? 'La structure doit avoir au moins une autre structure de rattachement valide, votre demande de suppression ne pourra aboutir' : 'Supprimer lien'"
                 *ngIf="row.structure.catStructure === catStructure.MAT ||
                        !row.typeLien.isHierarchique ||
                        (row.structureId === structure.structureId && row.typeLien.isHierarchique)">
              <button class="button" (click)="supprimerLien(row.lien)"
                      [disabled]="(row.structure.catStructure === catStructure.ORGA &&
                                   row.typeLien.isHierarchique && countStructureRattachementValides <= 1) || row.hasDemandeSuppressionEnCours || hasSuppressionStructureRattachementEnAttente"
                      *ngIf="isRemoveButtonVisible(row.lien)">
                <i class="fas fa-window-close fa-2x"></i></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </ng-container>
</div>

<app-ajout-structure-rattachement class="mt-4"
                                  [structure]="structure"
                                  (demandeEmitter)="parent.init()"
                                  *ngIf="selectedAction === action.ADD_STRUCT_RATT"></app-ajout-structure-rattachement>

<app-ajout-lien-non-hierarchique class="mt-4"
                                 [categorie]=catStructure.ORGA
                                 [structure]="structure"
                                 (demandeEmitter)="parent.init()"
                                 *ngIf="selectedAction === action.ADD_STRUCT_ORGA"></app-ajout-lien-non-hierarchique>

<app-ajout-lien-non-hierarchique class="mt-4"
                                 [categorie]=catStructure.MAT
                                 [structure]="structure"
                                 (demandeEmitter)="parent.init()"
                                 *ngIf="selectedAction === action.ADD_STRUCT_MAT"></app-ajout-lien-non-hierarchique>

<app-modification-structure-rattachement class="mt-4"
                                         [lienContexte]="selectedLien"
                                         [structure]="structure"
                                         (demandeEmitter)="parent.init()"
                                         *ngIf="selectedAction === action.MOD_STRUCT_RATT"></app-modification-structure-rattachement>

<app-suppression-lien class="mt-4"
                      [lienContexte]="selectedLien"
                      [structure]="structure"
                      (demandeEmitter)="parent.init()"
                      *ngIf="selectedAction === action.DEL_LIEN"></app-suppression-lien>
