import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pagination} from '../model/divers.model';
import {Log, TypeLog} from '../model/log.model';

@Injectable()
export class LogService {

  constructor(private http: HttpClient) {
  }

  public search(params: HttpParams, pagination: Pagination): Observable<any> {
    return this.http.post<any>('/log/search', pagination, {params, observe: 'response'});
  }

  public getLogsByUser(): Observable<Log[]> {
    return this.http.get<Log[]>('/log/user');
  }

  public getLogsByStructure(structureId: number): Observable<Log[]> {
    return this.http.get<Log[]>('/log/structure/' + structureId);
  }

  public getAllTypesLog(): Observable<TypeLog[]> {
    return this.http.get<TypeLog[]>('/log/all-types-log');
  }
}
