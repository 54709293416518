import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TypeVoierie} from '../model/nomenclature.model';

@Injectable()
export class TypeVoierieService {

  constructor(private http: HttpClient) {
  }

  public getAllTypesVoierie(): Observable<TypeVoierie[]> {
    return this.http.get<TypeVoierie[]>('/type-voierie/all');
  }

  public save(typesVoierie: TypeVoierie[]): Observable<TypeVoierie[]> {
    return this.http.put<TypeVoierie[]>('/type-voierie/save', typesVoierie);
  }
}
