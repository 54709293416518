import {Component, Inject} from '@angular/core';
import {IdentiteStructure, Lien, Structure} from '../../../../model/structure.model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ETAT_VALID} from '../../../../model/enum.model';

@Component({
  selector: 'app-identite-en-cours-dialog',
  templateUrl: './identite-en-cours-dialog.component.html',
  styleUrls: ['./identite-en-cours-dialog.component.scss']
})
export class IdentiteEnCoursDialogComponent {
  identite: IdentiteStructure;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.identite = data.structure.identites.filter(i => i.etatValid === ETAT_VALID.A_VALIDER)[0];
  }
}

export interface DialogData {
  name: string;
  structure: Structure;
}
