import {Domaine, Tutelle, TypeAdresse, TypeCode, TypeLien, TypeStructure, TypeVoierie} from './nomenclature.model';
import {CAT_STRUCTURE, ETAT_STRUCTURE, ETAT_VALID} from './enum.model';

export class Structure {
  structureId: number;
  identite: IdentiteStructure;
  catStructure: CAT_STRUCTURE;
  etatStructure: ETAT_STRUCTURE;
  interne: boolean;
  useRefAq: boolean;
  idRefAq: number;
  dateCreation: string;

  liensParent: Lien[];
  identites: IdentiteStructure[];
  domaines: Domaine[];
  tutelles: Tutelle[];
}

export class IdentiteStructure {
  identiteStructureId: number
  structureId:number;
  versionId: number;
  typeStructure: TypeStructure;
  libelleLong: string;
  libelleCourt: string;
  dateOuverture: Date;
  dateFermeture: Date;
  dateDebut: Date;
  dateFin: Date;
  dateCreation: Date;
  etatValid: ETAT_VALID;
}

export class DescriptionStructure {
  descriptionStructureId: number;
  structureId:number
  versionId: number;
  description: string;
  url: string;
  logoBase64: string;
  isCurrentVersion: boolean;
  dateCreation: Date;
}

export class Code {
  codeId: number;
  typeCode: TypeCode;
  structureId: number;
  codeValue: string;
  dateDebut: Date;
  dateFin: Date;
  isValid: boolean;
}

export class Adresse {
  adresseId: number;
  structureId: number;
  typeAdresse: TypeAdresse;
  pointGeo: string;
  num: string;
  compl: string;
  typeVoierie: TypeVoierie;
  libelleVoie: string;
  mentionSpec: string;
  cp: string;
  bureauDistrib: string;
  latitude: number;
  longitude: number;
  isValid = true;
}

export class Contact {
  contactId: number;
  structureId: number;
  intituleContact: string;
  nomPrenom: string;
  mail: string;
  telephone: string;
  mobile: string;
  fax: string;
  isPublic = true;
  isValid = true;
}

export class Lien {
  lienId: number;
  typeLien: TypeLien;
  structureId: number;
  structPorteuseId: number;
  dateDebut: Date;
  dateFin: Date;
  etatValid: ETAT_VALID;

  structure: Structure;
  structurePorteuse: Structure;
  hasDemandeSuppressionEnCours: boolean;

  constructor(typeLien: TypeLien) {
    this.typeLien = typeLien;
  }
}
