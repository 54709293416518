import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/user.model';
import {DomaineService} from '../../services/domaine.service';
import {TutelleService} from '../../services/tutelle.service';
import {ApplicationService} from '../../services/application.service';
import {TypeAdresseService} from '../../services/type-adresse.service';
import {TypeVoierieService} from '../../services/type-voierie.service';
import {TypeStructureService} from '../../services/type-structure.service';
import {TypeLienService} from '../../services/type-lien.service';
import {TypeCodeService} from '../../services/type-code.service';
import {InstanceService} from '../../services/instance.service';
import {DEMANDE} from '../../model/enum.model';
import {UserService} from '../../services/user.service';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() sidebar: boolean;

  groupesDemande = DEMANDE;

  user: User;

  typesStructValides: number;
  typesStructInvalides: number;
  tutellesValides: number;
  tutellesInvalides: number;
  domainesValides: number;
  domainesInvalides: number;
  instancesValides: number;
  instancesInvalides: number;
  typesLienValides: number;
  typesLienInvalides: number;
  typesCodeValides: number;
  typesCodeInvalides: number;
  typesAdresseValides: number;
  typesAdresseInvalides: number;
  typesVoierieValides: number;
  typesVoierieInvalides: number;
  applisValides: number;
  applisInvalides: number;

  constructor(private typeStructureService: TypeStructureService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private domaineService: DomaineService,
              private instanceService: InstanceService,
              private tutelleService: TutelleService,
              private typeLienService: TypeLienService,
              private typeCodeService: TypeCodeService,
              private typeAdresseService: TypeAdresseService,
              private typeVoierieService: TypeVoierieService,
              private applicationService: ApplicationService) {
    this.user = this.userService.getCurrentUser();
    this.sidebar = false;
  }

  ngOnInit() {
    if (this.authenticationService.getJwt && this.user.individu.isAdmin) {
      this.typeStructureService.getAllTypesStructure().subscribe(res => {
        this.typesStructValides = res.filter(t => t.isValid).length;
        this.typesStructInvalides = res.filter(t => !t.isValid).length;
      });

      this.domaineService.getAllDomaines().subscribe(res => {
        this.domainesValides = res.filter(d => d.isValid).length;
        this.domainesInvalides = res.filter(d => !d.isValid).length;
      });

      this.instanceService.getAllInstances().subscribe(res => {
        this.instancesValides = res.filter(d => d.isValid).length;
        this.instancesInvalides = res.filter(d => !d.isValid).length;
      });

      this.tutelleService.getAllTutelles().subscribe(res => {
        this.tutellesValides = res.filter(t => t.isValid).length;
        this.tutellesInvalides = res.filter(t => !t.isValid).length;
      });

      this.typeLienService.getAllTypesLien().subscribe(res => {
        this.typesLienValides = res.filter(t => t.isValid).length;
        this.typesLienInvalides = res.filter(t => !t.isValid).length;
      });

      this.typeCodeService.getAllTypesCode().subscribe(res => {
        this.typesCodeValides = res.filter(t => t.isValid).length;
        this.typesCodeInvalides = res.filter(t => !t.isValid).length;
      });

      this.typeAdresseService.getAllTypesAdresse().subscribe(res => {
        this.typesAdresseValides = res.filter(t => t.isValid).length;
        this.typesAdresseInvalides = res.filter(t => !t.isValid).length;
      });

      this.typeVoierieService.getAllTypesVoierie().subscribe(res => {
        this.typesVoierieValides = res.filter(t => t.isValid).length;
        this.typesVoierieInvalides = res.filter(t => !t.isValid).length;
      });

      this.applicationService.getAllAppliSI().subscribe(res => {
        this.applisValides = res.filter(a => a.isValid).length;
        this.applisInvalides = res.filter(a => !a.isValid).length;
      });
    }
  }

  getNavbarClasses(): string {
    if (!this.sidebar) {
      return 'navbar mr-auto';
    }
    return '';
  }

}
