<div class="row justify-content-center mt-4">
  <h1 class="title">Recherche</h1>
</div>

<div class="row justify-content-center">
  <mat-accordion class="col-lg-6 mt-4 mb-4 p-4">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>{{'Filtrer les structures'}}</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-structure-tree
        [structuresActivesOnly]="false"
        [title]="null"
        [displayOption]="true"
        [autoSelection]="false"
        [searchMode]="true"
        [tree]="false"
        (selectedStructureEmitter)="onStructureSelected($event)"></app-structure-tree>

      <div class="row">
        <h5 class="col-12">Formulaire de recherche</h5>
        <app-recherche-structure-form (searchEmitter)="search($event)"></app-recherche-structure-form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row justify-content-center">
  <mat-card class="col-lg-12" *ngIf="datasource?.length === 0">
    <h4 class="text-center">Votre recherche n'a retourné aucun résultat</h4>
  </mat-card>
</div>

<div class="row justify-content-center col-lg-12 mt-4" *ngIf="datasource.length > 0">
  <p-paginator class="col-lg-12"
               [rows]="rows"
               [totalRecords]="totalRecords"
               [pageLinkSize]="pageLinks"
               [rowsPerPageOptions]="rowsPerPageOptions"
               [first]="first"
               [showCurrentPageReport]="true"
               currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
               (onPageChange)="onPageChange($event)">
  </p-paginator>

  <p-table class="col-lg-12 mt-4"
           [columns]="cols"
           [value]="datasource"
           [responsive]="true"
           [resizableColumns]="true">
    <ng-template pTemplate="caption">Liste des structures trouvées</ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="text-center" *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>
          <a href appHighlightStructure
             [structure]="row.structure"
             [routerLink]="'/structure/' +  row.structure.structureId">
            {{row.structure | libelleStructure:user}}</a>
        </td>
        <td>{{row.libelleCourt}}</td>
        <td>{{row.catStructure}}</td>
        <td>{{row.typeStructure}}</td>
        <td>{{row.interne}}</td>
        <td>{{row.domaines}}</td>
        <td>{{row.tutelles}}</td>
        <td>{{row.etat}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
