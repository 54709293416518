import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Structure} from '../model/structure.model';
import {Pagination} from '../model/divers.model';

@Injectable()
export class StructureService {

  constructor(private http: HttpClient) {
  }

  public search(params: HttpParams, pagination: Pagination): Observable<any> {
    return this.http.post<any>('/structure/search', pagination, {params, observe: 'response'});
  }

  public getAllStructuresDTO(): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/all-extended-structures');
  }

  public getAllStructures(): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/all');
  }


  public getStructureByStructureId(structureId: number): Observable<Structure> {
    return this.http.get<Structure>('/structure/' + structureId);
  }

  public getStructuresFermees(): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/all-fermees');
  }

  public getStructuresInternes(): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/all-internes');
  }

  public getStructuresForAddingAutreLien(structureId: number, catStructure: string, typeLienId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/structures-for-adding-autre-lien/' + structureId + '/categorie/' + catStructure + '/type-lien/' + typeLienId);
  }

  public getStructuresByEtat(etatStructure: string): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/etat/' + etatStructure);
  }

  public getStructuresByCategorie(catStructure: string): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/categorie/' + catStructure);
  }

  public getStructuresByTypeStructure(typeStructureId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/type-structure/' + typeStructureId);
  }

  public getStructuresByTutelle(tutelleId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/tutelle/' + tutelleId);
  }

  public getStructuresByDomaine(domaineId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/domaine/' + domaineId);
  }

  public getStructuresByTypeLien(typeLienId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/type-lien/' + typeLienId);
  }

  public getStructuresByTypeCode(typeCodeId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/type-code/' + typeCodeId);
  }

  public getStructuresByTypeAdresse(typeAdresseId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/type-adresse/' + typeAdresseId);
  }

  public getStructuresByApplication(applicationId: number): Observable<Structure[]> {
    return this.http.get<Structure[]>('/structure/application/' + applicationId);
  }
}
