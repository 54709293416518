import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../../../services/application.service';
import {Structure} from '../../../../model/structure.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoaderService} from '../../../../services/loader.service';
import {AssociationSiService} from '../../../../services/association-si.service';
import {AssociationRequest} from '../../../../model/association.model';
import {Application} from '../../../../model/nomenclature.model';

@Component({
  selector: 'app-creation-nouvelle-association-dialog',
  templateUrl: './create-new-association-dialog.component.html',
  styleUrls: ['./create-new-association-dialog.component.scss']
})
export class CreateNewAssociationDialogComponent {

  applications: Application[];

  form: FormGroup;

  constructor(private loaderService: LoaderService,
              private applicationService: ApplicationService,
              private associationSiService: AssociationSiService,
              public dialogRef: MatDialogRef<CreateNewAssociationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.form = new FormGroup({
      application: new FormControl(null, Validators.required),
      createTodo: new FormControl(true),
      codeSI: new FormControl('FAKE_VALUE', Validators.required)
    });


    if (this.data) {
      this.applicationService.getAvailableApplications(this.data.structure.structureId).subscribe(res => this.applications = res);
    }
  }

  OnInitSynchroChange() {
    if (this.form.controls.createTodo.value) {
      this.form.controls.codeSI.setValue('FAKE_VALUE');
    } else {
      this.form.controls.codeSI.setValue(null);
    }
  }

  onConfirm() {
    const associationRequest: AssociationRequest = new AssociationRequest(
      {
        structureId: this.data.structure.structureId,
        application: this.form.controls.application.value,
        codeStructSI: this.form.controls.createTodo.value !== 'FAKE_VALUE' ? this.form.controls.codeSI.value : null,
      },
      this.form.controls.createTodo.value
    );

    this.loaderService.start();
    this.associationSiService.create(associationRequest).subscribe(() => {
      this.loaderService.stop();
      this.dialogRef.close();
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  structure: Structure;
}
