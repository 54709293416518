<form [formGroup]="form">
  <mat-form-field class="col-lg-6" appearance="outline">
    <mat-label>Types de la demande</mat-label>
    <mat-select formControlName="types" placeholder="Types de la demande" multiple>
      <mat-option *ngFor="let t of typeDemandes" [value]="t.typeDemandeId">
        {{t.typeDemandeLib}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-6" appearance="outline">
    <mat-label>Etats de la demande</mat-label>
    <mat-select formControlName="etats" placeholder="Etats de la demande" multiple>
      <mat-option *ngFor="let e of etatDemandes" [value]="e.value">
        {{e.libelle}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-6" appearance="outline" *ngIf="this.user.individu.isAdmin">
    <mat-label>Demandeurs</mat-label>
    <mat-select formControlName="demandeurs" placeholder="Demandeurs" multiple>
      <mat-option *ngFor="let d of demandeurs" [value]="d.individuId">
        {{d.nom}} {{d.prenom}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-6" appearance="outline" *ngIf="this.user.individu.isAdmin">
    <mat-label>Structures</mat-label>
    <mat-select formControlName="structures" placeholder="Structures" multiple>
      <mat-option appHighlightStructure [structure]="struct" *ngFor="let struct of structures"
                  [value]="struct.structureId">{{struct | libelleStructure:user}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-lg-6" appearance="outline">
    <mat-label>Avant le</mat-label>
    <input matInput formControlName="avant" [matDatepicker]="avantpicker" placeholder="Demande faite avant le">
    <mat-datepicker-toggle matSuffix [for]="avantpicker">
    </mat-datepicker-toggle>
    <mat-datepicker #avantpicker></mat-datepicker>
    <mat-error *ngIf="form.controls['avant'].invalid">Format non valide</mat-error>
  </mat-form-field>

  <mat-form-field class="col-lg-6" appearance="outline">
    <mat-label>Après le</mat-label>
    <input matInput formControlName="apres" [matDatepicker]="aprespicker" placeholder="Demande faite à partir du">
    <mat-datepicker-toggle matSuffix [for]="aprespicker">
    </mat-datepicker-toggle>
    <mat-datepicker #aprespicker></mat-datepicker>
    <mat-error *ngIf="form.controls['apres'].invalid">Format non valide</mat-error>
  </mat-form-field>

  <div class="col-lg-12">
    <mat-checkbox formControlName="isMine" [checked]="form.controls.isMine.value">
      Restreindre la recherche à mes demandes
    </mat-checkbox>
  </div>
</form>

<div class="row justify-content-center">
  <button class="mt-2 mb-2" mat-raised-button color="primary" matTooltip="Filtrer"
          (click)="search()"><i class="fas fa-search"></i></button>
</div>
