<h1 mat-dialog-title>
  {{!data.compteAppli ? 'Ajout d\'un nouveau compte applicatif' : 'Modification du compte applicatif ' + data.compteAppli.login}}
</h1>

<div mat-dialog-content class="row justify-content-center mt-4" *ngIf="display">
  <form [formGroup]="form">
    <div class="row">
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Compte applicatif</mat-label>
        <input matInput formControlName="login" placeholder="Compte applicatif"
               (input)="onLoginChanged()">
        <mat-error *ngIf="form.controls['login'].hasError('required') && form.controls['login'].touched">
          Valeur requise</mat-error>
        <mat-error *ngIf="form.controls['login'].hasError('login-exists')"></mat-error>
      </mat-form-field>

      <mat-slide-toggle class="col-6" color="primary" formControlName="superUser"
                        [disabled]="data.compteAppli != null">Super Utilisateur
      </mat-slide-toggle>
    </div>

    <div class="row">
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" placeholder="Description"></textarea>
        <mat-error
          *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">Valeur
          requise
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-slide-toggle class="col-6" color="primary" formControlName="alert"
                        *ngIf="data.compteAppli">Prévenir un administrateur pour une extension des droits sur l'API ou
        un changement du responsable de l'application
      </mat-slide-toggle>
    </div>

    <div *ngIf="!data.compteAppli">
      <mat-label>Responsable de l'application</mat-label>
      <app-recherche-ldap-form [selectedLdapAccount]="ldapAccount"
                               (ldapAccountEventEmitter)="getLdapAccount($event)"></app-recherche-ldap-form>
    </div>
  </form>
</div>

<div mat-dialog-actions class="row justify-content-center">
  <button mat-raised-button color="primary" (click)="validate()"
          [disabled]="form.pristine || form.invalid || !ldapAccount">
    <i class="fas fa-check fa-2x"></i>
  </button>

  <button mat-raised-button color="accent" (click)="onCancel()">
    <i class="fas fa-times fa-2x"></i>
  </button>
</div>
