import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Code} from '../model/structure.model';

@Injectable()
export class CodeService {

  constructor(private http: HttpClient) {
  }

  public getCodesByStructureId(structureId: number): Observable<Code[]> {
    return this.http.get<Code[]>('/code/structure/' + structureId);
  }

  public create(code: Code): Observable<Code> {
    return this.http.put<Code>('/code/create', code);
  }

  public updateOrDelete(code: Code): Observable<void> {
    return this.http.put<void>('/code/update', code);
  }

  public updateWithHisto(code: Code, dateFin: string): Observable<void> {
    return this.http.put<void>('/code/update/date-fin/' + dateFin, code);
  }

}
