import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TypeLien} from '../model/nomenclature.model';

@Injectable()
export class TypeLienService {

  constructor(private http: HttpClient) {
  }

  public getAllTypesLien(): Observable<TypeLien[]> {
    return this.http.get<TypeLien[]>('/type-lien/all');
  }

  public getAllTypesLienNonHierarchique(): Observable<TypeLien[]> {
    return this.http.get<TypeLien[]>('/type-lien/all/non-hierarchique');
  }

  public save(typesLien: TypeLien[]): Observable<TypeLien[]> {
    return this.http.put<TypeLien[]>('/type-lien/save', typesLien);
  }

}
