import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Demande} from '../../../model/demande.model';
import {Structure} from '../../../model/structure.model';
import {DemandeStructMatFormComponent} from '../../demande/demande-struct-mat-form/demande-struct-mat-form.component';
import {WorkflowService} from '../../../services/workflow.service';
import {LoaderService} from '../../../services/loader.service';
import {CreationRequest} from '../../../model/workflow.model';
import {User} from '../../../model/user.model';
import {isInGestRespActionPerimeter} from '../../../utils/user.utils';
import {ETAT_DEMANDE, TYPE_CREATION} from '../../../model/enum.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-modification-structure-matricielle',
  templateUrl: './modification-structure-matricielle.component.html',
  styleUrls: ['./modification-structure-matricielle.component.scss']
})
export class ModificationStructureMatricielleComponent {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() demande: Demande;
  @Input() structurePorteuse: Structure;

  @ViewChild(DemandeStructMatFormComponent) demandeStructMatComponent: DemandeStructMatFormComponent;

  user: User;
  motif: string;
  etatDemande = ETAT_DEMANDE;

  constructor(private loaderService: LoaderService,
              private userService: UserService,
              private workflowService: WorkflowService) {
    this.user = this.userService.getCurrentUser();
  }

  validate() {
    this.loaderService.start();
    this.workflowService.sendNewSubmission(this.getCreationRequest()).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  private getCreationRequest(): CreationRequest {
    return {
      isNewSubmission: !this.user.individu.isAdmin,
      demandeId: this.demande.demandeId,
      typeDemandeId: this.demande.typeDemande.typeDemandeId,
      motif: this.motif,
      catStructure: this.demande.lien.structure.catStructure,
      interne: this.demandeStructMatComponent.form.controls.interne.value,
      typeLienId: this.demandeStructMatComponent.form.controls.typeLien.value,
      structurePorteuseId: this.demandeStructMatComponent.getStructurePorteuseId(),
      typeStructureId: this.demandeStructMatComponent.identiteFormComponent.getIdentite().typeStructure.typeStructureId,
      libelleLong: this.demandeStructMatComponent.identiteFormComponent.getIdentite().libelleLong,
      libelleCourt: this.demandeStructMatComponent.identiteFormComponent.getIdentite().libelleCourt,
      dateOuverture: this.demandeStructMatComponent.identiteFormComponent.getIdentite().dateOuverture,
      dateFermeture: this.demandeStructMatComponent.identiteFormComponent.getIdentite().dateFermeture,
      domaineIds: this.demandeStructMatComponent.form.controls.domaines.value,
      tutelleId: null,
      typeCreation: TYPE_CREATION.NEW,
      typeCreationStruct: [],
      responsable: null,
      instanceDemande: null,
    };
  }

  isInGestRespPerimeter(structureId: number) {
    return isInGestRespActionPerimeter(structureId, this.user);
  }
}
