import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmSyncApplicationSiDialogComponent} from '../../../layout/dialog/synchro-application-si/confirm-sync-application-si-dialog/confirm-sync-application-si-dialog.component';
import {ApplicationService} from '../../../services/application.service';
import {FormGroup} from '@angular/forms';
import {TodoService} from '../../../services/todo.service';
import {Todo} from '../../../model/association.model';
import {getDDMMYYYYHHmm} from '../../../utils/date.utils';
import {Structure} from '../../../model/structure.model';
import {ETAT_TODO} from '../../../model/enum.model';
import {Paginator} from 'primeng/paginator';
import {Router} from '@angular/router';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss']
})
export class TodoComponent implements OnChanges {

  @Output() pageEmitter: EventEmitter<Paginator> = new EventEmitter<Paginator>();
  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() structure: Structure;
  @Input() todos: Todo[];

  @Input() first: number;
  @Input() rows: number;
  @Input() totalRecords: number;

  user:User;

  datasource: any[];
  cols: any[];
  selectedTodoId: number;

  rowsPerPageOptions = [10, 25, 50];
  pageLinks: number;
  etatTodo = ETAT_TODO;

  constructor(private loaderService: LoaderService,
              private applicationService: ApplicationService,
              private userService:UserService,
              private router: Router,
              private todoService: TodoService,
              public dialog: MatDialog) {
    this.user = this.userService.getCurrentUser();

    this.cols = [
      {field: 'structure', header: 'Structure'},
      {field: 'application', header: 'Application'},
      {field: 'etat', header: 'Etat'},
      {field: 'date', header: 'Date et heure'},
    ];
  }

  ngOnChanges() {
    if (this.totalRecords && this.rows) {
      this.pageLinks = Math.ceil(this.totalRecords / this.rows);
    }

    if (this.todos) {
      this.datasource = [];
      this.todos.forEach(e => this.datasource.push(this.convertTodoToData(e)));
    }
  }

  onPageChange(paginator: Paginator) {
    this.pageEmitter.emit(paginator);
  }

  onActionClick(row: any, isStart: boolean) {
    this.selectedTodoId = row.todoId;
    this.openDialog(isStart);
  }

  openDialog(isStart: boolean) {
    this.dialog.open(ConfirmSyncApplicationSiDialogComponent, {
      minWidth: 400,
      data: {
        question: isStart ? 'Récupérer le code la structure dans le SI ' : 'Formulaire de clôture manuelle de la tâche',
        isStart
      }
    }).afterClosed().subscribe(res => {
      if (res.ok) {
        isStart ? this.startTodo(res.form) : this.forceTodo(res.form);
      }
    });
  }

  private startTodo(form: FormGroup) {
    this.todoService.startTodo(this.selectedTodoId, form.controls.codeSI.value, form.controls.motif.value)
      .subscribe(() => this.actionEmitter.emit());
  }

  private forceTodo(form: FormGroup) {
    this.todoService.close(this.selectedTodoId, form.controls.motif.value)
      .subscribe(() => this.actionEmitter.emit());
  }

  convertTodoToData(todo: Todo) {
    return {
      todoId: todo.todoId,
      libelle: todo.structure.identite.libelleLong,
      application: todo.application.applicationLib,
      etat: todo.etatTodo,
      date: getDDMMYYYYHHmm(todo.dateTodo),
      type: todo.typeTodo,
      commentaire: todo.commentaire,
      structure: todo.structure
    };
  }
}
