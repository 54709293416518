import {Component, EventEmitter, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {Structure} from '../../../model/structure.model';
import {StructureService} from '../../../services/structure.service';
import {TypeLien} from '../../../model/nomenclature.model';
import {TypeLienService} from '../../../services/type-lien.service';
import {User} from '../../../model/user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-recherche-lien-form',
  templateUrl: './recherche-lien-form.component.html',
  styleUrls: ['./recherche-lien-form.component.scss']
})
export class RechercheLienFormComponent {

  @Output() searchEmitter: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  user: User;
  form: FormGroup;

  structures: Structure[];
  typesLien: TypeLien[];

  constructor(private typeLienService: TypeLienService,
              private userService: UserService,
              private structureService: StructureService) {
    this.user = this.userService.getCurrentUser();
    this.form = new FormGroup({
      structures: new FormControl(null),
      typesLien: new FormControl(null),
      structuresPorteuses: new FormControl(null),
    });

    structureService.getAllStructures().subscribe(res => this.structures = res);
    typeLienService.getAllTypesLien().subscribe(res => this.typesLien = res.filter(t => t.isAntecedent && t.isValid));
  }

  search() {
    let params = new HttpParams();

    if (this.form.controls.structures.value && this.form.controls.structures.value.length > 0) {
      params = params.append('structures', this.form.controls.structures.value);
    }
    if (this.form.controls.typesLien.value && this.form.controls.typesLien.value.length > 0) {
      params = params.append('typesLien', this.form.controls.typesLien.value);
    }
    if (this.form.controls.structures.value && this.form.controls.structures.value.length > 0) {
      params = params.append('structuresPorteuses', this.form.controls.structures.value);
    }

    this.searchEmitter.emit(params);
  }
}
