<div class="row justify-content-center mt-4" *ngIf="user.individu.isAdmin">
  <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
          (click)="displayLog = !displayLog" *ngIf="!displayLog">Consulter les logs
  </button>

  <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
          (click)="addLienAntecedent()" *ngIf="!displayLog">Ajouter un lien antécédent
  </button>

  <div matTooltip="Il n'y a actuellement aucune application du SI qui ne peut être ajoutée à cette structure."
       [matTooltipDisabled]="hasAvailableAssociations">
    <button class="btn-white d-inline-block ml-2 mt-2" mat-raised-button
            (click)="addSI()" [disabled]="!hasAvailableAssociations" *ngIf="!displayLog">Ajouter une application SI
    </button>
  </div>

  <button class="btn-white float-right" mat-raised-button
          (click)="displayLog = !displayLog"
          *ngIf="displayLog">Retour à l'administration de la structure
  </button>
</div>

<div *ngIf="structure">
  <!-- CONSULTER LES LOGS -->
  <div class="row justify-content-center" *ngIf="displayLog">
    <app-log class="col-lg-12 mt-4"
             [structure]="structure"
             [logs]="logs"></app-log>
  </div>

  <div *ngIf="!displayLog">
    <div class="row">
      <!-- SYNCHRO REF AQ -->
      <app-referentiel-aquitain class="col-lg-6 mt-4"
                                [structure]="structure"
                                *ngIf="structure && user.individu.isAdmin || structure?.idRefAq">
      </app-referentiel-aquitain>

      <!-- APPLICATION SI -->
      <app-repart-struct-si class="col-lg-6 mt-4"
                            [rssList]="rssList"
                            [structure]="structure"
                            (actionEmitter)="init()">
      </app-repart-struct-si>

      <!-- LIEN ANTECEDENTS -->
      <app-liens-antecedents class="col-lg-6"
                             [structure]="structure"
                             [liens]="liensAntecedents"
                             (actionEmitter)="init()">
      </app-liens-antecedents>

      <!--TODOS-->
      <app-todo class="col-lg-6 mt-4"
                title="Liste des todos"
                [structure]="structure"
                [todos]="todos"
                (actionEmitter)="init()"
                *ngIf="todos?.length > 0 && user.individu.isAdmin"></app-todo>

      <!-- EVENEMENTS -->
      <app-evenements class="col-lg-6 mt-4" [structure]="structure" *ngIf="user.individu.isAdmin"></app-evenements>
    </div>
  </div>
</div>
