import {Component, OnInit} from '@angular/core';
import {DomaineService} from '../../../services/domaine.service';
import {StructureService} from '../../../services/structure.service';
import {LoaderService} from '../../../services/loader.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {Domaine} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-domaine',
  templateUrl: './domaine.component.html',
  styleUrls: ['./domaine.component.scss']
})
export class DomaineComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: Domaine[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private domaineService: DomaineService,
              private snackBar: MatSnackBar,
              private structureService: StructureService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(domaine: Domaine) {
    domaine.hasError = false;

    if (!domaine.domaineLib || domaine.domaineLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      domaine.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, domaine.domaineLib, 'domaineLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      domaine.hasError = true;
    }
  }

  onIsValidChanged(domaine: Domaine) {
    domaine.isValid = !domaine.isValid;

    if (!domaine.isValid) {
      if (domaine.domaineId) {
        this.structureService.getStructuresByDomaine(domaine.domaineId).subscribe(
          res => {
            if (res.length > 0) {
              domaine.hasAssociation = true;
              this.snackBar.open(
                'Le domaine ' + domaine.domaineLib + ' est actuellement associé à des structures du référentiel.',
                'X',
                {panelClass: 'notif-warn'}
              );
            }
          });

      } else {
        const index = this.dataSource.indexOf(domaine, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        domaineId: null,
        domaineLib: null,
        isValid: true,
        hasAssociation: false,
        deleteAssociation: false,
        isHidden: false,
        hasError: true
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.domaineService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(d => d.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.domaineService.getAllDomaines().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }

}
