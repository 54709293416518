<form [formGroup]="form">
  <div class="row">
    <mat-form-field class="col-lg-4" appearance="outline" >
      <mat-label>Structure d'origine</mat-label>
      <input matInput type="text" placeholder="structure d'origine" formControlName="structure" required>
    </mat-form-field>


    <mat-form-field class="col-lg-4" appearance="outline" *ngIf="!lienContexte">
      <mat-label>Type de relation</mat-label>
      <mat-select formControlName="typeLien" placeholder="Type de relation" (selectionChange)="onTypeLienChange()" required>
        <mat-option *ngFor="let typeLien of typeLienList" [value]="typeLien.typeLienId">
          {{typeLien.relation}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="!form.controls['typeLien'].value">Valeur requise</mat-error>
    </mat-form-field>

    <mat-form-field class="col-lg-4" appearance="outline" *ngIf="!lienContexte">
      <mat-label>Structure à rattacher</mat-label>
      <mat-select formControlName="structureRattach" [disabled]="form.controls.typeLien.disabled" required>
        <mat-option appHighlightStructure [structure]="structureRattach"
                    *ngFor="let structureRattach of structRattachList"
                    [value]="structureRattach.structureId">
          {{structureRattach.identite.libelleLong}}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="form.controls.structureRattach.hasError('required') && form.controls.structureRattach.touched">
        Valeur requise
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-lg-4" appearance="outline" *ngIf="lienContexte">
      <mat-label>Type de relation</mat-label>
      <input matInput type="text" placeholder="Type de relation" aria-label="Type de relation à créer"
             formControlName="typeLien" required>
    </mat-form-field>

    <mat-form-field class="col-lg-4" appearance="outline" *ngIf="lienContexte">
      <mat-label>Structure à rattacher</mat-label>
      <input matInput type="text" placeholder="Structure à rattacher" aria-label="Structure à rattacher"
             formControlName="structureRattach">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-lg-6" appearance="outline">
      <mat-label>Date de début</mat-label>
      <input matInput formControlName="dateDebut" [min]="structure?.identite.dateOuverture"
             [matDatepicker]="debpicker" placeholder="Date de début" required>
      <mat-datepicker-toggle matSuffix [for]="debpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #debpicker></mat-datepicker>
      <mat-error *ngIf="form.controls['dateDebut'].hasError('matDatepickerMin')">
        La date choisie ne peut être antérieure à la date d'ouverture de la structure</mat-error>
    </mat-form-field>

    <mat-form-field class="col-lg-6" appearance="outline" *ngIf="lienContexte">
      <mat-label>Date de fin</mat-label>
      <input formControlName="dateFin" matInput [matDatepicker]="finpicker" placeholder="Date de fin">
      <mat-datepicker-toggle matSuffix [for]="finpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #finpicker></mat-datepicker>
      <mat-error *ngIf="form.controls['dateFin'].invalid">Format non valide</mat-error>
    </mat-form-field>
  </div>
</form>
