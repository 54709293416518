import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {AdresseService} from '../../../services/adresse.service';
import {TypeVoierieService} from '../../../services/type-voierie.service';
import {doesAlreadyExist} from '../../../utils/divers.utils';
import {TypeVoierie} from '../../../model/nomenclature.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-type-voierie',
  templateUrl: './type-voierie.component.html',
  styleUrls: ['./type-voierie.component.scss']
})
export class TypeVoierieComponent implements OnInit {

  hasInvalidValues: boolean;
  displayInvalidValues: boolean;

  dataSource: TypeVoierie[];
  first = 0;
  rows = 25;
  rowsPerPageOptions = [10, 25, 50];

  constructor(private loaderService: LoaderService,
              private adresseService: AdresseService,
              private snackBar: MatSnackBar,
              private typeVoierieService: TypeVoierieService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.init();
  }

  onLibelleChanged(typeVoierie: TypeVoierie) {
    typeVoierie.hasError = false;

    if (!typeVoierie.typeVoierieLib || typeVoierie.typeVoierieLib === '') {
      this.snackBar.open(
        'Le libellé est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeVoierie.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, typeVoierie.typeVoierieLib, 'typeVoierieLib')) {
      this.snackBar.open(
        'L\'intitulé saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeVoierie.hasError = true;
    }
  }

  onCodeChanged(typeVoierie: TypeVoierie) {
    typeVoierie.hasError = false;

    if (!typeVoierie.typeVoierieCode || typeVoierie.typeVoierieCode === '') {
      this.snackBar.open(
        'Le code est obligatoire.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeVoierie.hasError = true;

    } else if (doesAlreadyExist(this.dataSource, typeVoierie.typeVoierieCode, 'typeVoierieCode')) {
      this.snackBar.open(
        'Le code saisi existe déjà, votre demande ne pourra aboutir.',
        'X',
        {panelClass: 'notif-warn'}
      );
      typeVoierie.hasError = true;
    }
  }

  onIsValidChanged(typeVoierie: TypeVoierie) {
    typeVoierie.isValid = !typeVoierie.isValid;

    if (!typeVoierie.isValid) {
      if (typeVoierie.typeVoierieId) {
        this.adresseService.getAdressesByTypeVoierie(typeVoierie.typeVoierieId).subscribe(res => {
          if (res.length > 0) {
            this.snackBar.open(
              'Le type de voierie ' + typeVoierie.typeVoierieLib + ' est actuellement associé à des structures du référentiel.',
              'X',
              {panelClass: 'notif-warn'}
            );
            typeVoierie.isValid = true;
          }
        });

      } else {
        const index = this.dataSource.indexOf(typeVoierie, 0);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
      }
    }
  }

  add() {
    this.dataSource.unshift(
      {
        typeVoierieId: null,
        typeVoierieCode: null,
        typeVoierieLib: null,
        ordre: null,
        isValid: true,
        isHidden: false,
        hasError: true
      }
    );
  }

  validate() {
    this.loaderService.start();
    this.typeVoierieService.save(this.dataSource).subscribe(() => {
      this.loaderService.stop();
      this.init();
    });
  }

  cancel() {
    this.init();
  }

  displayInvalid() {
    this.displayInvalidValues = !this.displayInvalidValues;
    if (this.displayInvalidValues) {
      this.dataSource.forEach(t => t.isHidden = false);
    } else {
      this.dataSource.forEach(t => t.isHidden = !t.isValid);
    }
  }

  isInvalid(): boolean {
    return this.dataSource.filter(t => t.hasError).length > 0;
  }

  init() {
    this.displayInvalidValues = true;
    this.typeVoierieService.getAllTypesVoierie().subscribe(res => {
      this.dataSource = res;
      this.hasInvalidValues = res.length !== res.filter(r => r.isValid).length;
    });
  }


}
