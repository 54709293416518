<div class="row justify-content-center">
  <mat-card class="col-lg-4">
    <mat-card-header class="justify-content-center">
      <h3>Erreur d'authentification</h3>
    </mat-card-header>

    <mat-card-content>
      <div class="text-center">Aucun role ne vous a été attribué.<br/>Veuillez en faire la demande auprès d'un administrateur ou
        d'un gestionnaire.
      </div>

      <div class="row justify-content-center mt-4">
        <button class="button d-block" (click)="logout()"><i class="fas fa-sign-out-alt fa-2x"></i></button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

