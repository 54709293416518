import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user.model';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {

  user: User;
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  public getCurrentUser(): User {
    return this.currentUser;
  }

  public getLoginAs(): User {
    return JSON.parse(localStorage.getItem('loginAs'));
  }

  public getUser(): User {
    return this.user;
  }

  public getUpdatedUser(): Observable<User> {
    return this.http.get<User>('/user/current-user');
  }

  public setCurrentUser(currentUser: User): void {
    this.currentUser = currentUser;
  }

  loginAs(loginAs: User): void {
    this.http.get<string>('/user/login-as/' + loginAs.username, {responseType: 'text' as 'json'}).subscribe(res => {
      localStorage.setItem('token-login-as', res);
      localStorage.setItem('loginAs', JSON.stringify(loginAs));
      window.location.reload();
    });
  }

  logoutAs(): void {
    localStorage.removeItem('loginAs');
    localStorage.removeItem('token-login-as');
    window.location.reload();
  }

  public getJwtLoginAs(): string {
    return localStorage.getItem('token-login-as');
  }

  public setUser(user: User): void {
    this.user = user;
    this.currentUser = user;
  }

}
