<div class="row justify-content-center mt-4">
  <h1 class="title">Votre tableau de bord</h1>
</div>

<div class="row">
  <!--  COLONNE GAUCHE-->
  <div class="col-lg-4">
    <div class="row">
      <!--MES PRIVILEGES-->
      <mat-card id="mes-privileges" class="col-lg-12 top-buffer">
        <mat-card-header>
          <h2>Mes privilèges</h2>
        </mat-card-header>

        <mat-card-content>
          <p class="mt-4 alert-info" *ngIf="!user.individu.isAdmin
                                                && !user.individu.isModerateur
                                                && !user.individu.isResponsable
                                                && !user.individu.isGestionnaire
                                                && !user.individu.isRespAppli">
            Vous n'avez actuellement aucun privilège</p>
          <ul>
            <li *ngIf="user.individu.isAdmin">Administrateur</li>
            <li *ngIf="user.individu.isRespAppli">Responsable d'application</li>

            <li *ngIf="user.individu.isModerateur">Modérateur
              <ul>
                <li *ngFor="let role of moderateurRoles">
                  <a href appHighlightStructure [structure]="role.structure"
                     [routerLink]="'/structure/' +  role.structureId">{{role.structure.identite.libelleLong}}</a>
                </li>
              </ul>
            </li>

            <li *ngIf="user.individu.isResponsable">Responsable
              <ul>
                <li *ngFor="let role of responsableRole">
                  <a href appHighlightStructure [structure]="role.structure"
                     [routerLink]="'/structure/' +  role.structureId">{{role.structure.identite.libelleLong}}</a>
                </li>
              </ul>
            </li>

            <li *ngIf="user.individu.isGestionnaire">Gestionnaire
              <ul>
                <li *ngFor="let role of gestionnaireRoles">
                  <a href appHighlightStructure [structure]="role.structure"
                     [routerLink]="'/structure/' +  role.structureId">{{role.structure.identite.libelleLong}}</a>
                  <span *ngIf="role.isExtended"> et ses structures filles</span>
                </li>
              </ul>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>

      <!--MES DEMANDES-->
      <mat-card class="col-lg-12 top-buffer"
                *ngIf="user.individu.isAdmin || user.individu.isModerateur || user.individu.isGestionnaire || user.individu.isResponsable">
        <mat-card-header>
          <h2>Mes demandes</h2>
        </mat-card-header>

        <mat-card-content>
          <p class="mt-4 alert-info" *ngIf="mesNouvellesDemandes + mesDemandesEnCours + mesDemandesTraites === 0">
            Vous n'avez actuellement aucune demande</p>

          <p-chart type="pie" width="300" height="300" [data]="mesDemandes"
                   (onDataSelect)="selectMesDemandes($event)"
                   *ngIf="mesNouvellesDemandes + mesDemandesEnCours + mesDemandesTraites > 0"></p-chart>
        </mat-card-content>
      </mat-card>

      <mat-card class="col-lg-12 top-buffer" *ngIf="comptesAppli?.length > 0 && user.individu.isRespAppli">
        <mat-card-header>
          <h2>Mes applications</h2>
        </mat-card-header>
        <mat-card-content>
          <p>
            <a [href]="docSwagger" target="_blank">Lien vers la documentation Swagger</a>
            <button mat-stroked-button class="ml-4" [cdkCopyToClipboard]="jwt">
              <small>Copier le token</small>
            </button>
          </p>

          <ng-container *ngFor="let compteApppli of comptesAppli">
            <div class="border p-4">
              <div><b>Login : </b><i>{{compteApppli.login}}</i></div>
              <div><b>Description : </b><i>{{compteApppli.description}}</i></div>
              <div><b>Accés étendu à l'API : </b><i>{{compteApppli.superUser ? 'Oui' : 'Non'}}</i></div>
              <div class="row d-flex justify-content-center mt-4">
                <button mat-raised-button color="primary" class="m-2" (click)="updateCompteApplicatif(compteApppli)">
                  Modifier
                </button>
                <button mat-raised-button color="primary" class="m-2" (click)="generateNewPassword(compteApppli)">
                  Générer
                  un nouveau mot de passe
                </button>
              </div>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!--  COLONNE DROITE-->
  <div class="col-lg-8">
    <div class="row justify-content-center">
      <!--LES DEMANDES EN COURS-->
      <div class="col-lg-4 top-buffer" [class.clickable]="demandesEnCours > 0"
           *ngIf="user.individu.isGestionnaire || user.individu.isResponsable"
           (click)="selectDemandes(demandes.DEMANDES_EN_COURS)">
        <mat-card class="card-demande">
          <mat-card-content class="justify-content-center">
            <span class="card-demande-counter ml-2 mr-4">{{demandesEnCours}}</span>
            {{' Demande'}}{{demandesEnCours > 0 ? 's' : ''}}{{' en cours' }}
          </mat-card-content>
        </mat-card>
      </div>

      <!--DEMANDES A TRAITER-->
      <div class=" col-lg-4 top-buffer" [class.clickable]="demandesATraiter > 0"
           *ngIf="user.individu.isAdmin || user.individu.isModerateur"
           (click)="selectDemandes(demandes.DEMANDES_A_TRAITER)">
        <mat-card class="card-demande">
          <mat-card-content class="justify-content-center">
            <span class="card-demande-counter ml-2 mr-4">{{demandesATraiter}}</span>
            {{' Demande'}}{{demandesATraiter > 0 ? 's' : ''}}{{' à traiter' }}
          </mat-card-content>
        </mat-card>
      </div>

      <!--DEMANDES EN ATTENTE-->
      <div class="col-lg-4 top-buffer" [class.clickable]="demandesEnAttente > 0"
           *ngIf="user.individu.isAdmin"
           (click)="selectDemandes(demandes.DEMANDES_EN_ATTENTE)">
        <mat-card class="card-demande">
          <mat-card-content class="justify-content-center">
            <span class="card-demande-counter ml-2 mr-4">{{demandesEnAttente}}</span>
            {{' Demande'}}{{demandesEnAttente > 0 ? 's' : ''}}{{' en attente' }}
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row justify-content-left">
      <!-- STRUCTURES A DATE DE FERMETURE ECHUE-->
      <div class="col-lg-6 top-buffer" *ngIf="structuresFermees.length > 0">
        <mat-card>
          <mat-card-header>
            <h2>Structures en attente de fermeture, suite à une date de fermeture échue</h2>
          </mat-card-header>

          <mat-card-content>
            <p-table [value]="structuresFermees"
                     [responsive]="true"
                     [resizableColumns]="true"
                     [paginator]="true"
                     [rows]="rowsStructFermees"
                     [showCurrentPageReport]="true"
                     [(first)]="firstStructFermees"
                     currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                     [rowsPerPageOptions]="rowsPerPageOptionsStructFermees">
              <ng-template pTemplate="header">
                <tr>
                  <th>Structure</th>
                  <th>Date de fermeture</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td><a href appHighlightStructure [structure]="row"
                         [routerLink]="'/structure/' +  row.structureId">{{row | libelleStructure:user}}</a></td>
                  <td>{{row.identite.dateFermeture | date:'dd/MM/yyyy'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- STRUCTURES EN ATTENTE DE FERMETURE, SUITE A LA CREATION D'UNE NOUVELLE STRUCTURE-->
      <div class="col-lg-6 top-buffer" *ngIf="liensFusionnesOuRemplacesValides.length > 0">
        <mat-card>
          <mat-card-header>
            <h2>Structures en attente de fermeture suite à la création d'une nouvelle structure</h2>
          </mat-card-header>
          <mat-card-content>
            <p-table [value]="liensFusionnesOuRemplacesValides"
                     [responsive]="true"
                     [resizableColumns]="true"
                     [paginator]="true"
                     [rows]="rowsLiensFusionnesOuRemplacesValides"
                     [showCurrentPageReport]="true"
                     [(first)]="firstLiensFusionnesOuRemplacesValides"
                     currentPageReportTemplate="De {first} à {last} sur {totalRecords} lignes"
                     [rowsPerPageOptions]="rowsPerPageOptionsLiensFusionnesOuRemplacesValides">
              <ng-template pTemplate="header">
                <tr>
                  <th>Structure antécédente</th>
                  <th>Opération</th>
                  <th>Structure nouvelle</th>
                  <th>Date de création</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td><a href appHighlightStructure
                         [structure]="row.structurePorteuse"
                         [routerLink]="'/structure/' +  row.structurePorteuse.structureId">
                    {{row.structurePorteuse | libelleStructure:user}}</a>
                  </td>
                  <td>{{row.typeLien.typeLienLib}}</td>
                  <td><a href appHighlightStructure [structure]="row.structure"
                         [routerLink]="'/structure/' +  row.structure.structureId">{{row.structure | libelleStructure:user}}</a>
                  </td>
                  <td>{{row.dateDebut | date:'dd/MM/yyyy'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>
      </div>

      <!--DERNIERS EVENEMENTS-->
      <div class="col-lg-12 top-buffer"
           *ngIf="user.individu.isGestionnaire || user.individu.isResponsable || user.individu.isAdmin">
        <mat-card>
          <mat-card-header>
            <h2>Derniers événements</h2>
          </mat-card-header>

          <mat-card-content>
            <p class="mt-4 alert-info" *ngIf="lastEvents.length === 0">Vous n'avez actuellement aucun
              événement</p>

            <p-table [value]="lastEvents"
                     [responsive]="true"
                     [resizableColumns]="true"
                     *ngIf="lastEvents.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Structure</th>
                  <th>Structure porteuse</th>
                  <th>Date</th>
                  <th>Type de log</th>
                  <th>Acteur</th>
                  <th>Commentaire</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr [pSelectableRow]="row">
                  <td><a href appHighlightStructure
                         [structure]="row.structure"
                         [routerLink]="'/structure/' +  row.structure.structureId">
                    {{row.structure | libelleStructure:user}}</a>
                  </td>
                  <td>{{row.structurePorteuseLib}}</td>
                  <td>{{row.dateLog | date:'dd/MM/yyyy'}}</td>
                  <td>{{row.typeLog.typeLogLib}}</td>
                  <td>{{row.individu.nom}} {{row.individu.prenom}}</td>
                  <td>{{row.commentaire}}</td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
